import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import { Button } from '@/components/ui/button';
import { ArrowLeft, Lightbulb, GraduationCap, Building, Rocket, Clock, Users, BarChart2, FileCheck, Percent, LineChart, Leaf, TrendingUp, Target, Sprout } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription, CardFooter } from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";

const Incubation = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const academicProjects = [
    {
      id: 1,
      name: "BioRestore",
      institution: "Université de Montpellier",
      type: "Thèse",
      description: "Recherche sur la restauration des écosystèmes aquatiques contaminés par des microplastiques",
      status: "En cours",
      funding: "45 000 €"
    },
    {
      id: 2,
      name: "SolarLeaf",
      institution: "École Polytechnique Paris",
      type: "Projet étudiant",
      description: "Conception de panneaux solaires organiques à base de matériaux biosourcés",
      status: "Financement accordé",
      funding: "28 000 €"
    },
    {
      id: 3,
      name: "ForestSense",
      institution: "AgroParisTech",
      type: "Recherche doctorale",
      description: "Système de surveillance de la santé des forêts utilisant des capteurs IoT biodégradables",
      status: "En validation",
      funding: "52 000 €"
    },
    {
      id: 4,
      name: "CarbonTrack",
      institution: "HEC Montréal",
      type: "Étude de marché",
      description: "Analyse des mécanismes de fixation des prix des crédits carbone dans les pays émergents",
      status: "Financement accordé",
      funding: "20 000 €"
    },
    {
      id: 5,
      name: "BioDiversity Index",
      institution: "Université de Madagascar",
      type: "Recherche doctorale",
      description: "Développement d'une méthodologie de mesure de la biodiversité applicable aux écosystèmes de mangroves",
      status: "En cours",
      funding: "38 000 €"
    }
  ];

  const startupProjects = [
    {
      id: 1,
      name: "RegenFarm",
      focus: "Agriculture régénérative",
      stage: "Seed",
      description: "Plateforme connectant les agriculteurs en transition vers des pratiques régénératives avec des financeurs de crédits carbone",
      investment: "150 000 €",
      progress: 75
    },
    {
      id: 2,
      name: "OceanPlastic",
      focus: "Économie circulaire",
      stage: "Pré-amorçage",
      description: "Transformation des déchets plastiques marins en matériaux de construction durables",
      investment: "75 000 €",
      progress: 40
    },
    {
      id: 3,
      name: "BiomeTech",
      focus: "Biocarburants",
      stage: "Série A",
      description: "Production de biocarburants à partir de déchets agricoles et forestiers avec un bilan carbone négatif",
      investment: "350 000 €",
      progress: 90
    },
    {
      id: 4,
      name: "EcoMaterials",
      focus: "Matériaux durables",
      stage: "Amorçage",
      description: "Développement de matériaux d'isolation thermique biosourcés pour la construction",
      investment: "120 000 €",
      progress: 60
    },
    {
      id: 5,
      name: "BioDiversa",
      focus: "Conservation biodiversité",
      stage: "Seed",
      description: "Marketplace tokenisée pour le financement de projets de protection de la biodiversité et rémunération des communautés locales",
      investment: "190 000 €",
      progress: 65
    },
    {
      id: 6,
      name: "InsectProtein",
      focus: "Alimentation durable",
      stage: "Pré-amorçage",
      description: "Production de protéines alternatives à base d'insectes pour réduire l'empreinte carbone de l'industrie alimentaire",
      investment: "85 000 €",
      progress: 30
    }
  ];

  const strategicSectors = [
    {
      id: 1,
      name: "Biodiversité & Conservation",
      description: "Projets visant à protéger et restaurer les écosystèmes et la biodiversité",
      icon: <Leaf className="h-6 w-6 text-emerald-600" />,
      examples: ["Conservation d'espèces", "Restauration d'habitats", "Surveillance des écosystèmes"],
      color: "emerald"
    },
    {
      id: 2,
      name: "Technologies vertes",
      description: "Solutions technologiques innovantes pour réduire l'impact environnemental",
      icon: <Lightbulb className="h-6 w-6 text-amber-600" />,
      examples: ["Efficacité énergétique", "Énergies renouvelables", "Filtration de l'eau"],
      color: "amber"
    },
    {
      id: 3,
      name: "Économie circulaire",
      description: "Transformation des déchets en ressources et réduction de la consommation",
      icon: <Sprout className="h-6 w-6 text-green-600" />,
      examples: ["Upcycling", "Réduction des déchets", "Bioéconomie"],
      color: "green"
    },
    {
      id: 4,
      name: "Finance climatique",
      description: "Outils financiers pour soutenir la transition écologique et énergétique",
      icon: <TrendingUp className="h-6 w-6 text-blue-600" />,
      examples: ["Nouveaux mécanismes de financement", "Tokenisation d'actifs verts", "Marchés carbone"],
      color: "blue"
    }
  ];

  const biodiversityProjects = [
    {
      id: 1,
      title: "CoralGuard",
      type: "Startup",
      description: "Technologie de surveillance et restauration des récifs coralliens utilisant l'IA et des matériaux biosourcés",
      impact: "Protection de plus de 200 espèces marines par récif restauré",
      potential: "Marché estimé à 500M€ d'ici 2030",
      metrics: [
        { label: "Estimation ROI", value: "25% sur 3 ans" },
        { label: "Création d'emplois", value: "15-20 par projet" }
      ]
    },
    {
      id: 2,
      title: "InsectIndex",
      type: "Recherche",
      description: "Méthodologie standardisée de mesure de la biodiversité des insectes et évaluation de services écosystémiques",
      impact: "Base scientifique pour la tarification des crédits biodiversité",
      potential: "Adoption par les standards internationaux d'ici 2026",
      metrics: [
        { label: "Budget recherche", value: "175 000 €" },
        { label: "Partenariats", value: "6 universités, 2 ONG" }
      ]
    },
    {
      id: 3,
      title: "BioDiversa",
      type: "Plateforme",
      description: "Marketplace pour la création et l'échange de crédits biodiversité certifiés avec un suivi transparent",
      impact: "Protection d'écosystèmes hébergeant jusqu'à 5000 espèces",
      potential: "Contribution de 85M€ à la valorisation Guteco d'ici 5 ans",
      metrics: [
        { label: "Croissance annuelle", value: "40-60%" },
        { label: "Revenue par crédit", value: "15-35€" }
      ]
    },
  ];

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      
      <div className="container mx-auto px-4 py-8">
        <Button 
          variant="outline" 
          className="mb-6 flex items-center gap-2"
          onClick={handleGoBack}
        >
          <ArrowLeft className="h-4 w-4" />
          <span>Retour</span>
        </Button>
        
        <div className="mb-12">
          <h1 className="text-3xl font-bold text-brand-800 mb-4">Programme d'Incubation Guteco</h1>
          <p className="text-gray-600 max-w-3xl">
            Guteco s'engage à soutenir l'innovation dans le domaine environnemental en finançant et accompagnant 
            les projets académiques, recherches scientifiques et startups ayant un impact positif sur l'environnement 
            et le climat.
          </p>
          <div className="flex flex-col md:flex-row gap-4 mt-6">
            <div className="bg-brand-50 p-4 rounded-lg border border-brand-100 flex items-start gap-2">
              <Target className="h-5 w-5 text-brand-600 mt-0.5 flex-shrink-0" />
              <div>
                <p className="font-medium text-brand-700">Notre objectif à 5 ans</p>
                <p className="text-brand-600">Soutenir 25+ projets innovants contribuant à notre croissance ambitieuse de 262M€ de valorisation</p>
              </div>
            </div>
            <div className="bg-emerald-50 p-4 rounded-lg border border-emerald-100 flex items-start gap-2">
              <Leaf className="h-5 w-5 text-emerald-600 mt-0.5 flex-shrink-0" />
              <div>
                <p className="font-medium text-emerald-700">Focus biodiversité</p>
                <p className="text-emerald-600">Au moins 30% de nos projets soutenus concernent directement la préservation et mesure de la biodiversité</p>
              </div>
            </div>
          </div>
        </div>
        
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-12">
          <Card className="bg-brand-50 border-brand-100 hover:shadow-md transition-shadow">
            <CardHeader>
              <div className="w-12 h-12 bg-brand-100 rounded-full flex items-center justify-center mb-4">
                <GraduationCap className="h-6 w-6 text-brand-700" />
              </div>
              <CardTitle className="text-brand-800">Projets Académiques</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-gray-600">
                Financement de thèses, recherches doctorales et projets étudiants innovants 
                issus des universités et grandes écoles partenaires.
              </p>
            </CardContent>
          </Card>
          
          <Card className="bg-amber-50 border-amber-100 hover:shadow-md transition-shadow">
            <CardHeader>
              <div className="w-12 h-12 bg-amber-100 rounded-full flex items-center justify-center mb-4">
                <Lightbulb className="h-6 w-6 text-amber-700" />
              </div>
              <CardTitle className="text-amber-800">Recherche & Développement</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-gray-600">
                Soutien aux laboratoires de recherche travaillant sur des solutions 
                innovantes pour la capture de carbone et la réduction des émissions.
              </p>
            </CardContent>
          </Card>
          
          <Card className="bg-emerald-50 border-emerald-100 hover:shadow-md transition-shadow">
            <CardHeader>
              <div className="w-12 h-12 bg-emerald-100 rounded-full flex items-center justify-center mb-4">
                <Building className="h-6 w-6 text-emerald-700" />
              </div>
              <CardTitle className="text-emerald-800">Incubation Startups</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-gray-600">
                Accompagnement complet pour les startups vertes, de l'idée au marché, 
                avec financement, mentorat et accès à notre réseau d'experts.
              </p>
            </CardContent>
          </Card>
        </div>

        <div className="mb-12 bg-gray-50 p-6 rounded-lg border border-gray-100">
          <div className="mb-6">
            <h2 className="text-2xl font-bold text-brand-800 mb-4">Notre Modèle Économique</h2>
            <p className="text-gray-600 max-w-3xl mb-6">
              En tant que plateforme de mise en relation entre investisseurs et porteurs de projets, Guteco génère des revenus de manière éthique et transparente:
            </p>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <Card className="border-green-100">
                <CardHeader className="pb-2">
                  <div className="flex items-center gap-3">
                    <div className="w-10 h-10 bg-green-100 rounded-full flex items-center justify-center">
                      <Percent className="h-5 w-5 text-green-700" />
                    </div>
                    <CardTitle className="text-lg text-green-800">Commission sur les financements</CardTitle>
                  </div>
                </CardHeader>
                <CardContent>
                  <p className="text-gray-600">
                    Guteco prélève une commission de 3% sur chaque projet financé via notre plateforme. Cette commission peut varier selon le type et la taille du projet, permettant de maintenir notre infrastructure et d'étendre nos services.
                  </p>
                </CardContent>
              </Card>
              
              <Card className="border-blue-100">
                <CardHeader className="pb-2">
                  <div className="flex items-center gap-3">
                    <div className="w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center">
                      <LineChart className="h-5 w-5 text-blue-700" />
                    </div>
                    <CardTitle className="text-lg text-blue-800">Services de suivi et représentation</CardTitle>
                  </div>
                </CardHeader>
                <CardContent>
                  <p className="text-gray-600">
                    Nous proposons des services de représentation des investisseurs pour le suivi de l'évolution des projets. Cette prestation garantit la transparence, l'utilisation optimale des fonds et la conformité aux objectifs environnementaux fixés.
                  </p>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
        
        <div className="mb-12">
          <div className="mb-8">
            <h2 className="text-2xl font-bold text-brand-800 mb-4">Notre Programme d'Accompagnement</h2>
            <p className="text-gray-600 max-w-3xl">
              Guteco offre bien plus qu'un simple financement. Notre écosystème complet accompagne les projets à chaque étape.
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            <Card className="hover:shadow-md transition-shadow">
              <CardHeader className="pb-2">
                <div className="w-12 h-12 bg-brand-50 rounded-full flex items-center justify-center mb-4">
                  <Rocket className="h-6 w-6 text-brand-600" />
                </div>
                <CardTitle className="text-lg">Financement</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-gray-600 text-sm">
                  Accès à des subventions, investissements en capital et financement par crédits carbone selon les besoins du projet.
                </p>
              </CardContent>
            </Card>
            
            <Card className="hover:shadow-md transition-shadow">
              <CardHeader className="pb-2">
                <div className="w-12 h-12 bg-brand-50 rounded-full flex items-center justify-center mb-4">
                  <Users className="h-6 w-6 text-brand-600" />
                </div>
                <CardTitle className="text-lg">Mentorat</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-gray-600 text-sm">
                  Accompagnement personnalisé par des experts du secteur environnemental, de la finance verte et de l'entrepreneuriat.
                </p>
              </CardContent>
            </Card>
            
            <Card className="hover:shadow-md transition-shadow">
              <CardHeader className="pb-2">
                <div className="w-12 h-12 bg-brand-50 rounded-full flex items-center justify-center mb-4">
                  <BarChart2 className="h-6 w-6 text-brand-600" />
                </div>
                <CardTitle className="text-lg">Accès au Marché</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-gray-600 text-sm">
                  Intégration à l'écosystème Guteco et mise en relation avec notre réseau d'entreprises et investisseurs.
                </p>
              </CardContent>
            </Card>
            
            <Card className="hover:shadow-md transition-shadow">
              <CardHeader className="pb-2">
                <div className="w-12 h-12 bg-brand-50 rounded-full flex items-center justify-center mb-4">
                  <Clock className="h-6 w-6 text-brand-600" />
                </div>
                <CardTitle className="text-lg">Suivi Long Terme</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-gray-600 text-sm">
                  Accompagnement continu pour maximiser l'impact environnemental et assurer la viabilité économique des projets.
                </p>
              </CardContent>
            </Card>
          </div>
        </div>
        
        <div className="mb-12">
          <h2 className="text-2xl font-bold text-brand-800 mb-6">Projets Soutenus</h2>
          
          <Tabs defaultValue="academic" className="w-full">
            <TabsList className="grid grid-cols-2 mb-6">
              <TabsTrigger value="academic" className="text-base">Projets Académiques</TabsTrigger>
              <TabsTrigger value="startup" className="text-base">Startups</TabsTrigger>
            </TabsList>
            
            <TabsContent value="academic" className="mt-0">
              <Card>
                <CardContent className="pt-6">
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Projet</TableHead>
                        <TableHead>Institution</TableHead>
                        <TableHead>Type</TableHead>
                        <TableHead>Description</TableHead>
                        <TableHead>Statut</TableHead>
                        <TableHead>Financement</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {academicProjects.map((project) => (
                        <TableRow key={project.id}>
                          <TableCell className="font-medium">{project.name}</TableCell>
                          <TableCell>{project.institution}</TableCell>
                          <TableCell>{project.type}</TableCell>
                          <TableCell className="max-w-md">{project.description}</TableCell>
                          <TableCell>
                            <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                              project.status === 'En cours' ? 'bg-blue-100 text-blue-800' : 
                              project.status === 'Financement accordé' ? 'bg-green-100 text-green-800' :
                              'bg-amber-100 text-amber-800'
                            }`}>
                              {project.status}
                            </span>
                          </TableCell>
                          <TableCell>{project.funding}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </TabsContent>
            
            <TabsContent value="startup" className="mt-0">
              <Card>
                <CardContent className="pt-6">
                  <div className="grid gap-6">
                    {startupProjects.map((project) => (
                      <Card key={project.id} className="border-gray-200 overflow-hidden">
                        <div className="flex flex-col md:flex-row">
                          <div className="md:w-2/3 p-6">
                            <div className="flex justify-between items-start mb-2">
                              <h3 className="text-xl font-bold text-brand-800">{project.name}</h3>
                              <span className="bg-brand-100 text-brand-800 px-3 py-1 rounded-full text-xs font-medium">
                                {project.stage}
                              </span>
                            </div>
                            <p className="text-sm text-gray-500 mb-1">{project.focus}</p>
                            <p className="text-gray-700 mb-4">{project.description}</p>
                            <div className="flex items-center gap-2">
                              <FileCheck className="h-5 w-5 text-brand-600" />
                              <span className="text-sm font-medium">Investissement: {project.investment}</span>
                            </div>
                          </div>
                          <div className="md:w-1/3 bg-gray-50 p-6 flex flex-col justify-center">
                            <p className="text-center text-sm text-gray-500 mb-2">Progression du projet</p>
                            <div className="h-4 bg-gray-200 rounded-full overflow-hidden mb-2">
                              <div 
                                className="h-full bg-brand-600 rounded-full" 
                                style={{ width: `${project.progress}%` }}
                              ></div>
                            </div>
                            <p className="text-center font-medium text-brand-700">{project.progress}%</p>
                          </div>
                        </div>
                      </Card>
                    ))}
                  </div>
                </CardContent>
              </Card>
            </TabsContent>
          </Tabs>
        </div>
        
        <div className="mb-12">
          <div className="mb-8">
            <h2 className="text-2xl font-bold text-brand-800 mb-4">Secteurs Stratégiques d'Innovation</h2>
            <p className="text-gray-600 max-w-3xl">
              Nous ciblons quatre domaines clés qui soutiennent notre ambition de croissance et contribuent
              à notre objectif de valorisation de 262M€ en 5 ans.
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
            {strategicSectors.map((sector) => (
              <Card key={sector.id} className={`border-${sector.color}-100`}>
                <CardHeader className={`bg-${sector.color}-50 pb-4`}>
                  <div className="flex items-center gap-3">
                    <div className={`w-10 h-10 bg-${sector.color}-100 rounded-full flex items-center justify-center`}>
                      {sector.icon}
                    </div>
                    <CardTitle className={`text-${sector.color}-800`}>{sector.name}</CardTitle>
                  </div>
                </CardHeader>
                <CardContent className="pt-4">
                  <p className="text-gray-700 mb-4">{sector.description}</p>
                  <div className="flex flex-wrap gap-2">
                    {sector.examples.map((example, i) => (
                      <Badge key={i} variant="outline" className={`bg-${sector.color}-50 text-${sector.color}-700 border-${sector.color}-200`}>
                        {example}
                      </Badge>
                    ))}
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
        
        <div className="mb-12 bg-emerald-50 p-6 rounded-xl border border-emerald-100">
          <div className="mb-8">
            <h2 className="text-2xl font-bold text-emerald-800 mb-4 flex items-center gap-2">
              <Leaf className="h-6 w-6" />
              Projets Biodiversité à Fort Potentiel
            </h2>
            <p className="text-emerald-700">
              Ces projets innovants dédiés à la biodiversité représentent une composante essentielle de notre stratégie 
              de croissance et contribueront significativement à notre objectif de valorisation de 262M€ d'ici 5 ans.
            </p>
          </div>
          
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            {biodiversityProjects.map((project) => (
              <Card key={project.id} className="border-emerald-200 overflow-hidden">
                <CardHeader className="bg-white border-b border-emerald-100 pb-4">
                  <div className="flex justify-between items-center mb-2">
                    <CardTitle className="text-emerald-800">{project.title}</CardTitle>
                    <Badge variant="outline" className="bg-emerald-50 text-emerald-700">
                      {project.type}
                    </Badge>
                  </div>
                </CardHeader>
                <CardContent className="pt-4">
                  <p className="text-gray-700 mb-4">{project.description}</p>
                  
                  <div className="mb-4">
                    <h4 className="text-sm font-semibold text-emerald-700 mb-2">Impact environnemental</h4>
                    <p className="text-sm text-gray-600">{project.impact}</p>
                  </div>
                  
                  <div className="mb-4">
                    <h4 className="text-sm font-semibold text-emerald-700 mb-2">Potentiel économique</h4>
                    <p className="text-sm text-gray-600">{project.potential}</p>
                  </div>
                  
                  <div className="grid grid-cols-2 gap-2 mt-4">
                    {project.metrics.map((metric, idx) => (
                      <div key={idx} className="bg-emerald-50 p-2 rounded">
                        <p className="text-xs text-emerald-600">{metric.label}</p>
                        <p className="font-medium text-emerald-800">{metric.value}</p>
                      </div>
                    ))}
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
        
        <div className="mb-12 bg-blue-50 p-6 rounded-lg border border-blue-100">
          <div className="flex flex-col md:flex-row gap-6 items-center">
            <div className="md:w-1/2">
              <h2 className="text-2xl font-bold text-blue-800 mb-3">Notre contribution à la valorisation cible</h2>
              <p className="text-blue-700 mb-4">
                Le programme d'incubation joue un rôle clé dans notre stratégie de croissance pour atteindre 262M€ de valorisation en 5 ans.
              </p>
              <ul className="space-y-3">
                <li className="flex items-start gap-2">
                  <TrendingUp className="h-5 w-5 text-blue-600 mt-0.5 flex-shrink-0" />
                  <span className="text-gray-700">Les startups incubées contribueront directement à hauteur de <span className="font-semibold">35M€</span> à notre valorisation</span>
                </li>
                <li className="flex items-start gap-2">
                  <Leaf className="h-5 w-5 text-emerald-600 mt-0.5 flex-shrink-0" />
                  <span className="text-gray-700">Les projets biodiversité généreront <span className="font-semibold">45-60M€</span> de revenus cumulés sur 5 ans</span>
                </li>
                <li className="flex items-start gap-2">
                  <Rocket className="h-5 w-5 text-amber-600 mt-0.5 flex-shrink-0" />
                  <span className="text-gray-700">Les innovations technologiques issues du programme apporteront un avantage concurrentiel valorisé à <span className="font-semibold">25M€</span></span>
                </li>
              </ul>
            </div>
            <div className="md:w-1/2 bg-white p-5 rounded-lg shadow-sm">
              <h3 className="text-lg font-semibold text-gray-800 mb-4">Allocation des fonds d'incubation</h3>
              <div className="space-y-4">
                <div className="space-y-2">
                  <div className="flex justify-between text-sm">
                    <span>Projets biodiversité</span>
                    <span className="font-medium">30%</span>
                  </div>
                  <div className="h-2 bg-gray-100 rounded-full">
                    <div className="h-2 bg-emerald-500 rounded-full" style={{ width: '30%' }}></div>
                  </div>
                </div>
                <div className="space-y-2">
                  <div className="flex justify-between text-sm">
                    <span>Technologies vertes</span>
                    <span className="font-medium">25%</span>
                  </div>
                  <div className="h-2 bg-gray-100 rounded-full">
                    <div className="h-2 bg-blue-500 rounded-full" style={{ width: '25%' }}></div>
                  </div>
                </div>
                <div className="space-y-2">
                  <div className="flex justify-between text-sm">
                    <span>Économie circulaire</span>
                    <span className="font-medium">20%</span>
                  </div>
                  <div className="h-2 bg-gray-100 rounded-full">
                    <div className="h-2 bg-amber-500 rounded-full" style={{ width: '20%' }}></div>
                  </div>
                </div>
                <div className="space-y-2">
                  <div className="flex justify-between text-sm">
                    <span>Finance climatique</span>
                    <span className="font-medium">25%</span>
                  </div>
                  <div className="h-2 bg-gray-100 rounded-full">
                    <div className="h-2 bg-purple-500 rounded-full" style={{ width: '25%' }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="flex justify-center">
          <Button className="bg-brand-600 hover:bg-brand-700 px-8 py-3 text-white">
            Proposer un projet
          </Button>
        </div>
      </div>
      
      <Footer />
    </div>
  );
};

export default Incubation;
