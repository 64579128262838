
import { fr } from './fr';
import { en } from './en';
import { es } from './es';
import { it } from './it';
import { sv } from './sv';

export const translations = {
  fr,
  en,
  es,
  it,
  sv
};
