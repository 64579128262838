
import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip, TooltipProps } from 'recharts';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';

type AllocationData = {
  name: string;
  value: number;
  color: string;
};

interface TokenAllocationChartProps {
  title: string;
  description?: string;
  data: AllocationData[];
  className?: string;
}

const TokenAllocationChart = ({ title, description, data, className }: TokenAllocationChartProps) => {
  const RADIAN = Math.PI / 180;
  
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, name, payload }) => {
    // Utiliser un rayon plus important pour les étiquettes
    const radius = innerRadius + (outerRadius - innerRadius) * 1.8;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    
    // Récupérer la couleur du segment
    const color = payload.color;
    
    // Identifier les étiquettes potentiellement problématiques
    const isSmallSegment = percent < 0.1; // Segments de moins de 10%
    const isFraisTransaction = name.toLowerCase().includes("frais") || name.toLowerCase().includes("transaction");
    const isBiodiversite = name.toLowerCase().includes("biodiversité");
    const isMangroves = name.toLowerCase().includes("mangroves");
    const isCredits = name.toLowerCase().includes("crédit");
    
    // Si c'est un petit segment ou une étiquette problématique, on applique un style spécial
    if (isSmallSegment || isFraisTransaction || isBiodiversite || isMangroves || isCredits) {
      return (
        <g>
          <line 
            x1={cx + (outerRadius * 0.95) * Math.cos(-midAngle * RADIAN)} 
            y1={cy + (outerRadius * 0.95) * Math.sin(-midAngle * RADIAN)} 
            x2={x} 
            y2={y} 
            stroke={color} 
            strokeWidth={2}
          />
          <circle cx={x} cy={y} r={3} fill={color} />
          <text 
            x={x + (x > cx ? 5 : -5)} 
            y={y} 
            fill={color} 
            textAnchor={x > cx ? 'start' : 'end'} 
            dominantBaseline="central"
            fontSize={13}
            fontWeight="bold"
            stroke="white"
            strokeWidth={4}
            paintOrder="stroke"
            className="font-bold"
          >
            {name}
          </text>
          <text 
            x={x + (x > cx ? 5 : -5)} 
            y={y + 15} 
            fill={color} 
            textAnchor={x > cx ? 'start' : 'end'} 
            dominantBaseline="central"
            fontSize={13}
            fontWeight="bold"
            stroke="white"
            strokeWidth={3}
            paintOrder="stroke"
            className="font-bold"
          >
            {`${(percent * 100).toFixed(0)}%`}
          </text>
        </g>
      );
    }

    // Style standard pour les segments plus grands
    return (
      <g>
        <text 
          x={x} 
          y={y} 
          fill={color} 
          textAnchor={x > cx ? 'start' : 'end'} 
          dominantBaseline="central"
          fontSize={14}
          fontWeight="bold"
          stroke="white"
          strokeWidth={3}
          paintOrder="stroke"
          className="font-bold"
        >
          {`${name}: ${(percent * 100).toFixed(0)}%`}
        </text>
      </g>
    );
  };

  // Composant tooltip personnalisé
  const CustomTooltip = ({ active, payload }: TooltipProps<number, string>) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-3 border border-gray-200 rounded-md shadow-lg">
          <p className="font-semibold text-gray-800">{`${payload[0].name}`}</p>
          <p className="text-lg font-bold" style={{ color: payload[0].payload?.color }}>
            {`${payload[0].value}%`}
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <Card className={className}>
      <CardHeader className="bg-brand-50">
        <CardTitle className="text-xl text-brand-800">{title}</CardTitle>
      </CardHeader>
      <CardContent className="pt-6">
        <div className="h-[500px] flex items-center justify-center">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={true}
                label={renderCustomizedLabel}
                outerRadius={150}
                innerRadius={75}
                paddingAngle={5}
                fill="#8884d8"
                dataKey="value"
                startAngle={90} 
                endAngle={450}
                stroke="#fff"
                strokeWidth={2}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
              <Legend 
                layout="vertical" 
                verticalAlign="middle" 
                align="right"
                formatter={(value, entry, index) => (
                  <span 
                    className="text-sm font-medium"
                    style={{ color: data[index].color, fontSize: '14px' }}
                  >
                    {value}
                  </span>
                )}
                wrapperStyle={{ 
                  paddingLeft: '20px', 
                  fontSize: '14px',
                  fontWeight: 'bold'
                }}
              />
            </PieChart>
          </ResponsiveContainer>
        </div>
        
        {description && (
          <div className="mt-6 p-4 bg-blue-50 rounded-lg text-sm">
            <p className="text-gray-700">{description}</p>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default TokenAllocationChart;
