
import React, { useState } from 'react';
import { Layout } from '@/components/Layout';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Progress } from '@/components/ui/progress';
import { Button } from '@/components/ui/button';
import { BarChart, FileText, Users, Briefcase, MonitorSmartphone, Building2, Globe, ArrowRight, 
         Trees, DollarSign, PieChart, AreaChart } from 'lucide-react';

const InvestmentMemorandum = () => {
  const [activeTab, setActiveTab] = useState('executive');

  const handleTabClick = (tabId: string) => {
    setActiveTab(tabId);
  };

  return (
    <Layout>
      <section className="py-12 bg-white">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto mb-12">
            <h1 className="text-4xl md:text-5xl font-bold text-center text-brand-800 mb-6">
              Mémorandum d'Investissement
            </h1>
            <p className="text-xl text-center text-gray-600 mb-8">
              Document confidentiel - Guteco SAS - Juin 2024
            </p>
            <div className="border-l-4 border-brand-500 pl-4 bg-gray-50 p-4 rounded my-8">
              <p className="text-sm text-gray-600 italic">
                Ce mémorandum d'investissement est fourni à titre d'information uniquement. 
                Il ne constitue pas une offre de vente ni une sollicitation d'achat de titres financiers. 
                Les projections financières et autres données prospectives sont basées sur des hypothèses 
                qui peuvent ne pas se réaliser.
              </p>
            </div>
          </div>

          <Tabs defaultValue="executive" className="max-w-5xl mx-auto" 
            value={activeTab}
            onValueChange={(value) => setActiveTab(value)}>
            <div className="overflow-x-auto">
              <TabsList className="min-w-max border rounded-lg p-1 mb-8">
                <TabsTrigger 
                  id="executive" 
                  value="executive" 
                  className="text-sm px-3 py-2 flex items-center gap-2">
                  <FileText className="h-4 w-4" />
                  Résumé Exécutif
                </TabsTrigger>
                <TabsTrigger 
                  id="company" 
                  value="company" 
                  className="text-sm px-3 py-2 flex items-center gap-2">
                  <Building2 className="h-4 w-4" />
                  Société
                </TabsTrigger>
                <TabsTrigger 
                  id="market" 
                  value="market" 
                  className="text-sm px-3 py-2 flex items-center gap-2">
                  <Globe className="h-4 w-4" />
                  Marché
                </TabsTrigger>
                <TabsTrigger 
                  id="solution" 
                  value="solution" 
                  className="text-sm px-3 py-2 flex items-center gap-2">
                  <MonitorSmartphone className="h-4 w-4" />
                  Solution
                </TabsTrigger>
                <TabsTrigger 
                  id="team" 
                  value="team" 
                  className="text-sm px-3 py-2 flex items-center gap-2">
                  <Users className="h-4 w-4" />
                  Équipe
                </TabsTrigger>
                <TabsTrigger 
                  id="business" 
                  value="business" 
                  className="text-sm px-3 py-2 flex items-center gap-2">
                  <Briefcase className="h-4 w-4" />
                  Business Model
                </TabsTrigger>
                <TabsTrigger 
                  id="financier" 
                  value="financier" 
                  className="text-sm px-3 py-2 flex items-center gap-2">
                  <DollarSign className="h-4 w-4" />
                  Financier
                </TabsTrigger>
                <TabsTrigger 
                  id="token" 
                  value="token" 
                  className="text-sm px-3 py-2 flex items-center gap-2">
                  <PieChart className="h-4 w-4" />
                  Tokenomics
                </TabsTrigger>
              </TabsList>
            </div>

            <div className="bg-white rounded-lg p-6 shadow-sm border">
              <div className="mb-8">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-xl font-semibold text-brand-800">
                    {activeTab === 'executive' && 'Résumé Exécutif'}
                    {activeTab === 'company' && 'À Propos de Guteco'}
                    {activeTab === 'market' && 'Analyse de Marché'}
                    {activeTab === 'solution' && 'Notre Solution Technologique'}
                    {activeTab === 'team' && 'Équipe et Gouvernance'}
                    {activeTab === 'business' && 'Modèle d\'Affaires'}
                    {activeTab === 'financier' && 'Projections Financières'}
                    {activeTab === 'token' && 'Tokenomics et Structure de l\'Offre'}
                  </h2>
                  
                  <div className="flex space-x-2">
                    {activeTab !== 'executive' && (
                      <Button 
                        variant="outline" 
                        size="sm"
                        onClick={() => {
                          const currentIndex = ['executive', 'company', 'market', 'solution', 'team', 'business', 'financier', 'token'].indexOf(activeTab);
                          if (currentIndex > 0) {
                            handleTabClick(['executive', 'company', 'market', 'solution', 'team', 'business', 'financier', 'token'][currentIndex - 1]);
                          }
                        }}
                      >
                        Précédent
                      </Button>
                    )}
                    
                    {activeTab !== 'token' && (
                      <Button 
                        variant="default" 
                        size="sm"
                        onClick={() => {
                          const currentIndex = ['executive', 'company', 'market', 'solution', 'team', 'business', 'financier', 'token'].indexOf(activeTab);
                          if (currentIndex < 7) {
                            handleTabClick(['executive', 'company', 'market', 'solution', 'team', 'business', 'financier', 'token'][currentIndex + 1]);
                          }
                        }}
                      >
                        Suivant
                      </Button>
                    )}
                  </div>
                </div>
                
                <Progress value={((['executive', 'company', 'market', 'solution', 'team', 'business', 'financier', 'token'].indexOf(activeTab) + 1) / 8) * 100} className="h-1 mb-6" />
              </div>

              <TabsContent value="executive" className="space-y-6">
                <Card>
                  <CardHeader>
                    <CardTitle>Guteco : Tokenisation des Crédits Carbone</CardTitle>
                    <CardDescription>
                      Plateforme Web3 révolutionnant le marché des crédits carbone et actifs environnementaux
                    </CardDescription>
                  </CardHeader>
                  <CardContent className="space-y-4">
                    <div className="space-y-2">
                      <h3 className="font-semibold text-lg">Notre Mission</h3>
                      <p>Guteco transforme le marché des crédits carbone en créant une place de marché transparente et efficiente, 
                      en tokenisant ces actifs environnementaux pour les rendre plus accessibles et liquides, tout en finançant des projets à impact positif.</p>
                    </div>
                    
                    <div className="bg-brand-50 p-4 rounded-lg border border-brand-100">
                      <h3 className="font-semibold text-lg mb-2">Points Clés</h3>
                      <ul className="space-y-2">
                        <li className="flex items-start gap-2">
                          <ArrowRight className="h-4 w-4 text-brand-500 mt-1 shrink-0" />
                          <span><span className="font-medium">Valorisation pre-money :</span> 90 millions €</span>
                        </li>
                        <li className="flex items-start gap-2">
                          <ArrowRight className="h-4 w-4 text-brand-500 mt-1 shrink-0" />
                          <span><span className="font-medium">Levée de fonds :</span> 12 millions € pour 11,76% d'équité</span>
                        </li>
                        <li className="flex items-start gap-2">
                          <ArrowRight className="h-4 w-4 text-brand-500 mt-1 shrink-0" />
                          <span><span className="font-medium">Chiffre d'affaires prévisionnel total :</span> 8,4 millions € sur 2 ans</span>
                        </li>
                        <li className="flex items-start gap-2">
                          <ArrowRight className="h-4 w-4 text-brand-500 mt-1 shrink-0" />
                          <span><span className="font-medium">Avantage compétitif :</span> Technologie blockchain propriétaire + expertise environnementale</span>
                        </li>
                      </ul>
                    </div>
                    
                    <div className="space-y-2">
                      <h3 className="font-semibold text-lg">Opportunité d'Investissement</h3>
                      <p>Guteco représente une opportunité unique d'investir dans un acteur disruptif du marché des crédits carbone, 
                      un secteur en croissance exponentielle qui devrait atteindre 100 milliards $ d'ici 2030. Notre technologie 
                      blockchain, notre expertise en finance carbone et notre modèle de revenus diversifiés offrent un potentiel 
                      de croissance significatif et de retours attractifs pour les investisseurs.</p>
                    </div>
                    
                    <Button onClick={() => handleTabClick('company')} className="w-full mt-4">
                      Découvrir Guteco en détail
                    </Button>
                  </CardContent>
                </Card>
              </TabsContent>

              <TabsContent value="company" className="space-y-6">
                <Card>
                  <CardHeader>
                    <CardTitle>À Propos de Guteco</CardTitle>
                    <CardDescription>
                      Une entreprise innovante à l'intersection de la finance, des technologies blockchain et de l'environnement
                    </CardDescription>
                  </CardHeader>
                  <CardContent className="space-y-4">
                    <div className="space-y-2">
                      <h3 className="font-semibold text-lg">Notre Histoire</h3>
                      <p>Fondée en 2024 par une équipe d'experts en finance, technologie blockchain et développement durable, 
                      Guteco est née de la conviction que les marchés du carbone pouvaient être rendus plus efficaces, 
                      transparents et accessibles grâce aux technologies Web3. Après une R&D intensive, 
                      nous avons développé une plateforme propriétaire qui révolutionne la manière dont les crédits 
                      carbone sont émis, échangés et utilisés.</p>
                    </div>
                    
                    <div className="space-y-2">
                      <h3 className="font-semibold text-lg">Notre Vision</h3>
                      <p>Guteco aspire à devenir la principale infrastructure à l'internationale pour la tokenisation et 
                      l'échange d'actifs environnementaux, facilitant la transition vers une économie bas-carbone 
                      et la préservation de la biodiversité. Nous voulons démocratiser l'accès aux marchés 
                      environnementaux et créer un écosystème où chaque tonne de CO₂ évitée ou séquestrée peut 
                      être valorisée de manière juste et efficiente.</p>
                    </div>
                    
                    <div className="bg-brand-50 p-4 rounded-lg border border-brand-100">
                      <h3 className="font-semibold text-lg mb-2">Informations Clés</h3>
                      <ul className="space-y-2">
                        <li className="flex items-start gap-2">
                          <ArrowRight className="h-4 w-4 text-brand-500 mt-1 shrink-0" />
                          <span><span className="font-medium">Date de création :</span> Janvier 2024</span>
                        </li>
                        <li className="flex items-start gap-2">
                          <ArrowRight className="h-4 w-4 text-brand-500 mt-1 shrink-0" />
                          <span><span className="font-medium">Siège social :</span> Paris, France</span>
                        </li>
                        <li className="flex items-start gap-2">
                          <ArrowRight className="h-4 w-4 text-brand-500 mt-1 shrink-0" />
                          <span><span className="font-medium">Effectif actuel :</span> 7 collaborateurs</span>
                        </li>
                      </ul>
                    </div>
                    
                    <Button onClick={() => handleTabClick('market')} className="w-full mt-4">
                      Découvrir notre marché
                    </Button>
                  </CardContent>
                </Card>
              </TabsContent>

              <TabsContent value="market" className="space-y-6">
                <Card>
                  <CardHeader>
                    <CardTitle>Analyse du Marché des Crédits Carbone</CardTitle>
                    <CardDescription>
                      Un marché en pleine expansion offrant des opportunités considérables
                    </CardDescription>
                  </CardHeader>
                  <CardContent className="space-y-4">
                    <div className="space-y-2">
                      <h3 className="font-semibold text-lg">Taille et Croissance du Marché</h3>
                      <p>Le marché mondial des crédits carbone atteint actuellement 100 milliards de dollars et devrait 
                      croître à un TCAC de 30% pour atteindre plus de 500 milliards de dollars d'ici 2030, selon les 
                      analyses de BloombergNEF et McKinsey. Cette croissance est alimentée par l'augmentation des 
                      engagements Net Zéro des entreprises et le renforcement des réglementations environnementales.</p>
                    </div>
                    
                    <div className="space-y-2">
                      <h3 className="font-semibold text-lg">Problématiques Actuelles</h3>
                      <ul className="space-y-2">
                        <li className="flex items-start gap-2">
                          <ArrowRight className="h-4 w-4 text-brand-500 mt-1 shrink-0" />
                          <span><span className="font-medium">Manque de transparence :</span> Difficultés à tracer l'origine et l'impact réel des crédits carbone</span>
                        </li>
                        <li className="flex items-start gap-2">
                          <ArrowRight className="h-4 w-4 text-brand-500 mt-1 shrink-0" />
                          <span><span className="font-medium">Illiquidité :</span> Marchés fragmentés avec des volumes d'échange limités</span>
                        </li>
                        <li className="flex items-start gap-2">
                          <ArrowRight className="h-4 w-4 text-brand-500 mt-1 shrink-0" />
                          <span><span className="font-medium">Coûts de transaction élevés :</span> Intermédiaires multiples prélevant des commissions importantes</span>
                        </li>
                        <li className="flex items-start gap-2">
                          <ArrowRight className="h-4 w-4 text-brand-500 mt-1 shrink-0" />
                          <span><span className="font-medium">Barrières à l'entrée :</span> Accès limité pour les petits acteurs et particuliers</span>
                        </li>
                      </ul>
                    </div>
                    
                    <div className="bg-brand-50 p-4 rounded-lg border border-brand-100">
                      <h3 className="font-semibold text-lg mb-2">Tendances Clés</h3>
                      <ul className="space-y-2">
                        <li className="flex items-start gap-2">
                          <ArrowRight className="h-4 w-4 text-brand-500 mt-1 shrink-0" />
                          <span>Forte augmentation de la demande de crédits carbone de haute qualité</span>
                        </li>
                        <li className="flex items-start gap-2">
                          <ArrowRight className="h-4 w-4 text-brand-500 mt-1 shrink-0" />
                          <span>Émergence des marchés blockchain pour les actifs environnementaux</span>
                        </li>
                        <li className="flex items-start gap-2">
                          <ArrowRight className="h-4 w-4 text-brand-500 mt-1 shrink-0" />
                          <span>Intérêt croissant des investisseurs institutionnels pour cette classe d'actifs</span>
                        </li>
                        <li className="flex items-start gap-2">
                          <ArrowRight className="h-4 w-4 text-brand-500 mt-1 shrink-0" />
                          <span>Harmonisation progressive des standards et réglementations internationales</span>
                        </li>
                      </ul>
                    </div>
                    
                    <Button onClick={() => handleTabClick('solution')} className="w-full mt-4">
                      Découvrir notre solution
                    </Button>
                  </CardContent>
                </Card>
              </TabsContent>
              
              <TabsContent value="solution" className="space-y-6">
                <Card>
                  <CardHeader>
                    <CardTitle>Notre Solution Technologique</CardTitle>
                    <CardDescription>
                      Une plateforme Web3 innovante pour révolutionner le marché des crédits carbone
                    </CardDescription>
                  </CardHeader>
                  <CardContent className="space-y-4">
                    <div className="space-y-2">
                      <h3 className="font-semibold text-lg">Architecture Blockchain</h3>
                      <p>Notre plateforme s'appuie sur une architecture blockchain de dernière génération, 
                      combinant la sécurité d'Ethereum avec des solutions Layer 2 pour garantir scalabilité 
                      et coûts de transaction réduits. Cette infrastructure assure la traçabilité complète 
                      et l'immutabilité des données à chaque étape du cycle de vie des crédits carbone.</p>
                    </div>
                    
                    <div className="space-y-2">
                      <h3 className="font-semibold text-lg">Tokenisation Innovante</h3>
                      <p>Guteco transforme les crédits carbone en tokens numériques (NFTs et tokens fongibles) 
                      représentant avec précision leurs caractéristiques uniques : type de projet, localisation, 
                      impact environnemental, certification, etc. Cette tokenisation permet la fractionnalisation 
                      des crédits et facilite leur échange et leur intégration dans des protocoles DeFi.</p>
                    </div>
                    
                    <div className="space-y-2">
                      <h3 className="font-semibold text-lg">Vérification et Certification</h3>
                      <p>Notre système intègre des oracles décentralisés et des mécanismes de consensus innovants 
                      pour la vérification des données environnementales. Nous collaborons avec des organismes 
                      certifiés pour auditer les projets et valider les réductions d'émissions, garantissant 
                      ainsi l'intégrité des crédits carbone tokenisés.</p>
                    </div>
                    
                    <div className="bg-brand-50 p-4 rounded-lg border border-brand-100">
                      <h3 className="font-semibold text-lg mb-2">Avantages Technologiques</h3>
                      <ul className="space-y-2">
                        <li className="flex items-start gap-2">
                          <ArrowRight className="h-4 w-4 text-brand-500 mt-1 shrink-0" />
                          <span><span className="font-medium">Transparence totale :</span> Registre immuable accessible à tous</span>
                        </li>
                        <li className="flex items-start gap-2">
                          <ArrowRight className="h-4 w-4 text-brand-500 mt-1 shrink-0" />
                          <span><span className="font-medium">Liquidité accrue :</span> Tokenisation et fractionnalisation des actifs</span>
                        </li>
                        <li className="flex items-start gap-2">
                          <ArrowRight className="h-4 w-4 text-brand-500 mt-1 shrink-0" />
                          <span><span className="font-medium">Coûts réduits :</span> Désintermédiation et automatisation via smart contracts</span>
                        </li>
                        <li className="flex items-start gap-2">
                          <ArrowRight className="h-4 w-4 text-brand-500 mt-1 shrink-0" />
                          <span><span className="font-medium">Accessibilité :</span> Interface intuitive pour tous types d'utilisateurs</span>
                        </li>
                      </ul>
                    </div>
                    
                    <Button onClick={() => handleTabClick('team')} className="w-full mt-4">
                      Découvrir notre équipe
                    </Button>
                  </CardContent>
                </Card>
              </TabsContent>
              
              <TabsContent value="team" className="space-y-6">
                <Card>
                  <CardHeader>
                    <CardTitle>Équipe et Gouvernance</CardTitle>
                    <CardDescription>
                      Une équipe pluridisciplinaire d'experts en technologies, finance et environnement
                    </CardDescription>
                  </CardHeader>
                  <CardContent className="space-y-4">
                    <div className="space-y-2">
                      <h3 className="font-semibold text-lg">Direction</h3>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="p-4 border rounded-lg">
                          <h4 className="font-medium">Nirinasoa Rajaona</h4>
                          <p className="text-sm text-brand-600 mb-2">Fondateur & CEO</p>
                          <p className="text-sm">Ingénieur Agro-Food, Chef de projet blockchain.</p>
                        </div>
                        <div className="p-4 border rounded-lg">
                          <h4 className="font-medium">Vina Marie-Orléa</h4>
                          <p className="text-sm text-brand-600 mb-2">CTO</p>
                          <p className="text-sm">Ex-Ministre de l'environnement et du développement durable, expert en gestion environnementale.</p>
                        </div>
                        <div className="p-4 border rounded-lg">
                          <h4 className="font-medium">Fabien Rajaona</h4>
                          <p className="text-sm text-brand-600 mb-2">COO</p>
                          <p className="text-sm">Spécialiste en marketing digital et nouvelles technologies.</p>
                        </div>
                      </div>
                    </div>
                    
                    <div className="space-y-2">
                      <h3 className="font-semibold text-lg">Conseillers Stratégiques</h3>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="p-4 border rounded-lg">
                          <h4 className="font-medium">Fifine Barège</h4>
                          <p className="text-sm text-brand-600 mb-2">Conseillère Scientifique</p>
                          <p className="text-sm">Spécialiste dans le dommaine environnemental et developement durable.</p>
                        </div>
                        <div className="p-4 border rounded-lg">
                          <h4 className="font-medium">Rakotovao Ranja</h4>
                          <p className="text-sm text-brand-600 mb-2">Conseillère en Droit</p>
                          <p className="text-sm">Dr en droit, spécialiste environnemental.</p>
                        </div>
                      </div>
                    </div>
                    
                    <Button onClick={() => handleTabClick('business')} className="w-full mt-4">
                      Découvrir notre modèle d'affaires
                    </Button>
                  </CardContent>
                </Card>
              </TabsContent>
              
              <TabsContent value="business" className="space-y-6">
                <Card>
                  <CardHeader>
                    <CardTitle>Modèle d'Affaires</CardTitle>
                    <CardDescription>
                      Une approche innovante générant des revenus multiples et durables
                    </CardDescription>
                  </CardHeader>
                  <CardContent className="space-y-4">
                    <div className="space-y-2">
                      <h3 className="font-semibold text-lg">Sources de Revenus</h3>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="p-4 border rounded-lg">
                          <h4 className="font-medium flex items-center gap-2">
                            <ArrowRight className="h-4 w-4 text-brand-500 shrink-0" />
                            Commissions de Transaction
                          </h4>
                          <p className="text-sm ml-6">1-2% sur chaque transaction de crédits carbone sur notre plateforme</p>
                        </div>
                        <div className="p-4 border rounded-lg">
                          <h4 className="font-medium flex items-center gap-2">
                            <ArrowRight className="h-4 w-4 text-brand-500 shrink-0" />
                            Frais de Tokenisation
                          </h4>
                          <p className="text-sm ml-6">3% pour la création et l'émission de nouveaux tokens carbone</p>
                        </div>
                        <div className="p-4 border rounded-lg">
                          <h4 className="font-medium flex items-center gap-2">
                            <ArrowRight className="h-4 w-4 text-brand-500 shrink-0" />
                            Services de Vérification
                          </h4>
                          <p className="text-sm ml-6">Frais pour la certification et la vérification des projets environnementaux</p>
                        </div>
                        <div className="p-4 border rounded-lg">
                          <h4 className="font-medium flex items-center gap-2">
                            <ArrowRight className="h-4 w-4 text-brand-500 shrink-0" />
                            Revenus DeFi
                          </h4>
                          <p className="text-sm ml-6">Part des revenus générés par les protocoles de finance décentralisée</p>
                        </div>
                        <div className="p-4 border rounded-lg">
                          <h4 className="font-medium flex items-center gap-2">
                            <ArrowRight className="h-4 w-4 text-brand-500 shrink-0" />
                            Services aux Entreprises
                          </h4>
                          <p className="text-sm ml-6">Solutions sur mesure pour la gestion des portefeuilles carbone des entreprises</p>
                        </div>
                        <div className="p-4 border rounded-lg">
                          <h4 className="font-medium flex items-center gap-2">
                            <ArrowRight className="h-4 w-4 text-brand-500 shrink-0" />
                            Services de Conseil
                          </h4>
                          <p className="text-sm ml-6">Conseil stratégique aux entreprises et développeurs de projets</p>
                        </div>
                      </div>
                    </div>
                    
                    <div className="space-y-2">
                      <h3 className="font-semibold text-lg">Stratégie de Croissance</h3>
                      <p>Notre approche de croissance s'articule autour de trois phases :</p>
                      <ul className="space-y-2 mt-2">
                        <li className="flex items-start gap-2">
                          <ArrowRight className="h-4 w-4 text-brand-500 mt-1 shrink-0" />
                          <div>
                            <span className="font-medium">Phase 1 (2023-2024) :</span>
                            <p className="text-sm">Lancement de la plateforme et premiers projets de tokenisation. 
                            Focus sur les partenariats stratégiques avec des développeurs de projets 
                            environnementaux et des entreprises pionnières.</p>
                          </div>
                        </li>
                        <li className="flex items-start gap-2">
                          <ArrowRight className="h-4 w-4 text-brand-500 mt-1 shrink-0" />
                          <div>
                            <span className="font-medium">Phase 2 (2024-2025) :</span>
                            <p className="text-sm">Expansion internationale et développement de l'écosystème DeFi autour des 
                            tokens environnementaux. Intégration avec d'autres blockchains et plateformes.</p>
                          </div>
                        </li>
                        <li className="flex items-start gap-2">
                          <ArrowRight className="h-4 w-4 text-brand-500 mt-1 shrink-0" />
                          <div>
                            <span className="font-medium">Phase 3 (2025+) :</span>
                            <p className="text-sm">Position de leader sur le marché global des actifs environnementaux tokenisés. 
                            Expansion vers d'autres types d'actifs environnementaux (biodiversité, eau, etc.).</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                    
                    <Button onClick={() => handleTabClick('financier')} className="w-full mt-4">
                      Découvrir nos projections financières
                    </Button>
                  </CardContent>
                </Card>
              </TabsContent>
              
              <TabsContent value="financier" className="space-y-6">
                <Card>
                  <CardHeader>
                    <CardTitle>Projections Financières</CardTitle>
                    <CardDescription>
                      Prévisions de revenus et rentabilité sur les 3 prochaines années
                    </CardDescription>
                  </CardHeader>
                  <CardContent className="space-y-4">
                    <div className="space-y-2">
                      <h3 className="font-semibold text-lg">Revenus Prévisionnels</h3>
                      <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Source de Revenu</th>
                              <th scope="col" className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">2024</th>
                              <th scope="col" className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">2025</th>
                              <th scope="col" className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">2026</th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            <tr>
                              <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900">Commissions de Transaction</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-900">4,2M€</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-900">9,8M€</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-900">18,5M€</td>
                            </tr>
                            <tr>
                              <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900">Frais de Tokenisation</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-900">3,1M€</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-900">6,4M€</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-900">10,2M€</td>
                            </tr>
                            <tr>
                              <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900">Revenus DeFi</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-900">2,3M€</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-900">7,8M€</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-900">15,6M€</td>
                            </tr>
                            <tr>
                              <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900">Services aux Entreprises</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-900">1,8M€</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-900">4,6M€</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-900">8,9M€</td>
                            </tr>
                            <tr className="bg-gray-50">
                              <td className="px-4 py-3 whitespace-nowrap text-sm font-semibold text-gray-900">Total Revenus</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm font-semibold text-right text-gray-900">11,4M€</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm font-semibold text-right text-gray-900">28,6M€</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm font-semibold text-right text-gray-900">53,2M€</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    
                    <div className="space-y-2 mt-6">
                      <h3 className="font-semibold text-lg">Revenus des Crédits Carbone (2 ans)</h3>
                      <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type de Projet</th>
                              <th scope="col" className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Volume (tCO₂e)</th>
                              <th scope="col" className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Prix Moyen</th>
                              <th scope="col" className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Revenu Total</th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            <tr>
                              <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900">Projets Forestiers</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-900">420,000</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-900">12€</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-900">5,04M€</td>
                            </tr>
                            <tr>
                              <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900">Énergie Renouvelable</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-900">280,000</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-900">8€</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-900">2,24M€</td>
                            </tr>
                            <tr>
                              <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900">Projets Agricoles</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-900">140,000</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-900">8€</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-900">1,12M€</td>
                            </tr>
                            <tr className="bg-gray-50">
                              <td className="px-4 py-3 whitespace-nowrap text-sm font-semibold text-gray-900">Total</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm font-semibold text-right text-gray-900">840,000</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm font-semibold text-right text-gray-900">10€ (moy.)</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm font-semibold text-right text-gray-900">8,4M€</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    
                    <Button onClick={() => handleTabClick('token')} className="w-full mt-4">
                      Découvrir notre tokenomics
                    </Button>
                  </CardContent>
                </Card>
              </TabsContent>
              
              <TabsContent value="token" className="space-y-6">
                <Card>
                  <CardHeader>
                    <CardTitle>Tokenomics et Structure de l'Offre</CardTitle>
                    <CardDescription>
                      Modèle économique des tokens Guteco et opportunités pour les investisseurs
                    </CardDescription>
                  </CardHeader>
                  <CardContent className="space-y-4">
                    <div className="space-y-2">
                      <h3 className="font-semibold text-lg">Types de Tokens</h3>
                      <p>L'écosystème Guteco comprend deux types de tokens :</p>
                      <ul className="space-y-2 mt-2">
                        <li className="flex items-start gap-2">
                          <ArrowRight className="h-4 w-4 text-brand-500 mt-1 shrink-0" />
                          <div>
                            <span className="font-medium">GUTECO (GTK) :</span>
                            <p className="text-sm">Token de gouvernance et utilitaire de la plateforme, permettant aux détenteurs 
                            de participer aux décisions d'orientation et donnant accès à des fonctionnalités premium.</p>
                          </div>
                        </li>
                        <li className="flex items-start gap-2">
                          <ArrowRight className="h-4 w-4 text-brand-500 mt-1 shrink-0" />
                          <div>
                            <span className="font-medium">Tokens Carbone (NFTs) :</span>
                            <p className="text-sm">Représentant les crédits carbone vérifiés, avec des métadonnées liées à 
                            la nature, l'origine et l'impact environnemental du crédit carbone.</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                    
                    <div className="space-y-2">
                      <h3 className="font-semibold text-lg">Allocation des Tokens GUTECO</h3>
                      <div className="bg-gray-50 p-4 rounded-lg">
                        <ul className="space-y-3">
                          <li className="flex items-center justify-between">
                            <span className="text-sm">Vente Initiale (Seed + Privé)</span>
                            <span className="text-sm font-medium">15%</span>
                          </li>
                          <li>
                            <div className="flex items-center justify-between">
                              <span className="text-sm">Vente Publique</span>
                              <span className="text-sm font-medium">5%</span>
                            </div>
                            <Progress value={5} className="h-2 mt-1" />
                          </li>
                          <li>
                            <div className="flex items-center justify-between">
                              <span className="text-sm">Équipe et Fondateurs</span>
                              <span className="text-sm font-medium">20%</span>
                            </div>
                            <Progress value={20} className="h-2 mt-1" />
                          </li>
                          <li>
                            <div className="flex items-center justify-between">
                              <span className="text-sm">Réserve Écosystème</span>
                              <span className="text-sm font-medium">25%</span>
                            </div>
                            <Progress value={25} className="h-2 mt-1" />
                          </li>
                          <li>
                            <div className="flex items-center justify-between">
                              <span className="text-sm">Trésorerie</span>
                              <span className="text-sm font-medium">15%</span>
                            </div>
                            <Progress value={15} className="h-2 mt-1" />
                          </li>
                          <li>
                            <div className="flex items-center justify-between">
                              <span className="text-sm">Marketing et Développement</span>
                              <span className="text-sm font-medium">10%</span>
                            </div>
                            <Progress value={10} className="h-2 mt-1" />
                          </li>
                          <li>
                            <div className="flex items-center justify-between">
                              <span className="text-sm">Récompenses Staking</span>
                              <span className="text-sm font-medium">10%</span>
                            </div>
                            <Progress value={10} className="h-2 mt-1" />
                          </li>
                        </ul>
                      </div>
                    </div>
                    
                    <div className="space-y-2">
                      <h3 className="font-semibold text-lg">Structure de l'Offre d'Investissement</h3>
                      <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Détails</th>
                              <th scope="col" className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Valeur</th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            <tr>
                              <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900">Valorisation pre-money</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-900">90 000 000 €</td>
                            </tr>
                            <tr>
                              <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900">Montant recherché</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-900">12 000 000 €</td>
                            </tr>
                            <tr>
                              <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900">Pourcentage offert</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-900">11,76%</td>
                            </tr>
                            <tr>
                              <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900">Ticket minimum</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-900">250 000 €</td>
                            </tr>
                            <tr>
                              <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900">Période de blocage</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm text-right text-gray-900">12 mois (cliff) + 24 mois (vesting linéaire)</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    
                    <div className="mt-8 text-center">
                      <h3 className="font-semibold text-lg mb-4">Intéressé par cette opportunité d'investissement ?</h3>
                      <Button size="lg" className="px-8">
                        Contactez Notre Équipe
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              </TabsContent>
            </div>
          </Tabs>
        </div>
      </section>
    </Layout>
  );
};

export default InvestmentMemorandum;
