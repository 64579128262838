
import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { ArrowDown } from 'lucide-react';

type VestingRule = {
  group: string;
  rule: string;
  percent: string;
};

interface VestingRulesTableProps {
  rules: VestingRule[];
  title: string;
  description?: string;
  className?: string;
}

const VestingRulesTable = ({ rules, title, description, className }: VestingRulesTableProps) => {
  return (
    <Card className={className}>
      <CardHeader className="bg-amber-50">
        <CardTitle className="text-xl text-amber-800">{title}</CardTitle>
      </CardHeader>
      <CardContent className="pt-6">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Groupe</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Allocation</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Règle de Vesting</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {rules.map((rule, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{rule.group}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{rule.percent}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{rule.rule}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        
        {description && (
          <div className="mt-6 p-4 bg-amber-50 rounded-lg text-sm">
            <p className="flex items-center gap-2 text-amber-800">
              <ArrowDown className="h-5 w-5 shrink-0" />
              <span>{description}</span>
            </p>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default VestingRulesTable;
