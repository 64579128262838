
import React from 'react';
import { Layout } from "@/components/Layout";
import TokenEconomics from "@/components/TokenEconomics";
import TokenInfo from "@/components/TokenInfo";
import ProjectEvolution from "@/components/ProjectEvolution";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { ExternalLink, PieChart, TrendingUp, Users } from "lucide-react";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Separator } from "@/components/ui/separator";

const TokenEconomicsPage = () => {
  const navigate = useNavigate();

  const handleInvestmentClick = () => {
    navigate('/valuation');
  };

  const handleFundingClick = () => {
    // Création d'un ID pour le scroll vers la section de répartition des fonds
    const element = document.getElementById('funding-allocation');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Layout>
      <div className="py-12">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl font-bold text-brand-800 mb-8 text-center">Économie des Tokens</h1>
          <p className="text-lg text-gray-700 max-w-3xl mx-auto text-center mb-6">
            Découvrez comment fonctionne l'économie de nos tokens et comment ils créent de la valeur pour notre écosystème et pour l'environnement.
          </p>
          
          <div className="flex justify-center gap-4 mb-12">
            <Button 
              onClick={handleInvestmentClick}
              className="flex items-center gap-2 bg-brand-700 hover:bg-brand-800"
            >
              <ExternalLink size={16} />
              <span>Consulter notre mémorandum d'investissement</span>
            </Button>
            
            <Button 
              onClick={handleFundingClick}
              variant="outline"
              className="flex items-center gap-2 border-brand-600 text-brand-600"
            >
              <PieChart size={16} />
              <span>Répartition des fonds levés</span>
            </Button>
          </div>
          
          <div className="mb-16">
            <TokenInfo />
          </div>
          
          {/* Ajout de la section d'évolution du projet */}
          <div className="mb-16">
            <ProjectEvolution />
          </div>
          
          <Separator className="my-16" />
          
          <div id="funding-allocation" className="mb-16 py-8">
            <div className="bg-white shadow-lg rounded-xl p-6 max-w-4xl mx-auto">
              <h2 className="text-2xl font-bold text-brand-800 mb-6 text-center">Répartition des 12M€ Levés</h2>
              
              <div className="overflow-auto">
                <Table>
                  <TableHeader>
                    <TableRow className="bg-brand-50">
                      <TableHead className="text-brand-800">Poste</TableHead>
                      <TableHead className="text-right text-brand-800">Montant (€)</TableHead>
                      <TableHead className="text-right text-brand-800">% du total</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    <TableRow className="hover:bg-gray-50">
                      <TableCell>Restauration des 10 000 ha de mangroves</TableCell>
                      <TableCell className="text-right font-medium">5M€</TableCell>
                      <TableCell className="text-right">41,7%</TableCell>
                    </TableRow>
                    <TableRow className="hover:bg-gray-50">
                      <TableCell>Acquisition de drones professionnels</TableCell>
                      <TableCell className="text-right font-medium">2M€</TableCell>
                      <TableCell className="text-right">16,7%</TableCell>
                    </TableRow>
                    <TableRow className="hover:bg-gray-50">
                      <TableCell>Développement de la plateforme & Blockchain</TableCell>
                      <TableCell className="text-right font-medium">2M€</TableCell>
                      <TableCell className="text-right">16,7%</TableCell>
                    </TableRow>
                    <TableRow className="hover:bg-gray-50">
                      <TableCell>Certifications & audits (Verra, Gold Standard)</TableCell>
                      <TableCell className="text-right font-medium">1,5M€</TableCell>
                      <TableCell className="text-right">12,5%</TableCell>
                    </TableRow>
                    <TableRow className="hover:bg-gray-50">
                      <TableCell>Marketing & partenariats stratégiques</TableCell>
                      <TableCell className="text-right font-medium">1M€</TableCell>
                      <TableCell className="text-right">8,3%</TableCell>
                    </TableRow>
                    <TableRow className="hover:bg-gray-50">
                      <TableCell>Salaires et frais opérationnels (équipe, bureaux, gestion)</TableCell>
                      <TableCell className="text-right font-medium">500K€</TableCell>
                      <TableCell className="text-right">4,1%</TableCell>
                    </TableRow>
                    <TableRow className="bg-brand-50 font-semibold">
                      <TableCell>Total</TableCell>
                      <TableCell className="text-right">12M€</TableCell>
                      <TableCell className="text-right">100%</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
              
              <div className="mt-6">
                <div className="flex items-center gap-2 mb-4">
                  <Users className="h-5 w-5 text-brand-600" />
                  <h3 className="text-lg font-semibold text-brand-800">Répartition détaillée des 500K€ pour les salaires</h3>
                </div>
                
                <div className="overflow-auto">
                  <Table>
                    <TableHeader>
                      <TableRow className="bg-brand-50">
                        <TableHead className="text-brand-800">Poste</TableHead>
                        <TableHead className="text-right text-brand-800">Nombre</TableHead>
                        <TableHead className="text-right text-brand-800">Salaire moyen (€)</TableHead>
                        <TableHead className="text-right text-brand-800">Durée</TableHead>
                        <TableHead className="text-right text-brand-800">Total (€)</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      <TableRow className="hover:bg-gray-50">
                        <TableCell>CEO</TableCell>
                        <TableCell className="text-right">1</TableCell>
                        <TableCell className="text-right">7 000 €/mois</TableCell>
                        <TableCell className="text-right">12 mois</TableCell>
                        <TableCell className="text-right font-medium">84 000 €</TableCell>
                      </TableRow>
                      <TableRow className="hover:bg-gray-50">
                        <TableCell>CTO, CMO</TableCell>
                        <TableCell className="text-right">2</TableCell>
                        <TableCell className="text-right">5 500 €/mois</TableCell>
                        <TableCell className="text-right">12 mois</TableCell>
                        <TableCell className="text-right font-medium">132 000 €</TableCell>
                      </TableRow>
                      <TableRow className="hover:bg-gray-50">
                        <TableCell>Experts écologie & certification</TableCell>
                        <TableCell className="text-right">2</TableCell>
                        <TableCell className="text-right">5 000 €/mois</TableCell>
                        <TableCell className="text-right">12 mois</TableCell>
                        <TableCell className="text-right font-medium">120 000 €</TableCell>
                      </TableRow>
                      <TableRow className="hover:bg-gray-50">
                        <TableCell>Marketing & business dev</TableCell>
                        <TableCell className="text-right">2</TableCell>
                        <TableCell className="text-right">4 000 €/mois</TableCell>
                        <TableCell className="text-right">12 mois</TableCell>
                        <TableCell className="text-right font-medium">96 000 €</TableCell>
                      </TableRow>
                      <TableRow className="hover:bg-gray-50">
                        <TableCell>Finance & administration</TableCell>
                        <TableCell className="text-right">1</TableCell>
                        <TableCell className="text-right">4 000 €/mois</TableCell>
                        <TableCell className="text-right">12 mois</TableCell>
                        <TableCell className="text-right font-medium">48 000 €</TableCell>
                      </TableRow>
                      <TableRow className="bg-brand-50 font-semibold">
                        <TableCell>Total</TableCell>
                        <TableCell className="text-right">8 personnes</TableCell>
                        <TableCell className="text-right"></TableCell>
                        <TableCell className="text-right">1 an</TableCell>
                        <TableCell className="text-right">500 000 €</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
                
                <div className="mt-3 bg-green-50 p-3 rounded-lg border border-green-100">
                  <p className="text-sm text-green-700 flex items-start gap-2">
                    <span className="text-green-600 font-medium">✅</span>
                    <span>Avec cette répartition, les dirigeants et l'équipe clé ont des salaires réalistes sur 12 mois.</span>
                  </p>
                </div>
              </div>
              
              <div className="mt-6 bg-brand-50 p-4 rounded-lg">
                <h3 className="text-lg font-semibold text-brand-800 mb-2">Pourquoi 500K€ pour les salaires ?</h3>
                <ul className="space-y-1 text-sm">
                  <li className="flex items-start gap-2">
                    <span className="text-brand-600 font-medium">✔</span>
                    <span>Équipe core (CEO, CTO, CMO, experts blockchain, écologie, finance)</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <span className="text-brand-600 font-medium">✔</span>
                    <span>Opérations essentielles (support technique, administration, gestion de projet, etc.)</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <span className="text-brand-600 font-medium">✔</span>
                    <span>Assurer une bonne structure sans griller trop de cash</span>
                  </li>
                </ul>
              </div>
              
              <div className="mt-8 bg-gray-50 p-4 rounded-lg">
                <h3 className="text-lg font-semibold text-brand-800 mb-2">Rendement pour les Investisseurs</h3>
                <ul className="space-y-2">
                  <li className="flex items-start gap-2 text-sm">
                    <span className="font-medium">Valorisation Post-Money:</span> 102M€
                  </li>
                  <li className="flex items-start gap-2 text-sm">
                    <span className="font-medium">Équité offerte:</span> 11,76% en échange de 12M€
                  </li>
                  <li className="flex items-start gap-2 text-sm">
                    <span className="font-medium">ROI estimé:</span> 3,0% sur 2 ans
                  </li>
                </ul>
              </div>
              
              <div className="mt-6 bg-emerald-50 p-4 rounded-lg border border-emerald-100">
                <h3 className="text-lg font-semibold text-emerald-800 mb-3">Projections de revenus et remboursement</h3>
                <table className="w-full border-collapse">
                  <thead>
                    <tr className="bg-emerald-100">
                      <th className="px-4 py-2 text-left text-sm font-semibold text-emerald-800 border-b">Période</th>
                      <th className="px-4 py-2 text-right text-sm font-semibold text-emerald-800 border-b">Revenus (M€)</th>
                      <th className="px-4 py-2 text-right text-sm font-semibold text-emerald-800 border-b">Bénéfices (M€)</th>
                      <th className="px-4 py-2 text-right text-sm font-semibold text-emerald-800 border-b">Remboursement aux investisseurs (M€)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="hover:bg-emerald-50">
                      <td className="px-4 py-2 text-sm border-b border-emerald-200 font-medium">Année 1</td>
                      <td className="px-4 py-2 text-sm text-right border-b border-emerald-200">2,6</td>
                      <td className="px-4 py-2 text-sm text-right border-b border-emerald-200">0,4</td>
                      <td className="px-4 py-2 text-sm text-right border-b border-emerald-200">0,047</td>
                    </tr>
                    <tr className="hover:bg-emerald-50">
                      <td className="px-4 py-2 text-sm border-b border-emerald-200 font-medium">Année 2</td>
                      <td className="px-4 py-2 text-sm text-right border-b border-emerald-200">5,8</td>
                      <td className="px-4 py-2 text-sm text-right border-b border-emerald-200">0,9</td>
                      <td className="px-4 py-2 text-sm text-right border-b border-emerald-200">0,106</td>
                    </tr>
                    <tr className="bg-emerald-100 font-medium">
                      <td className="px-4 py-2 text-sm border-b border-emerald-200">Total</td>
                      <td className="px-4 py-2 text-sm text-right border-b border-emerald-200">8,4</td>
                      <td className="px-4 py-2 text-sm text-right border-b border-emerald-200">1,3</td>
                      <td className="px-4 py-2 text-sm text-right border-b border-emerald-200">0,153 (1,3% de l'investissement initial)</td>
                    </tr>
                  </tbody>
                </table>
                <div className="mt-3 text-sm text-emerald-700 flex items-start gap-2">
                  <TrendingUp className="h-4 w-4 shrink-0 mt-0.5" />
                  <span>Le remboursement aux investisseurs est calculé sur la base de leur part d'équité (11,76%) des bénéfices réalisés. La plus grande partie du ROI proviendra de l'appréciation de la valeur de l'entreprise, qui atteint 105M€ en année 2.</span>
                </div>
              </div>
            </div>
          </div>
          
          <div>
            <TokenEconomics />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TokenEconomicsPage;
