
import React, { useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import { Button } from '@/components/ui/button';
import { ChevronDown, CheckCircle2, Clock, CalendarDays, BarChart2, Rocket, PlusCircle, Globe, Award, Heart, Users, ChevronRight, BrainCircuit, GraduationCap } from 'lucide-react';
import { Separator } from '@/components/ui/separator';

const Roadmap = () => {
  const [showInnovations, setShowInnovations] = useState(false);
  
  const roadmapPhases = [
    {
      id: 'phase1',
      title: 'Phase 1: Fondation (2025-2026)',
      icon: <CheckCircle2 className="text-green-600" />,
      status: 'Complété',
      items: [
        'Création de l\'équipe fondatrice',
        'Développement du concept et de la vision',
        'Étude de marché et analyse de la concurrence',
        'Conception du modèle économique initial',
        'Développement de la première version de la plateforme (MVP)'
      ]
    },
    {
      id: 'phase2',
      title: 'Phase 2: Développement (2026-2027)',
      icon: <Clock className="text-amber-500" />,
      status: 'En cours',
      items: [
        'Première levée de fonds (Seed)',
        'Lancement du token GTC et VCarbs en version test',
        'Implémentation de la structure DAO pour la gouvernance',
        'Partenariats stratégiques avec des projets environnementaux et sociaux',
        'Certifications internationales et conformité réglementaire'
      ]
    },
    {
      id: 'phase3',
      title: 'Phase 3: Croissance (2027+)',
      icon: <CalendarDays className="text-blue-500" />,
      status: 'Planifié',
      items: [
        'Seconde levée de fonds (Série A)',
        'Expansion internationale à 5 nouveaux pays',
        'Lancement officiel du marché de crédits carbone',
        'Intégration avec d\'autres plateformes blockchain environnementales',
        'Programme d\'incubation pour projets à impact innovants avec prise de participation au capital social'
      ]
    },
    {
      id: 'phase4',
      title: 'Vision à long terme',
      icon: <Rocket className="text-purple-500" />,
      status: 'Futur',
      items: [
        'Devenir la référence mondiale en finance d\'impact décentralisée',
        'Étendre les services à d\'autres types de crédits environnementaux et sociaux',
        'Créer un écosystème complet de services financiers durables',
        'Impact mesurable sur la réduction des émissions globales',
        'Reconnaissance internationale comme leader de la finance climatique et solidaire'
      ]
    }
  ];

  const innovativeSolutions = [
    {
      title: "Tokenisation avancée des actifs environnementaux",
      description: "Système de fragmentation des crédits carbone utilisant l'IA pour déterminer la valorisation optimale, permettant l'accès aux petits investisseurs et l'augmentation de la liquidité du marché."
    },
    {
      title: "Plateforme de vérification distribuée",
      description: "Réseau décentralisé de vérificateurs utilisant l'intelligence artificielle et l'IoT pour certifier en temps réel l'impact des projets environnementaux et détecter les anomalies."
    },
    {
      title: "Marché d'échange pair-à-pair",
      description: "Protocole de finance décentralisée avec algorithmes d'IA pour le matching intelligent des acheteurs et vendeurs de crédits carbone, réduisant les coûts de transaction et optimisant les prix."
    },
    {
      title: "Système de gouvernance communautaire",
      description: "Structure DAO évolutive avec IA prédictive permettant d'évaluer l'impact potentiel des propositions avant les votes des détenteurs de tokens sur la sélection des projets."
    },
    {
      title: "Traçabilité intégrale de bout en bout",
      description: "Solution de traçabilité complète utilisant l'IA et des NFTs pour analyser, authentifier et suivre chaque crédit carbone de sa création à sa compensation finale, éliminant les risques de double comptage."
    }
  ];

  return (
    <>
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold text-brand-800 mb-4">Notre Feuille de Route</h2>
        <p className="text-gray-600 max-w-3xl mx-auto">
          Découvrez les étapes clés du développement de Guteco, de sa création jusqu'à sa vision à long terme.
        </p>
      </div>

      <div className="max-w-4xl mx-auto space-y-6">
        {roadmapPhases.map((phase, index) => (
          <Collapsible key={phase.id} className="w-full" defaultOpen={index < 2}>
            <Card className={`border-l-4 ${
              phase.status === 'Complété' ? 'border-l-green-500' : 
              phase.status === 'En cours' ? 'border-l-amber-500' : 
              'border-l-blue-500'
            }`}>
              <CollapsibleTrigger asChild>
                <CardHeader className="cursor-pointer hover:bg-gray-50 transition-colors flex flex-row items-center">
                  <div className="flex items-center flex-1 gap-3">
                    <div className="w-10 h-10 rounded-full flex items-center justify-center bg-gray-100">
                      {phase.icon}
                    </div>
                    <div>
                      <CardTitle className="text-lg md:text-xl">
                        {phase.title}
                      </CardTitle>
                      <p className={`text-sm font-medium mt-1 ${
                        phase.status === 'Complété' ? 'text-green-600' : 
                        phase.status === 'En cours' ? 'text-amber-500' : 
                        'text-blue-500'
                      }`}>
                        {phase.status}
                      </p>
                    </div>
                  </div>
                  <Button variant="ghost" size="icon" className="ml-auto">
                    <ChevronDown className="h-5 w-5" />
                  </Button>
                </CardHeader>
              </CollapsibleTrigger>
              <CollapsibleContent>
                <CardContent className="pt-0">
                  <ul className="space-y-3">
                    {phase.items.map((item, idx) => (
                      <li key={idx} className="flex items-start gap-3">
                        <PlusCircle className="h-5 w-5 text-brand-600 mt-0.5" />
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                </CardContent>
              </CollapsibleContent>
            </Card>
          </Collapsible>
        ))}
      </div>

      <div className="mt-16 max-w-4xl mx-auto bg-brand-50 rounded-lg p-6">
        <div className="flex items-center gap-4 mb-4">
          <div className="bg-brand-100 p-3 rounded-full">
            <Award className="h-6 w-6 text-brand-700" />
          </div>
          <h3 className="text-xl font-semibold text-brand-800">Objectifs Stratégiques</h3>
        </div>
        <div className="space-y-4">
          <div className="flex items-start gap-4">
            <Globe className="h-5 w-5 text-brand-600 mt-1" />
            <div>
              <h4 className="font-medium text-brand-800">Impact Environnemental</h4>
              <p className="text-gray-600">Faciliter la séquestration de 10 millions de tonnes de CO₂ d'ici 2030.</p>
            </div>
          </div>
          <Separator />
          <div className="flex items-start gap-4">
            <Heart className="h-5 w-5 text-brand-600 mt-1" />
            <div>
              <h4 className="font-medium text-brand-800">Impact Humanitaire et Social</h4>
              <p className="text-gray-600">Soutenir 150 projets à fort impact positif répartis entre initiatives environnementales, humanitaires et sociales d'ici 2027.</p>
            </div>
          </div>
          <Separator />
          <div className="flex items-start gap-4">
            <Users className="h-5 w-5 text-brand-600 mt-1" />
            <div>
              <h4 className="font-medium text-brand-800">Croissance Utilisateurs</h4>
              <p className="text-gray-600">Atteindre 100,000 utilisateurs actifs sur la plateforme d'ici 2027.</p>
            </div>
          </div>
          <Separator />
          <div className="flex items-start gap-4">
            <Rocket className="h-5 w-5 text-brand-600 mt-1" />
            <div className="w-full">
              <div className="flex justify-between items-center">
                <h4 className="font-medium text-brand-800">Innovation Technologique</h4>
                <Button 
                  variant="ghost" 
                  size="sm" 
                  onClick={() => setShowInnovations(!showInnovations)}
                  className="text-brand-600 -mr-2"
                >
                  {showInnovations ? "Masquer" : "Voir"} les 5 solutions
                  <ChevronRight className={`h-4 w-4 transition-transform ${showInnovations ? 'rotate-90' : ''}`} />
                </Button>
              </div>
              <p className="text-gray-600">Développer 5 solutions blockchain innovantes propulsées par l'intelligence artificielle pour l'impact social et environnemental d'ici 2028.</p>
              
              {showInnovations && (
                <div className="mt-4 bg-white rounded-lg p-4 space-y-4 border border-brand-100">
                  <div className="flex items-center justify-center mb-2">
                    <BrainCircuit className="h-10 w-10 text-brand-600 mr-2" />
                    <h5 className="text-lg font-medium text-brand-800">Solutions blockchain avec IA intégrée</h5>
                  </div>
                  {innovativeSolutions.map((solution, index) => (
                    <div key={index} className={index > 0 ? "pt-4 border-t border-gray-100" : ""}>
                      <h5 className="font-medium text-brand-700">{index + 1}. {solution.title}</h5>
                      <p className="text-sm text-gray-600 mt-1">{solution.description}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <Separator />
          <div className="flex items-start gap-4">
            <GraduationCap className="h-5 w-5 text-brand-600 mt-1" />
            <div>
              <h4 className="font-medium text-brand-800">Soutien à l'Innovation</h4>
              <p className="text-gray-600">Financer 10 projets académiques et startups environnementales via notre programme d'incubation d'ici 2027.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Roadmap;
