
import React from 'react';
import { 
  Card, 
  CardContent, 
  CardDescription, 
  CardFooter, 
  CardHeader, 
  CardTitle 
} from "@/components/ui/card";
import { 
  Table, 
  TableBody, 
  TableCaption, 
  TableCell, 
  TableHead, 
  TableHeader, 
  TableRow 
} from "@/components/ui/table";
import { TrendingUp, BarChart3, LineChart, ArrowRight, Scale, Check, X, AlertTriangle, Users } from 'lucide-react';
import { Separator } from "@/components/ui/separator";

const CompanyValuation = () => {
  // Données pour le tableau des revenus - uniformisées en euros
  const revenueData = [
    { year: 1, revenue: 26.2, profit: 1.3 },
    { year: 2, revenue: 26.2, profit: 1.3 },
    { year: 3, revenue: 34.1, profit: 17.1 },
    { year: 4, revenue: 44.3, profit: 22.1 },
    { year: 5, revenue: 57.6, profit: 28.8 }
  ];

  // Données pour le tableau des entreprises comparables - uniformisées en euros
  const comparableCompanies = [
    { name: "KlimaDAO", industry: "Blockchain & Carbone", valuation: "460M€+" },
    { name: "Flowcarbon", industry: "Blockchain & Carbone", valuation: "320M€+" },
    { name: "Verra", industry: "Certification carbone", valuation: "920M€+" },
    { name: "Regen Network", industry: "Blockchain & Nature", valuation: "275M€+" }
  ];

  // Facteurs pouvant influencer la valorisation
  const positiveFactors = [
    "Adoption massive du marché des crédits carbone tokenisés",
    "Partenariats avec des entreprises internationales",
    "Expansion vers d'autres types d'écosystèmes (forêts tropicales, océans)",
    "Hausse du prix des crédits carbone (>92€/tonne d'ici 2030)"
  ];

  const negativeFactors = [
    "Régulations plus strictes sur le marché du carbone",
    "Difficultés à obtenir les certifications Verra/Gold Standard rapidement",
    "Délais dans le développement technologique ou dans la restauration des mangroves"
  ];

  // Structure de valorisation progressive
  const valuationStages = [
    { stage: "Phase initiale (aujourd'hui)", valuation: "80-100M€", description: "Phase early-stage, levée de fonds en cours" },
    { stage: "Phase intermédiaire (1-2 ans)", valuation: "150-200M€", description: "Premiers crédits carbone certifiés, adoption croissante" },
    { stage: "Phase maturité (2-3 ans)", valuation: "200-250M€", description: "Objectifs atteints, revenus stables" },
    { stage: "Phase expansion (5 ans)", valuation: "260M€+", description: "Expansion internationale, diversification des écosystèmes" }
  ];

  // Détails de l'équipe pour l'évaluation
  const teamValuationDetails = [
    { category: "Expérience et crédibilité", value: "10M€", description: "Expertise en finance, blockchain et DeFi" },
    { category: "Accès aux institutions et certifications", value: "15M€", description: "Connexions gouvernementales (ex-Ministre) et certifications" },
    { category: "Capacité à lever des fonds", value: "10M€", description: "Réseau d'investisseurs et partenaires stratégiques" },
    { category: "Innovation technologique", value: "5M€", description: "Expertise en blockchain, drones et solutions techniques" }
  ];

  return (
    <section className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-brand-800 mb-4">Analyse de valorisation de GuTeco</h2>
            <p className="text-gray-600 max-w-3xl mx-auto">
              Évaluation détaillée de la valorisation potentielle de GuTeco à 260 millions d'euros et 
              des facteurs influençant cette valeur.
            </p>
          </div>

          {/* SECTION: Méthodes de valorisation - Introduction */}
          <Card className="mb-8 shadow-sm">
            <CardHeader>
              <div className="flex items-center gap-2">
                <Scale className="h-6 w-6 text-brand-600" />
                <CardTitle>Méthodes de valorisation</CardTitle>
              </div>
              <CardDescription>
                Trois approches pour établir la valeur de GuTeco
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="space-y-6">
                <div className="pl-4 border-l-4 border-brand-400 p-3 bg-brand-50">
                  <h3 className="text-lg font-medium text-brand-700 flex items-center gap-2">
                    <TrendingUp className="h-5 w-5" />
                    1️⃣ Méthode DCF (Discounted Cash Flow - Flux de trésorerie actualisés)
                  </h3>
                  <p className="text-gray-600 mt-1">
                    Basée sur l'actualisation des flux de trésorerie prévisionnels. Cette méthode DCF calcule la valeur actuelle 
                    des flux monétaires futurs attendus.
                  </p>
                </div>

                <div className="pl-4 border-l-4 border-blue-400 p-3 bg-blue-50">
                  <h3 className="text-lg font-medium text-blue-700 flex items-center gap-2">
                    <BarChart3 className="h-5 w-5" />
                    2️⃣ Méthode des comparables (Benchmarking avec d'autres startups similaires)
                  </h3>
                  <p className="text-gray-600 mt-1">
                    Comparaison avec d'autres startups similaires dans l'industrie de la blockchain et du carbone.
                  </p>
                </div>

                <div className="pl-4 border-l-4 border-emerald-400 p-3 bg-emerald-50">
                  <h3 className="text-lg font-medium text-emerald-700 flex items-center gap-2">
                    <LineChart className="h-5 w-5" />
                    3️⃣ Méthode des actifs (Valorisation des crédits carbone & biodiversité + technologie)
                  </h3>
                  <p className="text-gray-600 mt-1">
                    Valeur des terres restaurées + crédits carbone + crédits biodiversité + technologie blockchain.
                  </p>
                </div>
              </div>
            </CardContent>
          </Card>

          {/* SECTION: Valorisation de l'équipe */}
          <Card className="mb-8 shadow-sm">
            <CardHeader>
              <div className="flex items-center gap-2">
                <Users className="h-6 w-6 text-brand-600" />
                <CardTitle>Valorisation de l'équipe - 40 millions €</CardTitle>
              </div>
              <CardDescription>
                L'équipe GuTeco représente une part importante de la valorisation early-stage
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="mb-6">
                <p className="text-gray-700 mb-4">
                  En finance de startup, une équipe expérimentée peut représenter <span className="font-semibold">30 à 50%</span> de la valorisation 
                  d'un projet en phase initiale. L'équipe GuTeco combine:
                </p>
                <ul className="space-y-2 mb-6">
                  <li className="flex items-start gap-2">
                    <Check className="h-5 w-5 text-green-600 shrink-0 mt-0.5" />
                    <div>
                      <span className="font-medium">Nirinasoa Rajaona (Fondateur & CEO)</span>
                      <p className="text-gray-600">Expertise en blockchain et DeFi, 10 ans d'expérience en marchés carbone</p>
                    </div>
                  </li>
                  <li className="flex items-start gap-2">
                    <Check className="h-5 w-5 text-green-600 shrink-0 mt-0.5" />
                    <div>
                      <span className="font-medium">Vina Marie-Orléa (CTO)</span>
                      <p className="text-gray-600">Ex-Ministre de l'environnement, accès stratégique aux réglementations</p>
                    </div>
                  </li>
                  <li className="flex items-start gap-2">
                    <Check className="h-5 w-5 text-green-600 shrink-0 mt-0.5" />
                    <div>
                      <span className="font-medium">Fabien Rajaona (COO)</span>
                      <p className="text-gray-600">Expert en marketing digital et nouvelles technologies</p>
                    </div>
                  </li>
                  <li className="flex items-start gap-2">
                    <Check className="h-5 w-5 text-green-600 shrink-0 mt-0.5" />
                    <div>
                      <span className="font-medium">Barège Fifine (Conseillère technique)</span>
                      <p className="text-gray-600">20 ans d'expérience en environnement, ex-conseillère ministérielle</p>
                    </div>
                  </li>
                  <li className="flex items-start gap-2">
                    <Check className="h-5 w-5 text-green-600 shrink-0 mt-0.5" />
                    <div>
                      <span className="font-medium">Rakotovao Ranja N. (Spécialiste juridique)</span>
                      <p className="text-gray-600">Docteur en droit, spécialiste des questions environnementales</p>
                    </div>
                  </li>
                </ul>

                <Table>
                  <TableCaption>Décomposition de la valeur de l'équipe (40M€)</TableCaption>
                  <TableHeader>
                    <TableRow>
                      <TableHead className="w-[300px]">Catégorie</TableHead>
                      <TableHead>Valeur estimée</TableHead>
                      <TableHead>Description</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {teamValuationDetails.map((detail) => (
                      <TableRow key={detail.category}>
                        <TableCell className="font-medium">{detail.category}</TableCell>
                        <TableCell className="font-bold text-brand-700">{detail.value}</TableCell>
                        <TableCell>{detail.description}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>

              <div className="bg-brand-50 p-4 rounded-lg border border-brand-100">
                <h4 className="font-medium text-brand-700 mb-2">Impact sur la valorisation globale</h4>
                <div className="space-y-2">
                  <div className="flex justify-between items-center p-2 bg-white rounded border border-brand-200">
                    <span>Valeur de l'équipe</span>
                    <span className="font-bold text-brand-700">40M€</span>
                  </div>
                  <div className="flex justify-between items-center p-2 bg-white rounded border border-brand-200">
                    <span>Valeur des actifs du projet (technologie, crédits potentiels, partenariats)</span>
                    <span className="font-bold text-brand-700">40-60M€</span>
                  </div>
                  <div className="flex justify-between items-center p-2 bg-white rounded border border-brand-200 font-medium">
                    <span>Valorisation Pre-Money</span>
                    <span className="font-bold text-brand-700">80-100M€</span>
                  </div>
                  <div className="flex justify-between items-center p-2 bg-green-50 rounded border border-green-200 font-medium">
                    <span>Levée prévue</span>
                    <span className="font-bold text-green-700">12M€</span>
                  </div>
                  <div className="flex justify-between items-center p-2 bg-amber-50 rounded border border-amber-200 font-medium">
                    <span>Valorisation Post-Money</span>
                    <span className="font-bold text-amber-700">92-112M€</span>
                  </div>
                </div>
              </div>
            </CardContent>
            <CardFooter className="bg-brand-50 border-t border-brand-100 text-sm text-brand-700 font-medium">
              La valorisation de l'équipe à 40M€ est justifiée par l'expertise unique et les connexions stratégiques qu'elle apporte au projet.
            </CardFooter>
          </Card>

          {/* Section 2: DCF Valorisation */}
          <Card className="mb-8 shadow-sm">
            <CardHeader>
              <div className="flex items-center gap-2">
                <TrendingUp className="h-6 w-6 text-brand-600" />
                <CardTitle>1️⃣ Méthode DCF (Discounted Cash Flow - Flux de trésorerie actualisés)</CardTitle>
              </div>
              <CardDescription>
                Analyse DCF avec projections financières sur 5 ans et croissance annuelle de 30% après les 2 premières années
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Table>
                <TableCaption>Projections financières sur 5 ans (en millions €) pour calcul DCF</TableCaption>
                <TableHeader>
                  <TableRow>
                    <TableHead>Année</TableHead>
                    <TableHead className="text-right">Revenus estimés (€)</TableHead>
                    <TableHead className="text-right">Bénéfices nets (€)</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {revenueData.map((row) => (
                    <TableRow key={row.year}>
                      <TableCell className="font-medium">{row.year}</TableCell>
                      <TableCell className="text-right">{row.revenue} M€</TableCell>
                      <TableCell className="text-right">{row.profit} M€</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              <div className="mt-6 bg-brand-50 p-4 rounded-lg border border-brand-100">
                <h4 className="text-lg font-medium text-brand-700 mb-2">Calcul de valorisation DCF (Discounted Cash Flow)</h4>
                <p className="text-gray-700">
                  La méthode DCF consiste à actualiser les flux de trésorerie futurs. En prenant un coefficient de valorisation de 10x le bénéfice net de l'année 5 :
                </p>
                <div className="bg-white p-3 rounded mt-2 border border-brand-200">
                  <p className="font-medium text-lg">
                    28,8 M€ × 10 = <span className="text-brand-700 font-bold">288 millions €</span>
                  </p>
                  <p className="text-gray-600">
                    Valorisation cible : <span className="text-brand-700 font-bold">≈ 260 millions €</span>
                  </p>
                </div>
              </div>
            </CardContent>
            <CardFooter className="bg-brand-50 border-t border-brand-100 text-sm italic text-brand-700">
              La valorisation par Discounted Cash Flow (DCF) est justifiée si les objectifs de revenus et de croissance sont atteints.
            </CardFooter>
          </Card>

          {/* Section 3: Comparaison Entreprises */}
          <Card className="mb-8 shadow-sm">
            <CardHeader>
              <div className="flex items-center gap-2">
                <BarChart3 className="h-6 w-6 text-blue-600" />
                <CardTitle>2️⃣ Méthode des comparables (Benchmarking)</CardTitle>
              </div>
              <CardDescription>
                Méthode de comparaison avec des startups similaires pour justifier la valorisation
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Entreprise</TableHead>
                    <TableHead>Industrie</TableHead>
                    <TableHead className="text-right">Valorisation</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {comparableCompanies.map((company) => (
                    <TableRow key={company.name}>
                      <TableCell className="font-medium">{company.name}</TableCell>
                      <TableCell>{company.industry}</TableCell>
                      <TableCell className="text-right">{company.valuation}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              <div className="mt-6 bg-blue-50 p-4 rounded-lg border border-blue-100">
                <p className="text-gray-700">
                  <span className="font-medium">GuTeco est similaire à Regen Network et Flowcarbon</span>, 
                  mais avec une approche plus complète (token + crédits carbone + staking + drones).
                </p>
                <p className="mt-2 text-blue-700 font-medium">
                  Une valorisation de 260M€ est donc réaliste si GuTeco réussit son expansion internationale et attire des investisseurs institutionnels.
                </p>
              </div>
            </CardContent>
          </Card>

          {/* Section 4: Valorisation basée sur les actifs */}
          <Card className="mb-8 shadow-sm">
            <CardHeader>
              <div className="flex items-center gap-2">
                <LineChart className="h-6 w-6 text-emerald-600" />
                <CardTitle>3️⃣ Méthode des actifs</CardTitle>
              </div>
              <CardDescription>
                Évaluation des crédits carbone, biodiversité et technologie
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div className="bg-gray-50 p-4 rounded-lg">
                  <p className="text-gray-700">
                    <span className="font-medium">GuTeco génère 500 000 crédits carbone/an.</span>
                  </p>
                  <ul className="mt-3 space-y-2">
                    <li className="flex items-start gap-2">
                      <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-emerald-600" />
                      <span>Si le prix du crédit carbone monte à <span className="font-medium text-emerald-700">60-70 €</span>, GuTeco pourrait atteindre <span className="font-medium text-emerald-700">35M€ de revenus/an</span> juste avec les crédits carbone.</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-emerald-600" />
                      <span>En ajoutant les <span className="font-medium text-emerald-700">crédits biodiversité, staking et stablecoin VCarbs</span>, les actifs totaux du projet pourraient dépasser <span className="font-medium text-emerald-700">100 millions € en 5 ans</span>.</span>
                    </li>
                  </ul>
                </div>

                <div className="bg-emerald-50 p-4 rounded-lg border border-emerald-100">
                  <h4 className="text-lg font-medium text-emerald-700 mb-2">Calcul de valorisation par les actifs</h4>
                  <p className="text-gray-700">
                    En appliquant un multiple de valorisation (2,5x les actifs estimés), on obtient :
                  </p>
                  <div className="bg-white p-3 rounded mt-2 border border-emerald-200">
                    <p className="font-medium text-lg">
                      2,5 × 100M€ = <span className="text-emerald-700 font-bold">250M€ – 300M€</span>
                    </p>
                    <p className="text-gray-600">
                      Valorisation cible : <span className="text-emerald-700 font-bold">≈ 260M€</span>
                    </p>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>

          {/* Section 5: Facteurs pouvant faire varier la valorisation */}
          <Card className="mb-8 shadow-sm">
            <CardHeader>
              <CardTitle>Facteurs pouvant influencer la valorisation</CardTitle>
              <CardDescription>
                Éléments susceptibles d'augmenter ou de diminuer la valeur de GuTeco
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="grid md:grid-cols-2 gap-6">
                <div className="bg-green-50 p-4 rounded-lg border border-green-100">
                  <h4 className="text-lg font-medium text-green-700 mb-2 flex items-center gap-2">
                    <TrendingUp className="h-5 w-5" />
                    Facteurs pouvant AUGMENTER la valorisation
                  </h4>
                  <ul className="space-y-2">
                    {positiveFactors.map((factor, index) => (
                      <li key={index} className="flex items-start gap-2">
                        <Check className="h-5 w-5 text-green-600 shrink-0 mt-0.5" />
                        <span className="text-gray-700">{factor}</span>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="bg-red-50 p-4 rounded-lg border border-red-100">
                  <h4 className="text-lg font-medium text-red-700 mb-2 flex items-center gap-2">
                    <AlertTriangle className="h-5 w-5" />
                    Facteurs pouvant DIMINUER la valorisation
                  </h4>
                  <ul className="space-y-2">
                    {negativeFactors.map((factor, index) => (
                      <li key={index} className="flex items-start gap-2">
                        <X className="h-5 w-5 text-red-600 shrink-0 mt-0.5" />
                        <span className="text-gray-700">{factor}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </CardContent>
          </Card>

          {/* Section 6: Valorisation progressive */}
          <Card className="mb-8 shadow-sm">
            <CardHeader>
              <CardTitle>Valorisation progressive</CardTitle>
              <CardDescription>
                Évolution attendue de la valorisation de GuTeco au fil du temps
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Étape</TableHead>
                    <TableHead>Valorisation</TableHead>
                    <TableHead>Description</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {valuationStages.map((stage) => (
                    <TableRow key={stage.stage}>
                      <TableCell className="font-medium">{stage.stage}</TableCell>
                      <TableCell className="font-bold text-brand-700">{stage.valuation}</TableCell>
                      <TableCell>{stage.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
            <CardFooter className="bg-amber-50 border-t border-amber-100 text-amber-800">
              Cette structure progressive de valorisation permet de rassurer les investisseurs tout en présentant 
              le potentiel de croissance à long terme.
            </CardFooter>
          </Card>

          {/* Conclusion */}
          <Card className="border-green-200 shadow-sm">
            <CardHeader className="bg-green-50 border-b border-green-100">
              <CardTitle className="text-green-800">Conclusion: Quelle est la valorisation actuelle de GuTeco?</CardTitle>
            </CardHeader>
            <CardContent className="pt-6">
              <div className="space-y-4">
                <div className="flex items-start gap-2">
                  <Check className="h-5 w-5 text-green-600 shrink-0 mt-0.5" />
                  <p className="text-gray-700">
                    <span className="font-medium">L'équipe de GuTeco est valorisée à 40M€</span>, ce qui est justifié 
                    compte tenu de l'expertise unique et des connexions stratégiques.
                  </p>
                </div>
                <div className="flex items-start gap-2">
                  <Check className="h-5 w-5 text-green-600 shrink-0 mt-0.5" />
                  <p className="text-gray-700">
                    <span className="font-medium">La valorisation Pre-Money entre 80M€ et 100M€</span> est réaliste 
                    en tenant compte de l'équipe et des premiers actifs du projet.
                  </p>
                </div>
                <div className="flex items-start gap-2">
                  <Check className="h-5 w-5 text-green-600 shrink-0 mt-0.5" />
                  <p className="text-gray-700">
                    <span className="font-medium">Après la levée de 12M€</span>, la valorisation Post-Money sera 
                    entre 92M€ et 112M€.
                  </p>
                </div>
                
                <Separator />
                
                <div className="flex items-start gap-2">
                  <ArrowRight className="h-5 w-5 text-amber-600 shrink-0 mt-0.5" />
                  <p className="text-gray-700">
                    <span className="font-medium">D'ici 5 ans, si GuTeco suit son plan</span>, la valorisation peut 
                    atteindre et dépasser 260M€ avec le développement des projets carbone.
                  </p>
                </div>
                
                <div className="bg-brand-50 p-4 rounded-lg border border-brand-100 mt-4">
                  <h4 className="font-medium text-brand-700 mb-2">Recommandation</h4>
                  <p className="text-gray-700">
                    Pour les investisseurs aujourd'hui, voici la perspective de valorisation:
                  </p>
                  <ul className="mt-2 space-y-2">
                    <li className="flex items-start gap-2">
                      <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-brand-600" />
                      <span>Aujourd'hui: <span className="font-medium text-brand-700">Entrée à 80-100M€</span> (valorisation Pre-Money)</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-brand-600" />
                      <span>Dans 2-3 ans: <span className="font-medium text-brand-700">Valorisation 200-250M€</span> possible avec l'atteinte des objectifs</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-brand-600" />
                      <span>Dans 5 ans: <span className="font-medium text-brand-700">260M€+</span> avec l'expansion internationale</span>
                    </li>
                  </ul>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default CompanyValuation;
