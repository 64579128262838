
import React from 'react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Users, Briefcase, TrendingUp, Award, Layers, ArrowRight, Handshake, BookOpen, Building, Globe, LineChart } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';

const InvestorStrategy = () => {
  const navigate = useNavigate();

  return (
    <section className="py-12 md:py-16 bg-gradient-to-b from-gray-50 to-white" id="investor-strategy">
      <div className="container mx-auto px-4">
        <div className="text-center mb-10">
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-brand-800 mb-4">
            Stratégie pour Attirer les Investisseurs
          </h2>
          <p className="text-gray-600 max-w-3xl mx-auto">
            Notre approche est conçue pour attirer des investisseurs stratégiques qui partagent notre vision 
            d'un avenir durable et souhaitent contribuer à la révolution du marché des crédits carbone.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8 max-w-6xl mx-auto">
          {/* Partenariats Stratégiques */}
          <Card className="border border-brand-100 shadow-sm hover:shadow-md transition-all">
            <CardHeader className="pb-2">
              <div className="rounded-full bg-brand-50 w-12 h-12 flex items-center justify-center mb-3">
                <Handshake className="w-6 h-6 text-brand-600" />
              </div>
              <CardTitle className="text-lg text-brand-700">Partenariats Stratégiques</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-sm text-gray-600">
                Nous avons développé des partenariats avec des acteurs clés du marché des crédits carbone, des institutions financières 
                et des entreprises engagées dans le développement durable pour renforcer notre crédibilité et notre portée.
              </p>
            </CardContent>
          </Card>

          {/* Structure d'Investissement */}
          <Card className="border border-brand-100 shadow-sm hover:shadow-md transition-all">
            <CardHeader className="pb-2">
              <div className="rounded-full bg-brand-50 w-12 h-12 flex items-center justify-center mb-3">
                <Layers className="w-6 h-6 text-brand-600" />
              </div>
              <CardTitle className="text-lg text-brand-700">Structure d'Investissement</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-sm text-gray-600">
                Notre structure d'investissement offre un équilibre entre capital-risque et obligations vertes, avec une 
                valorisation pré-money de 90M€ et une levée de fonds de 12M€ pour 11,76% d'équité.
              </p>
            </CardContent>
          </Card>

          {/* Proposition de Valeur */}
          <Card className="border border-brand-100 shadow-sm hover:shadow-md transition-all">
            <CardHeader className="pb-2">
              <div className="rounded-full bg-brand-50 w-12 h-12 flex items-center justify-center mb-3">
                <Award className="w-6 h-6 text-brand-600" />
              </div>
              <CardTitle className="text-lg text-brand-700">Proposition de Valeur</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-sm text-gray-600">
                Nous démontrons une proposition de valeur claire avec un retour sur investissement anticipé de 40% sur 3 ans, 
                soutenu par un marché des crédits carbone en expansion et notre technologie blockchain innovante.
              </p>
            </CardContent>
          </Card>

          {/* Stratégie de Communication */}
          <Card className="border border-brand-100 shadow-sm hover:shadow-md transition-all">
            <CardHeader className="pb-2">
              <div className="rounded-full bg-brand-50 w-12 h-12 flex items-center justify-center mb-3">
                <BookOpen className="w-6 h-6 text-brand-600" />
              </div>
              <CardTitle className="text-lg text-brand-700">Stratégie de Communication</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-sm text-gray-600">
                Notre stratégie de communication comprend des présentations ciblées pour les investisseurs, une présence active dans les 
                conférences sur le changement climatique et une communication transparente sur nos progrès et impact.
              </p>
            </CardContent>
          </Card>

          {/* Options de Sortie */}
          <Card className="border border-brand-100 shadow-sm hover:shadow-md transition-all">
            <CardHeader className="pb-2">
              <div className="rounded-full bg-brand-50 w-12 h-12 flex items-center justify-center mb-3">
                <TrendingUp className="w-6 h-6 text-brand-600" />
              </div>
              <CardTitle className="text-lg text-brand-700">Options de Sortie</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-sm text-gray-600">
                Nous offrons des perspectives de sortie attrayantes incluant des acquisitions potentielles par des acteurs majeurs 
                du marché, des entreprises technologiques et des institutions financières, ainsi qu'une possible entrée en bourse.
              </p>
            </CardContent>
          </Card>

          {/* Engagement Communautaire */}
          <Card className="border border-brand-100 shadow-sm hover:shadow-md transition-all">
            <CardHeader className="pb-2">
              <div className="rounded-full bg-brand-50 w-12 h-12 flex items-center justify-center mb-3">
                <Users className="w-6 h-6 text-brand-600" />
              </div>
              <CardTitle className="text-lg text-brand-700">Engagement Communautaire</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-sm text-gray-600">
                Notre modèle de DAO permet aux investisseurs de participer activement à la gouvernance du projet, créant 
                ainsi une communauté engagée d'acteurs partageant notre vision à long terme.
              </p>
            </CardContent>
          </Card>

          {/* Investisseurs Cibles */}
          <Card className="border border-brand-100 shadow-sm hover:shadow-md transition-all">
            <CardHeader className="pb-2">
              <div className="rounded-full bg-brand-50 w-12 h-12 flex items-center justify-center mb-3">
                <Building className="w-6 h-6 text-brand-600" />
              </div>
              <CardTitle className="text-lg text-brand-700">Investisseurs Cibles</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-sm text-gray-600">
                Nous ciblons principalement les fonds d'impact, family offices sensibles à l'environnement, business angels spécialisés 
                en blockchain/climat, et fonds institutionnels cherchant une exposition aux crédits carbone.
              </p>
            </CardContent>
          </Card>

          {/* Portée Internationale */}
          <Card className="border border-brand-100 shadow-sm hover:shadow-md transition-all">
            <CardHeader className="pb-2">
              <div className="rounded-full bg-brand-50 w-12 h-12 flex items-center justify-center mb-3">
                <Globe className="w-6 h-6 text-brand-600" />
              </div>
              <CardTitle className="text-lg text-brand-700">Portée Internationale</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-sm text-gray-600">
                Notre approche globale nous permet d'attirer des investisseurs du monde entier, avec une attention particulière 
                aux marchés européens, nord-américains et asiatiques sensibles aux enjeux climatiques.
              </p>
            </CardContent>
          </Card>

          {/* Métriques Clés */}
          <Card className="border border-brand-100 shadow-sm hover:shadow-md transition-all">
            <CardHeader className="pb-2">
              <div className="rounded-full bg-brand-50 w-12 h-12 flex items-center justify-center mb-3">
                <LineChart className="w-6 h-6 text-brand-600" />
              </div>
              <CardTitle className="text-lg text-brand-700">Métriques Clés</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-sm text-gray-600">
                Nous présentons des métriques transparentes sur la croissance des utilisateurs, l'impact environnemental mesurable, 
                la tokenisation des crédits carbone et les projections de revenus pour démontrer notre potentiel.
              </p>
            </CardContent>
          </Card>
        </div>

        <div className="flex justify-center mt-10">
          <Button 
            onClick={() => navigate('/investment-memorandum')}
            className="bg-brand-600 hover:bg-brand-700 flex items-center gap-2 text-white"
            size="lg"
          >
            <Briefcase className="h-4 w-4" />
            Consulter le Mémorandum d'Investissement
            <ArrowRight className="h-4 w-4 ml-1" />
          </Button>
        </div>
      </div>
    </section>
  );
};

export default InvestorStrategy;
