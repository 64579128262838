
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '@/components/Layout';
import { Button } from '@/components/ui/button';
import { ArrowLeft, HelpCircle, MapPin, Users, TreePine, Globe, Droplet, BookOpen, Rocket, Wind, Cpu, Leaf } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription, CardFooter } from '@/components/ui/card';
import PricingInfo from '@/components/PricingInfo';

const Projects = () => {
  const navigate = useNavigate();
  const [showPricingInfo, setShowPricingInfo] = useState<boolean>(false);

  const handleGoBack = () => {
    navigate('/');
  };

  const fictionalProjects = [
    {
      id: 1,
      title: "Reforestation Côtière",
      location: "Côte Ouest, Afrique",
      size: 1200,
      credits: 6000,
      description: "Plantation de mangroves et restauration des écosystèmes côtiers pour protéger les littoraux et créer des puits de carbone naturels.",
      icon: <TreePine className="h-10 w-10 text-green-600" />,
      investors: 24,
      price: 28,
      category: "Reforestation"
    },
    {
      id: 2,
      title: "Énergie Solaire Villageoise",
      location: "Savane centrale",
      size: 350,
      credits: 1750,
      description: "Installation de panneaux solaires dans 15 villages éloignés, remplaçant les générateurs diesel et fournissant de l'électricité propre à 2200 personnes.",
      icon: <Globe className="h-10 w-10 text-amber-500" />,
      investors: 31,
      price: 32,
      category: "Énergie"
    },
    {
      id: 3,
      title: "Conservation Forestière",
      location: "Forêt tropicale Sud",
      size: 45000,
      credits: 225000,
      description: "Protection de forêts anciennes menacées par la déforestation, préservant la biodiversité et les moyens de subsistance des communautés locales.",
      icon: <Leaf className="h-10 w-10 text-emerald-600" />,
      investors: 47,
      price: 30,
      category: "Conservation"
    },
    {
      id: 4,
      title: "Captage Méthane Agricole",
      location: "Terres agricoles Est",
      size: 2800,
      credits: 14000,
      description: "Systèmes de capture du méthane provenant des déchets agricoles, réduisant les émissions et produisant du biogaz pour l'énergie locale.",
      icon: <Wind className="h-10 w-10 text-teal-500" />,
      investors: 19,
      price: 26,
      category: "Agriculture"
    },
    {
      id: 5,
      title: "Purification d'Eau Solaire",
      location: "Région des lacs",
      size: 180,
      credits: 900,
      description: "Technologie de purification d'eau fonctionnant à l'énergie solaire, éliminant le besoin de bouillir l'eau avec du bois et améliorant la santé communautaire.",
      icon: <Droplet className="h-10 w-10 text-blue-500" />,
      investors: 15,
      price: 24,
      category: "Eau"
    },
    {
      id: 6,
      title: "Transport Écologique Urbain",
      location: "Zone métropolitaine",
      size: 520,
      credits: 2600,
      description: "Réseau de transport électrique dans les zones urbaines en croissance, réduisant la dépendance aux véhicules à essence et améliorant la qualité de l'air.",
      icon: <Rocket className="h-10 w-10 text-indigo-500" />,
      investors: 28,
      price: 35,
      category: "Transport"
    },
    {
      id: 7,
      title: "Éducation Environnementale",
      location: "Réseau scolaire national",
      size: 75,
      credits: 1500,
      description: "Programmes éducatifs sur la durabilité dans 120 écoles, formant la prochaine génération aux pratiques respectueuses du climat et aux solutions innovantes.",
      icon: <BookOpen className="h-10 w-10 text-purple-600" />,
      investors: 22,
      price: 27,
      category: "Éducation"
    },
    {
      id: 8,
      title: "Cuisinières Efficaces",
      location: "Communautés rurales",
      size: 110,
      credits: 3300,
      description: "Distribution de cuisinières améliorées réduisant la consommation de bois de 70% et diminuant la pollution de l'air intérieur pour 5000 foyers.",
      icon: <Cpu className="h-10 w-10 text-orange-500" />,
      investors: 33,
      price: 23,
      category: "Technologie"
    },
    {
      id: 9,
      title: "Ferme Éolienne Côtière",
      location: "Littoral Nord",
      size: 850,
      credits: 4250,
      description: "Installation d'éoliennes produisant de l'électricité propre pour 35 000 personnes et réduisant la dépendance aux centrales à charbon polluantes.",
      icon: <Wind className="h-10 w-10 text-cyan-600" />,
      investors: 42,
      price: 31,
      category: "Énergie"
    }
  ];

  return (
    <Layout>
      <div className="container mx-auto px-4 py-8">
        <Button 
          variant="outline" 
          className="mb-6 flex items-center gap-2"
          onClick={handleGoBack}
        >
          <ArrowLeft className="h-4 w-4" />
          <span>Retour à l'accueil</span>
        </Button>
        
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-8">
          <div>
            <h1 className="text-2xl font-bold text-brand-800 mb-2">Projets de Crédits Carbone Disponibles</h1>
            <p className="text-gray-600 mb-3">
              Découvrez notre catalogue de projets écologiques en Afrique et investissez dans des crédits carbone à impact positif sur les communautés locales.
            </p>
            <div className="bg-brand-50 border border-brand-100 rounded-lg p-4 mb-4">
              <h2 className="text-lg font-semibold text-brand-700 mb-2">Innovation Technologique</h2>
              <p className="text-gray-700">
                Nos projets bénéficient des dernières avancées technologiques pour maximiser leur efficacité et leur impact :
              </p>
              <ul className="list-disc pl-5 mt-2 space-y-1">
                <li>Surveillance par <strong>drones équipés de capteurs</strong> pour un suivi précis de la croissance des forêts et des écosystèmes</li>
                <li>Analyse des données par <strong>intelligence artificielle</strong> pour optimiser les interventions et mesurer l'impact carbone avec précision</li>
                <li>Modélisation prédictive permettant d'anticiper l'évolution des projets et d'ajuster les stratégies en temps réel</li>
              </ul>
            </div>
          </div>
          <Button 
            variant="outline" 
            className="flex items-center justify-center w-full md:w-auto gap-2 bg-gray-700 hover:bg-gray-800 text-white shadow-md border-gray-800 font-medium py-3 px-6 text-base"
            onClick={() => setShowPricingInfo(true)}
            size="lg"
          >
            <HelpCircle className="h-6 w-6" />
            <span>Comment les prix sont-ils déterminés ?</span>
          </Button>
        </div>
        
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
          {fictionalProjects.map((project) => (
            <Card key={project.id} className="overflow-hidden hover:shadow-lg transition-shadow">
              <CardHeader className="pb-2">
                <div className="bg-brand-50 p-4 rounded-full w-16 h-16 flex items-center justify-center mb-4">
                  {project.icon}
                </div>
                <CardTitle>{project.title}</CardTitle>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-1 text-gray-500 text-sm">
                    <MapPin className="h-4 w-4" />
                    <span>{project.location}</span>
                  </div>
                  <span className="text-xs font-medium px-2.5 py-0.5 rounded bg-gray-100 text-gray-800">
                    {project.category}
                  </span>
                </div>
              </CardHeader>
              
              <CardContent>
                <CardDescription className="text-sm mb-4">
                  {project.description}
                </CardDescription>
                
                <div className="grid grid-cols-2 gap-4 mt-4">
                  <div className="bg-gray-50 p-3 rounded-md text-center">
                    <p className="text-lg font-semibold text-brand-700">{project.size} ha</p>
                    <p className="text-xs text-gray-500">Surface</p>
                  </div>
                  <div className="bg-gray-50 p-3 rounded-md text-center">
                    <p className="text-lg font-semibold text-brand-700">{project.credits}</p>
                    <p className="text-xs text-gray-500">Crédits</p>
                  </div>
                </div>
              </CardContent>
              
              <CardFooter className="flex items-center justify-between border-t p-4 bg-gray-50">
                <div className="flex items-center gap-1">
                  <Users className="h-4 w-4 text-brand-600" />
                  <span className="text-sm text-gray-600">{project.investors} investisseurs</span>
                </div>
                <div className="font-semibold text-brand-700">{project.price} €/crédit</div>
              </CardFooter>
            </Card>
          ))}
        </div>
        
        <div className="flex justify-center">
          <Button 
            className="bg-brand-600 hover:bg-brand-700 px-8 py-3 text-white"
            onClick={() => navigate('/simulator')}
          >
            Simuler mon projet de crédits carbone
          </Button>
        </div>
      </div>
      
      <PricingInfo 
        open={showPricingInfo} 
        onOpenChange={setShowPricingInfo} 
      />
    </Layout>
  );
};

export default Projects;
