
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Layout } from "@/components/Layout";
import { Button } from "@/components/ui/button";
import { AlertTriangle } from "lucide-react";

const Error = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const errorMessage = location.state?.message || "Une erreur inattendue s'est produite";
  const errorType = location.state?.type || "general";
  const errorCode = location.state?.code || null;
  
  // Customize error information based on type
  const getErrorInfo = () => {
    switch (errorType) {
      case "network":
        return {
          title: "Erreur de connexion",
          icon: <AlertTriangle className="h-16 w-16 text-red-500" />,
          description: "Impossible de se connecter au serveur. Vérifiez votre connexion internet.",
          bgColor: "bg-red-50",
          borderColor: "border-red-100",
          textColor: "text-red-700"
        };
      case "auth":
        return {
          title: "Erreur d'authentification",
          icon: <AlertTriangle className="h-16 w-16 text-amber-500" />,
          description: errorMessage,
          bgColor: "bg-amber-50",
          borderColor: "border-amber-100",
          textColor: "text-amber-700"
        };
      case "notFound":
        return {
          title: "Page introuvable",
          icon: <AlertTriangle className="h-16 w-16 text-blue-500" />,
          description: "La page que vous recherchez n'existe pas ou a été déplacée.",
          bgColor: "bg-blue-50",
          borderColor: "border-blue-100",
          textColor: "text-blue-700"
        };
      default:
        return {
          title: "Une erreur est survenue",
          icon: <AlertTriangle className="h-16 w-16 text-amber-500" />,
          description: errorMessage,
          bgColor: "bg-amber-50", 
          borderColor: "border-amber-100",
          textColor: "text-amber-700"
        };
    }
  };

  const errorInfo = getErrorInfo();

  return (
    <Layout>
      <div className="flex flex-col items-center justify-center py-20">
        <div className={`${errorInfo.bgColor} p-4 rounded-full mb-6`}>
          {errorInfo.icon}
        </div>
        <h1 className="text-3xl font-bold text-gray-800 mb-4">
          {errorInfo.title}
        </h1>
        {errorCode && (
          <div className="text-lg font-semibold text-gray-600 mb-4">
            Code: {errorCode}
          </div>
        )}
        <div className={`bg-white shadow-md rounded-lg p-6 max-w-md w-full border ${errorInfo.borderColor}`}>
          <p className="text-gray-600 mb-6 text-center">{errorInfo.description}</p>
          <div className={`flex items-center mb-6 p-3 ${errorInfo.bgColor} rounded-md border ${errorInfo.borderColor}`}>
            <AlertTriangle className={`h-5 w-5 ${errorInfo.textColor} mr-3 flex-shrink-0`} />
            <p className={`text-sm ${errorInfo.textColor}`}>
              Si vous pensez qu'il s'agit d'une erreur, veuillez contacter notre équipe de support.
            </p>
          </div>
          <div className="flex justify-center space-x-4">
            <Button onClick={() => navigate(-1)} variant="outline" size="lg" className="w-1/2">
              Retour
            </Button>
            <Button onClick={() => navigate('/')} size="lg" className="w-1/2">
              Accueil
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Error;
