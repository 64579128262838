import React, { Component, ErrorInfo, ReactNode } from 'react';
import { toast } from '@/hooks/use-toast';
import { Navigate } from 'react-router-dom';
import { AlertTriangle } from 'lucide-react';

interface Props {
  children: ReactNode;
  fallback?: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
  redirect: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null,
    errorInfo: null,
    redirect: false
  };

  public static getDerivedStateFromError(error: Error): Partial<State> {
    // Mettre à jour l'état pour afficher l'UI de secours
    return { 
      hasError: true, 
      error,
      redirect: false
    };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Erreur attrapée par ErrorBoundary:", error, errorInfo);
    
    // Enregistrer l'erreur pour analyse ultérieure
    this.logError(error, errorInfo);
    
    toast({
      title: "Une erreur s'est produite",
      description: "L'application a rencontré un problème, nous essayons de le résoudre.",
      variant: "destructive",
    });

    // Si l'erreur est critique, définir redirect sur true après un délai
    // pour permettre à l'utilisateur de voir le toast
    if (this.isCriticalError(error)) {
      setTimeout(() => {
        this.setState({ redirect: true });
      }, 1500);
    }
  }

  private isCriticalError(error: Error): boolean {
    // Identifier les erreurs critiques qui devraient rediriger vers la page d'erreur
    const message = error.message.toLowerCase();
    return (
      message.includes("failed to fetch") ||
      message.includes("network") ||
      message.includes("chunk failed") ||
      message.includes("loading chunk") ||
      message.includes("unexpected token") ||
      error.name === "ChunkLoadError" ||
      error.name === "SyntaxError"
    );
  }

  private logError(error: Error, errorInfo: ErrorInfo): void {
    // Ici, vous pourriez envoyer l'erreur à un service de suivi des erreurs comme Sentry
    console.group("Détails de l'erreur pour le support technique");
    console.error("Nom:", error.name);
    console.error("Message:", error.message);
    console.error("Stack:", error.stack);
    console.error("Composant:", errorInfo.componentStack);
    console.groupEnd();
  }

  private resetError = () => {
    this.setState({
      hasError: false,
      error: null,
      errorInfo: null,
      redirect: false
    });
  };

  public render() {
    if (this.state.redirect) {
      // Rediriger vers la page d'erreur avec des informations sur l'erreur
      return (
        <Navigate 
          to="/error" 
          state={{ 
            message: this.state.error?.message || "Une erreur inattendue s'est produite",
            type: this.isCriticalError(this.state.error as Error) ? "network" : "general",
            code: this.state.error?.name
          }} 
          replace 
        />
      );
    }

    if (this.state.hasError) {
      // Afficher le fallback ou l'UI de secours par défaut
      if (this.props.fallback) {
        return this.props.fallback;
      }
      
      return (
        <div className="min-h-screen flex flex-col items-center justify-center p-4 bg-gray-50">
          <div className="text-center max-w-md">
            <div className="bg-red-100 p-4 rounded-full mb-6 mx-auto w-16 h-16 flex items-center justify-center">
              <AlertTriangle className="h-8 w-8 text-red-600" />
            </div>
            <h1 className="text-2xl font-bold text-red-600 mb-4">Une erreur est survenue</h1>
            <p className="text-gray-700 mb-6">
              L'application a rencontré un problème. Veuillez rafraîchir la page ou réessayer plus tard.
            </p>
            {this.state.error && (
              <pre className="bg-gray-100 p-4 rounded text-sm text-left overflow-auto max-h-40 mb-4">
                {this.state.error.message}
              </pre>
            )}
            <div className="flex space-x-4 justify-center">
              <button
                onClick={() => window.location.reload()}
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
              >
                Rafraîchir
              </button>
              <button
                onClick={this.resetError}
                className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 transition-colors"
              >
                Continuer
              </button>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
