import React from 'react';
import { 
  Card, 
  CardContent, 
  CardDescription, 
  CardFooter, 
  CardHeader, 
  CardTitle 
} from "@/components/ui/card";
import { Lightbulb, TrendingUp, Sparkles, AlertCircle } from 'lucide-react';

const AIPredictions = () => {
  return (
    <div className="space-y-6">
      <div className="flex items-center gap-2 mb-2">
        <Sparkles className="h-5 w-5 text-brand-600" />
        <h2 className="text-xl font-semibold text-brand-800">Analyses et Prévisions IA</h2>
      </div>
      
      <p className="text-gray-600 mb-4">
        Ces analyses sont générées par des modèles d'intelligence artificielle avancés 
        et basées sur les données actuelles du marché carbone. Elles sont fournies à titre informatif.
      </p>
      
      <div className="grid md:grid-cols-2 gap-6">
        <Card className="border-l-4 border-l-brand-500">
          <CardHeader className="pb-2">
            <div className="flex items-center gap-2">
              <TrendingUp className="h-5 w-5 text-brand-600" />
              <CardTitle className="text-lg">Prévisions du marché</CardTitle>
            </div>
            <CardDescription>Tendances à court et moyen terme</CardDescription>
          </CardHeader>
          <CardContent>
            <ul className="space-y-3 text-gray-700">
              <li className="flex gap-2">
                <span className="text-brand-600 font-semibold">2025-2026:</span>
                <span>Augmentation prévue de 15-20% du prix moyen des crédits carbone due aux nouvelles réglementations européennes.</span>
              </li>
              <li className="flex gap-2">
                <span className="text-brand-600 font-semibold">2026-2027:</span>
                <span>Expansion du marché vers de nouvelles régions, notamment l'Asie du Sud-Est et l'Afrique, avec des projets de conservation forestière.</span>
              </li>
              <li className="flex gap-2">
                <span className="text-brand-600 font-semibold">2028-2030:</span>
                <span>Stabilisation possible entre 40-60€ par tonne avec l'harmonisation des standards internationaux.</span>
              </li>
            </ul>
          </CardContent>
        </Card>
        
        <Card className="border-l-4 border-l-emerald-500">
          <CardHeader className="pb-2">
            <div className="flex items-center gap-2">
              <Lightbulb className="h-5 w-5 text-emerald-600" />
              <CardTitle className="text-lg">Analyses sectorielles</CardTitle>
            </div>
            <CardDescription>Impact par secteur d'activité</CardDescription>
          </CardHeader>
          <CardContent>
            <ul className="space-y-3 text-gray-700">
              <li className="flex gap-2">
                <span className="text-emerald-600 font-semibold">Transport:</span>
                <span>Fort potentiel d'investissement dans les projets de mobilité durable, avec des rendements estimés à 8-12% sur 5 ans.</span>
              </li>
              <li className="flex gap-2">
                <span className="text-emerald-600 font-semibold">Agriculture:</span>
                <span>Les projets d'agriculture régénérative pourraient générer 80% plus de crédits carbone d'ici 2027 qu'aujourd'hui.</span>
              </li>
              <li className="flex gap-2">
                <span className="text-emerald-600 font-semibold">Énergie:</span>
                <span>La transition vers les énergies renouvelables continuera de dominer le marché, représentant ~40% des crédits d'ici 2025.</span>
              </li>
            </ul>
          </CardContent>
        </Card>
        
        <Card className="md:col-span-2 border-l-4 border-l-amber-500">
          <CardHeader className="pb-2">
            <div className="flex items-center gap-2">
              <AlertCircle className="h-5 w-5 text-amber-600" />
              <CardTitle className="text-lg">Facteurs d'influence à surveiller</CardTitle>
            </div>
            <CardDescription>Éléments pouvant impacter le marché du carbone</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="grid md:grid-cols-2 gap-4">
              <div className="space-y-2">
                <h4 className="font-medium text-gray-800">Facteurs positifs</h4>
                <ul className="space-y-2 text-gray-700">
                  <li className="flex items-start gap-2">
                    <div className="h-5 w-5 rounded-full bg-green-100 flex items-center justify-center text-green-700 flex-shrink-0 mt-0.5">+</div>
                    <span>Renforcement des engagements des entreprises vers le net-zéro</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <div className="h-5 w-5 rounded-full bg-green-100 flex items-center justify-center text-green-700 flex-shrink-0 mt-0.5">+</div>
                    <span>Accords internationaux sur la tarification du carbone</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <div className="h-5 w-5 rounded-full bg-green-100 flex items-center justify-center text-green-700 flex-shrink-0 mt-0.5">+</div>
                    <span>Innovations technologiques en matière de surveillance et vérification</span>
                  </li>
                </ul>
              </div>
              
              <div className="space-y-2">
                <h4 className="font-medium text-gray-800">Facteurs de risque</h4>
                <ul className="space-y-2 text-gray-700">
                  <li className="flex items-start gap-2">
                    <div className="h-5 w-5 rounded-full bg-red-100 flex items-center justify-center text-red-700 flex-shrink-0 mt-0.5">-</div>
                    <span>Fragmentation continue des standards de certification</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <div className="h-5 w-5 rounded-full bg-red-100 flex items-center justify-center text-red-700 flex-shrink-0 mt-0.5">-</div>
                    <span>Ralentissements économiques majeurs limitant les investissements</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <div className="h-5 w-5 rounded-full bg-red-100 flex items-center justify-center text-red-700 flex-shrink-0 mt-0.5">-</div>
                    <span>Défis de permanence liés aux catastrophes naturelles</span>
                  </li>
                </ul>
              </div>
            </div>
          </CardContent>
          <CardFooter className="bg-amber-50 text-sm text-amber-800 italic">
            Note: Ces prévisions sont sujettes à révision en fonction de l'évolution des politiques climatiques mondiales.
          </CardFooter>
        </Card>
      </div>
      
      <div className="text-center text-sm text-gray-500 mt-4">
        Analyses générées par notre modèle d'IA spécialisé dans les marchés carbone, basées sur les données de janvier 2025.
        Les informations fournies ne constituent pas des conseils financiers.
      </div>
    </div>
  )
}

export default AIPredictions;
