
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Layout from '@/components/Layout';
import { Button } from '@/components/ui/button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { ArrowLeft, Shield, FileText, Lock, Award, Leaf, Coins } from 'lucide-react';
import { Separator } from '@/components/ui/separator';

const Legal = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<string>('terms');

  const handleGoBack = () => {
    navigate('/');
  };

  useEffect(() => {
    const tab = searchParams.get('tab');
    if (tab && ['terms', 'privacy', 'legal', 'certifications'].includes(tab)) {
      setActiveTab(tab);
    }
  }, [searchParams]);

  const handleTabChange = (value: string) => {
    setActiveTab(value);
    navigate(`/legal?tab=${value}`, { replace: true });
  };

  return (
    <Layout className="pt-0">
      <div className="container mx-auto px-4 pt-4 pb-8">
        <Button 
          variant="outline" 
          className="mb-4 flex items-center gap-2"
          onClick={handleGoBack}
        >
          <ArrowLeft className="h-4 w-4" />
          <span>Retour à l'accueil</span>
        </Button>
        
        <div className="flex flex-col mb-4">
          <h1 className="text-2xl font-bold text-brand-800 mb-2">Informations légales</h1>
          <p className="text-gray-600">
            Consultez les informations légales, politiques et certifications relatives à notre plateforme.
          </p>
        </div>
        
        <Tabs value={activeTab} onValueChange={handleTabChange} className="space-y-6">
          <div className="pt-2 pb-2 bg-background z-10">
            <TabsList className="w-full grid grid-cols-2 md:grid-cols-4 gap-2">
              <TabsTrigger value="terms" className="data-[state=active]:bg-brand-100 data-[state=active]:text-brand-800">
                <FileText className="h-4 w-4 mr-2" /> Conditions d'utilisation
              </TabsTrigger>
              <TabsTrigger value="privacy" className="data-[state=active]:bg-brand-100 data-[state=active]:text-brand-800">
                <Lock className="h-4 w-4 mr-2" /> Politique de confidentialité
              </TabsTrigger>
              <TabsTrigger value="legal" className="data-[state=active]:bg-brand-100 data-[state=active]:text-brand-800">
                <Shield className="h-4 w-4 mr-2" /> Mentions légales
              </TabsTrigger>
              <TabsTrigger value="certifications" className="data-[state=active]:bg-brand-100 data-[state=active]:text-brand-800">
                <Award className="h-4 w-4 mr-2" /> Certifications
              </TabsTrigger>
            </TabsList>
          </div>
          
          <TabsContent value="terms">
            <Card>
              <CardHeader>
                <CardTitle className="text-xl">Conditions Générales d'Utilisation</CardTitle>
                <CardDescription>
                  Dernière mise à jour: 1 Juin 2023
                </CardDescription>
              </CardHeader>
              <CardContent className="space-y-6">
                <section>
                  <h3 className="font-semibold text-lg mb-2">1. Acceptation des conditions</h3>
                  <p className="text-gray-600">
                    En accédant ou en utilisant la plateforme Guteco, vous reconnaissez avoir lu, compris et accepté d'être lié par les présentes conditions générales d'utilisation. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser notre plateforme.
                  </p>
                </section>
                
                <section>
                  <h3 className="font-semibold text-lg mb-2">2. Description du service</h3>
                  <p className="text-gray-600">
                    Guteco est une plateforme qui met en relation les porteurs de projets écologiques avec les investisseurs pour la tokenisation et l'échange de crédits carbone sous forme de NFT.
                  </p>
                </section>
                
                <section>
                  <h3 className="font-semibold text-lg mb-2">3. Inscription et compte utilisateur</h3>
                  <p className="text-gray-600">
                    Pour accéder à certaines fonctionnalités de la plateforme, vous devrez créer un compte. Vous êtes responsable de maintenir la confidentialité de vos informations de compte et de toutes les activités qui se produisent sous votre compte.
                  </p>
                </section>
                
                <section>
                  <h3 className="font-semibold text-lg mb-2">4. Propriété intellectuelle</h3>
                  <p className="text-gray-600">
                    Tout contenu présent sur la plateforme, y compris les textes, graphiques, logos, et images, est la propriété de Guteco ou de ses concédants de licence et est protégé par les lois sur la propriété intellectuelle.
                  </p>
                </section>
                
                <section className="border-t pt-6 mt-6">
                  <h3 className="font-semibold text-lg mb-2">5. Tokens et cryptomonnaies</h3>
                  <div className="space-y-4">
                    <div>
                      <h4 className="font-medium text-brand-800 flex items-center gap-2 mb-1">
                        <Leaf className="h-5 w-5 text-highlight" />Token GUTECO (GTC)
                      </h4>
                      <p className="text-gray-600">
                        Le token GUTECO (GTC) est un token utilitaire et de gouvernance sur notre plateforme. Il permet aux détenteurs de :
                      </p>
                      <ul className="list-disc pl-6 mt-2 space-y-1 text-gray-600">
                        <li>Participer aux votes et à la gouvernance de la DAO</li>
                        <li>Recevoir des réductions sur les frais de transaction</li>
                        <li>Accéder à des fonctionnalités exclusives de la plateforme</li>
                        <li>Recevoir une part des revenus générés par la plateforme</li>
                      </ul>
                      <p className="text-gray-600 mt-2">
                        Les tokens GUTECO ne constituent pas des titres financiers et ne donnent pas droit à des dividendes. Leur valeur peut fluctuer et leur achat comporte des risques.
                      </p>
                    </div>
                    
                    <div>
                      <h4 className="font-medium text-brand-800 flex items-center gap-2 mb-1">
                        <Coins className="h-5 w-5 text-highlight" />Stablecoin VCarbs
                      </h4>
                      <p className="text-gray-600">
                        La stablecoin VCarbs est une cryptomonnaie stable indexée sur l'euro (1 VCarbs = 1 EUR) utilisée sur notre plateforme pour :
                      </p>
                      <ul className="list-disc pl-6 mt-2 space-y-1 text-gray-600">
                        <li>Faciliter les transactions d'achat et de vente de crédits carbone</li>
                        <li>Réduire la volatilité et les risques pour les utilisateurs</li>
                        <li>Fournir un moyen de paiement stable pour les porteurs de projet</li>
                        <li>Garantir la transparence et la traçabilité des flux financiers</li>
                      </ul>
                      <p className="text-gray-600 mt-2">
                        La stablecoin VCarbs est adossée à des réserves en euros détenues par notre partenaire financier et est auditée régulièrement pour garantir sa solvabilité.
                      </p>
                    </div>
                  </div>
                </section>
                
                <section>
                  <h3 className="font-semibold text-lg mb-2">6. Limitation de responsabilité</h3>
                  <p className="text-gray-600">
                    Guteco ne peut être tenu responsable des dommages directs ou indirects résultant de l'utilisation ou de l'impossibilité d'utiliser notre plateforme, y compris mais sans s'y limiter, la perte de données, de profits ou d'opportunités commerciales.
                  </p>
                </section>
                
                <section>
                  <h3 className="font-semibold text-lg mb-2">7. Modification des conditions</h3>
                  <p className="text-gray-600">
                    Nous nous réservons le droit de modifier ces conditions à tout moment. Les modifications prendront effet dès leur publication sur la plateforme. Votre utilisation continue de la plateforme après de telles modifications constitue votre acceptation des nouvelles conditions.
                  </p>
                </section>
                
                <section>
                  <h3 className="font-semibold text-lg mb-2">8. Loi applicable et juridiction</h3>
                  <p className="text-gray-600">
                    Ces conditions sont régies par les lois françaises. Tout litige relatif à l'interprétation ou à l'exécution de ces conditions sera soumis à la compétence exclusive des tribunaux de Paris, France.
                  </p>
                </section>
              </CardContent>
            </Card>
          </TabsContent>
          
          <TabsContent value="privacy">
            <Card>
              <CardHeader>
                <CardTitle className="text-xl">Politique de Confidentialité</CardTitle>
                <CardDescription>
                  Dernière mise à jour: 1 Juin 2023
                </CardDescription>
              </CardHeader>
              <CardContent className="space-y-6">
                <section>
                  <h3 className="font-semibold text-lg mb-2">1. Collecte des informations</h3>
                  <p className="text-gray-600">
                    Nous collectons des informations lorsque vous vous inscrivez sur notre plateforme, vous connectez à votre compte, effectuez une transaction, et/ou vous déconnectez. Les informations collectées incluent votre nom, votre adresse e-mail, votre adresse de portefeuille numérique, et les données relatives à vos transactions.
                  </p>
                </section>
                
                <section>
                  <h3 className="font-semibold text-lg mb-2">2. Utilisation des informations</h3>
                  <p className="text-gray-600">
                    Les informations que nous collectons sont utilisées pour :
                  </p>
                  <ul className="list-disc pl-6 mt-2 space-y-1 text-gray-600">
                    <li>Personnaliser votre expérience</li>
                    <li>Améliorer notre plateforme</li>
                    <li>Traiter vos transactions</li>
                    <li>Administrer un concours, une promotion, une enquête ou une autre fonctionnalité du site</li>
                    <li>Vous envoyer des e-mails périodiques</li>
                  </ul>
                </section>
                
                <section>
                  <h3 className="font-semibold text-lg mb-2">3. Protection des informations</h3>
                  <p className="text-gray-600">
                    Nous mettons en œuvre diverses mesures de sécurité pour maintenir la sécurité de vos informations personnelles. Nous utilisons un cryptage avancé pour protéger les informations sensibles transmises en ligne.
                  </p>
                </section>
                
                <section>
                  <h3 className="font-semibold text-lg mb-2">4. Cookies</h3>
                  <p className="text-gray-600">
                    Nous utilisons des cookies pour améliorer votre expérience sur notre plateforme, comprendre les tendances d'utilisation et administrer le site. Vous pouvez choisir de faire en sorte que votre ordinateur vous avertisse chaque fois qu'un cookie est envoyé, ou vous pouvez choisir de désactiver tous les cookies. Cela se fait par le biais des paramètres de votre navigateur.
                  </p>
                </section>
                
                <section>
                  <h3 className="font-semibold text-lg mb-2">5. Divulgation à des tiers</h3>
                  <p className="text-gray-600">
                    Nous ne vendons, n'échangeons, ni ne transférons de quelque autre manière que ce soit vos informations personnelles identifiables à des tiers. Cela n'inclut pas les tiers de confiance qui nous aident à exploiter notre site web, à mener nos activités, ou à vous servir, tant que ces parties conviennent de garder ces informations confidentielles.
                  </p>
                </section>
                
                <section>
                  <h3 className="font-semibold text-lg mb-2">6. Conformité au RGPD</h3>
                  <p className="text-gray-600">
                    En tant qu'entreprise opérant dans l'Union européenne, nous nous conformons au Règlement général sur la protection des données (RGPD). Cela signifie que vous avez le droit d'accéder, de rectifier, de supprimer et de limiter le traitement de vos données personnelles.
                  </p>
                </section>
                
                <section>
                  <h3 className="font-semibold text-lg mb-2">7. Modifications de la politique de confidentialité</h3>
                  <p className="text-gray-600">
                    Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Les modifications prendront effet dès leur publication sur cette page. Nous vous encourageons à consulter régulièrement cette page pour prendre connaissance des changements.
                  </p>
                </section>
                
                <section>
                  <h3 className="font-semibold text-lg mb-2">8. Nous contacter</h3>
                  <p className="text-gray-600">
                    Si vous avez des questions concernant cette politique de confidentialité, vous pouvez nous contacter à l'adresse suivante : gtc.vcarbs@gmail.com ou par téléphone au +33650849475.
                  </p>
                </section>
              </CardContent>
            </Card>
          </TabsContent>
          
          <TabsContent value="legal">
            <Card>
              <CardHeader>
                <CardTitle className="text-xl">Mentions Légales</CardTitle>
                <CardDescription>
                  Dernière mise à jour: 1 Juin 2023
                </CardDescription>
              </CardHeader>
              <CardContent className="space-y-6">
                <section>
                  <h3 className="font-semibold text-lg mb-2">Éditeur du site</h3>
                  <p className="text-gray-600">
                    <strong>Raison sociale :</strong> Guteco SAS<br />
                    <strong>Forme juridique :</strong> Société par Actions Simplifiée<br />
                    <strong>Capital social :</strong> 10 000 €<br />
                    <strong>Siège social :</strong> 3, rue Balny d'Avricourt 75017 Paris<br />
                    <strong>SIRET :</strong> 123 456 789 00010<br />
                    <strong>N° TVA intracommunautaire :</strong> FR12345678900<br />
                    <strong>Directeur de la publication :</strong> Jean-Paul Dupont
                  </p>
                </section>
                
                <section>
                  <h3 className="font-semibold text-lg mb-2">Hébergement</h3>
                  <p className="text-gray-600">
                    <strong>Nom :</strong> Amazon Web Services (AWS)<br />
                    <strong>Adresse :</strong> 38 Avenue John F. Kennedy, L-1855 Luxembourg<br />
                    <strong>Téléphone :</strong> +352 26 73 30 00
                  </p>
                </section>
                
                <section>
                  <h3 className="font-semibold text-lg mb-2">Tokens et Cryptomonnaies</h3>
                  <Separator className="my-4" />
                  
                  <div className="grid md:grid-cols-2 gap-6">
                    <div className="border p-4 rounded-lg bg-gray-50">
                      <div className="flex items-center gap-3 mb-3">
                        <div className="w-10 h-10 bg-brand-100 rounded-full flex items-center justify-center">
                          <Leaf className="h-6 w-6 text-brand-600" />
                        </div>
                        <h4 className="font-semibold text-brand-800">Token GUTECO (GTC)</h4>
                      </div>
                      
                      <p className="text-gray-600 mb-4">
                        Le token GUTECO est l'élément central de notre écosystème. Il s'agit d'un token utilitaire et de gouvernance permettant de participer activement à l'évolution de la plateforme et d'accéder à des avantages exclusifs.
                      </p>
                      
                      <div className="space-y-3">
                        <h5 className="font-medium text-sm text-brand-700">Caractéristiques</h5>
                        <ul className="list-disc pl-5 text-sm text-gray-600 space-y-1">
                          <li>Type : Token ERC-20 sur la blockchain Ethereum</li>
                          <li>Approvisionnement total : 100 000 000 GTC</li>
                          <li>Utilisation : Gouvernance, staking, réductions de frais</li>
                          <li>Distribution initiale : ICO, airdrop communautaire, trésorerie DAO</li>
                        </ul>
                        
                        <h5 className="font-medium text-sm text-brand-700 mt-3">Fonctions principales</h5>
                        <ul className="list-disc pl-5 text-sm text-gray-600 space-y-1">
                          <li>Droits de vote sur les propositions de la DAO</li>
                          <li>Staking pour obtenir des réductions de frais</li>
                          <li>Accès à des fonctionnalités premium</li>
                          <li>Redistribution des revenus de la plateforme</li>
                        </ul>
                      </div>
                    </div>
                    
                    <div className="border p-4 rounded-lg bg-gray-50">
                      <div className="flex items-center gap-3 mb-3">
                        <div className="w-10 h-10 bg-brand-100 rounded-full flex items-center justify-center">
                          <Coins className="h-6 w-6 text-brand-600" />
                        </div>
                        <h4 className="font-semibold text-brand-800">Stablecoin VCarbs</h4>
                      </div>
                      
                      <p className="text-gray-600 mb-4">
                        La stablecoin VCarbs est notre monnaie d'échange principale pour les transactions sur la plateforme. Elle offre stabilité et prévisibilité dans un marché souvent volatil.
                      </p>
                      
                      <div className="space-y-3">
                        <h5 className="font-medium text-sm text-brand-700">Caractéristiques</h5>
                        <ul className="list-disc pl-5 text-sm text-gray-600 space-y-1">
                          <li>Type : Stablecoin ERC-20 adossée à l'euro</li>
                          <li>Parité : 1 VCarbs = 1 EUR</li>
                          <li>Garantie : Réserves en euros auditées trimestriellement</li>
                          <li>Émission : Contrôlée par un smart contract et validée par la DAO</li>
                        </ul>
                        
                        <h5 className="font-medium text-sm text-brand-700 mt-3">Fonctions principales</h5>
                        <ul className="list-disc pl-5 text-sm text-gray-600 space-y-1">
                          <li>Achat et vente de crédits carbone</li>
                          <li>Paiement des frais de transaction</li>
                          <li>Rémunération des porteurs de projets</li>
                          <li>Facilitation des échanges entre utilisateurs</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  
                  <div className="bg-amber-50 border border-amber-200 rounded-lg p-4 mt-6">
                    <h4 className="font-medium text-amber-800 mb-2 flex items-center gap-2">
                      <Shield className="h-5 w-5" />
                      Information importante
                    </h4>
                    <p className="text-amber-700 text-sm">
                      Les cryptomonnaies et tokens présentent des risques de marché importants. Leur valeur peut fluctuer significativement et les performances passées ne préjugent pas des performances futures. Nous vous recommandons de ne pas investir plus que ce que vous êtes prêt à perdre et de consulter un conseiller financier indépendant avant tout investissement.
                    </p>
                  </div>
                </section>
                
                <section>
                  <h3 className="font-semibold text-lg mb-2">Propriété intellectuelle</h3>
                  <p className="text-gray-600">
                    L'ensemble du contenu de ce site, incluant, de façon non limitative, les graphismes, images, textes, vidéos, animations, sons, logos, et icônes sont la propriété exclusive de Guteco SAS, à l'exception des marques, logos ou contenus appartenant à d'autres sociétés partenaires ou auteurs.
                  </p>
                  <p className="text-gray-600 mt-2">
                    Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de ces différents éléments est strictement interdite sans l'accord exprès par écrit de Guteco SAS.
                  </p>
                </section>
                
                <section>
                  <h3 className="font-semibold text-lg mb-2">Contact</h3>
                  <p className="text-gray-600">
                    <strong>Email :</strong> gtc.vcarbs@gmail.com<br />
                    <strong>Téléphone :</strong> +33650849475<br />
                    <strong>Adresse :</strong> 3, rue Balny d'Avricourt 75017 Paris
                  </p>
                </section>
              </CardContent>
            </Card>
          </TabsContent>
          
          <TabsContent value="certifications">
            <Card>
              <CardHeader>
                <CardTitle className="text-xl">Certifications</CardTitle>
                <CardDescription>
                  Nos certifications et partenariats garantissent la qualité et la légitimité de notre plateforme
                </CardDescription>
              </CardHeader>
              <CardContent className="space-y-6">
                <section>
                  <h3 className="font-semibold text-lg mb-2">Standards de vérification carbone</h3>
                  <p className="text-gray-600 mb-4">
                    Tous les projets de crédits carbone proposés sur notre plateforme sont vérifiés et certifiés selon les normes internationales les plus rigoureuses :
                  </p>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                    <div className="border rounded-lg p-4 flex items-start gap-3">
                      <div className="bg-brand-50 p-2 rounded-md">
                        <Award className="h-6 w-6 text-brand-600" />
                      </div>
                      <div>
                        <h4 className="font-medium text-brand-800">Verified Carbon Standard (VCS)</h4>
                        <p className="text-sm text-gray-600">
                          Le programme VCS de Verra est l'un des standards les plus utilisés au monde pour la certification de projets de réduction des émissions de gaz à effet de serre.
                        </p>
                      </div>
                    </div>
                    
                    <div className="border rounded-lg p-4 flex items-start gap-3">
                      <div className="bg-brand-50 p-2 rounded-md">
                        <Award className="h-6 w-6 text-brand-600" />
                      </div>
                      <div>
                        <h4 className="font-medium text-brand-800">Gold Standard</h4>
                        <p className="text-sm text-gray-600">
                          Norme établie par le WWF qui certifie les projets de compensation carbone tout en garantissant des bénéfices pour les communautés locales.
                        </p>
                      </div>
                    </div>
                    
                    <div className="border rounded-lg p-4 flex items-start gap-3">
                      <div className="bg-brand-50 p-2 rounded-md">
                        <Award className="h-6 w-6 text-brand-600" />
                      </div>
                      <div>
                        <h4 className="font-medium text-brand-800">Climate Action Reserve (CAR)</h4>
                        <p className="text-sm text-gray-600">
                          Programme offrant des standards rigoureux pour quantifier et vérifier les projets de réduction d'émissions en Amérique du Nord.
                        </p>
                      </div>
                    </div>
                    
                    <div className="border rounded-lg p-4 flex items-start gap-3">
                      <div className="bg-brand-50 p-2 rounded-md">
                        <Award className="h-6 w-6 text-brand-600" />
                      </div>
                      <div>
                        <h4 className="font-medium text-brand-800">American Carbon Registry (ACR)</h4>
                        <p className="text-sm text-gray-600">
                          Premier registre volontaire de carbone aux États-Unis, reconnu pour sa rigueur scientifique et son intégrité.
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
                
                <section>
                  <h3 className="font-semibold text-lg mb-2">Certifications blockchain et sécurité</h3>
                  <p className="text-gray-600 mb-4">
                    Notre infrastructure technique est régulièrement auditée et certifiée pour garantir la sécurité de vos transactions :
                  </p>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="border rounded-lg p-4 flex items-start gap-3">
                      <div className="bg-brand-50 p-2 rounded-md">
                        <Shield className="h-6 w-6 text-brand-600" />
                      </div>
                      <div>
                        <h4 className="font-medium text-brand-800">CertiK</h4>
                        <p className="text-sm text-gray-600">
                          Audit de sécurité complet de nos smart contracts et de notre infrastructure blockchain.
                        </p>
                      </div>
                    </div>
                    
                    <div className="border rounded-lg p-4 flex items-start gap-3">
                      <div className="bg-brand-50 p-2 rounded-md">
                        <Shield className="h-6 w-6 text-brand-600" />
                      </div>
                      <div>
                        <h4 className="font-medium text-brand-800">ISO 27001</h4>
                        <p className="text-sm text-gray-600">
                          Certification internationale pour la gestion de la sécurité de l'information.
                        </p>
                      </div>
                    </div>
                    
                    <div className="border rounded-lg p-4 flex items-start gap-3">
                      <div className="bg-brand-50 p-2 rounded-md">
                        <Shield className="h-6 w-6 text-brand-600" />
                      </div>
                      <div>
                        <h4 className="font-medium text-brand-800">RGPD</h4>
                        <p className="text-sm text-gray-600">
                          Conformité totale avec le Règlement Général sur la Protection des Données de l'Union Européenne.
                        </p>
                      </div>
                    </div>
                    
                    <div className="border rounded-lg p-4 flex items-start gap-3">
                      <div className="bg-brand-50 p-2 rounded-md">
                        <Shield className="h-6 w-6 text-brand-600" />
                      </div>
                      <div>
                        <h4 className="font-medium text-brand-800">Utilisation des blockchain et smartcontrat</h4>
                        <p className="text-sm text-gray-600">
                          Utilisation des standards blockchain et smart contracts pour des transactions sécurisées et transparentes.
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
                
                <section>
                  <h3 className="font-semibold text-lg mb-2">Partenaires institutionnels</h3>
                  <p className="text-gray-600 mb-4">
                    Nous collaborons avec des institutions de renom pour promouvoir l'action climatique :
                  </p>
                  
                  <ul className="list-disc pl-6 space-y-2 text-gray-600">
                    <li>Ministère de la Transition Écologique</li>
                    <li>Agence de l'Environnement et de la Maîtrise de l'Énergie (ADEME)</li>
                    <li>Climate Chain Coalition</li>
                    <li>World Economic Forum - Climate Initiative</li>
                    <li>United Nations Framework Convention on Climate Change (UNFCCC)</li>
                  </ul>
                </section>
              </CardContent>
            </Card>
          </TabsContent>
        </Tabs>
      </div>
    </Layout>
  );
};

export default Legal;
