
import React from 'react';
import { Layout } from "@/components/Layout";
import CompanyValuation from "@/components/CompanyValuation";
import FinancialSimulation from "@/components/FinancialSimulation";
import { Button } from "@/components/ui/button";
import { ArrowLeft, Calendar } from "lucide-react";
import { useNavigate } from "react-router-dom";

const ValuationPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/token-economics');
  };

  return (
    <Layout>
      <div className="container mx-auto px-4 py-8">
        <Button 
          variant="outline" 
          className="mb-6 flex items-center gap-2"
          onClick={handleGoBack}
        >
          <ArrowLeft className="h-4 w-4" />
          <span>Retour à l'économie des tokens</span>
        </Button>

        <h1 className="text-3xl font-bold text-brand-800 text-center mb-8">Valorisation de l'entreprise</h1>
        
        <div className="mb-8 max-w-3xl mx-auto text-center">
          <p className="text-lg text-gray-700">
            Analyse détaillée de la valorisation de GuTeco à différentes étapes de son développement, 
            avec une valorisation pré-monétaire établie à 90 millions d'euros et un objectif à 5 ans de 105 millions d'euros.
          </p>
          <p className="text-lg text-gray-700 mt-4">
            Notre levée de fonds de 12 millions d'euros en échange de 11,76% d'équité nous donnera 
            une valorisation post-money de 102 millions d'euros pour accélérer notre croissance.
          </p>
          <div className="mt-4 flex items-center justify-center text-sm text-gray-600 italic">
            <Calendar className="h-4 w-4 mr-2" />
            <span>Les projections financières présentées ici sont des prévisions qui dépendent de la date de lancement et sont susceptibles d'évolution.</span>
          </div>
        </div>
        
        <FinancialSimulation />
        
        <CompanyValuation />
      </div>
    </Layout>
  );
};

export default ValuationPage;
