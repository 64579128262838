
import React, { useEffect } from 'react';
import { 
  Dialog, 
  DialogContent, 
  DialogHeader, 
  DialogTitle, 
  DialogDescription,
  DialogClose,
  DialogFooter
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { 
  Table, 
  TableBody, 
  TableCaption, 
  TableCell, 
  TableHead, 
  TableHeader, 
  TableRow 
} from "@/components/ui/table";
import { Info, XCircle, TreePine, Globe, Droplet } from 'lucide-react';

interface PricingInfoProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

const pricingFactors = [
  {
    id: 1,
    factor: "Type de projet",
    impact: "Élevé",
    description: "Les projets de reforestation, énergie renouvelable, ou protection de zones humides ont des impacts différents sur l'environnement."
  },
  {
    id: 2,
    factor: "Localisation",
    impact: "Moyen",
    description: "Les coûts diffèrent selon le pays (main d'œuvre, terrains, réglementations)."
  },
  {
    id: 3,
    factor: "Taille du projet",
    impact: "Moyen",
    description: "Les projets plus grands bénéficient d'économies d'échelle, réduisant le prix par crédit."
  },
  {
    id: 4,
    factor: "Cobenéfices",
    impact: "Élevé",
    description: "Projets avec bénéfices sociaux ou pour la biodiversité obtiennent une prime de prix."
  },
  {
    id: 5,
    factor: "Méthodologie de certification",
    impact: "Élevé",
    description: "Les certifications reconnues (Gold Standard, Verra) influencent la crédibilité et le prix des crédits."
  }
];

const exampleProjects = [
  {
    name: "Reforestation Amazonie",
    price: 28,
    icon: <TreePine className="h-6 w-6 text-green-600" />,
    reason: "Prix plus élevé en raison de l'importance écologique mondiale, des cobenéfices pour la biodiversité et des communautés indigènes"
  },
  {
    name: "Énergie Solaire Sahel",
    price: 32,
    icon: <Globe className="h-6 w-6 text-amber-500" />,
    reason: "Prix premium dû aux cobenéfices sociaux importants (accès à l'électricité), défis logistiques dans des zones éloignées"
  },
  {
    name: "Mangroves Indonésie",
    price: 25,
    icon: <Droplet className="h-6 w-6 text-blue-500" />,
    reason: "Équilibre entre efficacité des mangroves pour capter le carbone et coûts d'implémentation modérés"
  }
];

const priceCalculationSteps = [
  {
    step: 1,
    title: "Évaluation du projet",
    description: "Notre comité d'experts évalue le type de projet, la localisation, et la méthodologie utilisée."
  },
  {
    step: 2,
    title: "Calcul du prix de base",
    description: "Le prix de base est établi selon le type de projet (ex: reforestation = 20-25€, énergie renouvelable = 25-30€)."
  },
  {
    step: 3,
    title: "Ajustements selon les cobenéfices",
    description: "Des bonus sont ajoutés pour les bénéfices sociaux (+3-5€) ou impacts sur la biodiversité (+2-4€)."
  },
  {
    step: 4,
    title: "Ajustements selon la certification",
    description: "Un projet certifié Gold Standard reçoit un bonus de prix de 10% par rapport à une certification standard."
  },
  {
    step: 5,
    title: "Validation finale",
    description: "Le comité valide le prix final après révision complète du dossier du projet."
  }
];

const stabilizationMechanisms = [
  {
    id: 1,
    mechanism: "Indexation directe",
    description: "La valeur du VCarbs est directement indexée sur un panier diversifié de crédits carbone, avec une parité de 1 VCarbs = 1€."
  },
  {
    id: 2,
    mechanism: "Réserve de stabilité",
    description: "Une réserve de 20% des crédits carbone est maintenue pour absorber les fluctuations du marché et garantir la stabilité du token."
  },
  {
    id: 3,
    mechanism: "Ajustements périodiques",
    description: "Le comité d'évaluation ajuste trimestriellement la composition du panier de crédits pour maintenir la stabilité face aux évolutions du marché."
  },
  {
    id: 4,
    mechanism: "Contrats intelligents",
    description: "Des smart contracts automatisent les ajustements mineurs en temps réel selon les variations de prix des crédits carbone."
  }
];

const PricingInfo = ({ open, onOpenChange }: PricingInfoProps) => {
  useEffect(() => {
    // Add event listener to handle opening the dialog from other components
    const handleOpenDialog = () => {
      onOpenChange(true);
    };

    window.addEventListener('openPricingDialog', handleOpenDialog);

    return () => {
      window.removeEventListener('openPricingDialog', handleOpenDialog);
    };
  }, [onOpenChange]);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-4xl max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="text-2xl flex items-center gap-2">
            <Info className="h-6 w-6 text-brand-600" />
            Évaluation des Prix et Indexation du VCarbs
          </DialogTitle>
          <DialogDescription>
            Comment les prix des crédits carbone sont déterminés et comment le VCarbs maintient sa stabilité
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-6 my-4">
          <div className="bg-brand-50 p-4 rounded-lg">
            <h3 className="text-lg font-medium text-brand-800 mb-2">Qu'est-ce qui influence le prix des crédits carbone?</h3>
            <p className="text-gray-600 mb-4">
              Le prix des crédits carbone varie considérablement d'un projet à l'autre, généralement entre 5€ et 50€ par tonne de CO₂ 
              équivalente séquestrée. Ces variations dépendent de plusieurs facteurs clés:
            </p>

            <Table>
              <TableCaption>Facteurs d'influence sur le prix des crédits carbone</TableCaption>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[200px]">Facteur</TableHead>
                  <TableHead>Impact sur le prix</TableHead>
                  <TableHead>Description</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {pricingFactors.map((factor) => (
                  <TableRow key={factor.id}>
                    <TableCell className="font-medium">{factor.factor}</TableCell>
                    <TableCell>
                      <span className={`px-2 py-1 rounded text-xs ${
                        factor.impact === "Élevé" 
                          ? "bg-red-100 text-red-800" 
                          : "bg-yellow-100 text-yellow-800"
                      }`}>
                        {factor.impact}
                      </span>
                    </TableCell>
                    <TableCell>{factor.description}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>

          <div className="bg-emerald-50 p-5 rounded-lg border border-emerald-200">
            <h3 className="text-lg font-medium text-emerald-800 mb-4">Comment le VCarbs maintient sa stabilité</h3>
            
            <p className="text-gray-700 mb-4">
              Le VCarbs est indexé sur un panier diversifié de crédits carbone pour maintenir une valeur stable de 1€. 
              Cette stabilité est garantie par plusieurs mécanismes:
            </p>
            
            <Table>
              <TableCaption>Mécanismes de stabilisation du VCarbs</TableCaption>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[200px]">Mécanisme</TableHead>
                  <TableHead>Description</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {stabilizationMechanisms.map((mechanism) => (
                  <TableRow key={mechanism.id}>
                    <TableCell className="font-medium">{mechanism.mechanism}</TableCell>
                    <TableCell>{mechanism.description}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            
            <div className="mt-4 bg-white p-4 rounded-lg border border-emerald-100">
              <h4 className="font-medium text-emerald-700 mb-2">Composition du panier de référence</h4>
              <ul className="space-y-2">
                <li className="flex items-start gap-2 text-gray-700">
                  <span className="font-medium w-48">30% - Projets forestiers</span>
                  <span>(reforestation, conservation, agroforesterie)</span>
                </li>
                <li className="flex items-start gap-2 text-gray-700">
                  <span className="font-medium w-48">25% - Énergies renouvelables</span>
                  <span>(solaire, éolien, biomasse)</span>
                </li>
                <li className="flex items-start gap-2 text-gray-700">
                  <span className="font-medium w-48">20% - Protection des océans</span>
                  <span>(mangroves, récifs coralliens, herbiers marins)</span>
                </li>
                <li className="flex items-start gap-2 text-gray-700">
                  <span className="font-medium w-48">15% - Gestion des déchets</span>
                  <span>(méthanisation, compostage)</span>
                </li>
                <li className="flex items-start gap-2 text-gray-700">
                  <span className="font-medium w-48">10% - Innovation</span>
                  <span>(capture directe, matériaux durables)</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="bg-gray-50 p-5 rounded-lg border border-gray-200">
            <h3 className="text-lg font-medium text-brand-800 mb-4">Comment fixons-nous le prix pour chaque projet</h3>
            
            <div className="space-y-6">
              {priceCalculationSteps.map((step) => (
                <div key={step.step} className="flex gap-4">
                  <div className="bg-brand-600 text-white rounded-full w-8 h-8 flex-shrink-0 flex items-center justify-center font-bold">
                    {step.step}
                  </div>
                  <div>
                    <h4 className="font-medium text-brand-700">{step.title}</h4>
                    <p className="text-gray-600 mt-1">{step.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div>
            <h3 className="text-lg font-medium text-brand-800 mb-4">Exemples concrets: nos projets de démonstration</h3>
            
            <div className="grid md:grid-cols-3 gap-6">
              {exampleProjects.map((project, index) => (
                <div key={index} className="border rounded-lg p-4 hover:shadow-md transition-shadow">
                  <div className="flex items-center gap-3 mb-3">
                    {project.icon}
                    <h4 className="font-medium">{project.name}</h4>
                  </div>
                  <div className="bg-brand-50 rounded-md p-3 mb-3">
                    <span className="text-2xl font-bold text-brand-700">{project.price}€</span>
                    <span className="text-gray-500 text-sm"> / crédit carbone</span>
                  </div>
                  <p className="text-sm text-gray-600">{project.reason}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-amber-50 p-4 rounded-lg border border-amber-200">
            <h3 className="text-lg font-medium text-amber-800 mb-2 flex items-center gap-2">
              <Info className="h-5 w-5" />
              Mise à jour et supervision
            </h3>
            <p className="text-gray-700">
              Le comité de gouvernance de la DAO supervise le système d'indexation du VCarbs et peut proposer des ajustements 
              qui sont soumis au vote des détenteurs de tokens GTC. Ce processus démocratique garantit la transparence et 
              la résilience du système face aux évolutions du marché des crédits carbone.
            </p>
          </div>
        </div>

        <DialogFooter>
          <DialogClose asChild>
            <Button className="bg-brand-600 hover:bg-brand-700">Fermer</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default PricingInfo;
