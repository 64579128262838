
import * as React from "react"

// Define breakpoints
const MOBILE_BREAKPOINT = 768
const TABLET_BREAKPOINT = 1024
const TABLET_MAX_BREAKPOINT = 1366

/**
 * Hook to detect if the device is a mobile device
 * @returns boolean indicating if the current device is mobile
 */
export function useIsMobile() {
  const [isMobile, setIsMobile] = React.useState<boolean | undefined>(undefined)

  React.useEffect(() => {
    // Mobile detection function
    const detectMobileUserAgent = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      return /android|webos|iphone|ipod|blackberry|iemobile|opera mini|mobile(?!.*tablet)/i.test(userAgent);
    };

    // Size-based tablet detection
    const isTabletBySize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const isLandscape = width > height;
      
      return (
        (width >= MOBILE_BREAKPOINT && width <= TABLET_MAX_BREAKPOINT) || 
        (isLandscape && height >= MOBILE_BREAKPOINT && height <= TABLET_MAX_BREAKPOINT)
      );
    };
    
    // User agent tablet detection
    const detectTabletUserAgent = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      
      // Enhanced tablet detection pattern
      const tabletPattern = /ipad|android(?!.*mobile)|tablet|kindle|playbook|silk|tablet-pc|(?:android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i;
      
      // Specific iPad detection for newer iPads that report as desktop
      const isIPad = /macintosh/i.test(userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
      
      return tabletPattern.test(userAgent) || isIPad;
    };

    const handleResize = () => {
      // Get tablet status directly without hook
      const isTabletDevice = isTabletBySize() || detectTabletUserAgent();
      
      // Determine if device is mobile (not tablet)
      const mobileByWidth = window.innerWidth < MOBILE_BREAKPOINT && !isTabletDevice;
      const mobileByUserAgent = detectMobileUserAgent() && !isTabletDevice;
      const result = mobileByWidth || mobileByUserAgent;
      
      setIsMobile(result);
      
      // Debug logging
      console.log('Mobile detection:', 
        'Width:', window.innerWidth, 
        'UA detected:', detectMobileUserAgent(),
        'Final result:', result
      );
    };

    // Listen for orientation changes and resize events
    window.addEventListener('orientationchange', handleResize);
    window.addEventListener('resize', handleResize);
    
    // Initial detection
    handleResize();
    
    // Cleanup
    return () => {
      window.removeEventListener('orientationchange', handleResize);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return !!isMobile;
}

/**
 * Hook to detect if the device is a tablet
 * @returns boolean indicating if the current device is a tablet
 */
export function useIsTablet() {
  const [isTablet, setIsTablet] = React.useState<boolean | undefined>(undefined)

  React.useEffect(() => {
    // Tablet user agent detection function
    const detectTabletUserAgent = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      
      // Enhanced tablet detection pattern
      const tabletPattern = /ipad|android(?!.*mobile)|tablet|kindle|playbook|silk|tablet-pc|(?:android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i;
      
      // Specific iPad detection for newer iPads that report as desktop
      const isIPad = /macintosh/i.test(userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
      
      const result = tabletPattern.test(userAgent) || isIPad;
      
      // Log detection for debugging
      console.log('Tablet UA detection:', userAgent, 'Result:', result);
      
      return result;
    };
    
    // Tablet detection handler
    const checkTablet = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const isLandscape = width > height;
      
      // Size-based tablet detection with orientation consideration
      const isTabletBySize = (
        (width >= MOBILE_BREAKPOINT && width <= TABLET_MAX_BREAKPOINT) || 
        (isLandscape && height >= MOBILE_BREAKPOINT && height <= TABLET_MAX_BREAKPOINT)
      );
      
      const isTabletByUA = detectTabletUserAgent();
      
      // A tablet is detected by size OR user agent
      const result = isTabletBySize || isTabletByUA;
      setIsTablet(result);
      
      // Log for debugging
      console.log('Tablet detection:', 
        'Width:', width, 
        'Height:', height, 
        'Size detection:', isTabletBySize, 
        'UA detection:', isTabletByUA,
        'Final result:', result
      );
      
      // Apply CSS classes when tablet is detected
      if (result) {
        document.documentElement.classList.add('tablet-view');
        document.documentElement.classList.add('tablet-device');
        
        // Force viewport settings to work better on tablets
        const viewportMeta = document.querySelector('meta[name="viewport"]');
        if (viewportMeta) {
          viewportMeta.setAttribute('content', 
            'width=device-width, initial-scale=1.0, maximum-scale=5.0, viewport-fit=cover, user-scalable=yes'
          );
        }
        
        // Add meta tag for mobile indexing
        if (!document.querySelector('meta[name="google"]')) {
          const googleMeta = document.createElement('meta');
          googleMeta.setAttribute('name', 'google');
          googleMeta.setAttribute('content', 'notranslate');
          document.head.appendChild(googleMeta);
        }
      }
    };
    
    // Register event listeners
    window.addEventListener('resize', checkTablet);
    window.addEventListener('orientationchange', checkTablet);
    window.addEventListener('load', checkTablet);
    
    // Initial check
    checkTablet();
    
    // Cleanup
    return () => {
      window.removeEventListener('resize', checkTablet);
      window.removeEventListener('orientationchange', checkTablet);
      window.removeEventListener('load', checkTablet);
    };
  }, []);

  return !!isTablet;
}

/**
 * Hook to get comprehensive device type information
 * @returns object with device type information (isMobile, isTablet, isDesktop, orientation)
 */
export function useDeviceType() {
  const [deviceInfo, setDeviceInfo] = React.useState({
    isMobile: false,
    isTablet: false,
    isDesktop: true,
    orientation: 'landscape'
  });
  
  React.useEffect(() => {
    // Comprehensive device detection function
    const detectDevice = () => {
      const ua = navigator.userAgent.toLowerCase();
      
      // Enhanced tablet patterns for better detection
      const tabletPatterns = [
        /ipad/i,
        /tablet/i,
        /kindle/i,
        /playbook/i,
        /silk/i,
        /android(?!.*mobile)/i,
        // Modern iPad detection
        function() { 
          return /macintosh/i.test(ua) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
        }
      ];
      
      // Enhanced mobile patterns for better detection
      const mobilePatterns = [
        /android.*mobile/i,
        /iphone/i,
        /ipod/i,
        /windows phone/i,
        /blackberry/i,
        /bb\d+/i,
        /mobi/i
      ];
      
      // Check if device matches any tablet pattern
      const isTablet = tabletPatterns.some(pattern => 
        typeof pattern === 'function' ? pattern() : pattern.test(ua)
      );
      
      // Check if device matches any mobile pattern (not tablet)
      const isMobile = !isTablet && mobilePatterns.some(pattern => pattern.test(ua));
      
      // Get device orientation
      const width = window.innerWidth;
      const height = window.innerHeight;
      const orientation = width > height ? 'landscape' : 'portrait';
      
      // Set all device info at once
      setDeviceInfo({
        isTablet,
        isMobile,
        isDesktop: !isTablet && !isMobile,
        orientation
      });
      
      // Apply appropriate CSS classes
      document.documentElement.classList.toggle('tablet-device', isTablet);
      document.documentElement.classList.toggle('mobile-device', isMobile);
      document.documentElement.classList.toggle('desktop-device', !isTablet && !isMobile);
      document.documentElement.classList.toggle('landscape', orientation === 'landscape');
      document.documentElement.classList.toggle('portrait', orientation === 'portrait');
      
      // Add mobile-specific metadata
      if (isMobile || isTablet) {
        // Create meta tags if they don't exist
        if (!document.querySelector('meta[name="robots"]')) {
          const robotsMeta = document.createElement('meta');
          robotsMeta.name = 'robots';
          robotsMeta.content = 'index, follow, max-image-preview:large';
          document.head.appendChild(robotsMeta);
        }
        
        if (!document.querySelector('meta[name="mobile-web-app-capable"]')) {
          const verificationMeta = document.createElement('meta');
          verificationMeta.name = 'mobile-web-app-capable';
          verificationMeta.content = 'yes';
          document.head.appendChild(verificationMeta);
        }
      }
    };
    
    // Call on mount and register event listeners
    detectDevice();
    window.addEventListener('resize', detectDevice);
    window.addEventListener('orientationchange', detectDevice);
    
    // Debug info
    console.log('Enhanced device detection initialized - UserAgent:', navigator.userAgent);
    
    // Cleanup
    return () => {
      window.removeEventListener('resize', detectDevice);
      window.removeEventListener('orientationchange', detectDevice);
    };
  }, []);
  
  return deviceInfo;
}
