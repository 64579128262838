import React from 'react';
import { Layout } from '../components/Layout';
import TokenEconomics from '../components/TokenEconomics';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Printer, AlertCircle, Coins, ArrowRight, ShieldCheck, DollarSign, LineChart, Scale } from 'lucide-react';
import { useToast } from "@/hooks/use-toast";

const Dao = () => {
  const { toast } = useToast();
  
  const handlePrintableVersion = () => {
    toast({
      title: "Version imprimable disponible",
      description: "Vous êtes redirigé vers la version imprimable du pitch DAO",
    });
  };
  
  return (
    <Layout>
      <div className="py-16 bg-white">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center mb-6">
            <div>
              <h1 className="text-4xl font-bold text-center md:text-left text-brand-800 mb-3">Gouvernance DAO</h1>
              <p className="text-xl text-center md:text-left text-gray-600 md:max-w-2xl">
                Participez à la gouvernance décentralisée de l'écosystème Guteco
              </p>
            </div>
            <div className="mt-4 md:mt-0">
              <Link to="/dao-pitch" onClick={handlePrintableVersion}>
                <Button 
                  variant="outline" 
                  className="flex items-center gap-2 border-brand-300 text-brand-700 hover:bg-brand-50"
                >
                  <Printer size={18} />
                  <span>Version imprimable</span>
                </Button>
              </Link>
            </div>
          </div>
          
          <div className="bg-amber-50 border border-amber-200 rounded-md p-4 flex items-start gap-3 mb-8">
            <AlertCircle className="text-amber-500 h-5 w-5 mt-0.5" />
            <div>
              <h3 className="font-medium text-amber-800">Accéder à la version imprimable</h3>
              <p className="text-amber-700">
                Pour imprimer le pitch DAO, cliquez sur le bouton "Version imprimable" ci-dessus, 
                ou accédez directement à <Link to="/dao-pitch" className="underline font-medium">cette page</Link>.
              </p>
            </div>
          </div>
        </div>
      </div>
      
      <section className="py-12 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center text-brand-800 mb-6">Relation entre GTC et VCarbs</h2>
          <p className="text-xl text-center text-gray-600 max-w-3xl mx-auto mb-10">
            Comprendre la complémentarité et l'utilité des deux tokens au sein de l'écosystème Guteco
          </p>
          
          <div className="bg-white p-8 rounded-lg shadow-sm mb-8">
            <div className="grid md:grid-cols-2 gap-10">
              <div>
                <div className="flex items-center gap-3 mb-4">
                  <div className="w-12 h-12 bg-brand-100 rounded-full flex items-center justify-center">
                    <Coins className="h-6 w-6 text-brand-700" />
                  </div>
                  <h3 className="text-2xl font-semibold text-brand-700">Système à Double Token</h3>
                </div>
                <p className="text-gray-700 mb-6">
                  L'écosystème Guteco utilise un système à double token, où GTC et VCarbs ont des rôles complémentaires mais distincts :
                </p>
                <ul className="space-y-3">
                  <li className="flex items-start gap-2">
                    <ArrowRight className="h-5 w-5 shrink-0 mt-1 text-brand-600" />
                    <div>
                      <span className="font-semibold text-brand-700">Token GTC</span> : Token de gouvernance et d'utilité qui donne accès aux droits de vote et permet de participer au staking.
                    </div>
                  </li>
                  <li className="flex items-start gap-2">
                    <ArrowRight className="h-5 w-5 shrink-0 mt-1 text-brand-600" />
                    <div>
                      <span className="font-semibold text-emerald-700">Token VCarbs</span> : Stablecoin interne à l'écosystème, indexé sur la valeur des crédits carbone (1 VCarbs = 1€).
                    </div>
                  </li>
                </ul>
                
                <div className="mt-6 bg-emerald-50 p-4 rounded-lg border border-emerald-100">
                  <h4 className="font-medium text-emerald-700 mb-2">Équivalence CO₂ de VCarbs</h4>
                  <p className="text-gray-700">
                    1 VCarbs représente directement 100 kg de CO₂ compensés. Cette équivalence garantit que chaque token 
                    utilisé a un impact mesurable et concret sur l'environnement. Ainsi, pour chaque VCarbs en circulation, 
                    Guteco s'assure qu'une quantité correspondante de carbone est effectivement capturée ou évitée grâce 
                    aux projets certifiés de notre écosystème.
                  </p>
                </div>
              </div>
              
              <div>
                <h3 className="text-xl font-semibold text-brand-700 mb-4">Comment GTC et VCarbs interagissent ?</h3>
                <div className="bg-gray-50 p-5 rounded-md mb-5">
                  <h4 className="font-medium text-brand-700 mb-2">Paiements internes et transactions</h4>
                  <p className="text-gray-700">
                    Les VCarbs servent de monnaie stable pour toutes les transactions au sein de l'écosystème Guteco. 
                    Ils facilitent les échanges à valeur stable entre les participants, réduisant la volatilité et 
                    augmentant la prévisibilité des coûts pour les projets environnementaux.
                  </p>
                </div>
                <div className="bg-gray-50 p-5 rounded-md">
                  <h4 className="font-medium text-brand-700 mb-2">Unité de compte et valorisation</h4>
                  <p className="text-gray-700">
                    Les VCarbs servent d'unité de compte standardisée pour mesurer la valeur des services 
                    et biens échangés au sein de Guteco. Chaque crédit carbone est quantifié en VCarbs, ce qui 
                    permet une évaluation claire et constante des impacts environnementaux.
                  </p>
                </div>
              </div>
            </div>
          </div>
          
          <div className="grid md:grid-cols-3 gap-6">
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h4 className="text-xl font-semibold text-brand-600 mb-3">Staking GTC → VCarbs</h4>
              <p className="text-gray-700">Le staking de tokens GTC génère des récompenses en VCarbs, permettant aux participants actifs d'obtenir un flux régulier de la stablecoin de l'écosystème.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h4 className="text-xl font-semibold text-brand-600 mb-3">Acheter des projets avec VCarbs</h4>
              <p className="text-gray-700">Les VCarbs permettent d'acheter des parts dans des projets environnementaux, chaque VCarb représentant une valeur fixe et stable de compensation carbone.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h4 className="text-xl font-semibold text-brand-600 mb-3">Échanges GTC/VCarbs</h4>
              <p className="text-gray-700">Les détenteurs de GTC bénéficient de frais réduits pour l'échange entre GTC et VCarbs, avec des avantages croissants selon leur niveau d'influence dans la DAO.</p>
            </div>
          </div>
          
          <div className="bg-white p-6 rounded-lg shadow-sm mt-8">
            <div className="flex items-center gap-3 mb-4">
              <div className="w-10 h-10 bg-gradient-to-r from-brand-100 to-emerald-100 rounded-full flex items-center justify-center">
                <Scale className="h-5 w-5 text-brand-700" />
              </div>
              <h3 className="text-xl font-semibold text-brand-700">Que choisir pour l'achat de parts dans un projet ?</h3>
            </div>
            
            <div className="grid md:grid-cols-2 gap-6 mb-6">
              <div className="bg-brand-50 p-4 rounded-lg border border-brand-100">
                <h4 className="font-medium text-brand-700 mb-2">Avantages d'utiliser GTC</h4>
                <ul className="space-y-2 text-gray-700">
                  <li className="flex items-start gap-2">
                    <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-brand-600" />
                    <span>Participation à la gouvernance du projet via la DAO</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-brand-600" />
                    <span>Potentiel de croissance si la valeur du token augmente</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-brand-600" />
                    <span>Réductions sur les frais pour les détenteurs à long terme</span>
                  </li>
                </ul>
              </div>
              
              <div className="bg-emerald-50 p-4 rounded-lg border border-emerald-100">
                <h4 className="font-medium text-emerald-700 mb-2">Avantages d'utiliser VCarbs</h4>
                <ul className="space-y-2 text-gray-700">
                  <li className="flex items-start gap-2">
                    <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-emerald-600" />
                    <span>Stabilité de valeur (1 VCarbs = 1€) pour une meilleure prévisibilité</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-emerald-600" />
                    <span>Impact environnemental direct et immédiat</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-emerald-600" />
                    <span>Simplicité pour les nouveaux utilisateurs et entreprises</span>
                  </li>
                </ul>
              </div>
            </div>
            
            <div className="bg-gray-50 p-5 rounded-lg">
              <h4 className="font-medium text-gray-800 mb-2">En conclusion</h4>
              <p className="text-gray-700">
                Pour l'écosystème dans son ensemble, un équilibre entre les deux tokens est optimal. L'utilisation de 
                VCarbs assure la stabilité et la liquidité nécessaire pour le fonctionnement quotidien et l'évaluation des 
                projets. Le GTC encourage l'engagement à long terme et la participation active à la gouvernance.
              </p>
              <p className="text-gray-700 mt-3">
                Les utilisateurs qui cherchent à maximiser leur impact écologique immédiat tout en gardant une valeur 
                stable pourraient préférer VCarbs, tandis que ceux qui souhaitent s'impliquer dans la gouvernance et 
                potentiellement bénéficier de la croissance de l'écosystème privilégieraient GTC.
              </p>
            </div>
          </div>
        </div>
      </section>
      
      <section className="py-12 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center text-brand-800 mb-6">Pourquoi VCarbs comme stablecoin propre ?</h2>
          <p className="text-xl text-center text-gray-600 max-w-3xl mx-auto mb-10">
            Comprendre comment VCarbs maintient sa valeur de 1€ et pourquoi nous avons créé notre propre stablecoin plutôt que d'utiliser des options existantes
          </p>
          
          <div className="grid md:grid-cols-2 gap-10 mb-10">
            <div className="bg-emerald-50 p-8 rounded-lg border border-emerald-100">
              <div className="flex items-center gap-3 mb-6">
                <div className="w-12 h-12 bg-emerald-100 rounded-full flex items-center justify-center">
                  <LineChart className="h-6 w-6 text-emerald-700" />
                </div>
                <h3 className="text-2xl font-semibold text-emerald-700">Comment 1 VCarbs = 1€ ?</h3>
              </div>
              
              <div className="space-y-6">
                <div className="bg-white p-5 rounded-md">
                  <h4 className="font-medium text-emerald-700 mb-2">Indexation sur les crédits carbone</h4>
                  <p className="text-gray-700">
                    Le VCarbs est directement indexé sur un panier diversifié de crédits carbone vérifiés, avec une valeur de référence de 1€. 
                    Ce panier est géré par la DAO et comprend différents types de projets (forestiers, énergétiques, océaniques) pour assurer 
                    la stabilité face aux fluctuations d'un seul secteur.
                  </p>
                </div>
                
                <div className="bg-white p-5 rounded-md">
                  <h4 className="font-medium text-emerald-700 mb-2">Réserve de garantie</h4>
                  <p className="text-gray-700">
                    Chaque VCarbs en circulation est garanti par une réserve de crédits carbone d'une valeur équivalente ou supérieure. 
                    La DAO maintient une réserve de sécurité supplémentaire de 20% pour absorber les fluctuations potentielles du marché 
                    des crédits carbone.
                  </p>
                </div>
                
                <div className="bg-white p-5 rounded-md">
                  <h4 className="font-medium text-emerald-700 mb-2">Ajustements automatiques et DAO</h4>
                  <p className="text-gray-700">
                    Des smart contracts surveillent et ajustent automatiquement la composition du panier de crédits carbone. 
                    De plus, le comité d'évaluation de la DAO effectue des revues trimestrielles pour assurer que la valeur du VCarbs 
                    reste fermement ancrée à 1€.
                  </p>
                </div>
              </div>
            </div>
            
            <div className="bg-blue-50 p-8 rounded-lg border border-blue-100">
              <div className="flex items-center gap-3 mb-6">
                <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
                  <DollarSign className="h-6 w-6 text-blue-700" />
                </div>
                <h3 className="text-2xl font-semibold text-blue-700">Pourquoi pas USDC/DAI/USDT ?</h3>
              </div>
              
              <div className="space-y-6">
                <div className="bg-white p-5 rounded-md">
                  <h4 className="font-medium text-blue-700 mb-2">Alignement avec notre mission</h4>
                  <p className="text-gray-700">
                    Les stablecoins traditionnels sont indexés sur des monnaies fiat comme le dollar américain, sans lien direct avec 
                    l'impact environnemental. VCarbs, en revanche, représente directement une quantité de CO₂ compensée, ce qui aligne 
                    parfaitement son utilisation avec la mission écologique de Guteco.
                  </p>
                </div>
                
                <div className="bg-white p-5 rounded-md">
                  <h4 className="font-medium text-blue-700 mb-2">Autonomie et contrôle</h4>
                  <p className="text-gray-700">
                    En créant notre propre stablecoin, la DAO Guteco garde le contrôle sur les mécanismes de stabilité et l'émission des tokens. 
                    Cela nous protège des risques liés à la dépendance envers des entités externes qui pourraient changer leurs politiques 
                    ou faire face à des problèmes réglementaires.
                  </p>
                </div>
                
                <div className="bg-white p-5 rounded-md">
                  <h4 className="font-medium text-blue-700 mb-2">Caractéristiques spécifiques</h4>
                  <p className="text-gray-700">
                    VCarbs intègre des fonctionnalités spécifiques à notre écosystème, comme la traçabilité des impacts environnementaux, 
                    l'intégration avec nos mécanismes de staking, et des frais d'échange réduits pour les détenteurs de GTC. Ces caractéristiques 
                    seraient impossibles à mettre en œuvre avec un stablecoin externe.
                  </p>
                </div>
              </div>
            </div>
          </div>
          
          <div className="bg-brand-50 p-6 rounded-lg border border-brand-100">
            <div className="flex items-start gap-3">
              <ShieldCheck className="h-6 w-6 text-brand-600 mt-1" />
              <div>
                <h4 className="font-medium text-brand-700 mb-2">Avantages du VCarbs pour les utilisateurs</h4>
                <ul className="grid md:grid-cols-2 gap-x-6 gap-y-4">
                  <li className="flex items-start gap-2">
                    <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-brand-600" />
                    <span className="text-gray-700">Protection contre la volatilité des cryptomonnaies traditionnelles</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-brand-600" />
                    <span>Transparence totale sur les crédits carbone qui soutiennent sa valeur</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-brand-600" />
                    <span>Conversion facile entre VCarbs et euros pour les entrées/sorties du système</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-brand-600" />
                    <span>Impact environnemental positif direct avec chaque transaction</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-brand-600" />
                    <span>Participation à la gouvernance de l'écosystème via la DAO si détention de GTC</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-brand-600" />
                    <span>Accès privilégié aux nouveaux projets environnementaux sur la plateforme</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <TokenEconomics />
      
      <section className="py-16 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center text-brand-800 mb-6">Structure de la DAO Guteco</h2>
          <p className="text-xl text-center text-gray-600 max-w-3xl mx-auto mb-12">
            Notre organisation autonome décentralisée (DAO) permet aux détenteurs de tokens GTC de participer aux décisions importantes de l'écosystème
          </p>
          
          <div className="grid md:grid-cols-2 gap-8 mb-16">
            <div className="bg-gray-50 p-8 rounded-lg shadow-sm">
              <h3 className="text-2xl font-semibold text-brand-700 mb-4">Processus de vote</h3>
              <p className="text-gray-700 mb-4">
                Les détenteurs de tokens GTC peuvent voter sur les propositions avec un poids proportionnel à leur allocation de tokens. 
                Chaque GTC représente une voix dans le système de gouvernance.
              </p>
              <ul className="list-disc pl-5 text-gray-700 space-y-2">
                <li>Création de propositions à partir de 1% des tokens en circulation</li>
                <li>Période de discussion de 7 jours pour chaque proposition</li>
                <li>Période de vote de 5 jours</li>
                <li>Quorum minimum de 10% pour validation d'un vote</li>
              </ul>
            </div>
            
            <div className="bg-gray-50 p-8 rounded-lg shadow-sm">
              <h3 className="text-2xl font-semibold text-brand-700 mb-4">Domaines de gouvernance</h3>
              <p className="text-gray-700 mb-4">
                La DAO Guteco permet aux membres de la communauté de voter sur plusieurs aspects stratégiques de l'écosystème:
              </p>
              <ul className="list-disc pl-5 text-gray-700 space-y-2">
                <li>Allocation des fonds de trésorerie pour de nouveaux projets</li>
                <li>Validation des projets environnementaux à financer</li>
                <li>Modifications des paramètres économiques (frais, récompenses)</li>
                <li>Améliorations techniques et nouvelles fonctionnalités</li>
                <li>Partenariats stratégiques et intégrations d'écosystèmes</li>
              </ul>
            </div>
          </div>
          
          <div className="bg-brand-50 p-8 rounded-lg shadow-sm mb-16">
            <h3 className="text-2xl font-semibold text-brand-700 mb-4">Niveaux d'influence</h3>
            <p className="text-gray-700 mb-4">
              Le système de gouvernance Guteco intègre différents niveaux d'influence basés sur l'engagement à long terme et la quantité de tokens stakés:
            </p>
            <div className="grid md:grid-cols-3 gap-6 mt-8">
              <div className="bg-white p-6 rounded-lg shadow-sm">
                <h4 className="text-xl font-semibold text-brand-600 mb-3">Niveau Standard</h4>
                <ul className="space-y-2 text-gray-700">
                  <li className="flex items-start gap-2">
                    <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-brand-600" />
                    <span><strong>Minimum:</strong> 100 GTC</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-brand-600" />
                    <span>Droits de vote basiques proportionnels aux tokens détenus</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-brand-600" />
                    <span>Accès aux forums de discussion de la communauté</span>
                  </li>
                </ul>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-sm">
                <h4 className="text-xl font-semibold text-brand-600 mb-3">Niveau Ambassadeur</h4>
                <ul className="space-y-2 text-gray-700">
                  <li className="flex items-start gap-2">
                    <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-brand-600" />
                    <span><strong>Minimum:</strong> 1 000 GTC stakés pendant {'>'}3 mois</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-brand-600" />
                    <span>Bonus de 20% sur le poids de vote</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-brand-600" />
                    <span>Accès anticipé aux nouveaux projets</span>
                  </li>
                </ul>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-sm">
                <h4 className="text-xl font-semibold text-brand-600 mb-3">Niveau Protecteur</h4>
                <ul className="space-y-2 text-gray-700">
                  <li className="flex items-start gap-2">
                    <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-brand-600" />
                    <span><strong>Minimum:</strong> 5 000 GTC stakés pendant {'>'}1 an</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-brand-600" />
                    <span>Bonus de 50% sur le poids de vote</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-brand-600" />
                    <span>Possibilité de créer des propositions privilégiées</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-brand-600" />
                    <span>Invitations aux événements exclusifs</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          
          <div className="text-center">
            <h3 className="text-2xl font-semibold text-brand-700 mb-6">Rejoindre la DAO Guteco</h3>
            <p className="text-gray-700 max-w-2xl mx-auto mb-8">
              Participez à la gouvernance de l'écosystème en acquérant des tokens GTC et en contribuant activement aux discussions communautaires.
            </p>
            <button className="bg-brand-600 hover:bg-brand-700 text-white font-semibold py-3 px-8 rounded-md transition-colors">
              Découvrir nos canaux communautaires
            </button>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Dao;
