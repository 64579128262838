import React, { useState } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { 
  HelpCircle, 
  ChevronDown, 
  Plus, 
  Minus, 
  ArrowRight, 
  Info, 
  AlertCircle, 
  Leaf, 
  Coins, 
  Shield,
  Mail
} from 'lucide-react';
import { useLanguage } from '@/contexts/LanguageContext';

const FAQ = () => {
  const [openQuestions, setOpenQuestions] = useState<Record<number, boolean>>({0: true});
  const { t } = useLanguage();

  const toggleQuestion = (index: number) => {
    setOpenQuestions(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const faqItems = [
    {
      category: t('faq.categories.general'),
      icon: <Info className="h-5 w-5 text-blue-500" />,
      questions: [
        {
          question: "Qu'est-ce que Guteco et quelle est sa mission ?",
          answer: "Guteco est une plateforme blockchain dédiée au financement de projets environnementaux via un marché de crédits carbone décentralisé. Notre mission est de faciliter et démocratiser l'investissement dans des solutions écologiques tout en garantissant la transparence et la traçabilité des crédits carbone."
        },
        {
          question: "Comment Guteco se différencie-t-il des autres plateformes de crédits carbone ?",
          answer: "Guteco se distingue par son approche décentralisée utilisant la blockchain, son modèle de gouvernance DAO permettant aux utilisateurs de participer aux décisions, et son double token (GTC et VCarbs) qui offre des options d'investissement flexibles tout en assurant la stabilité du système."
        }
      ]
    },
    {
      category: t('faq.categories.investment'),
      icon: <Coins className="h-5 w-5 text-amber-500" />,
      questions: [
        {
          question: "Comment fonctionnent les tokens GTC et VCarbs ?",
          answer: "Le GTC est le token de gouvernance de la plateforme qui permet de participer aux décisions via la DAO et de bénéficier de la croissance de l'écosystème. Le VCarbs est un stablecoin indexé sur la valeur des crédits carbone, offrant stabilité et liquidité pour les transactions quotidiennes et l'investissement dans des projets environnementaux."
        },
        {
          question: "Quels sont les rendements attendus pour les investisseurs ?",
          answer: "Les rendements varient selon le type d'investissement. Le staking de GTC peut générer entre 8-12% de rendement annuel, tandis que les investissements dans des projets spécifiques peuvent offrir des rendements de 5-14% selon la catégorie et la maturité du projet. Consultez notre page de rendements espérés pour plus de détails."
        },
        {
          question: "Comment puis-je investir dans Guteco ?",
          answer: "Vous pouvez investir dans Guteco de plusieurs façons : en acquérant des tokens GTC lors des phases de financement, en participant directement au financement de projets environnementaux via la plateforme, ou en fournissant des liquidités dans les pools GTC/VCarbs. Contactez-nous pour les opportunités d'investissement actuelles."
        }
      ]
    },
    {
      category: t('faq.categories.projects'),
      icon: <Leaf className="h-5 w-5 text-green-500" />,
      questions: [
        {
          question: "Comment les projets environnementaux sont-ils sélectionnés et validés ?",
          answer: "Les projets passent par un processus rigoureux de sélection en trois étapes : 1) Évaluation initiale basée sur des critères environnementaux, sociaux et économiques, 2) Vérification technique par des experts indépendants, et 3) Vote de la communauté DAO pour l'approbation finale. Des audits réguliers garantissent que les projets continuent de respecter nos standards."
        },
        {
          question: "Comment puis-je suivre l'impact de mon investissement ?",
          answer: "Notre plateforme offre un tableau de bord personnel qui suit en temps réel l'impact environnemental de vos investissements, y compris les tonnes de CO₂ séquestrées, les hectares préservés, et d'autres métriques pertinentes. Des rapports d'impact trimestriels détaillés sont également disponibles pour chaque projet."
        }
      ]
    },
    {
      category: t('faq.categories.security'),
      icon: <Shield className="h-5 w-5 text-purple-500" />,
      questions: [
        {
          question: "Comment Guteco assure-t-il la sécurité des fonds et des données ?",
          answer: "Guteco utilise des protocoles de sécurité blockchain de pointe, avec des contrats intelligents audités par des tiers, une authentification multi-facteurs pour les utilisateurs, et des solutions de stockage à froid pour les actifs. Nous suivons également les meilleures pratiques en matière de protection des données conformément aux réglementations internationales."
        },
        {
          question: "Guteco est-il conforme aux réglementations sur les cryptomonnaies et le marché carbone ?",
          answer: "Oui, Guteco travaille en étroite collaboration avec des conseillers juridiques spécialisés pour assurer la conformité avec les réglementations sur les cryptomonnaies, les marchés financiers et les standards internationaux du marché carbone comme le Verified Carbon Standard (VCS) et Gold Standard."
        }
      ]
    }
  ];

  return (
    <section id="faq" className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-brand-800 mb-4">{t('faq.title')}</h2>
          <p className="text-gray-600 max-w-3xl mx-auto">
            {t('faq.description')}
          </p>
          <div className="flex justify-center mt-6">
            <Popover>
              <PopoverTrigger asChild>
                <Button variant="outline" className="flex items-center gap-2">
                  <HelpCircle className="h-4 w-4" />
                  <span>{t('faq.notFound')}</span>
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-80">
                <div className="space-y-4">
                  <h4 className="font-medium">{t('faq.needMore')}</h4>
                  <p className="text-sm text-gray-600">
                    {t('faq.contactUs')}
                  </p>
                  <div className="flex items-center gap-2 text-sm text-gray-600 mb-2">
                    <Mail className="h-4 w-4 text-brand-600" />
                    <span>gtc.vcarbs@gmail.com</span>
                  </div>
                  <Button className="w-full" asChild>
                    <a href="mailto:gtc.vcarbs@gmail.com">
                      {t('faq.contact')}
                      <ArrowRight className="ml-2 h-4 w-4" />
                    </a>
                  </Button>
                </div>
              </PopoverContent>
            </Popover>
          </div>
        </div>

        <div className="max-w-4xl mx-auto">
          {faqItems.map((category, categoryIndex) => (
            <div key={categoryIndex} className="mb-8">
              <div className="flex items-center gap-3 mb-4">
                <div className="bg-white p-2 rounded-full shadow-sm">
                  {category.icon}
                </div>
                <h3 className="text-xl font-semibold text-gray-800">{category.category}</h3>
              </div>
              
              <div className="space-y-4">
                {category.questions.map((item, questionIndex) => {
                  const globalIndex = categoryIndex * 10 + questionIndex;
                  return (
                    <Card key={questionIndex} className="border hover:border-brand-200 transition-colors">
                      <CardHeader 
                        className="py-4 cursor-pointer flex flex-row items-center"
                        onClick={() => toggleQuestion(globalIndex)}
                      >
                        <div className="flex-1">
                          <CardTitle className="text-base md:text-lg text-gray-800 flex items-center gap-2">
                            <AlertCircle className="h-4 w-4 text-brand-600" />
                            {item.question}
                          </CardTitle>
                        </div>
                        <Button 
                          variant="ghost" 
                          size="icon" 
                          className="ml-auto shrink-0"
                        >
                          {openQuestions[globalIndex] ? (
                            <Minus className="h-4 w-4" />
                          ) : (
                            <Plus className="h-4 w-4" />
                          )}
                        </Button>
                      </CardHeader>
                      
                      {openQuestions[globalIndex] && (
                        <CardContent className="pt-0 pb-4">
                          <Separator className="mb-4" />
                          <CardDescription className="text-gray-600 text-base">
                            {item.answer}
                          </CardDescription>
                        </CardContent>
                      )}
                    </Card>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
