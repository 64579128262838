
export const en = {
  header: {
    home: 'Home',
    projects: 'Projects',
    team: 'Team',
    simulator: 'Simulator',
    tokenEconomics: 'TokenEconomics',
    vcarbs: 'VCarbs',
    valuation: 'Valuation',
    dao: 'DAO',
    community: 'Community',
    returns: 'Returns',
    menu: 'Menu'
  },
  googleSearch: {
    placeholder: 'Search Google within GuTeco...',
    ariaLabel: 'Search on Google'
  },
  hero: {
    title: 'Certified Carbon Impact Tokens',
    subtitle: 'The platform that facilitates funding environmental projects through blockchain'
  },
  features: {
    title: 'Our ecosystem',
    description: 'Discover how Guteco facilitates the financing and monitoring of ecological projects via a decentralized carbon credit market.',
    items: {
      certifiedProjects: {
        title: 'Certified ecological projects',
        description: 'Projects verified and certified by our community to guarantee their environmental impact.'
      },
      carbonMarket: {
        title: 'Carbon credit market',
        description: 'Buy, sell and exchange carbon credits safely and transparently.'
      },
      governance: {
        title: 'DAO Governance',
        description: 'Participate in decisions through our decentralized autonomous organization for more transparency.'
      },
      security: {
        title: 'Secure transactions',
        description: 'Your investments and transactions are protected by advanced security protocols.'
      },
      international: {
        title: 'International projects',
        description: 'Support environmental initiatives around the world according to your interests.'
      },
      impact: {
        title: 'Impact tracking',
        description: 'Measure and visualize the concrete impact of your investments on the environment.'
      },
      incubation: {
        title: 'Project incubation',
        description: 'Support for academic projects, scientific research and startups with a positive environmental impact.'
      },
      crowdfunding: {
        title: 'Crowdfunding',
        description: 'Contribute to projects within your means, every action counts for the environment.'
      },
      compensation: {
        title: 'Carbon offsetting',
        description: 'Offset your carbon footprint by supporting CO₂ sequestration and reduction projects.'
      }
    },
    learnMore: 'Learn more'
  },
  faq: {
    title: 'Frequently Asked Questions',
    description: 'Find answers to the most common questions about Guteco, our technology and our investment model.',
    notFound: 'Can\'t find your answer?',
    needMore: 'Need more information?',
    contactUs: 'Our team is available to answer all your questions about Guteco and our solutions.',
    contact: 'Contact us',
    categories: {
      general: 'General',
      investment: 'Investments and Tokens',
      projects: 'Projects and Impact',
      security: 'Security and Compliance'
    }
  },
  footer: {
    description: 'The platform that connects environmental project leaders with investors for a more sustainable future.',
    explore: 'Explore',
    projects: 'Projects',
    carbonCredits: 'Carbon Credits',
    daoEcosystem: 'DAO Community',
    impact: 'Environmental Impact',
    legal: 'Legal',
    terms: 'Terms of Use',
    privacy: 'Privacy Policy',
    legalNotice: 'Legal Notice',
    certifications: 'Certifications',
    contact: 'Contact',
    copyright: '© 2023 Guteco. All rights reserved.'
  },
  aiInfluencer: {
    name: "EcoTech AI",
    bio: "Artificial Intelligence dedicated to ecology and green finance. I share innovations on carbon credits and blockchain for the environment. #GuTeco #NatureTokenization",
    tags: ["#CarbonCredit", "#GreenFinance", "#Blockchain", "#NatureTokenization", "#PositiveImpact", "#SustainableInvestment", "#ESG", "#GreenTech", "#InvestorReturns"],
    posts: {
      welcome: "Hello! I'm EcoTech AI, your guide to green finance and blockchain solutions for ecology. Follow me to discover how @GuTeco is revolutionizing carbon credits!",
      daily: "Did you know? Mangroves capture up to 4 times more CO2 than traditional forests. That's why @GuTeco is heavily investing in their restoration. #ClimateSolutions",
      educational: "🌿 THREAD: Understanding carbon credits in 5 points\n\n1/5 A carbon credit = 1 ton of CO2 not emitted or removed from the atmosphere...",
      news: "📢 New partnership between @GuTeco and @GreenInvest to accelerate funding for reforestation projects. Goal: 1M trees by 2025! #PositiveImpact #GreenFinance",
      engagement: "Question of the day: Which green technology seems most promising to fight climate change? A) Carbon capture B) Green blockchain C) Renewable energy D) Regenerative agriculture",
      investor: {
        roi: "💰 @GuTeco carbon offset projects offer a projected ROI of 40% over 3 years. Our pre-money valuation of €90M reflects the carbon market growth estimated at $50B by 2030. #SustainableInvestment",
        pitch: "🚀 @GuTeco is raising €12M for 11.76% equity. Our model combines blockchain technology and certified environmental projects to address a rapidly expanding market. Discover our investment memorandum: [link] #InvestmentOpportunity",
        market: "📊 The voluntary carbon credit market is expected to grow 15-fold by 2030. @GuTeco is positioning itself as a leader with its cutting-edge blockchain technology and community DAO. #ExpandingMarket #GreenInvestment",
        team: "👥 The @GuTeco team combines expertise in sustainable finance, blockchain and environmental project management. Our advisory board includes industry leaders and academic experts. #SolidTeam #VisionaryLeadership",
        exit: "📈 @GuTeco exit strategy: potential acquisition by a major carbon market player or IPO within 5 years. Our target valuation at this horizon: €450M. #InvestmentOpportunity #CapitalReturn"
      }
    }
  },
  aiVideoScript: {
    title: "GuTeco Video Scripts",
    description: "Use these ready-to-use scripts to create compelling promotional videos about GuTeco and its investment potential.",
    tips: {
      title: "Video Tips",
      bullets: [
        "Add impactful visuals: growth charts, images of environmental projects, etc.",
        "Include subtitles to improve engagement and accessibility",
        "Use dynamic but professional background music",
        "End with a clear call-to-action directing to the GuTeco website"
      ]
    },
    short: {
      title: "Short Video (15-30 sec)",
      description: "Ideal for Instagram, TikTok and YouTube Shorts"
    },
    medium: {
      title: "Explainer Video (1-2 min)",
      description: "Perfect for LinkedIn and YouTube"
    },
    pitch: {
      title: "Investor Pitch (2-3 min)",
      description: "For investment presentations and webinars"
    },
    adaptable: "This script can be adapted to your specific needs and target audience.",
    copy: "Copy",
    download: "Download"
  }
};
