import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';
import { Coins, LineChart, ArrowRight, ShieldCheck, Leaf, Info, TrendingUp, ArrowUpDown, BookOpen, Rocket, BarChart4, Globe } from 'lucide-react';

const TokenInfo = () => {
  const navigate = useNavigate();

  // Function to open the pricing dialog defined in the parent component
  const openPricingDialog = () => {
    // Find the PricingInfo component and open it
    const event = new CustomEvent('openPricingDialog');
    window.dispatchEvent(event);
  };

  // Function to navigate to the Team page and focus on the returns section
  const viewExpectedReturns = () => {
    // This will require adding the ExpectedReturns component to a page
    navigate('/expected-returns');
  };

  // Function to navigate to the valuation page
  const viewValuation = () => {
    navigate('/valuation');
  };

  React.useEffect(() => {
    // Add event listener to handle the dialog opening from Index.tsx
    const handleOpenDialog = () => {
      const event = new CustomEvent('openPricingDialog');
      window.dispatchEvent(event);
    };

    // Listen for events to open the dialog
    window.addEventListener('openPricingDialog', handleOpenDialog);

    return () => {
      window.removeEventListener('openPricingDialog', handleOpenDialog);
    };
  }, []);

  return (
    <section className="py-16 bg-brand-50">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-brand-800 mb-4">Nos Tokens</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Découvrez l'écosystème tokenisé de Guteco, conçu pour favoriser l'impact environnemental et social, et récompenser la participation active.
          </p>
        </div>

        <div className="max-w-4xl mx-auto mb-8">
          <Card className="bg-gray-50 border-brand-100">
            <CardHeader className="pb-2">
              <CardTitle className="text-lg text-center">Taux de conversion</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="flex items-center justify-center gap-4 py-3">
                <div className="text-lg font-medium">1 GTC = 0.15 VCarbs</div>
                <ArrowUpDown className="h-5 w-5 text-gray-500" />
                <div className="text-lg font-medium">1 VCarbs = 6.67 GTC</div>
              </div>
              <p className="text-sm text-center text-gray-500 mt-2">
                Le prix initial est fixé à 0.15€ par GTC. Cette valeur peut être ajustée selon l'évolution du marché, la demande et l'adoption de l'écosystème. Notre conseil d'administration revoit le prix trimestriellement.
              </p>
            </CardContent>
          </Card>
        </div>

        <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
          <Card>
            <CardHeader>
              <div className="w-12 h-12 bg-brand-100 rounded-full flex items-center justify-center mb-4">
                <Coins className="h-6 w-6 text-brand-700" />
              </div>
              <CardTitle className="text-xl mb-2">Token GUTECO (GTC)</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div className="bg-gray-50 p-4 rounded-lg">
                  <h4 className="font-medium mb-2 text-brand-700">Comment l'obtenir ?</h4>
                  <ul className="space-y-2 text-gray-600">
                    <li className="flex items-start gap-2">
                      <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-brand-600" />
                      <span>Achat direct exclusivement sur la plateforme Guteco</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-brand-600" />
                      <span>Récompenses de staking et participation à la DAO</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-brand-600" />
                      <span>Distribution aux propriétaires de projets certifiés</span>
                    </li>
                  </ul>
                </div>

                <div className="bg-gray-50 p-4 rounded-lg">
                  <h4 className="font-medium mb-2 text-brand-700">Utilisation</h4>
                  <ul className="space-y-2 text-gray-600">
                    <li className="flex items-start gap-2">
                      <ShieldCheck className="h-5 w-5 shrink-0 mt-0.5 text-brand-600" />
                      <span>Gouvernance : vote sur les propositions et participation aux décisions de la DAO</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <Leaf className="h-5 w-5 shrink-0 mt-0.5 text-brand-600" />
                      <span>Staking pour générer des revenus passifs et des récompenses en VCarbs</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <Coins className="h-5 w-5 shrink-0 mt-0.5 text-brand-600" />
                      <span>Réductions sur les frais de plateforme et accès prioritaire aux projets</span>
                    </li>
                  </ul>
                </div>

                <Button 
                  onClick={() => navigate('/dao')} 
                  className="w-full"
                >
                  En savoir plus sur la DAO et le staking
                </Button>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <div className="w-12 h-12 bg-emerald-100 rounded-full flex items-center justify-center mb-4">
                <LineChart className="h-6 w-6 text-emerald-700" />
              </div>
              <CardTitle className="text-xl mb-2">Stablecoin VCarbs</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div className="bg-gray-50 p-4 rounded-lg">
                  <h4 className="font-medium mb-2 text-emerald-700">Comment l'obtenir ?</h4>
                  <ul className="space-y-2 text-gray-600">
                    <li className="flex items-start gap-2">
                      <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-emerald-600" />
                      <span>Récompenses mensuelles du staking de GTC</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-emerald-600" />
                      <span>Vente de crédits carbone sur la plateforme</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <ArrowRight className="h-5 w-5 shrink-0 mt-0.5 text-emerald-600" />
                      <span>Échange sur les plateformes de trading partenaires</span>
                    </li>
                  </ul>
                </div>

                <div className="bg-gray-50 p-4 rounded-lg">
                  <h4 className="font-medium mb-2 text-emerald-700">Utilisation</h4>
                  <ul className="space-y-2 text-gray-600">
                    <li className="flex items-start gap-2">
                      <Coins className="h-5 w-5 shrink-0 mt-0.5 text-emerald-600" />
                      <span>Achat de crédits carbone sur la plateforme</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <BookOpen className="h-5 w-5 shrink-0 mt-0.5 text-emerald-600" />
                      <span>Financement de projets sociaux et éducatifs jugés pertinents</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <Rocket className="h-5 w-5 shrink-0 mt-0.5 text-emerald-600" />
                      <span>Soutien aux innovations technologiques à impact positif</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <ShieldCheck className="h-5 w-5 shrink-0 mt-0.5 text-emerald-600" />
                      <span>Compensation carbone pour les entreprises et particuliers</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <Leaf className="h-5 w-5 shrink-0 mt-0.5 text-emerald-600" />
                      <span>Investissement direct dans des projets environnementaux</span>
                    </li>
                  </ul>
                </div>

                <div className="bg-emerald-50 p-4 rounded-lg border border-emerald-100">
                  <div className="flex items-center justify-between">
                    <p className="text-sm text-emerald-700 flex items-center gap-2">
                      <span>1 VCarbs = 1€ = $1.10, indexé sur la valeur des crédits carbone</span>
                    </p>
                    <Button 
                      variant="ghost" 
                      size="sm" 
                      onClick={openPricingDialog} 
                      className="p-0 h-auto"
                    >
                      <Info className="h-5 w-5 text-emerald-600" />
                    </Button>
                  </div>
                  <p className="text-xs text-emerald-600 mt-1">
                    1 VCarbs représente 1 tonne de CO₂ évitée ou séquestrée
                  </p>
                </div>

                <div className="bg-blue-50 p-4 rounded-lg border border-blue-100">
                  <h4 className="font-medium text-blue-700 mb-2 flex items-center gap-2">
                    <Globe className="h-4 w-4" />
                    Conversion vers les monnaies fiat
                  </h4>
                  <p className="text-sm text-gray-700 mb-2">
                    Guteco assure la liquidité des VCarbs avec plusieurs mécanismes:
                  </p>
                  <ul className="space-y-1 text-sm text-gray-600">
                    <li className="flex items-start gap-2">
                      <ArrowRight className="h-4 w-4 shrink-0 mt-0.5 text-blue-600" />
                      <span>Fonds de réserve garantissant le rachat des VCarbs (€, $, CHF)</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <ArrowRight className="h-4 w-4 shrink-0 mt-0.5 text-blue-600" />
                      <span>Intégration avec DEX partenaires (Uniswap, Balancer)</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <ArrowRight className="h-4 w-4 shrink-0 mt-0.5 text-blue-600" />
                      <span>Programme de market makers pour maintenir la liquidité</span>
                    </li>
                  </ul>
                </div>

                <Button 
                  onClick={() => navigate('/projects')} 
                  variant="outline" 
                  className="w-full"
                >
                  Voir les projets disponibles
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>

        <div className="mt-10 text-center space-x-4">
          <Button 
            onClick={viewExpectedReturns}
            className="bg-brand-600 hover:bg-brand-700 gap-2"
          >
            <TrendingUp className="h-4 w-4" />
            Voir les rendements espérés
          </Button>

          <Button 
            onClick={viewValuation}
            variant="outline"
            className="gap-2 border-brand-600 text-brand-600 hover:bg-brand-50"
          >
            <BarChart4 className="h-4 w-4" />
            Analyse de valorisation
          </Button>
        </div>
      </div>
    </section>
  );
};

export default TokenInfo;
