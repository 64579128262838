
import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { LucideIcon } from 'lucide-react';

type TokenMechanism = {
  title: string;
  description: string;
  icon: LucideIcon;
  color: string;
  iconColor: string;
};

interface TokenMechanismCardsProps {
  mechanisms: TokenMechanism[];
  title: string;
  className?: string;
}

const TokenMechanismCards = ({ mechanisms, title, className }: TokenMechanismCardsProps) => {
  return (
    <Card className={className}>
      <CardHeader className="bg-emerald-50">
        <CardTitle className="text-xl text-emerald-800">{title}</CardTitle>
      </CardHeader>
      <CardContent className="pt-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {mechanisms.map((mechanism, index) => (
            <div key={index} className="p-4 border border-gray-200 rounded-lg shadow-sm">
              <div className={`w-10 h-10 ${mechanism.color} rounded-full flex items-center justify-center mb-3`}>
                <mechanism.icon className={`h-5 w-5 ${mechanism.iconColor}`} />
              </div>
              <h3 className="text-lg font-semibold text-gray-800 mb-2">{mechanism.title}</h3>
              <p className="text-gray-600">{mechanism.description}</p>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default TokenMechanismCards;
