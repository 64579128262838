
import React, { useState } from 'react';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import { Button } from '@/components/ui/button';
import { 
  Card, 
  CardContent, 
  CardFooter, 
  CardHeader, 
  CardTitle 
} from '@/components/ui/card';
import { 
  Dialog, 
  DialogContent, 
  DialogDescription, 
  DialogFooter, 
  DialogHeader, 
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { 
  Tabs, 
  TabsContent, 
  TabsList, 
  TabsTrigger 
} from '@/components/ui/tabs';
import { useToast } from '@/hooks/use-toast';
import { Coins, ShoppingCart, Filter, Search, Tag, CreditCard, ArrowLeft, Info, BookOpen, Rocket } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const VCarbs = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [selectedTab, setSelectedTab] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCredit, setSelectedCredit] = useState<CarbonCredit | null>(null);
  const [purchaseAmount, setPurchaseAmount] = useState(1);
  const [isPurchasing, setIsPurchasing] = useState(false);

  type CarbonCredit = {
    id: number;
    title: string;
    location: string;
    type: string;
    price: number;
    available: number;
    description: string;
    image: string;
  };

  const carbonCredits: CarbonCredit[] = [
    {
      id: 1,
      title: "Reforestation Amazonie",
      location: "Brésil",
      type: "forestry",
      price: 28,
      available: 750,
      description: "Crédits générés par le projet de reforestation de la forêt amazonienne. Ces crédits soutiennent la plantation d'espèces indigènes et la restauration des écosystèmes.",
      image: "https://images.unsplash.com/photo-1619546952812-520e98064a52?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3"
    },
    {
      id: 2,
      title: "Énergie Solaire Sahel",
      location: "Mali",
      type: "renewable",
      price: 32,
      available: 420,
      description: "Crédits issus du projet d'énergie solaire remplaçant les générateurs diesel dans les zones rurales, réduisant considérablement les émissions de carbone.",
      image: "https://images.unsplash.com/photo-1509391366360-2e959784a276?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3"
    },
    {
      id: 3,
      title: "Mangroves Indonésie",
      location: "Indonésie",
      type: "blue",
      price: 25,
      available: 630,
      description: "Ces crédits financent la restauration des mangroves côtières en Indonésie, qui constituent des puits de carbone exceptionnels tout en protégeant les littoraux.",
      image: "https://images.unsplash.com/photo-1482938289607-e9573fc25ebb?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3"
    },
    {
      id: 4,
      title: "Éducation Numérique",
      location: "Sénégal",
      type: "social",
      price: 30,
      available: 280,
      description: "Financement de programmes éducatifs numériques pour les zones rurales. Ces projets permettent l'accès à l'éducation et réduisent les inégalités.",
      image: "https://images.unsplash.com/photo-1503676260728-1c00da094a0b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3"
    },
    {
      id: 5,
      title: "Éolien Offshore Écosse",
      location: "Écosse",
      type: "renewable",
      price: 35,
      available: 1200,
      description: "Ces crédits sont générés par le projet d'énergie éolienne en mer du Nord, fournissant de l'électricité propre à plus de 100 000 foyers britanniques.",
      image: "https://images.unsplash.com/photo-1466611653911-95081537e5b7?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3"
    },
    {
      id: 6,
      title: "Tourbières Canada",
      location: "Canada",
      type: "blue",
      price: 27,
      available: 540,
      description: "Ces crédits soutiennent la protection et la restauration des tourbières boréales au Canada, d'importants puits de carbone naturels menacés par l'exploitation minière.",
      image: "https://images.unsplash.com/photo-1509316975850-ff9c5deb0cd9?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3"
    },
    {
      id: 7,
      title: "Innovations Agricoles",
      location: "Kenya",
      type: "tech",
      price: 33,
      available: 320,
      description: "Soutien aux technologies agricoles innovantes permettant de réduire l'empreinte carbone tout en améliorant les rendements pour les communautés locales.",
      image: "https://images.unsplash.com/photo-1530836369250-ef72a3f5cda8?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3"
    }
  ];

  const filteredCredits = carbonCredits.filter(credit => {
    const typeMatch = selectedTab === 'all' || credit.type === selectedTab;
    
    const searchMatch = 
      credit.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      credit.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
      credit.description.toLowerCase().includes(searchTerm.toLowerCase());
    
    return typeMatch && searchMatch;
  });

  const handlePurchase = () => {
    if (!selectedCredit) return;
    
    setIsPurchasing(true);
    
    setTimeout(() => {
      setIsPurchasing(false);
      toast({
        title: "Achat réussi!",
        description: `Vous avez acheté ${purchaseAmount} crédit(s) de "${selectedCredit.title}" pour un total de ${purchaseAmount * selectedCredit.price}€.`,
      });
      setSelectedCredit(null);
      setPurchaseAmount(1);
    }, 1500);
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      
      <main className="flex-grow">
        <div className="container mx-auto px-4 py-8">
          <Button 
            variant="outline" 
            className="mb-6 flex items-center gap-2"
            onClick={() => navigate('/')}
          >
            <ArrowLeft className="h-4 w-4" />
            <span>Retour à l'accueil</span>
          </Button>
          
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-8">
            <div>
              <h1 className="text-2xl font-bold text-brand-800 mb-2">Marketplace VCarbs</h1>
              <p className="text-gray-600">
                Achetez des crédits carbone vérifiés et soutenez des projets écologiques, sociaux, éducatifs et technologiques à fort impact
              </p>
            </div>
            <div className="flex flex-col sm:flex-row gap-4 w-full md:w-auto">
              <div className="relative w-full md:w-64">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4" />
                <Input
                  placeholder="Rechercher des crédits..."
                  className="pl-10"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
          </div>
          
          {/* Notification pour indiquer que les projets sont fictifs */}
          <div className="mb-6 p-4 bg-amber-50 border border-amber-200 rounded-lg flex items-start gap-3">
            <Info className="h-5 w-5 text-amber-500 mt-0.5 flex-shrink-0" />
            <p className="text-amber-800 text-sm">
              <strong>Note importante :</strong> Les projets présentés sur cette marketplace sont fictifs et servent uniquement de démonstration. 
              Dans un environnement de production, seuls des projets réels et vérifiés seraient proposés.
            </p>
          </div>
          
          <Tabs defaultValue="all" className="mb-8" onValueChange={setSelectedTab}>
            <div className="flex items-center gap-4 mb-6">
              <Filter className="h-5 w-5 text-gray-500" />
              <span className="font-medium text-gray-700">Filtres:</span>
              <TabsList>
                <TabsTrigger value="all">Tous</TabsTrigger>
                <TabsTrigger value="forestry">Forestier</TabsTrigger>
                <TabsTrigger value="renewable">Énergies Renouvelables</TabsTrigger>
                <TabsTrigger value="blue">Carbone Bleu</TabsTrigger>
                <TabsTrigger value="social">Projets Sociaux</TabsTrigger>
                <TabsTrigger value="tech">Nouvelles Technologies</TabsTrigger>
              </TabsList>
            </div>
            
            <TabsContent value="all" className="mt-0">
              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
                {filteredCredits.map((credit) => (
                  <Card key={credit.id} className="overflow-hidden hover:shadow-lg transition-shadow">
                    <div className="h-48 overflow-hidden">
                      <img 
                        src={credit.image} 
                        alt={credit.title} 
                        className="w-full h-full object-cover transition-transform hover:scale-105"
                      />
                    </div>
                    <CardHeader className="pb-2">
                      <div className="flex justify-between items-start">
                        <CardTitle className="text-xl">{credit.title}</CardTitle>
                        <div className="px-2 py-1 bg-brand-50 text-brand-700 rounded-md text-sm font-medium">
                          {credit.type === 'forestry' && 'Forestier'}
                          {credit.type === 'renewable' && 'Énergie Renouvelable'}
                          {credit.type === 'blue' && 'Carbone Bleu'}
                          {credit.type === 'social' && 'Projet Social'}
                          {credit.type === 'tech' && 'Innovation Tech'}
                        </div>
                      </div>
                      <div className="flex items-center gap-1 text-gray-500 text-sm">
                        <Tag className="h-4 w-4" />
                        <span>{credit.location}</span>
                      </div>
                    </CardHeader>
                    
                    <CardContent>
                      <p className="text-sm text-gray-600 line-clamp-3 mb-4">
                        {credit.description}
                      </p>
                      
                      <div className="flex justify-between items-center">
                        <div className="flex items-center gap-1">
                          <Coins className="h-4 w-4 text-amber-500" />
                          <span className="font-bold text-lg">{credit.price} €</span>
                          <span className="text-gray-500 text-sm">/ crédit</span>
                        </div>
                        <div className="text-sm text-gray-500">
                          {credit.available} disponibles
                        </div>
                      </div>
                    </CardContent>
                    
                    <CardFooter>
                      <Dialog>
                        <DialogTrigger asChild>
                          <Button 
                            onClick={() => setSelectedCredit(credit)}
                            className="w-full bg-highlight hover:bg-highlight-hover text-black flex items-center gap-2"
                          >
                            <ShoppingCart className="h-4 w-4" />
                            <span>Acheter</span>
                          </Button>
                        </DialogTrigger>
                        
                        {selectedCredit && (
                          <DialogContent className="sm:max-w-[425px]">
                            <DialogHeader>
                              <DialogTitle>Acheter des crédits carbone</DialogTitle>
                              <DialogDescription>
                                Vous êtes sur le point d'acheter des crédits du projet {selectedCredit.title}.
                              </DialogDescription>
                            </DialogHeader>
                            
                            <div className="grid gap-4 py-4">
                              <div>
                                <div className="mb-4 p-4 bg-gray-50 rounded-md">
                                  <div className="flex justify-between mb-2">
                                    <span className="text-gray-600">Projet:</span>
                                    <span className="font-medium">{selectedCredit.title}</span>
                                  </div>
                                  <div className="flex justify-between mb-2">
                                    <span className="text-gray-600">Prix unitaire:</span>
                                    <span className="font-medium">{selectedCredit.price} €</span>
                                  </div>
                                  <div className="flex justify-between mb-2">
                                    <span className="text-gray-600">Disponibilité:</span>
                                    <span className="font-medium">{selectedCredit.available} crédits</span>
                                  </div>
                                  <div className="border-t mt-2 pt-2 flex justify-between">
                                    <span className="font-medium">Total:</span>
                                    <span className="font-bold text-brand-700">{selectedCredit.price * purchaseAmount} €</span>
                                  </div>
                                </div>
                                
                                <div className="space-y-2">
                                  <Label htmlFor="amount">Quantité</Label>
                                  <Input
                                    id="amount"
                                    type="number"
                                    value={purchaseAmount}
                                    onChange={(e) => setPurchaseAmount(Math.max(1, Math.min(selectedCredit.available, parseInt(e.target.value) || 1)))}
                                    min={1}
                                    max={selectedCredit.available}
                                  />
                                </div>
                              </div>
                            </div>
                            
                            <DialogFooter>
                              <Button 
                                onClick={handlePurchase}
                                className="bg-highlight hover:bg-highlight-hover text-black"
                                disabled={isPurchasing}
                              >
                                {isPurchasing ? (
                                  <>
                                    <span className="animate-spin rounded-full h-4 w-4 border-b-2 border-black mr-2"></span>
                                    <span>Achat en cours...</span>
                                  </>
                                ) : (
                                  <>
                                    <CreditCard className="h-4 w-4 mr-2" />
                                    <span>Confirmer l'achat</span>
                                  </>
                                )}
                              </Button>
                            </DialogFooter>
                          </DialogContent>
                        )}
                      </Dialog>
                    </CardFooter>
                  </Card>
                ))}
              </div>
              
              {filteredCredits.length === 0 && (
                <div className="flex flex-col items-center justify-center py-16 text-center">
                  <div className="bg-gray-100 p-6 rounded-full mb-4">
                    <Search className="h-8 w-8 text-gray-400" />
                  </div>
                  <h3 className="text-xl font-semibold mb-2">Aucun crédit trouvé</h3>
                  <p className="text-gray-600 max-w-md">
                    Aucun crédit carbone ne correspond à vos critères de recherche. Essayez de modifier vos filtres.
                  </p>
                </div>
              )}
            </TabsContent>
            
            <TabsContent value="forestry" className="mt-0">
              {/* Same grid as "all" but with filtered results */}
            </TabsContent>
            
            <TabsContent value="renewable" className="mt-0">
              {/* Same grid as "all" but with filtered results */}
            </TabsContent>
            
            <TabsContent value="blue" className="mt-0">
              {/* Same grid as "all" but with filtered results */}
            </TabsContent>
            
            <TabsContent value="social" className="mt-0">
              {/* Same grid as "all" but with filtered results */}
            </TabsContent>
            
            <TabsContent value="tech" className="mt-0">
              {/* Same grid as "all" but with filtered results */}
            </TabsContent>
          </Tabs>
        </div>
      </main>
      
      <Footer />
    </div>
  );
};

export default VCarbs;
