
import React from 'react';
import { Wallet, Lock, Leaf, Users, LineChart, GraduationCap, BookOpen, Rocket, TrendingUp } from 'lucide-react';
import TokenAllocationChart from './TokenAllocationChart';
import TokenMechanismCards from './TokenMechanismCards';
import VestingRulesTable from './VestingRulesTable';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';
import { useIsMobile } from '@/hooks/use-mobile';

const TokenEconomics = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  
  // Allocation des tokens GTC
  const tokenAllocation = [
    { name: 'Récompenses utilisateurs', value: 30, color: '#10b981' },
    { name: 'Vente privée', value: 15, color: '#3b82f6' },
    { name: 'Vente publique (IDO/ICO)', value: 10, color: '#8b5cf6' },
    { name: 'Trésorerie', value: 20, color: '#f59e0b' },
    { name: 'Équipe et conseillers', value: 15, color: '#ec4899' },
    { name: 'Liquidité', value: 10, color: '#06b6d4' }
  ];

  // Utilisation des fonds levés - Mise à jour des pourcentages
  const fundUsage = [
    { name: 'Restauration mangroves', value: 41.7, color: '#10b981' },
    { name: 'Drones professionnels', value: 16.7, color: '#3b82f6' },
    { name: 'Développement tech & Blockchain', value: 16.7, color: '#8b5cf6' },
    { name: 'Certifications & audits', value: 12.5, color: '#f59e0b' },
    { name: 'Marketing & partenariats', value: 8.3, color: '#ec4899' },
    { name: 'Salaires & opérations', value: 4.1, color: '#06b6d4' }
  ];

  // Sources de revenus - more concise names for better visibility in chart
  const revenueSources = [
    { name: 'Crédits-Arbres', value: 25, color: '#10b981' },
    { name: 'Crédits-Mangroves', value: 35, color: '#3b82f6' },
    { name: 'Biodiversité', value: 15, color: '#8b5cf6' },
    { name: 'Écotourisme', value: 10, color: '#f59e0b' },
    { name: 'Services conseil', value: 8, color: '#ec4899' },
    { name: 'Frais transaction', value: 7, color: '#06b6d4' }
  ];

  // Informations sur les mécanismes du token
  const tokenMechanisms = [
    {
      title: "Staking & Rendement",
      description: "Blocage de tokens GTC pour générer des VCarbs mensuellement",
      icon: Lock,
      color: "bg-blue-100",
      iconColor: "text-blue-700"
    },
    {
      title: "Gouvernance DAO",
      description: "Vote sur les décisions importantes proportionnel aux GTC détenus",
      icon: Users,
      color: "bg-purple-100",
      iconColor: "text-purple-700"
    },
    {
      title: "VCarbs & Projets",
      description: "Financement direct de projets environnementaux via VCarbs",
      icon: Leaf,
      color: "bg-green-100",
      iconColor: "text-green-700"
    },
    {
      title: "Projets Sociaux",
      description: "Financement de projets sociaux et éducatifs jugés pertinents par la gouvernance",
      icon: BookOpen,
      color: "bg-indigo-100",
      iconColor: "text-indigo-700"
    },
    {
      title: "Innovations Tech",
      description: "Soutien aux technologies innovantes qui répondent à des besoins collectifs",
      icon: Rocket,
      color: "bg-rose-100",
      iconColor: "text-rose-700"
    },
    {
      title: "Échange DEX/AMM",
      description: "Liquidité pour échanger GTC contre VCarbs avec frais réduits",
      icon: LineChart,
      color: "bg-amber-100",
      iconColor: "text-amber-700"
    },
    {
      title: "Incubation de projets",
      description: "Financement et accompagnement de nouveaux projets à impact",
      icon: GraduationCap,
      color: "bg-pink-100",
      iconColor: "text-pink-700"
    },
    {
      title: "Récompenses d'adoption",
      description: "Distribution à l'usage et aux contributeurs de l'écosystème",
      icon: Wallet,
      color: "bg-teal-100",
      iconColor: "text-teal-700"
    }
  ];

  // Mise à jour des règles de vesting
  const vestingRules = [
    {
      group: "Équipe et conseillers",
      rule: "18 mois de cliff + 36 mois de vesting linéaire",
      percent: "15%"
    },
    {
      group: "Vente privée",
      rule: "6 mois de cliff + 18 mois de vesting linéaire",
      percent: "15%"
    },
    {
      group: "Vente publique (IDO/ICO)",
      rule: "Déblocage initial 20%, puis 10% tous les 3 mois",
      percent: "10%"
    },
    {
      group: "Trésorerie",
      rule: "Déblocage progressif sur 5 ans selon les besoins",
      percent: "20%"
    },
    {
      group: "Récompenses utilisateurs",
      rule: "Distribution progressive sur 4 ans selon l'usage",
      percent: "30%"
    },
    {
      group: "Liquidité",
      rule: "Déblocage initial 30%, puis linéaire sur 2 ans",
      percent: "10%"
    }
  ];

  // Hauteur adaptée selon le device
  const chartHeight = isMobile ? '400px' : '500px';

  return (
    <section id="tokeneconomics" className="py-10 md:py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl md:text-3xl font-bold text-center text-brand-800 mb-4 md:mb-6">Tokenomics</h2>
        <p className="text-lg md:text-xl text-center text-gray-600 max-w-3xl mx-auto mb-6">
          Notre modèle économique tokenisé est conçu pour favoriser la croissance de l'écosystème et récompenser les participants
        </p>
        
        <div className="text-center mb-8 md:mb-12">
          <Button 
            variant="outline" 
            onClick={() => {
              // Scroll to the ProjectEvolution section
              const element = document.getElementById('project-evolution');
              if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
              }
            }}
            className="inline-flex items-center gap-2 border-brand-600 text-brand-600"
          >
            <TrendingUp size={16} />
            <span>Voir l'évolution du projet sur 5 ans</span>
          </Button>
        </div>
        
        {/* Section 1: Allocation des Tokens GTC */}
        <div className="mb-10 md:mb-16">
          <h3 className="text-xl md:text-2xl font-bold text-center text-brand-800 mb-4 md:mb-6">Allocation des Tokens GTC</h3>
          <div className="max-w-5xl mx-auto overflow-hidden">
            <TokenAllocationChart 
              title="" 
              data={tokenAllocation}
              description="Supply Total: 100 000 000 GTC | Modèle Déflationniste: Mécanisme de rachat et burn de 2% des frais de transaction"
              className={`w-full h-[${chartHeight}]`}
            />
          </div>
        </div>
        
        {/* Section 2: Utilisation des Fonds Levés */}
        <div className="mb-10 md:mb-16">
          <h3 className="text-xl md:text-2xl font-bold text-center text-brand-800 mb-4 md:mb-6">Utilisation des 12M€ Levés</h3>
          <div className="max-w-5xl mx-auto overflow-hidden">
            <TokenAllocationChart 
              title="" 
              data={fundUsage}
              description="Objectif de levée: 12 000 000 € pour financer la restauration de 10 000 hectares de mangroves, le développement technologique, l'acquisition d'équipements, les certifications, le marketing et les opérations."
              className={`w-full h-[${chartHeight}]`}
            />
          </div>
        </div>
        
        {/* Section 3: Sources de Revenus */}
        <div className="mb-10 md:mb-16">
          <h3 className="text-xl md:text-2xl font-bold text-center text-brand-800 mb-4 md:mb-6">Sources de Revenus</h3>
          <div className="max-w-5xl mx-auto overflow-hidden">
            <TokenAllocationChart 
              title=""
              data={revenueSources}
              description="Les mangroves représentent notre source principale de revenus grâce à leur efficacité 4 à 10 fois supérieure aux forêts traditionnelles pour la captation de carbone, permettant une commercialisation plus rapide des crédits."
              className={`w-full h-[${chartHeight}]`}
            />
          </div>
        </div>
        
        <div className="grid grid-cols-1 mb-8 md:mb-12">
          <TokenMechanismCards 
            title="Mécanismes du Token"
            mechanisms={tokenMechanisms}
          />
        </div>
        
        <div className="grid grid-cols-1 mb-6">
          <VestingRulesTable 
            title="Règles de Vesting et Libération"
            rules={vestingRules}
            description="La période de vesting est conçue pour assurer un engagement à long terme des parties prenantes et une distribution progressive des tokens pour soutenir la stabilité du prix."
          />
        </div>
      </div>
    </section>
  );
};

export default TokenEconomics;
