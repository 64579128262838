export const sv = {
  header: {
    home: 'Hem',
    projects: 'Projekt',
    team: 'Team',
    simulator: 'Simulator',
    tokenEconomics: 'TokenEconomics',
    vcarbs: 'VCarbs',
    valuation: 'Värderingar',
    dao: 'DAO',
    community: 'Gemenskap',
    returns: 'Avkastning',
    menu: 'Meny'
  },
  hero: {
    title: 'Certifierade koldioxidpåverkan-tokens',
    subtitle: 'Plattformen som underlättar finansiering av miljöprojekt genom blockchain'
  },
  features: {
    title: 'Vårt ekosystem',
    description: 'Upptäck hur Guteco underlättar finansiering och övervakning av ekologiska projekt via en decentraliserad koldioxidkreditmarknad.',
    items: {
      certifiedProjects: {
        title: 'Certifierade ekologiska projekt',
        description: 'Projekt verifierade och certifierade av vårt community för att garantera deras miljöpåverkan.'
      },
      carbonMarket: {
        title: 'Koldioxidkreditmarknad',
        description: 'Köp, sälj och byt koldioxidkrediter säkert och transparent.'
      },
      governance: {
        title: 'DAO-styrning',
        description: 'Delta i beslut genom vår decentraliserade autonoma organisation för mer transparens.'
      },
      security: {
        title: 'Säkra transaktioner',
        description: 'Dina investeringar och transaktioner skyddas av avancerade säkerhetsprotokoll.'
      },
      international: {
        title: 'Internationella projekt',
        description: 'Stöd miljöinitiativ över hela världen enligt dina intressen.'
      },
      impact: {
        title: 'Påverkansuppföljning',
        description: 'Mät och visualisera den konkreta påverkan av dina investeringar på miljön.'
      },
      incubation: {
        title: 'Projektinkubation',
        description: 'Stöd till akademiska projekt, vetenskaplig forskning och startups med positiv miljöpåverkan.'
      },
      crowdfunding: {
        title: 'Gräsrotsfinansiering',
        description: 'Bidra till projekt inom dina möjligheter, varje handling räknas för miljön.'
      },
      compensation: {
        title: 'Kolkompensation',
        description: 'Kompensera ditt koldioxidavtryck genom att stödja projekt för avskiljning och minskning av CO₂.'
      }
    },
    learnMore: 'Läs mer'
  },
  faq: {
    title: 'Vanliga frågor',
    description: 'Hitta svar på de vanligaste frågorna om Guteco, vår teknik och vår investeringsmodell.',
    notFound: 'Kan du inte hitta ditt svar?',
    needMore: 'Behöver du mer information?',
    contactUs: 'Vårt team finns tillgängligt för att svara på alla dina frågor om Guteco och våra lösningar.',
    contact: 'Kontakta oss',
    categories: {
      general: 'Allmänt',
      investment: 'Investeringar och Tokens',
      projects: 'Projekt och Påverkan',
      security: 'Säkerhet och Överensstämmelse'
    }
  },
  footer: {
    description: 'Plattformen som kopplar ihop miljöprojektledare med investerare för en mer hållbar framtid.',
    explore: 'Utforska',
    projects: 'Projekt',
    carbonCredits: 'Koldioxidkrediter',
    daoEcosystem: 'DAO-gemenskap',
    impact: 'Miljöpåverkan',
    legal: 'Juridiskt',
    terms: 'Användarvillkor',
    privacy: 'Integritetspolicy',
    legalNotice: 'Juridiskt meddelande',
    certifications: 'Certifieringar',
    contact: 'Kontakt',
    copyright: '© 2023 Guteco. Alla rättigheter förbehållna.'
  },
  aiInfluencer: {
    name: "EcoTech AI",
    bio: "Artificiell Intelligens dedikerad till ekologi och grön finansiering. Jag delar innovationer om kolkrediter och blockchain för miljön. #GuTeco #NaturTokenisering",
    tags: ["#Kolkrediter", "#GrönFinansiering", "#Blockchain", "#NaturTokenisering", "#PositivPåverkan"],
    posts: {
      welcome: "Hej! Jag är EcoTech AI, din guide i världen av grön finansiering och blockkedjelösningar för ekologi. Följ mig för att upptäcka hur @GuTeco revolutionerar kolkrediter!",
      daily: "Visste du? Mangroveträsk fångar upp till 4 gånger mer CO2 än traditionella skogar. Därför investerar @GuTeco kraftigt i deras återställande. #Klimatlösningar",
      educational: "🌿 TRÅD: Förstå kolkrediter i 5 punkter\n\n1/5 En kolkredit = 1 ton CO2 som inte släpps ut eller tas bort från atmosfären...",
      news: "📢 Nytt partnerskap mellan @GuTeco och @GreenInvest för att påskynda finansieringen av återplanteringsprojekt. Mål: 1M träd till 2025! #PositivPåverkan #GrönFinansiering",
      engagement: "Dagens fråga: Vilken grön teknik verkar mest lovande för att bekämpa klimatförändringen? A) Koldioxidinfångning B) Grön blockchain C) Förnybar energi D) Regenerativt jordbruk"
    }
  }
};
