
import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { translations } from '@/translations';

// Define available languages
export type LanguageCode = 'fr' | 'en' | 'es' | 'it' | 'sv';

type LanguageContextType = {
  language: LanguageCode;
  setLanguage: (lang: LanguageCode) => void;
  t: (key: string) => string;
};

// Create context with a default value
const defaultContext: LanguageContextType = {
  language: 'fr',
  setLanguage: () => {}, // Will be replaced with actual implementation
  t: (key: string) => key, // Default simply returns the key
};

const LanguageContext = createContext<LanguageContextType>(defaultContext);

export const LanguageProvider = ({ children }: { children: ReactNode }) => {
  // Get browser language or default to French
  const getBrowserLanguage = (): LanguageCode => {
    if (typeof window === 'undefined') return 'fr';
    
    const browserLang = navigator.language.split('-')[0];
    const availableLangs = ['fr', 'en', 'es', 'it', 'sv'];
    return availableLangs.includes(browserLang) ? browserLang as LanguageCode : 'fr';
  };

  // Get saved language or use browser language
  const getSavedLanguage = (): LanguageCode => {
    if (typeof window === 'undefined') return 'fr';
    
    try {
      const savedLang = localStorage.getItem('guteco-language');
      if (savedLang && ['fr', 'en', 'es', 'it', 'sv'].includes(savedLang)) {
        return savedLang as LanguageCode;
      }
    } catch (e) {
      console.error('Could not access localStorage for language setting:', e);
    }
    return getBrowserLanguage();
  };

  const [language, setLanguageState] = useState<LanguageCode>(getSavedLanguage());
  
  // Set language and save to localStorage
  const setLanguage = (lang: LanguageCode) => {
    if (lang === language) return;
    
    console.log(`Setting language to: ${lang}`);
    setLanguageState(lang);
    
    try {
      localStorage.setItem('guteco-language', lang);
    } catch (e) {
      console.error('Could not save language to localStorage:', e);
    }
    
    // Update HTML lang attribute
    if (typeof document !== 'undefined') {
      document.documentElement.lang = lang;
    }
    
    // Force a complete page reload - this is the most reliable way to update all components
    window.location.reload();
  };

  // Get translation for a key based on current language
  const t = (key: string): string => {
    const langData = translations[language];
    if (!langData) {
      console.error(`No translations found for language: ${language}`);
      return key;
    }
    
    const keys = key.split('.');
    let value: any = langData;
    
    for (const k of keys) {
      if (value && typeof value === 'object' && k in value) {
        value = value[k];
      } else {
        console.warn(`Translation missing: ${key} for language ${language}`);
        return key; // Return the key itself as fallback
      }
    }
    
    return value;
  };

  useEffect(() => {
    console.log(`Language context initialized with: ${language}`);
    
    // Set HTML lang attribute
    if (typeof document !== 'undefined') {
      document.documentElement.lang = language;
    }
  }, [language]);
  
  // Provide the context value
  const contextValue = {
    language,
    setLanguage,
    t
  };

  return (
    <LanguageContext.Provider value={contextValue}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    console.error("useLanguage must be used within a LanguageProvider");
  }
  return context;
};
