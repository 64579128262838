
import React from 'react';
import { Badge } from '@/components/ui/badge';
import { Linkedin } from 'lucide-react';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';

interface TeamMemberProps {
  member: {
    name: string;
    role: string;
    bio: string;
    image: string;
    skills: string[];
    linkedin: string;
  };
}

const TeamMember = ({ member }: TeamMemberProps) => {
  const getInitials = (name: string) => {
    return name
      .split(' ')
      .map(part => part[0])
      .join('')
      .toUpperCase();
  };
  
  // Fallback colors for avatar backgrounds
  const avatarColors = [
    'bg-blue-100 text-blue-700',
    'bg-green-100 text-green-700',
    'bg-purple-100 text-purple-700',
    'bg-amber-100 text-amber-700',
    'bg-rose-100 text-rose-700',
    'bg-cyan-100 text-cyan-700',
  ];
  
  // Select a color based on the name (consistent for same name)
  const colorIndex = member.name.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0) % avatarColors.length;
  const avatarColor = avatarColors[colorIndex];

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow">
      <div className="p-6 flex flex-col items-center">
        <Avatar className="h-24 w-24 mb-4 border-2 border-brand-200">
          <AvatarFallback className={`${avatarColor} text-xl font-bold`}>
            {getInitials(member.name)}
          </AvatarFallback>
        </Avatar>
        
        <h3 className="text-xl font-bold text-brand-800 text-center">{member.name}</h3>
        <p className="text-brand-600 font-medium mb-1 text-center">{member.role}</p>
        
        <a 
          href={member.linkedin} 
          target="_blank"
          rel="noopener noreferrer" 
          className="inline-flex items-center gap-1 text-brand-600 hover:text-brand-800 transition-colors mb-4"
        >
          <Linkedin className="h-4 w-4" />
          <span className="text-sm">LinkedIn</span>
        </a>
        
        <p className="text-gray-600 mb-5 text-sm text-center">
          {member.bio}
        </p>
        
        <div className="flex flex-wrap gap-2 justify-center">
          {member.skills.map((skill, idx) => (
            <Badge key={idx} variant="outline" className="bg-brand-50 text-brand-700 border-brand-200">
              {skill}
            </Badge>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamMember;
