
import React from 'react';
import Layout from '@/components/Layout';
import TeamMember from '@/components/TeamMember';
import Roadmap from '@/components/Roadmap';
import FinancialProjections from '@/components/FinancialProjections';
import TokenEconomics from '@/components/TokenEconomics';
import { Separator } from '@/components/ui/separator';
import { ArrowDownIcon, Coins, ArrowLeftRight, BarChart3, ArrowRightLeft, Calculator, Users } from 'lucide-react';
import TokenMechanismCards from '@/components/TokenMechanismCards';

const Team = () => {
  const teamMembers = [
    {
      name: "Nirinasoa Rajaona",
      role: "Fondateur & CEO",
      bio: "Ingénieur Agro-Food, Chef de projet blockchain avec 10 ans d'expérience dans les marchés carbone internationaux.",
      image: "",
      skills: ["Finance durable", "Marchés carbone", "Leadership"],
      linkedin: "https://linkedin.com/in/placeholder"
    },
    {
      name: "Vina Marie-Orléa",
      role: "CTO",
      bio: "Ex-Ministre de l'environnement et du développement durable.",
      image: "",
      skills: ["Gestion de projet", "Développement durable"],
      linkedin: "https://linkedin.com/in/placeholder"
    },
    {
      name: "Fabien Rajaona",
      role: "COO",
      bio: "Spécialiste en marketing digital et nouvelles technologies. Passionné par l'application des technologies innovantes aux défis environnementaux.",
      image: "",
      skills: ["Marketing digital", "Nouvelles technologies", "Opérations"],
      linkedin: "https://linkedin.com/in/placeholder"
    }
  ];

  const strategicAdvisors = [
    {
      name: "Fifine Barège",
      role: "Conseillère en environnement et développement",
      bio: "20 ans d'expérience en environnement, ex-conseillère ministérielle. Expertise en politiques environnementales et réglementations internationales.",
      image: "",
      skills: ["Politiques environnementales", "Développement durable", "Conseil stratégique"],
      linkedin: "https://linkedin.com/in/placeholder"
    },
    {
      name: "Rakotovao Ranja",
      role: "Conseillère juridique",
      bio: "Docteur en droit, spécialiste des questions environnementales. Expert en cadres réglementaires pour les marchés de compensation carbone.",
      image: "",
      skills: ["Droit environnemental", "Marchés carbone", "Réglementations internationales"],
      linkedin: "https://linkedin.com/in/placeholder"
    }
  ];

  // Token mechanism data
  const vcarbsMechanisms = [
    {
      title: "Paiement interne",
      description: "Le VCarbs facilite les échanges à valeur stable dans l'écosystème Guteco, permettant des transactions fiables entre les participants.",
      icon: ArrowRightLeft,
      color: "bg-emerald-100",
      iconColor: "text-emerald-600"
    },
    {
      title: "Unité de compte",
      description: "Sert d'unité de mesure standardisée pour évaluer et comparer les services et biens au sein de l'écosystème Guteco.",
      icon: Calculator,
      color: "bg-emerald-100",
      iconColor: "text-emerald-600"
    },
    {
      title: "Stabilité économique",
      description: "Indexé sur les crédits carbone, le VCarbs assure une stabilité des prix et une protection contre la volatilité du marché.",
      icon: BarChart3,
      color: "bg-emerald-100",
      iconColor: "text-emerald-600"
    },
  ];

  return (
    <Layout>
      <section className="py-12 bg-gray-50">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl font-bold text-center text-brand-800 mb-4">Notre Équipe</h1>
          <p className="text-xl text-center text-gray-600 max-w-3xl mx-auto mb-8">
            Une équipe fondatrice dédiée à révolutionner la finance carbone par la blockchain.
          </p>
          
          <div className="flex justify-center mt-12 mb-4">
            <a href="#notre-equipe" className="flex flex-col items-center text-brand-600 hover:text-brand-700 transition">
              <span className="mb-2">Découvrir l'équipe</span>
              <ArrowDownIcon className="h-6 w-6 animate-bounce" />
            </a>
          </div>
        </div>
      </section>

      <section id="notre-equipe" className="py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center text-brand-800 mb-12">L'équipe fondatrice</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {teamMembers.map((member) => (
              <TeamMember key={member.name} member={member} />
            ))}
          </div>
          
          <h2 className="text-3xl font-bold text-center text-brand-800 mt-20 mb-12">Conseillers stratégiques</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
            {strategicAdvisors.map((advisor) => (
              <TeamMember key={advisor.name} member={advisor} />
            ))}
          </div>
        </div>
      </section>
      
      <Separator className="my-8" />
      
      <TokenEconomics />
      
      <Separator className="my-8" />
      
      <section className="py-16 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center text-brand-800 mb-4">Écosystème Tokenisé</h2>
          <p className="text-xl text-center text-gray-600 max-w-3xl mx-auto mb-12">
            Comment les tokens GTC et VCarbs travaillent ensemble pour créer un écosystème économique durable et efficace
          </p>
          
          <div className="grid md:grid-cols-2 gap-8 mb-12">
            <div className="bg-gray-50 p-8 rounded-lg shadow-sm">
              <div className="flex items-center gap-3 mb-4">
                <div className="w-10 h-10 bg-brand-100 rounded-full flex items-center justify-center">
                  <Coins className="h-5 w-5 text-brand-600" />
                </div>
                <h3 className="text-xl font-semibold text-brand-700">Token GTC (Guteco)</h3>
              </div>
              <p className="text-gray-700 mb-4">
                Le token GTC est le cœur du système de gouvernance de l'écosystème Guteco. Il représente à la fois un droit de vote 
                au sein de la DAO et un mécanisme d'investissement dans la croissance globale de la plateforme.
              </p>
              <div className="bg-white p-4 rounded-lg mb-4">
                <h4 className="font-medium text-brand-700 mb-2">Utilisation principale</h4>
                <ul className="space-y-2">
                  <li className="flex items-start gap-2">
                    <ArrowDownIcon className="h-5 w-5 shrink-0 mt-0.5 text-brand-600" />
                    <span>Gouvernance: vote sur les décisions stratégiques et allocation des ressources</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <ArrowDownIcon className="h-5 w-5 shrink-0 mt-0.5 text-brand-600" />
                    <span>Staking: générer des revenus passifs sous forme de VCarbs</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <ArrowDownIcon className="h-5 w-5 shrink-0 mt-0.5 text-brand-600" />
                    <span>Avantages sur la plateforme: réductions sur les frais, accès privilégié aux projets</span>
                  </li>
                </ul>
              </div>
              <p className="text-gray-600 italic">
                Le GTC représente un investissement dans la vision à long terme de Guteco et offre une exposition à la croissance 
                globale de l'écosystème de finance carbone.
              </p>
            </div>
            
            <div className="bg-gray-50 p-8 rounded-lg shadow-sm">
              <div className="flex items-center gap-3 mb-4">
                <div className="w-10 h-10 bg-emerald-100 rounded-full flex items-center justify-center">
                  <BarChart3 className="h-5 w-5 text-emerald-600" />
                </div>
                <h3 className="text-xl font-semibold text-emerald-700">VCarbs (Stablecoin)</h3>
              </div>
              <p className="text-gray-700 mb-4">
                Le VCarbs est le stablecoin de l'écosystème Guteco, indexé sur la valeur des crédits carbone pour garantir une stabilité 
                prix (1 VCarbs = 1€). Il joue un rôle essentiel dans les opérations quotidiennes de la plateforme.
              </p>
              
              <TokenMechanismCards 
                mechanisms={vcarbsMechanisms} 
                title="Fonctions clés du VCarbs" 
                className="mb-4" 
              />
              
              <p className="text-gray-600 italic">
                Le VCarbs représente la couche de transaction stable de l'écosystème, facilitant l'adoption et la fluidité des échanges 
                tout en maintenant un lien direct avec la valeur environnementale.
              </p>
            </div>
          </div>
          
          <div className="bg-brand-50 p-8 rounded-lg shadow-sm">
            <h3 className="text-xl font-semibold text-brand-700 mb-4">Comment les deux tokens travaillent ensemble</h3>
            <p className="text-gray-700 mb-4">
              L'écosystème Guteco repose sur l'interaction complémentaire entre le GTC et le VCarbs, chacun ayant un rôle distinct 
              mais interdépendant:
            </p>
            
            <div className="grid md:grid-cols-2 gap-8">
              <div className="bg-white p-5 rounded-lg">
                <h4 className="font-medium text-brand-700 mb-3 flex items-center gap-2">
                  <ArrowRightLeft className="h-5 w-5 text-brand-600" />
                  <span>Cycle économique</span>
                </h4>
                <ol className="space-y-3 list-decimal pl-5">
                  <li className="text-gray-700">
                    Les détenteurs de GTC peuvent générer des VCarbs via le staking
                  </li>
                  <li className="text-gray-700">
                    Les VCarbs sont utilisés pour acheter des crédits carbone ou services sur la plateforme
                  </li>
                  <li className="text-gray-700">
                    Les transactions en VCarbs génèrent des frais qui sont redistribués aux détenteurs de GTC
                  </li>
                  <li className="text-gray-700">
                    La demande accrue en VCarbs stimule indirectement la valeur du GTC
                  </li>
                </ol>
              </div>
              
              <div className="bg-white p-5 rounded-lg">
                <h4 className="font-medium text-brand-700 mb-3 flex items-center gap-2">
                  <Coins className="h-5 w-5 text-brand-600" />
                  <span>Cas d'utilisation complémentaires</span>
                </h4>
                <ul className="space-y-3">
                  <li className="flex items-start gap-2">
                    <span className="font-medium text-brand-700">Entreprises:</span>
                    <span className="text-gray-700">Utilisent les VCarbs pour leurs opérations de compensation carbone quotidiennes</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <span className="font-medium text-brand-700">Investisseurs:</span>
                    <span className="text-gray-700">Détiennent des GTC pour la gouvernance et l'exposition à la croissance</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <span className="font-medium text-brand-700">Développeurs de projets:</span>
                    <span className="text-gray-700">Reçoivent des VCarbs pour leurs crédits carbone, peuvent les convertir ou les utiliser</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <Separator className="my-8" />
      
      <FinancialProjections />
      
      <Separator className="my-8" />
      
      <section id="roadmap" className="py-16 bg-gray-50">
        <div className="container mx-auto px-4">
          <Roadmap />
        </div>
      </section>
      
      <Separator className="my-8" />
      
      <section id="faq" className="py-16 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center text-brand-800 mb-12">Questions fréquentes</h2>
          
          <div className="max-w-4xl mx-auto space-y-8">
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h3 className="text-xl font-semibold text-brand-700 mb-3">Comment Guteco sélectionne-t-il les projets environnementaux?</h3>
              <p className="text-gray-600">Notre processus de sélection rigoureux comprend une vérification complète par des auditeurs indépendants, l'évaluation de l'impact carbone réel, et la conformité aux standards internationaux comme Verra et Gold Standard.</p>
            </div>
            
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h3 className="text-xl font-semibold text-brand-700 mb-3">Quel est le modèle économique de Guteco?</h3>
              <p className="text-gray-600">Guteco génère des revenus par des frais de transaction sur l'achat/vente de crédits carbone (2%), des frais d'abonnement pour les entreprises utilisant notre plateforme, des services de conseil en stratégie carbone, et des commissions sur les échanges effectués via notre DEX/AMM intégré.</p>
            </div>
            
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h3 className="text-xl font-semibold text-brand-700 mb-3 flex items-center gap-2">
                <ArrowLeftRight className="h-5 w-5 text-brand-600" />
                <span>Comment fonctionne votre DEX/AMM?</span>
              </h3>
              <p className="text-gray-600">Notre plateforme d'échange décentralisée (DEX) avec market maker automatisé (AMM) permet aux utilisateurs d'échanger facilement des tokens GTC contre des VCarbs ou d'autres cryptomonnaies. Cette fonctionnalité génère des revenus via des frais de transaction (0.3%) et augmente la liquidité de l'écosystème Guteco. Les revenus significatifs sont attendus à partir de la 3ème année, lorsque l'adoption de la plateforme atteindra une masse critique.</p>
            </div>
            
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h3 className="text-xl font-semibold text-brand-700 mb-3">Comment garantissez-vous la transparence des crédits carbone?</h3>
              <p className="text-gray-600">Chaque crédit carbone sur notre plateforme est tokenisé avec un identifiant unique sur la blockchain, permettant de tracer son origine, sa certification, et son historique de transactions. Nous publions également des rapports d'audit réguliers.</p>
            </div>
            
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h3 className="text-xl font-semibold text-brand-700 mb-3">Quels sont vos objectifs financiers à 5 ans?</h3>
              <p className="text-gray-600">Nous prévoyons des revenus significatifs à partir de la 3ème année, une fois que les arbres et mangroves de nos projets atteignent leur maturité. Notre objectif est d'atteindre un volume de transactions de 50 millions d'euros d'ici 2027, avec une rentabilité opérationnelle à partir de la 3ème année. L'intégration de notre DEX/AMM contribuera significativement à ces objectifs en créant un écosystème financier complet.</p>
            </div>
            
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h3 className="text-xl font-semibold text-brand-700 mb-3">Quelle est votre stratégie d'acquisition de clients?</h3>
              <p className="text-gray-600">Notre stratégie combine le marketing digital ciblé, les partenariats stratégiques avec des consultants ESG, la participation à des conférences sectorielles, et un programme d'ambassadeurs. Nous visons principalement les moyennes et grandes entreprises ayant des objectifs de neutralité carbone.</p>
            </div>
            
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h3 className="text-xl font-semibold text-brand-700 mb-3">Quels types de projets soutenez-vous?</h3>
              <p className="text-gray-600">Guteco soutient une diversité de projets: environnementaux (conservation forestière, énergies renouvelables), humanitaires (accès à l'eau potable, infrastructures sanitaires) et sociaux (éducation, inclusion économique). Nous visons à financer 150 projets à impact positif d'ici 2027 dans ces trois catégories.</p>
            </div>
            
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h3 className="text-xl font-semibold text-brand-700 mb-3 flex items-center gap-2">
                <Coins className="h-5 w-5 text-brand-600" />
                <span>Quels sont les avantages du DEX/AMM de Guteco?</span>
              </h3>
              <p className="text-gray-600">Notre DEX/AMM intégré offre plusieurs avantages : des frais réduits pour les détenteurs de tokens GTC, une liquidité accrue pour l'écosystème Guteco, un accès simplifié aux crédits carbone tokenisés, et des opportunités de yield farming pour les fournisseurs de liquidité. Ce modèle génère des revenus récurrents pour la plateforme tout en renforçant l'utilité des tokens GTC.</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Team;
