import React, { useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Button } from '@/components/ui/button';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  TooltipProps,
  AreaChart,
  Area
} from 'recharts';
import { Info, TrendingUp, Users, GraduationCap, Leaf, Coins, BarChart4, ArrowRight, AlertCircle } from 'lucide-react';
import { ValueType } from 'recharts/types/component/DefaultTooltipContent';

const ProjectEvolution = () => {
  const [selectedTab, setSelectedTab] = useState('revenue');

  // Données d'évolution des revenus sur 5 ans en euros
  const revenueData = [
    { year: 'Année 1', revenus: 26200000, utilisateurs: 20000, impact: 25000, tokenValue: 0.18 },
    { year: 'Année 2', revenus: 52400000, utilisateurs: 75000, impact: 100000, tokenValue: 0.45 },
    { year: 'Année 3', revenus: 94300000, utilisateurs: 250000, impact: 230000, tokenValue: 0.85 },
    { year: 'Année 4', revenus: 157500000, utilisateurs: 700000, impact: 510000, tokenValue: 1.30 },
    { year: 'Année 5', revenus: 262000000, utilisateurs: 1800000, impact: 1000000, tokenValue: 2.60 },
  ];

  // Jalons clés du projet
  const milestones = [
    { 
      year: 'Année 1', 
      events: [
        { quarter: "T1", title: "Tokenisation des premiers 5 000 hectares", description: "Première phase des projets de mangroves à Madagascar" },
        { quarter: "T2", title: "Lancement des VCarbs", description: "Introduction du stablecoin indexé sur les crédits carbone" },
        { quarter: "T3", title: "Première certification Verra", description: "Validation externe des standards de qualité de nos projets environnementaux" },
        { quarter: "T4", title: "Partenariat avec 3 grandes entreprises", description: "Première vague d'adoption B2B pour la compensation carbone" }
      ] 
    },
    { 
      year: 'Année 2', 
      events: [
        { quarter: "T1", title: "Expansion à 20 000 hectares", description: "Extension des projets de mangroves et forêts" },
        { quarter: "T2", title: "Lancement de la marketplace NFT", description: "Tokenisation des crédits biodiversité" },
        { quarter: "T3", title: "Intégration avec 2 exchanges majeurs", description: "Augmentation de la liquidité et accessibilité des tokens" },
        { quarter: "T4", title: "Expansion vers l'écotourisme tokenisé", description: "Monétisation de l'expérience de visite des zones protégées" }
      ] 
    },
    { 
      year: 'Année 3', 
      events: [
        { quarter: "T1", title: "Expansion internationale (Afrique, Amérique latine, Asie)", description: "Nouveaux projets dans 5 pays supplémentaires" },
        { quarter: "T2", title: "Plateforme d'impact pour entreprises", description: "Solution B2B complète de suivi et reporting" },
        { quarter: "T3", title: "Adoption par 2 gouvernements", description: "Partenariats publics pour les politiques environnementales" },
        { quarter: "T4", title: "100 000 hectares sous gestion", description: "Capture de plus de 230 000 tonnes de CO2" }
      ] 
    },
    { 
      year: 'Année 4', 
      events: [
        { quarter: "T1", title: "Intégration avec systèmes financiers traditionnels", description: "Passerelles avec les marchés de capitaux" },
        { quarter: "T2", title: "Lancement de l'index de biodiversité", description: "Nouveau produit financier basé sur la santé des écosystèmes" },
        { quarter: "T3", title: "Programme d'incubation de projets", description: "Soutien de 10 nouveaux projets environnementaux" },
        { quarter: "T4", title: "Adoption par 25+ multinationales", description: "Intégration dans les stratégies ESG des grandes entreprises" }
      ] 
    },
    { 
      year: 'Année 5', 
      events: [
        { quarter: "T1", title: "Valorisation à 260M€", description: "Basée sur les revenus et l'impact environnemental" },
        { quarter: "T2", title: "1 million d'hectares sous gestion", description: "Impact carbone équivalent à 1M tonnes de CO2 par an" },
        { quarter: "T3", title: "Intégration dans les indices ESG mondiaux", description: "Reconnaissance par les marchés financiers traditionnels" },
        { quarter: "T4", title: "Adoption par 2M d'utilisateurs", description: "Démocratisation de l'investissement à impact" }
      ] 
    }
  ];

  // Fonction pour formatter les valeurs dans le tooltip
  const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, string>) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      
      if (selectedTab === 'revenue') {
        return (
          <div className="bg-white p-3 shadow-lg rounded-lg border border-gray-200">
            <p className="text-sm text-gray-500">{label}</p>
            <p className="text-lg font-bold text-brand-700">
              {typeof payload[0].value === 'number' 
                ? `${(payload[0].value / 1000000).toFixed(1)}M€` 
                : payload[0].value}
            </p>
          </div>
        );
      } 
      else if (selectedTab === 'users') {
        return (
          <div className="bg-white p-3 shadow-lg rounded-lg border border-gray-200">
            <p className="text-sm text-gray-500">{label}</p>
            <p className="text-lg font-bold text-indigo-700">
              {typeof payload[0].value === 'number' 
                ? `${payload[0].value.toLocaleString()}` 
                : payload[0].value}
            </p>
          </div>
        );
      }
      else if (selectedTab === 'impact') {
        return (
          <div className="bg-white p-3 shadow-lg rounded-lg border border-gray-200">
            <p className="text-sm text-gray-500">{label}</p>
            <p className="text-lg font-bold text-emerald-700">
              {typeof payload[0].value === 'number' 
                ? `${payload[0].value.toLocaleString()} tonnes` 
                : payload[0].value}
            </p>
          </div>
        );
      }
      else if (selectedTab === 'token') {
        return (
          <div className="bg-white p-3 shadow-lg rounded-lg border border-gray-200">
            <p className="text-sm text-gray-500">{label}</p>
            <p className="text-lg font-bold text-amber-700">
              {typeof payload[0].value === 'number' 
                ? `${payload[0].value.toFixed(2)}€` 
                : payload[0].value}
            </p>
          </div>
        );
      }
    }
    
    return null;
  };

  return (
    <section id="project-evolution" className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="max-w-5xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-brand-800 mb-4">Évolution du Projet sur 5 Ans</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Prévisions de croissance basées sur notre modèle financier et notre stratégie d'expansion
            </p>
          </div>
          
          <div className="bg-amber-50 p-4 rounded-lg mb-10 border border-amber-200">
            <div className="flex items-start gap-3">
              <AlertCircle className="h-5 w-5 text-amber-600 shrink-0 mt-0.5" />
              <div>
                <h3 className="font-semibold text-amber-800 mb-1">Calendrier conditionnel à la levée de fonds</h3>
                <p className="text-amber-700 text-sm">
                  Ce calendrier d'évolution représente les 5 années suivant l'obtention du financement complet de 12M€. 
                  Aucune étape significative ne peut être entamée avant la sécurisation des fonds nécessaires.
                </p>
              </div>
            </div>
          </div>
          
          <Tabs defaultValue="revenue" value={selectedTab} onValueChange={setSelectedTab} className="mb-10">
            <div className="flex justify-center mb-6">
              <TabsList className="grid grid-cols-4 w-full max-w-3xl">
                <TabsTrigger value="revenue" className="flex gap-2 items-center">
                  <BarChart4 className="h-4 w-4" />
                  <span>Revenus</span>
                </TabsTrigger>
                <TabsTrigger value="users" className="flex gap-2 items-center">
                  <Users className="h-4 w-4" />
                  <span>Utilisateurs</span>
                </TabsTrigger>
                <TabsTrigger value="impact" className="flex gap-2 items-center">
                  <Leaf className="h-4 w-4" />
                  <span>Impact CO₂</span>
                </TabsTrigger>
                <TabsTrigger value="token" className="flex gap-2 items-center">
                  <Coins className="h-4 w-4" />
                  <span>Valeur GTC</span>
                </TabsTrigger>
              </TabsList>
            </div>
            
            <TabsContent value="revenue">
              <Card>
                <CardHeader className="pb-0">
                  <CardTitle className="text-xl text-brand-700 flex items-center gap-2">
                    <BarChart4 className="h-5 w-5" />
                    Évolution des revenus (€)
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="h-80">
                    <ResponsiveContainer width="100%" height="100%">
                      <AreaChart data={revenueData}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#e5e7eb" />
                        <XAxis dataKey="year" stroke="#6b7280" />
                        <YAxis
                          stroke="#6b7280"
                          tickFormatter={(value) => `${(value / 1000000).toFixed(0)}M`}
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <Area
                          type="monotone"
                          dataKey="revenus"
                          name="Revenus"
                          stroke="#059669"
                          fill="#10b981"
                          fillOpacity={0.3}
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                  <div className="mt-4 p-4 bg-green-50 rounded-lg">
                    <p className="text-sm flex items-start gap-2">
                      <Info className="h-5 w-5 shrink-0 mt-0.5 text-green-600" />
                      <span className="text-green-700">
                        Les revenus devraient atteindre 262M€ en année 5, avec une croissance rapide grâce à l'expansion des projets de mangroves et la valorisation des crédits carbone.
                      </span>
                    </p>
                  </div>
                </CardContent>
              </Card>
            </TabsContent>
            
            <TabsContent value="users">
              <Card>
                <CardHeader className="pb-0">
                  <CardTitle className="text-xl text-indigo-700 flex items-center gap-2">
                    <Users className="h-5 w-5" />
                    Croissance des utilisateurs
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="h-80">
                    <ResponsiveContainer width="100%" height="100%">
                      <AreaChart data={revenueData}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#e5e7eb" />
                        <XAxis dataKey="year" stroke="#6b7280" />
                        <YAxis stroke="#6b7280" />
                        <Tooltip content={<CustomTooltip />} />
                        <Area
                          type="monotone"
                          dataKey="utilisateurs"
                          name="Utilisateurs"
                          stroke="#4f46e5"
                          fill="#6366f1"
                          fillOpacity={0.3}
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                  <div className="mt-4 p-4 bg-indigo-50 rounded-lg">
                    <p className="text-sm flex items-start gap-2">
                      <Info className="h-5 w-5 shrink-0 mt-0.5 text-indigo-600" />
                      <span className="text-indigo-700">
                        Nous prévoyons de dépasser les 1,8 million d'utilisateurs d'ici l'année 5, avec une accélération forte à partir de l'année 3 grâce aux partenariats et à l'expansion internationale.
                      </span>
                    </p>
                  </div>
                </CardContent>
              </Card>
            </TabsContent>
            
            <TabsContent value="impact">
              <Card>
                <CardHeader className="pb-0">
                  <CardTitle className="text-xl text-emerald-700 flex items-center gap-2">
                    <Leaf className="h-5 w-5" />
                    Impact environnemental (tonnes de CO₂)
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="h-80">
                    <ResponsiveContainer width="100%" height="100%">
                      <AreaChart data={revenueData}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#e5e7eb" />
                        <XAxis dataKey="year" stroke="#6b7280" />
                        <YAxis stroke="#6b7280" />
                        <Tooltip content={<CustomTooltip />} />
                        <Area
                          type="monotone"
                          dataKey="impact"
                          name="Impact CO₂"
                          stroke="#047857"
                          fill="#10b981"
                          fillOpacity={0.3}
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                  <div className="mt-4 p-4 bg-emerald-50 rounded-lg">
                    <p className="text-sm flex items-start gap-2">
                      <Info className="h-5 w-5 shrink-0 mt-0.5 text-emerald-600" />
                      <span className="text-emerald-700">
                        Notre impact environnemental atteindra 1 million de tonnes de CO₂ capturées annuellement d'ici l'année 5, grâce à l'expansion des projets de mangroves et de reforestation.
                      </span>
                    </p>
                  </div>
                </CardContent>
              </Card>
            </TabsContent>
            
            <TabsContent value="token">
              <Card>
                <CardHeader className="pb-0">
                  <CardTitle className="text-xl text-amber-700 flex items-center gap-2">
                    <Coins className="h-5 w-5" />
                    Évolution de la valeur du token GTC (€)
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="h-80">
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart data={revenueData}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#e5e7eb" />
                        <XAxis dataKey="year" stroke="#6b7280" />
                        <YAxis stroke="#6b7280" />
                        <Tooltip content={<CustomTooltip />} />
                        <Line
                          type="monotone"
                          dataKey="tokenValue"
                          name="Valeur GTC"
                          stroke="#d97706"
                          strokeWidth={2}
                          dot={{ r: 6, fill: "#d97706" }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                  <div className="mt-4 p-4 bg-amber-50 rounded-lg">
                    <p className="text-sm flex items-start gap-2">
                      <Info className="h-5 w-5 shrink-0 mt-0.5 text-amber-600" />
                      <span className="text-amber-700">
                        Le prix initial du token GTC est fixé à 0,15€, avec une projection de croissance à 2,60€ d'ici l'année 5, basée sur l'adoption, l'utilité et la rareté croissante des tokens.
                      </span>
                    </p>
                  </div>
                </CardContent>
              </Card>
            </TabsContent>
          </Tabs>
          
          <div className="mt-12">
            <h3 className="text-2xl font-bold text-brand-800 mb-6 text-center">
              Jalons Clés <span className="text-base font-normal text-brand-600">(à partir de l'obtention des fonds)</span>
            </h3>
            
            <div className="bg-indigo-50 p-4 rounded-lg mb-8 border border-indigo-200">
              <div className="flex items-start gap-3">
                <Info className="h-5 w-5 text-indigo-600 shrink-0 mt-0.5" />
                <p className="text-indigo-700 text-sm">
                  Les délais ci-dessous commencent à compter de l'obtention complète du financement requis. 
                  Sans l'investissement initial de 12M€, le calendrier sera reporté en conséquence.
                </p>
              </div>
            </div>
            
            <div className="space-y-8">
              {milestones.map((milestone, index) => (
                <Card key={index} className="overflow-hidden">
                  <CardHeader className="bg-brand-50 py-4">
                    <CardTitle className="text-xl text-brand-800">
                      {milestone.year} <span className="text-sm font-normal">(après financement)</span>
                    </CardTitle>
                  </CardHeader>
                  <CardContent className="pt-6">
                    <div className="space-y-6">
                      {milestone.events.map((event, eventIndex) => (
                        <div key={eventIndex} className="flex gap-4">
                          <div className="w-14 flex-shrink-0 text-center font-semibold text-brand-700 mt-0.5">
                            {event.quarter}
                          </div>
                          <div className="flex-grow">
                            <h4 className="font-semibold text-lg text-gray-800 mb-1">{event.title}</h4>
                            <p className="text-gray-600">{event.description}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
          
          <div className="mt-12 text-center">
            <Button
              variant="outline"
              className="inline-flex items-center gap-2 border-brand-600 text-brand-600"
              onClick={() => {
                const element = document.getElementById('tokeneconomics');
                if (element) {
                  element.scrollIntoView({ behavior: 'smooth' });
                }
              }}
            >
              <TrendingUp size={16} />
              <span>Voir les détails des tokenomics</span>
            </Button>
            
            <div className="mt-4 p-4 bg-gray-100 rounded-lg inline-block">
              <p className="text-sm text-gray-700 flex items-center gap-2">
                <GraduationCap className="h-5 w-5 text-gray-600" />
                <span>Pour plus de détails sur la valorisation, consultez notre <a href="/valuation" className="text-brand-600 hover:underline flex items-center gap-1">mémorandum d'investissement <ArrowRight className="h-3 w-3" /></a></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectEvolution;
