
import * as React from "react"
import * as TabsPrimitive from "@radix-ui/react-tabs"

import { cn } from "@/lib/utils"
import { ScrollArea } from "./scroll-area"

const Tabs = TabsPrimitive.Root

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(
      "inline-flex h-10 items-center justify-center rounded-md bg-muted p-1 text-muted-foreground",
      className
    )}
    {...props}
  />
))
TabsList.displayName = TabsPrimitive.List.displayName

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      "inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow-sm",
      className
    )}
    {...props}
  />
))
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content> & {
    scrollToContent?: boolean;
    scrollOffset?: number;
  }
>(({ className, scrollToContent = false, scrollOffset = 0, ...props }, ref) => {
  const contentRef = React.useRef<HTMLDivElement>(null);

  // Cette fonction sera appelée lorsque l'onglet devient actif
  React.useEffect(() => {
    if (contentRef.current && props["data-state"] === "active") {
      // Aucun délai, on s'assure simplement que le contenu est affiché immédiatement
      contentRef.current.scrollIntoView({ behavior: 'auto', block: 'start' });
      
      // Dans certains cas, un petit déplacement vers le haut peut être nécessaire
      if (scrollToContent) {
        window.scrollTo({
          top: 0,
          behavior: 'auto'
        });
      }
    }
  }, [props["data-state"], scrollToContent]);

  return (
    <TabsPrimitive.Content
      ref={(node) => {
        // Assigner le nœud à la ref de forwardRef
        if (typeof ref === 'function') {
          ref(node);
        } else if (ref) {
          ref.current = node;
        }
        // Également assigner à notre ref locale
        contentRef.current = node;
      }}
      className={cn(
        "mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
        className
      )}
      {...props}
    />
  );
});
TabsContent.displayName = TabsPrimitive.Content.displayName

export { Tabs, TabsList, TabsTrigger, TabsContent }
