
import React, { useEffect } from 'react';

interface SEOHeadersProps {
  title: string;
  description: string;
  canonicalPath: string;
  robots?: string;
  schema?: object;
  imageUrl?: string;
}

const SEOHeaders: React.FC<SEOHeadersProps> = ({
  title,
  description,
  canonicalPath,
  robots = "index, follow",
  schema,
  imageUrl = "/og-image.png"
}) => {
  useEffect(() => {
    // Update page title
    document.title = title;
    
    // Update meta tags
    let metaTags = [
      { name: "description", content: description },
      { name: "robots", content: robots },
      { property: "og:title", content: title },
      { property: "og:description", content: description },
      { property: "og:image", content: imageUrl },
      { property: "og:url", content: `https://guteco.com${canonicalPath}` },
      { property: "og:type", content: "website" },
      { name: "twitter:title", content: title },
      { name: "twitter:description", content: description },
      { name: "twitter:image", content: imageUrl }
    ];
    
    // Clean up existing meta tags to avoid duplicates
    const existingMetaTags = document.querySelectorAll('meta[data-dynamic="true"]');
    existingMetaTags.forEach(tag => tag.remove());
    
    // Add new meta tags
    metaTags.forEach(tag => {
      const meta = document.createElement('meta');
      if (tag.name) meta.name = tag.name;
      if (tag.property) meta.setAttribute('property', tag.property);
      meta.content = tag.content;
      meta.setAttribute('data-dynamic', 'true');
      document.head.appendChild(meta);
    });
    
    // Update canonical link - Fix TypeScript errors by using HTMLLinkElement
    let canonical = document.querySelector('link[rel="canonical"]') as HTMLLinkElement;
    if (canonical) {
      canonical.href = `https://guteco.com${canonicalPath}`;
    } else {
      canonical = document.createElement('link');
      canonical.rel = 'canonical';
      canonical.href = `https://guteco.com${canonicalPath}`;
      document.head.appendChild(canonical);
    }
    
    // Ensure website is indexed by Google
    const googleBotMeta = document.querySelector('meta[name="googlebot"]');
    if (!googleBotMeta) {
      const meta = document.createElement('meta');
      meta.name = 'googlebot';
      meta.content = 'index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1';
      meta.setAttribute('data-dynamic', 'true');
      document.head.appendChild(meta);
    }
    
    // Ensure all search engines can index the site
    const robotsMeta = document.querySelector('meta[name="robots"]');
    if (!robotsMeta) {
      const meta = document.createElement('meta');
      meta.name = 'robots';
      meta.content = 'index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1';
      meta.setAttribute('data-dynamic', 'true');
      document.head.appendChild(meta);
    }
    
    // Add structured data if provided
    if (schema) {
      const existingSchema = document.getElementById('structured-data-component');
      if (existingSchema) {
        existingSchema.remove();
      }
      
      const script = document.createElement('script');
      script.id = 'structured-data-component';
      script.type = 'application/ld+json';
      script.textContent = JSON.stringify(schema);
      document.head.appendChild(script);
    }
    
    // Add additional headers for better Google indexing
    const viewportMeta = document.querySelector('meta[name="viewport"]');
    if (!viewportMeta) {
      const meta = document.createElement('meta');
      meta.name = 'viewport';
      meta.content = 'width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=yes';
      document.head.appendChild(meta);
    }
    
    return () => {
      // Clean up on unmount
      const dynamicTags = document.querySelectorAll('meta[data-dynamic="true"]');
      dynamicTags.forEach(tag => tag.remove());
      
      const schemaScript = document.getElementById('structured-data-component');
      if (schemaScript) {
        schemaScript.remove();
      }
    };
  }, [title, description, canonicalPath, robots, schema, imageUrl]);
  
  return null; // This component doesn't render anything visible
};

export default SEOHeaders;
