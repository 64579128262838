import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { LineChart, BarChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { Euro, TrendingUp, ShieldCheck, Leaf, Calendar, DollarSign } from 'lucide-react';
import { ChartContainer, ChartTooltip, ChartTooltipContent } from '@/components/ui/chart';
import { monthlyCostsData, revenueSourcesData } from './expected-returns/data';

const FinancialProjections = () => {
  // Calcul du coût mensuel total
  const totalMonthlyCost = monthlyCostsData.reduce((sum, item) => sum + item.monthlyCost, 0);
  const annualBurnRate = totalMonthlyCost * 12;

  const fundingNeeds = [
    {
      category: "Équipement technique",
      description: "Drones professionnels et capteurs pour surveillance des projets",
      amount: 135000,
      year: 1
    },
    {
      category: "Développement technique",
      description: "Infrastructure blockchain et développement de la plateforme",
      amount: 240000,
      year: 1
    },
    {
      category: "Opérations terrain Madagascar",
      description: "Équipes locales, matériel de plantation et suivi",
      amount: 180000,
      year: 1
    },
    {
      category: "Personnel",
      description: "Équipe technique, scientifique et opérationnelle",
      amount: 320000,
      year: 1
    },
    {
      category: "Marketing & Acquisition",
      description: "Acquisition de projets et d'investisseurs",
      amount: 170000,
      year: 1
    },
    {
      category: "Expansion régionale",
      description: "Extension à d'autres régions de Madagascar",
      amount: 210000,
      year: 2
    },
    {
      category: "R&D",
      description: "Amélioration des algorithmes de mesure d'impact",
      amount: 150000,
      year: 2
    },
    {
      category: "Réserve & Imprévus",
      description: "Fonds de réserve pour opportunités ou risques imprévus",
      amount: 195000,
      year: 2
    }
  ];

  const totalFundingNeeds = fundingNeeds.reduce((total, item) => total + item.amount, 0);
  const year1Needs = fundingNeeds.filter(item => item.year === 1).reduce((total, item) => total + item.amount, 0);
  const year2Needs = fundingNeeds.filter(item => item.year === 2).reduce((total, item) => total + item.amount, 0);

  // Projections financières révisées avec une croissance plus rapide basée sur l'impact des mangroves
  const financialProjections = [
    { year: "Année 1", revenus: 280000, depenses: 1045000, benefices: -765000 },
    { year: "Année 2", revenus: 820000, depenses: 1200000, benefices: -380000 },
    { year: "Année 3", revenus: 1750000, depenses: 1320000, benefices: 430000 },
    { year: "Année 4", revenus: 3100000, depenses: 1450000, benefices: 1650000 },
    { year: "Année 5", revenus: 4800000, depenses: 1700000, benefices: 3100000 },
    { year: "Année 6", revenus: 6500000, depenses: 2100000, benefices: 4400000 },
    { year: "Année 7", revenus: 8900000, depenses: 2600000, benefices: 6300000 }
  ];

  const revenueStreams = [
    { name: "Crédits carbone - Arbres", value: 25 },
    { name: "Crédits carbone - Mangroves", value: 35 },
    { name: "Crédits biodiversité", value: 15 },
    { name: "Écotourisme", value: 10 },
    { name: "Services de conseil", value: 8 },
    { name: "Frais de transaction", value: 7 }
  ];

  const COLORS = ['#10b981', '#0ea5e9', '#8b5cf6', '#f59e0b', '#6366f1', '#ec4899'];

  const chartConfig = {
    revenus: {
      label: "Revenus",
      theme: {
        light: "hsl(143, 85%, 40%)",
        dark: "hsl(143, 85%, 40%)"
      }
    },
    depenses: {
      label: "Dépenses",
      theme: {
        light: "hsl(346, 85%, 61%)",
        dark: "hsl(346, 85%, 61%)"
      }
    },
    benefices: {
      label: "Bénéfices",
      theme: {
        light: "hsl(245, 85%, 50%)",
        dark: "hsl(245, 85%, 50%)"
      }
    }
  };

  // Calcul d'hectares de mangroves et impact financier
  const hectaresMangroves = {
    year1: 2500,
    year2: 5000,
    year3: 8500,
    revenuePerHectare: 30
  };

  const mangroveImpact = {
    year1: hectaresMangroves.year1 * hectaresMangroves.revenuePerHectare,
    year2: hectaresMangroves.year2 * hectaresMangroves.revenuePerHectare,
    year3: hectaresMangroves.year3 * hectaresMangroves.revenuePerHectare
  };

  return (
    <section id="projections-financieres" className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-brand-800 mb-12">Projections Financières</h2>
        
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-12">
          <Card>
            <CardHeader className="bg-brand-50">
              <div className="flex items-center gap-3">
                <div className="w-10 h-10 bg-brand-100 rounded-full flex items-center justify-center">
                  <Euro className="h-5 w-5 text-brand-700" />
                </div>
                <CardTitle className="text-xl text-brand-800">Coûts Mensuels et Besoins en Financement</CardTitle>
              </div>
            </CardHeader>
            <CardContent className="pt-6">
              <div className="flex flex-col md:flex-row justify-between mb-6 gap-4">
                <div className="flex-1 bg-brand-50 p-4 rounded-lg text-center">
                  <p className="text-sm text-gray-600 mb-1">Dépense Mensuelle</p>
                  <p className="text-2xl font-bold text-brand-800">{totalMonthlyCost.toLocaleString()} €</p>
                </div>
                <div className="flex-1 bg-red-50 p-4 rounded-lg text-center">
                  <p className="text-sm text-gray-600 mb-1">Taux de combustion annuel</p>
                  <p className="text-2xl font-bold text-red-800">{annualBurnRate.toLocaleString()} €</p>
                </div>
                <div className="flex-1 bg-blue-50 p-4 rounded-lg text-center">
                  <p className="text-sm text-gray-600 mb-1">Financement total</p>
                  <p className="text-2xl font-bold text-blue-800">{totalFundingNeeds.toLocaleString()} €</p>
                </div>
              </div>
              
              <div className="mb-8">
                <h4 className="font-semibold text-gray-800 mb-3">Détail des coûts mensuels</h4>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Catégorie</TableHead>
                      <TableHead className="text-right">Coût mensuel (€)</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {monthlyCostsData.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell className="font-medium">{item.category}</TableCell>
                        <TableCell className="text-right font-mono">{item.monthlyCost.toLocaleString()}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow className="bg-gray-50">
                      <TableCell className="font-bold">Total mensuel</TableCell>
                      <TableCell className="text-right font-mono font-bold">{totalMonthlyCost.toLocaleString()}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
              
              <h4 className="font-semibold text-gray-800 mb-3">Besoins en financement</h4>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead className="w-[180px]">Catégorie</TableHead>
                    <TableHead>Description</TableHead>
                    <TableHead className="text-right">Montant (€)</TableHead>
                    <TableHead className="text-right">Année</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {fundingNeeds.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell className="font-medium">{item.category}</TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell className="text-right font-mono">{item.amount.toLocaleString()}</TableCell>
                      <TableCell className="text-right">{item.year}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow className="bg-brand-50 font-bold">
                    <TableCell colSpan={2}>TOTAL DES BESOINS EN FINANCEMENT</TableCell>
                    <TableCell className="text-right font-mono">{totalFundingNeeds.toLocaleString()}</TableCell>
                    <TableCell className="text-right">-</TableCell>
                  </TableRow>
                  <TableRow className="bg-blue-50">
                    <TableCell colSpan={2}>Total Année 1</TableCell>
                    <TableCell className="text-right font-mono">{year1Needs.toLocaleString()}</TableCell>
                    <TableCell className="text-right">1</TableCell>
                  </TableRow>
                  <TableRow className="bg-green-50">
                    <TableCell colSpan={2}>Total Année 2</TableCell>
                    <TableCell className="text-right font-mono">{year2Needs.toLocaleString()}</TableCell>
                    <TableCell className="text-right">2</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
          
          <Card>
            <CardHeader className="bg-emerald-50">
              <div className="flex items-center gap-3">
                <div className="w-10 h-10 bg-emerald-100 rounded-full flex items-center justify-center">
                  <TrendingUp className="h-5 w-5 text-emerald-700" />
                </div>
                <CardTitle className="text-xl text-emerald-800">Projections Financières sur 7 ans</CardTitle>
              </div>
            </CardHeader>
            <CardContent className="pt-6">
              <div className="h-[250px] mb-6">
                <ChartContainer config={chartConfig}>
                  <LineChart data={financialProjections}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="year" />
                    <YAxis />
                    <ChartTooltip content={<ChartTooltipContent />} />
                    <Line type="monotone" dataKey="revenus" stroke="var(--color-revenus)" strokeWidth={2} />
                    <Line type="monotone" dataKey="depenses" stroke="var(--color-depenses)" strokeWidth={2} />
                    <Line type="monotone" dataKey="benefices" stroke="var(--color-benefices)" strokeWidth={2} />
                    <Legend />
                  </LineChart>
                </ChartContainer>
              </div>
              
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Période</TableHead>
                    <TableHead className="text-right">Revenus (€)</TableHead>
                    <TableHead className="text-right">Dépenses (€)</TableHead>
                    <TableHead className="text-right">Bénéfices (€)</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {financialProjections.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell className="font-medium">{item.year}</TableCell>
                      <TableCell className="text-right font-mono text-emerald-600">{item.revenus.toLocaleString()}</TableCell>
                      <TableCell className="text-right font-mono text-red-600">{item.depenses.toLocaleString()}</TableCell>
                      <TableCell className={`text-right font-mono font-semibold ${item.benefices >= 0 ? 'text-emerald-600' : 'text-red-600'}`}>
                        {item.benefices.toLocaleString()}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </div>
        
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-12">
          <Card>
            <CardHeader className="bg-green-50">
              <div className="flex items-center gap-3">
                <div className="w-10 h-10 bg-green-100 rounded-full flex items-center justify-center">
                  <Leaf className="h-5 w-5 text-green-700" />
                </div>
                <CardTitle className="text-xl text-green-800">Impact Financier des Mangroves</CardTitle>
              </div>
            </CardHeader>
            <CardContent className="pt-6">
              <div className="mb-6">
                <p className="text-gray-700 mb-4">
                  Les mangroves représentent un investissement stratégique avec un rendement de 30€ par hectare par an. Voici l'impact attendu sur nos revenus:
                </p>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Période</TableHead>
                      <TableHead className="text-right">Hectares de mangroves</TableHead>
                      <TableHead className="text-right">Impact financier (€/an)</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    <TableRow>
                      <TableCell className="font-medium">Année 1</TableCell>
                      <TableCell className="text-right font-mono">{hectaresMangroves.year1.toLocaleString()}</TableCell>
                      <TableCell className="text-right font-mono text-emerald-600">{mangroveImpact.year1.toLocaleString()}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="font-medium">Année 2</TableCell>
                      <TableCell className="text-right font-mono">{hectaresMangroves.year2.toLocaleString()}</TableCell>
                      <TableCell className="text-right font-mono text-emerald-600">{mangroveImpact.year2.toLocaleString()}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="font-medium">Année 3</TableCell>
                      <TableCell className="text-right font-mono">{hectaresMangroves.year3.toLocaleString()}</TableCell>
                      <TableCell className="text-right font-mono text-emerald-600">{mangroveImpact.year3.toLocaleString()}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
              
              <div className="p-4 bg-green-50 rounded-lg text-sm">
                <p className="flex items-start gap-2 text-green-800">
                  <Leaf className="h-5 w-5 shrink-0 mt-0.5" />
                  <span>
                    <strong>Avantage des mangroves :</strong> La captation de carbone 4 à 10 fois plus élevée que les forêts traditionnelles et la commercialisation plus rapide des crédits carbone permettent d'atteindre une rentabilité plus rapide. Les partenariats avec des ONG comme WWF et les Nations Unies renforcent la crédibilité et l'accès aux marchés.
                  </span>
                </p>
              </div>
            </CardContent>
          </Card>
          
          <Card>
            <CardHeader className="bg-amber-50">
              <div className="flex items-center gap-3">
                <div className="w-10 h-10 bg-amber-100 rounded-full flex items-center justify-center">
                  <Leaf className="h-5 w-5 text-amber-700" />
                </div>
                <CardTitle className="text-xl text-amber-800">Sources de Revenus</CardTitle>
              </div>
            </CardHeader>
            <CardContent className="pt-6">
              <div className="h-[250px] mb-6">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={revenueStreams}
                      cx="50%"
                      cy="50%"
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                      label={({name, percent}) => `${name} (${(percent * 100).toFixed(0)}%)`}
                    >
                      {revenueStreams.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip formatter={(value) => `${value}%`} />
                  </PieChart>
                </ResponsiveContainer>
              </div>
              
              <div className="mb-8">
                <h4 className="font-semibold text-gray-800 mb-3">Calendrier des premières revenus</h4>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Source de revenu</TableHead>
                      <TableHead className="text-right">Pourcentage</TableHead>
                      <TableHead className="text-right">Premier revenu (mois)</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {revenueSourcesData.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell className="font-medium">{item.source}</TableCell>
                        <TableCell className="text-right font-mono">{item.percentage}%</TableCell>
                        <TableCell className="text-right font-mono">{item.timeToFirstRevenue}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
              
              <div className="p-4 bg-amber-50 rounded-lg text-sm">
                <p className="flex items-start gap-2 text-amber-800">
                  <Calendar className="h-5 w-5 shrink-0 mt-0.5" />
                  <span>
                    <strong>Cycle de croissance naturel :</strong> Les arbres à Madagascar commencent à capturer du CO₂ dès la première année, mais avec une séquestration faible. Les mangroves sont 4 à 10 fois plus efficaces et commencent à générer des crédits carbone significatifs dès 9-12 mois.
                  </span>
                </p>
              </div>
            </CardContent>
          </Card>
        </div>
        
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <Card>
            <CardHeader className="bg-blue-50">
              <div className="flex items-center gap-3">
                <div className="w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center">
                  <ShieldCheck className="h-5 w-5 text-blue-700" />
                </div>
                <CardTitle className="text-xl text-blue-800">Facteurs Clés de Succès</CardTitle>
              </div>
            </CardHeader>
            <CardContent className="pt-6">
              <ul className="space-y-4">
                <li className="flex gap-3">
                  <div className="w-6 h-6 bg-green-100 rounded-full flex items-center justify-center shrink-0 mt-0.5">
                    <Leaf className="h-4 w-4 text-green-700" />
                  </div>
                  <div>
                    <h4 className="font-semibold text-gray-800">Sélection d'essences à forte séquestration</h4>
                    <p className="text-gray-600">Utilisation d'espèces d'arbres et de mangroves à croissance rapide et forte capacité de séquestration de carbone, adaptées au climat de Madagascar.</p>
                  </div>
                </li>
                <li className="flex gap-3">
                  <div className="w-6 h-6 bg-green-100 rounded-full flex items-center justify-center shrink-0 mt-0.5">
                    <DollarSign className="h-4 w-4 text-green-700" />
                  </div>
                  <div>
                    <h4 className="font-semibold text-gray-800">Diversification des revenus</h4>
                    <p className="text-gray-600">Combinaison des crédits carbone, crédits biodiversité, écotourisme et services de conseil dès la première année pour générer des revenus avant la maturité complète des projets.</p>
                  </div>
                </li>
                <li className="flex gap-3">
                  <div className="w-6 h-6 bg-green-100 rounded-full flex items-center justify-center shrink-0 mt-0.5">
                    <TrendingUp className="h-4 w-4 text-green-700" />
                  </div>
                  <div>
                    <h4 className="font-semibold text-gray-800">Technologie propriétaire</h4>
                    <p className="text-gray-600">L'utilisation de drones et de notre algorithme d'évaluation permettent un suivi précis de la croissance et captation carbone, valorisant nos crédits à un prix premium sur le marché.</p>
                  </div>
                </li>
                <li className="flex gap-3">
                  <div className="w-6 h-6 bg-green-100 rounded-full flex items-center justify-center shrink-0 mt-0.5">
                    <ShieldCheck className="h-4 w-4 text-green-700" />
                  </div>
                  <div>
                    <h4 className="font-semibold text-gray-800">Partenariats gouvernementaux et ONG</h4>
                    <p className="text-gray-600">Collaboration avec WWF, Nations Unies et les initiatives du gouvernement malgache pour les plantations de mangroves, permettant d'accélérer le déploiement et réduire les coûts.</p>
                  </div>
                </li>
              </ul>
              
              <div className="mt-6 p-4 bg-blue-50 rounded-lg text-sm">
                <p className="flex items-start gap-2 text-blue-800">
                  <ShieldCheck className="h-5 w-5 shrink-0 mt-0.5" />
                  <span>
                    <strong>Seuil de rentabilité :</strong> Atteint en année 3 grâce à l'efficacité des mangroves, l'écotourisme, et l'augmentation progressive de la séquestration carbone des arbres plantés en année 1, combiné à notre technologie de mesure précise qui optimise la valorisation des crédits.
                  </span>
                </p>
              </div>
            </CardContent>
          </Card>
          
          <Card>
            <CardHeader className="bg-purple-50">
              <div className="flex items-center gap-3">
                <div className="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center">
                  <Euro className="h-5 w-5 text-purple-700" />
                </div>
                <CardTitle className="text-xl text-purple-800">Contribution des Activités DeFi</CardTitle>
              </div>
            </CardHeader>
            <CardContent className="pt-6">
              <p className="text-gray-700 mb-4">
                L'écosystème de tokens (GTC et VCarbs) génère des revenus supplémentaires grâce aux diverses activités DeFi qui accélèrent l'atteinte de la rentabilité:
              </p>
              
              <div className="space-y-4">
                <div className="border rounded-lg p-4">
                  <h4 className="font-semibold text-purple-800 mb-2">Staking et gouvernance</h4>
                  <p className="text-gray-600 mb-2">Le staking des tokens GTC par les utilisateurs génère des frais de transaction qui alimentent le trésor du projet.</p>
                  <div className="flex justify-between">
                    <span className="text-sm text-gray-500">Contribution aux revenus Y3</span>
                    <span className="font-medium text-purple-700">150 000 € / an</span>
                  </div>
                </div>
                
                <div className="border rounded-lg p-4">
                  <h4 className="font-semibold text-purple-800 mb-2">Fourniture de liquidité</h4>
                  <p className="text-gray-600 mb-2">Les paires de liquidité GTC/VCarbs génèrent des frais qui soutiennent la croissance du projet.</p>
                  <div className="flex justify-between">
                    <span className="text-sm text-gray-500">Contribution aux revenus Y3</span>
                    <span className="font-medium text-purple-700">180 000 € / an</span>
                  </div>
                </div>
                
                <div className="border rounded-lg p-4">
                  <h4 className="font-semibold text-purple-800 mb-2">Tokenisation des crédits carbone</h4>
                  <p className="text-gray-600 mb-2">La transformation des crédits carbone en tokens négociables crée une source de revenus significative.</p>
                  <div className="flex justify-between">
                    <span className="text-sm text-gray-500">Contribution aux revenus Y3</span>
                    <span className="font-medium text-purple-700">320 000 € / an</span>
                  </div>
                </div>
              </div>
              
              <div className="mt-6 p-4 bg-purple-50 rounded-lg text-sm">
                <p className="flex items-start gap-2 text-purple-800">
                  <ShieldCheck className="h-5 w-5 shrink-0 mt-0.5" />
                  <span>
                    <strong>Effet de levier financier :</strong> Les mécanismes DeFi permettent de mobiliser des capitaux supplémentaires pour développer les projets environnementaux, créant ainsi un cercle vertueux où les rendements des activités DeFi sont réinvestis dans de nouveaux projets de plantation.
                  </span>
                </p>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default FinancialProjections;
