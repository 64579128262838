
import React from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Leaf, Coins, Users, Globe, Sprout, LineChart, Shield, Recycle, GraduationCap } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { useLanguage } from '@/contexts/LanguageContext';

const Features = () => {
  const { t } = useLanguage();
  
  const featuresList = [
    {
      icon: <Leaf className="text-highlight" />,
      title: t('features.items.certifiedProjects.title'),
      description: t('features.items.certifiedProjects.description')
    },
    {
      icon: <Coins className="text-highlight" />,
      title: t('features.items.carbonMarket.title'),
      description: t('features.items.carbonMarket.description')
    },
    {
      icon: <Users className="text-highlight" />,
      title: t('features.items.governance.title'),
      description: t('features.items.governance.description'),
      link: "/dao"
    },
    {
      icon: <Shield className="text-highlight" />,
      title: t('features.items.security.title'),
      description: t('features.items.security.description')
    },
    {
      icon: <Globe className="text-highlight" />,
      title: t('features.items.international.title'),
      description: t('features.items.international.description')
    },
    {
      icon: <LineChart className="text-highlight" />,
      title: t('features.items.impact.title'),
      description: t('features.items.impact.description')
    },
    {
      icon: <GraduationCap className="text-highlight" />,
      title: t('features.items.incubation.title'),
      description: t('features.items.incubation.description'),
      link: "/incubation"
    },
    {
      icon: <Sprout className="text-highlight" />,
      title: t('features.items.crowdfunding.title'),
      description: t('features.items.crowdfunding.description')
    },
    {
      icon: <Recycle className="text-highlight" />,
      title: t('features.items.compensation.title'),
      description: t('features.items.compensation.description')
    }
  ];

  return (
    <section id="features" className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-brand-800 mb-4">{t('features.title')}</h2>
          <p className="text-gray-600 max-w-2xl mx-auto text-lg">
            {t('features.description')}
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {featuresList.map((feature, index) => (
            <Card key={index} className="border border-gray-200 hover:shadow-md transition-shadow duration-300">
              <CardHeader className="pb-2">
                <div className="mb-4 w-12 h-12 flex items-center justify-center bg-brand-50 rounded-lg">
                  {feature.icon}
                </div>
                <CardTitle className="text-xl">{feature.title}</CardTitle>
              </CardHeader>
              <CardContent>
                <CardDescription className="text-gray-600 text-base mb-4">
                  {feature.description}
                </CardDescription>
                {feature.link && (
                  <Link to={feature.link}>
                    <Button variant="outline" size="sm" className="w-full">
                      {t('features.learnMore')}
                    </Button>
                  </Link>
                )}
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
