
import React, { Suspense, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import SEOHeaders from '@/components/SEOHeaders';
import { LanguageProvider } from '@/contexts/LanguageContext';
import { AuthProvider } from '@/contexts/AuthContext';
import { Toaster } from '@/components/ui/toaster';

// Pages
import Index from './pages/Index';
import Team from './pages/Team';
import NotFound from './pages/NotFound';
import Projects from './pages/Projects';
import Simulator from './pages/Simulator';
import TokenEconomics from './pages/TokenEconomics';
import VCarbs from './pages/VCarbs';
import Valuation from './pages/Valuation';
import VerifyNFT from './pages/VerifyNFT';
import Dao from './pages/Dao';
import DaoPitch from './pages/DaoPitch';
import Incubation from './pages/Incubation';
import Community from './pages/Community';
import ExpectedReturns from './pages/ExpectedReturns';
import Legal from './pages/Legal';
import InvestmentMemorandum from './pages/InvestmentMemorandum';
import Error from './pages/Error';
import FallbackScreen from './components/FallbackScreen';
import AIInfluencerPage from './pages/AIInfluencer';
import AIVideoScriptPage from './pages/AIVideoScript';

// Loading fallback amélioré
const Loading = () => (
  <div className="flex items-center justify-center h-[80vh]">
    <div className="text-center">
      <div className="rounded-full h-12 w-12 border-4 border-t-brand-600 animate-spin mx-auto"></div>
      <p className="mt-4 text-brand-700 font-medium">Chargement...</p>
    </div>
  </div>
);

function App() {
  const location = useLocation();
  
  // Descriptions des routes pour le SEO
  const routeDescriptions: Record<string, string> = {
    '/': 'GuTeco - Premier marketplace de crédits carbone tokenisés pour un futur durable. Solutions pour entreprises, particuliers et investisseurs.',
    '/team': 'Découvrez l\'équipe de GuTeco, des experts passionnés par la blockchain et le développement durable.',
    '/projects': 'Explorez les projets de crédits carbone tokenisés de GuTeco. Des projets verts avec impact réel et transparence totale.',
    '/simulator': 'Simulez votre compensation carbone avec notre outil innovant. Calculez et compensez votre empreinte en quelques clics.',
    '/token-economics': 'Découvrez le modèle économique des tokens GuTeco. Comprendre la valeur et l\'utilité de nos tokens carbone.',
    '/vcarbs': 'Les VCarbs, notre solution innovante de crédits carbone vérifiés et tokenisés pour la transparence du marché.',
    '/valuation': 'Méthodologie d\'évaluation des projets verts et des crédits carbone sur la plateforme GuTeco.',
    '/dao': 'La DAO GuTeco - Gouvernance décentralisée pour nos projets verts et nos mécanismes de compensation carbone.',
    '/community': 'Rejoignez la communauté GuTeco et participez à la révolution verte. Ensemble pour un avenir durable.',
    '/expected-returns': 'Analyse des rendements attendus pour les investisseurs et détenteurs de tokens de la plateforme GuTeco.',
    '/legal': 'Mentions légales et conditions d\'utilisation de la plateforme GuTeco.',
    '/investment-memorandum': 'Mémorandum d\'investissement complet pour les investisseurs intéressés par GuTeco.',
    '/ai-influencer': 'Utilisez notre AI Influenceur pour promouvoir GuTeco sur les réseaux sociaux avec du contenu optimisé.',
    '/ai-video-script': 'Scripts prêts à l\'emploi pour créer des vidéos promotionnelles convaincantes sur GuTeco et son potentiel d\'investissement.',
  };
  
  // Titres des pages
  const routeToTitle: Record<string, string> = {
    '/': 'GuTeco - Tokenised Carbon Credits',
    '/team': 'GuTeco - Notre Équipe',
    '/projects': 'GuTeco - Nos Projets',
    '/simulator': 'GuTeco - Simulateur',
    '/token-economics': 'GuTeco - TokenEconomics',
    '/vcarbs': 'GuTeco - VCarbs',
    '/valuation': 'GuTeco - Valorisation',
    '/dao': 'GuTeco - DAO',
    '/community': 'GuTeco - Communauté',
    '/expected-returns': 'GuTeco - Rendements Attendus',
    '/legal': 'GuTeco - Mentions Légales',
    '/investment-memorandum': 'GuTeco - Mémorandum d\'Investissement',
    '/ai-influencer': 'GuTeco - AI Influenceur',
    '/ai-video-script': 'GuTeco - Scripts Vidéo',
  };
  
  useEffect(() => {
    document.title = routeToTitle[location.pathname] || 'GuTeco - Page Introuvable';
    
    // Force visibility of content
    document.body.style.display = 'block';
    document.documentElement.style.visibility = 'visible';
    
    // Scroll to top on route change
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <LanguageProvider>
      <AuthProvider>
        <div className="app-container" key={location.key}>
          {/* Global SEO headers */}
          <SEOHeaders 
            title={document.title}
            description={routeDescriptions[location.pathname] || 'GuTeco - Plateforme innovante de crédits carbone tokenisés pour un avenir plus durable.'}
            canonicalPath={location.pathname}
            robots="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
          />
          
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/" element={<Index />} />
              <Route path="/team" element={<Team />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/simulator" element={<Simulator />} />
              <Route path="/token-economics" element={<TokenEconomics />} />
              <Route path="/vcarbs" element={<VCarbs />} />
              <Route path="/valuation" element={<Valuation />} />
              <Route path="/verify-nft/:id" element={<VerifyNFT />} />
              <Route path="/verify-nft" element={<VerifyNFT />} />
              <Route path="/dao" element={<Dao />} />
              <Route path="/dao-pitch" element={<DaoPitch />} />
              <Route path="/incubation" element={<Incubation />} />
              <Route path="/community" element={<Community />} />
              <Route path="/expected-returns" element={<ExpectedReturns />} />
              <Route path="/legal" element={<Legal />} />
              <Route path="/investment-memorandum" element={<InvestmentMemorandum />} />
              <Route path="/error" element={<Error />} />
              <Route path="/ai-influencer" element={<AIInfluencerPage />} />
              <Route path="/ai-influenceur" element={<AIInfluencerPage />} />
              <Route path="/ai-video-script" element={<AIVideoScriptPage />} />
              <Route path="/scripts-video" element={<AIVideoScriptPage />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
          <Toaster />
        </div>
      </AuthProvider>
    </LanguageProvider>
  );
}

export default App;
