import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Calculator, TrendingUp, LineChart, DollarSign, Wallet, Lock, HelpCircle } from 'lucide-react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

const FinancialSimulation = () => {
  // Mise à jour des données financières pour être cohérentes
  const financialData = {
    year1: {
      revenue: 2600000,
      expenses: 2200000,
      profit: 400000,
      valuation: 93000000,
    },
    year2: {
      revenue: 5800000,
      expenses: 4900000,
      profit: 900000,
      valuation: 105000000,
    },
    total: {
      revenue: 8400000,
      profit: 1300000,
    },
    investment: {
      amount: 12000000,
      equity: 11.76,
      initialValue: 12000000,
      finalValue: 12348000,
      roi: 2.9,
      annualRoi: 1.44,
    },
    defi: {
      stakingYear1: 320000,
      stakingYear2: 520000,
      liquidityYear1: 180000,
      liquidityYear2: 310000,
      tokenizationYear1: 140000,
      tokenizationYear2: 290000,
      totalYear1: 640000,
      totalYear2: 1120000,
      total: 1760000
    },
    carbonCredits: {
      mangroveYear1: 850000,
      mangroveYear2: 1950000,
      forestYear1: 680000,
      forestYear2: 1270000,
      biodiversityYear1: 310000,
      biodiversityYear2: 720000,
      totalYear1: 1840000,
      totalYear2: 3940000,
      total: 5780000
    }
  };

  const equityPercentage = 11.76;
  const initialInvestment = 12000000;
  const finalCompanyValuation = 105000000;

  const finalEquityValue = (finalCompanyValuation * equityPercentage) / 100;
  const totalDefiRevenue = 1760000;
  const investorDefiShare = (totalDefiRevenue * equityPercentage) / 100;

  const adjustedFinalValue = finalEquityValue + investorDefiShare;
  const adjustedRoi = ((adjustedFinalValue - initialInvestment) / initialInvestment) * 100;
  const adjustedAnnualRoi = (Math.sqrt(1 + (adjustedRoi / 100)) - 1) * 100;

  return (
    <Card className="mb-10">
      <CardHeader className="bg-blue-50">
        <div className="flex items-center gap-3">
          <div className="w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center">
            <Calculator className="h-5 w-5 text-blue-700" />
          </div>
          <CardTitle className="text-xl text-blue-800">Simulation Financière – GuTeco (2 ans)</CardTitle>
        </div>
      </CardHeader>
      <CardContent className="pt-6">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Période</TableHead>
              <TableHead className="text-right">Revenus (€)</TableHead>
              <TableHead className="text-right">Dépenses (€)</TableHead>
              <TableHead className="text-right">Bénéfices (€)</TableHead>
              <TableHead className="text-right">Valorisation (€)</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell className="font-medium">Année 1</TableCell>
              <TableCell className="text-right font-mono">{financialData.year1.revenue.toLocaleString()}</TableCell>
              <TableCell className="text-right font-mono">{financialData.year1.expenses.toLocaleString()}</TableCell>
              <TableCell className="text-right font-mono">{financialData.year1.profit.toLocaleString()}</TableCell>
              <TableCell className="text-right font-mono">{financialData.year1.valuation.toLocaleString()}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-medium">Année 2</TableCell>
              <TableCell className="text-right font-mono">{financialData.year2.revenue.toLocaleString()}</TableCell>
              <TableCell className="text-right font-mono">{financialData.year2.expenses.toLocaleString()}</TableCell>
              <TableCell className="text-right font-mono">{financialData.year2.profit.toLocaleString()}</TableCell>
              <TableCell className="text-right font-mono">{financialData.year2.valuation.toLocaleString()}</TableCell>
            </TableRow>
            <TableRow className="bg-blue-50 font-bold">
              <TableCell>TOTAL (2 ans)</TableCell>
              <TableCell className="text-right font-mono">{financialData.total.revenue.toLocaleString()}</TableCell>
              <TableCell className="text-right font-mono">{(financialData.year1.expenses + financialData.year2.expenses).toLocaleString()}</TableCell>
              <TableCell className="text-right font-mono">{financialData.total.profit.toLocaleString()}</TableCell>
              <TableCell className="text-right font-mono">-</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Tabs defaultValue="defi" className="mt-8 mb-8">
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="defi" className="flex items-center gap-2">
              <Wallet className="h-4 w-4" />
              <span>Activités DeFi et Tokenisation</span>
            </TabsTrigger>
            <TabsTrigger value="carbon" className="flex items-center gap-2">
              <LineChart className="h-4 w-4" />
              <span>Crédits Carbone et Biodiversité</span>
            </TabsTrigger>
          </TabsList>
          
          <TabsContent value="defi" className="pt-6">
            <div className="mb-4">
              <p className="text-gray-700 mb-2">
                En plus des revenus traditionnels, les activités DeFi génèrent des revenus supplémentaires significatifs qui améliorent le retour sur investissement global:
              </p>
            </div>
            
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Activité DeFi</TableHead>
                  <TableHead className="text-right">Année 1 (€)</TableHead>
                  <TableHead className="text-right">Année 2 (€)</TableHead>
                  <TableHead className="text-right">Total (€)</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell className="font-medium">Staking GTC</TableCell>
                  <TableCell className="text-right font-mono">{financialData.defi.stakingYear1.toLocaleString()}</TableCell>
                  <TableCell className="text-right font-mono">{financialData.defi.stakingYear2.toLocaleString()}</TableCell>
                  <TableCell className="text-right font-mono">{(financialData.defi.stakingYear1 + financialData.defi.stakingYear2).toLocaleString()}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-medium">Fourniture de liquidité</TableCell>
                  <TableCell className="text-right font-mono">{financialData.defi.liquidityYear1.toLocaleString()}</TableCell>
                  <TableCell className="text-right font-mono">{financialData.defi.liquidityYear2.toLocaleString()}</TableCell>
                  <TableCell className="text-right font-mono">{(financialData.defi.liquidityYear1 + financialData.defi.liquidityYear2).toLocaleString()}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-medium">Tokenisation crédits carbone</TableCell>
                  <TableCell className="text-right font-mono">{financialData.defi.tokenizationYear1.toLocaleString()}</TableCell>
                  <TableCell className="text-right font-mono">{financialData.defi.tokenizationYear2.toLocaleString()}</TableCell>
                  <TableCell className="text-right font-mono">{(financialData.defi.tokenizationYear1 + financialData.defi.tokenizationYear2).toLocaleString()}</TableCell>
                </TableRow>
                <TableRow className="bg-purple-50 font-bold">
                  <TableCell>TOTAL Activités DeFi</TableCell>
                  <TableCell className="text-right font-mono">{financialData.defi.totalYear1.toLocaleString()}</TableCell>
                  <TableCell className="text-right font-mono">{financialData.defi.totalYear2.toLocaleString()}</TableCell>
                  <TableCell className="text-right font-mono">{financialData.defi.total.toLocaleString()}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TabsContent>
          
          <TabsContent value="carbon" className="pt-6">
            <div className="mb-4">
              <p className="text-gray-700 mb-2">
                Les projets environnementaux génèrent des crédits carbone et biodiversité qui représentent une source majeure de revenus sur le moyen et long terme:
              </p>
            </div>
            
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Type de Projet</TableHead>
                  <TableHead className="text-right">Année 1 (€)</TableHead>
                  <TableHead className="text-right">Année 2 (€)</TableHead>
                  <TableHead className="text-right">Total (€)</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell className="font-medium">Projets de mangroves</TableCell>
                  <TableCell className="text-right font-mono">{financialData.carbonCredits.mangroveYear1.toLocaleString()}</TableCell>
                  <TableCell className="text-right font-mono">{financialData.carbonCredits.mangroveYear2.toLocaleString()}</TableCell>
                  <TableCell className="text-right font-mono">{(financialData.carbonCredits.mangroveYear1 + financialData.carbonCredits.mangroveYear2).toLocaleString()}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-medium">Projets forestiers</TableCell>
                  <TableCell className="text-right font-mono">{financialData.carbonCredits.forestYear1.toLocaleString()}</TableCell>
                  <TableCell className="text-right font-mono">{financialData.carbonCredits.forestYear2.toLocaleString()}</TableCell>
                  <TableCell className="text-right font-mono">{(financialData.carbonCredits.forestYear1 + financialData.carbonCredits.forestYear2).toLocaleString()}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-medium">Crédits biodiversité</TableCell>
                  <TableCell className="text-right font-mono">{financialData.carbonCredits.biodiversityYear1.toLocaleString()}</TableCell>
                  <TableCell className="text-right font-mono">{financialData.carbonCredits.biodiversityYear2.toLocaleString()}</TableCell>
                  <TableCell className="text-right font-mono">{(financialData.carbonCredits.biodiversityYear1 + financialData.carbonCredits.biodiversityYear2).toLocaleString()}</TableCell>
                </TableRow>
                <TableRow className="bg-green-50 font-bold">
                  <TableCell>TOTAL Crédits Environnementaux</TableCell>
                  <TableCell className="text-right font-mono">{financialData.carbonCredits.totalYear1.toLocaleString()}</TableCell>
                  <TableCell className="text-right font-mono">{financialData.carbonCredits.totalYear2.toLocaleString()}</TableCell>
                  <TableCell className="text-right font-mono">{financialData.carbonCredits.total.toLocaleString()}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TabsContent>
        </Tabs>
          
        <div className="mt-4 p-4 bg-purple-50 rounded-lg">
          <p className="text-purple-800 font-medium">
            En combinant tous les revenus, le total sur 2 ans atteint <span className="font-bold">{(financialData.total.revenue + financialData.defi.total + financialData.carbonCredits.total).toLocaleString()} €</span>, incluant les revenus traditionnels, les activités DeFi et les crédits environnementaux.
          </p>
        </div>

        <div className="mt-10 mb-4">
          <h3 className="font-semibold text-lg text-blue-800 flex items-center gap-2 mb-4">
            <TrendingUp className="h-5 w-5" />
            <span>Retour sur Investissement (ROI)</span>
          </h3>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <Card className="bg-blue-50">
              <CardContent className="pt-6 text-center">
                <div className="flex items-center justify-center mb-2">
                  <DollarSign className="h-6 w-6 text-blue-700" />
                </div>
                <h4 className="text-sm font-medium text-blue-900 mb-1">Investissement Initial</h4>
                <p className="text-2xl font-bold text-blue-800">{initialInvestment.toLocaleString()} €</p>
                <p className="text-sm text-blue-700 mt-1">
                  pour {equityPercentage}% d'équité de GuTeco
                </p>
              </CardContent>
            </Card>
            
            <Card className="bg-purple-50">
              <CardContent className="pt-6 text-center">
                <div className="flex items-center justify-center mb-2">
                  <Lock className="h-6 w-6 text-purple-700" />
                </div>
                <h4 className="text-sm font-medium text-purple-900 mb-1">
                  <span className="flex items-center justify-center gap-1">
                    ROI avec Activités DeFi
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          <HelpCircle className="h-4 w-4 text-purple-500" />
                        </TooltipTrigger>
                        <TooltipContent className="max-w-xs">
                          Le ROI (Retour sur Investissement) est calculé sur la base de la valeur finale de l'investissement divisée par l'investissement initial, moins 1.
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </span>
                </h4>
                <p className="text-2xl font-bold text-purple-800">+{adjustedRoi.toFixed(1)}%</p>
                <p className="text-sm text-purple-700 mt-1 flex items-center justify-center gap-1">
                  {adjustedAnnualRoi.toFixed(1)}% par an (composé)
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <HelpCircle className="h-4 w-4 text-purple-500" />
                      </TooltipTrigger>
                      <TooltipContent className="max-w-xs p-4 bg-white">
                        <p className="font-medium mb-2">Qu'est-ce que le taux "composé"?</p>
                        <p className="text-sm mb-2">Le taux composé signifie que les rendements s'accumulent année après année et génèrent eux-mêmes des rendements supplémentaires.</p>
                        <p className="text-sm">Exemple: Avec un ROI annuel composé de {adjustedAnnualRoi.toFixed(1)}%, 100€ deviennent:</p>
                        <ul className="text-xs mt-1 space-y-1">
                          <li>• Année 1: 100€ × (1 + {(adjustedAnnualRoi/100).toFixed(3)}) = {(100 * (1 + adjustedAnnualRoi/100)).toFixed(2)}€</li>
                          <li>• Année 2: {(100 * (1 + adjustedAnnualRoi/100)).toFixed(2)}€ × (1 + {(adjustedAnnualRoi/100).toFixed(3)}) = {(100 * (1 + adjustedAnnualRoi/100) * (1 + adjustedAnnualRoi/100)).toFixed(2)}€</li>
                        </ul>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </p>
              </CardContent>
            </Card>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <Card className="bg-green-50">
              <CardContent className="pt-6 text-center">
                <div className="flex items-center justify-center mb-2">
                  <LineChart className="h-6 w-6 text-green-700" />
                </div>
                <h4 className="text-sm font-medium text-green-900 mb-1">Valeur de l'Équité Après 2 ans</h4>
                <p className="text-2xl font-bold text-green-800">{finalEquityValue.toLocaleString()} €</p>
                <div className="text-sm text-green-700 mt-1 space-y-1">
                  <p>
                    {equityPercentage}% de la valorisation finale de {finalCompanyValuation.toLocaleString()} €
                  </p>
                  <p className="font-medium">
                    Calcul: {equityPercentage}% × {finalCompanyValuation.toLocaleString()} € = {finalEquityValue.toLocaleString()} €
                  </p>
                </div>
              </CardContent>
            </Card>
            
            <Card className="bg-green-50">
              <CardContent className="pt-6 text-center">
                <div className="flex items-center justify-center mb-2">
                  <Wallet className="h-6 w-6 text-green-700" />
                </div>
                <h4 className="text-sm font-medium text-green-900 mb-1">Part des Revenus DeFi (2 ans)</h4>
                <p className="text-2xl font-bold text-green-800">{investorDefiShare.toLocaleString()} €</p>
                <div className="text-sm text-green-700 mt-1 space-y-1">
                  <p>
                    {equityPercentage}% des revenus DeFi de {totalDefiRevenue.toLocaleString()} €
                  </p>
                  <p className="font-medium">
                    Calcul: {equityPercentage}% × {totalDefiRevenue.toLocaleString()} € = {investorDefiShare.toLocaleString()} €
                  </p>
                </div>
              </CardContent>
            </Card>
          </div>

          <div className="p-4 bg-blue-50 rounded-lg border border-blue-200 mb-6">
            <h4 className="font-medium text-blue-800 mb-2">Explication détaillée des calculs:</h4>
            <ul className="space-y-3 text-sm text-blue-700">
              <li>
                <span className="font-semibold">Valeur de l'équité après 2 ans:</span> L'investissement de {initialInvestment.toLocaleString()} € achète {equityPercentage}% de GuTeco. 
                Après 2 ans, la valorisation de l'entreprise atteint {finalCompanyValuation.toLocaleString()} €, donc la valeur de l'équité devient {equityPercentage}% de {finalCompanyValuation.toLocaleString()} € = {finalEquityValue.toLocaleString()} €.
              </li>
              <li>
                <span className="font-semibold">Part des revenus DeFi:</span> Les activités DeFi génèrent {totalDefiRevenue.toLocaleString()} € sur 2 ans. 
                L'investisseur, détenant {equityPercentage}% de l'entreprise, a droit à {equityPercentage}% de ces revenus, soit {investorDefiShare.toLocaleString()} €.
              </li>
              <li>
                <span className="font-semibold">Retour total:</span> La valeur finale totale pour l'investisseur est de {finalEquityValue.toLocaleString()} € (équité) + {investorDefiShare.toLocaleString()} € (revenus DeFi) = {adjustedFinalValue.toLocaleString()} €.
              </li>
              <li>
                <span className="font-semibold">ROI:</span> ({adjustedFinalValue.toLocaleString()} € - {initialInvestment.toLocaleString()} €) ÷ {initialInvestment.toLocaleString()} € × 100 = {adjustedRoi.toFixed(1)}% sur 2 ans.
              </li>
            </ul>
          </div>
        </div>
        
        <div className="bg-amber-50 p-4 rounded-lg border border-amber-200">
          <p className="text-amber-800 font-medium">
            ⚠️ Note : Le rendement combiné des activités DeFi et des projets environnementaux rend cet investissement plus attractif, avec un ROI ajusté de {adjustedRoi.toFixed(1)}% sur 2 ans. La croissance s'accélère significativement à partir de l'année 3.
          </p>
          <p className="text-amber-700 text-sm mt-2 italic">
            Les investisseurs peuvent bénéficier de revenus réguliers via les activités DeFi (staking, liquidité) tout en attendant la pleine maturité des projets environnementaux sur 5 ans, offrant ainsi un profil de rendement plus équilibré.
          </p>
        </div>
      </CardContent>
    </Card>
  );
};

export default FinancialSimulation;
