
import React, { useState } from 'react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { Badge } from '@/components/ui/badge';
import { Copy, Check, Clock, Video, Film, Share2, Download, AlertTriangle } from 'lucide-react';
import { useLanguage } from '@/contexts/LanguageContext';
import { toast } from '@/hooks/use-toast';

const AIVideoScript = () => {
  const { t, language } = useLanguage();
  const [copied, setCopied] = useState<string | null>(null);

  const handleCopy = (text: string, type: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setCopied(type);
        toast({
          title: "Copié !",
          description: "Le texte a été copié dans le presse-papier",
          variant: "success"
        });
        
        setTimeout(() => setCopied(null), 2000);
      },
      (err) => {
        console.error('Erreur lors de la copie :', err);
        toast({
          title: "Erreur",
          description: "Impossible de copier le texte",
          variant: "destructive"
        });
      }
    );
  };

  const videoScripts = {
    short: {
      title: "Vidéo courte (15-30 sec)",
      content: `Imaginez investir dans le futur de notre planète tout en générant des rendements exceptionnels. 🌍💰

GuTeco révolutionne le marché des crédits carbone avec notre technologie blockchain de pointe.

Nos projets environnementaux offrent un ROI projeté de 40% sur 3 ans, soutenus par un marché qui devrait atteindre 50 milliards de dollars d'ici 2030.

Nous levons actuellement 12M€ pour 11,76% d'équité, avec une valorisation de 90M€.

Rejoignez notre révolution verte et devenez acteur du changement tout en sécurisant votre avenir financier.

Visitez guteco.com pour découvrir notre mémorandum d'investissement. #CréditCarbone #InvestissementVert #Finance`
    },
    medium: {
      title: "Vidéo explicative (1-2 min)",
      content: `SCRIPT - PRÉSENTATION GUTECO (1 minute)

[INTRO - 10s]
Dans un monde où l'urgence climatique appelle des solutions innovantes, GuTeco révolutionne la manière dont nous finançons la protection de notre planète.

[PROBLÈME - 15s]
Le marché actuel des crédits carbone manque de transparence et d'accessibilité. Les investisseurs peinent à identifier les projets véritablement impactants, tandis que les porteurs de projets environnementaux luttent pour obtenir des financements.

[SOLUTION - 20s]
GuTeco utilise la technologie blockchain pour créer un marketplace de crédits carbone tokenisés. Notre plateforme connecte directement investisseurs et projets environnementaux, avec une traçabilité totale et des rendements attractifs.

[OPPORTUNITÉ - 15s]
Avec une valorisation de 90M€, nous levons 12M€ pour financer l'expansion de notre plateforme. Le marché des crédits carbone volontaires devrait être multiplié par 15 d'ici 2030, offrant un potentiel de ROI de 40% sur 3 ans.

[CALL TO ACTION - 10s]
Investissez dans GuTeco aujourd'hui et participez à la révolution de la finance verte. Rendez-vous sur guteco.com pour découvrir notre mémorandum d'investissement complet.`
    },
    pitch: {
      title: "Pitch investisseurs (2-3 min)",
      content: `SCRIPT - PITCH INVESTISSEURS GUTECO (2-3 minutes)

[ACCROCHE - 15s]
Imaginez une technologie qui transforme la manière dont nous finançons la lutte contre le changement climatique tout en générant des rendements exceptionnels pour les investisseurs. C'est exactement ce que fait GuTeco.

[PROBLÈME - 30s]
Le marché des crédits carbone, bien que prometteur, souffre de trois problèmes majeurs:
1. Un manque de transparence qui freine la confiance des investisseurs
2. Des processus de vérification coûteux et inefficaces
3. Une liquidité limitée qui réduit l'attractivité du marché

Les conséquences? Des projets environnementaux essentiels qui peinent à trouver des financements, et des investisseurs qui hésitent à s'engager.

[SOLUTION - 40s]
GuTeco a développé une plateforme qui révolutionne ce marché grâce à trois innovations majeures:

Premièrement, notre technologie blockchain assure une traçabilité complète de chaque crédit carbone, de sa création à son utilisation finale.

Deuxièmement, notre algorithme IA et notre réseau de vérificateurs distribués réduisent drastiquement les coûts de certification tout en augmentant la fiabilité.

Enfin, notre tokenisation des crédits carbone en VCarbs crée un marché liquide où particuliers, entreprises et investisseurs peuvent échanger facilement.

[TRACTION ET VALIDATION - 30s]
Nous avons déjà:
- Sécurisé des partenariats avec 5 projets de restauration de mangroves dans 3 pays
- Développé et testé notre plateforme avec 1000 utilisateurs pilotes
- Constitué une équipe de 12 experts en finance verte, blockchain et ingénierie écologique
- Établi des partenariats avec deux organisations internationales de certification

[OPPORTUNITÉ DE MARCHÉ - 20s]
Le marché volontaire des crédits carbone devrait être multiplié par 15 d'ici 2030 pour atteindre 50 milliards de dollars. Notre modèle nous permet de capturer 5% de ce marché d'ici 5 ans.

[MODÈLE FINANCIER - 25s]
Nous générons des revenus de plusieurs façons:
- Commission sur les transactions (3-5%)
- Vente directe de crédits carbone issus de nos propres projets
- Services de conseil en stratégie de compensation carbone pour les entreprises

Nos projections financières montrent un ROI potentiel de 40% sur 3 ans pour nos investisseurs.

[DEMANDE - 15s]
Nous levons 12 millions d'euros en échange de 11,76% d'équité, valorisant GuTeco à 90 millions d'euros pre-money. Ces fonds seront principalement utilisés pour:
- L'acquisition d'équipements techniques pour nos projets de mangroves
- Le développement de notre plateforme technologique
- L'expansion internationale

[CONCLUSION - 15s]
GuTeco n'est pas seulement un investissement financier, c'est un investissement dans l'avenir de notre planète. Rejoignez-nous pour révolutionner la finance verte et générer des retours exceptionnels tout en créant un impact positif durable.`
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-8 text-brand-700">
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-brand-600 to-brand-800">
          Scripts Vidéo GuTeco
        </span>
      </h1>

      <p className="text-center text-gray-600 max-w-2xl mx-auto mb-8">
        Utilisez ces scripts prêts à l'emploi pour créer des vidéos promotionnelles convaincantes sur GuTeco et son potentiel d'investissement.
      </p>
      
      <div className="bg-amber-50 border border-amber-200 rounded-md p-4 mb-8 max-w-3xl mx-auto">
        <div className="flex items-start gap-3">
          <AlertTriangle className="h-5 w-5 text-amber-500 flex-shrink-0 mt-0.5" />
          <div>
            <p className="font-medium text-amber-800 mb-1">Conseils pour vos vidéos</p>
            <ul className="text-sm text-amber-700 list-disc pl-5 space-y-1">
              <li>Ajoutez des visuels percutants: graphiques de croissance, images de projets environnementaux, etc.</li>
              <li>Incluez des sous-titres pour améliorer l'engagement et l'accessibilité</li>
              <li>Utilisez une musique de fond dynamique mais professionnelle</li>
              <li>Terminez par un call-to-action clair dirigeant vers le site GuTeco</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-8">
        {Object.entries(videoScripts).map(([key, script]) => (
          <Card key={key} className="border-brand-100">
            <CardHeader className="pb-3">
              <div className="flex items-center justify-between">
                <CardTitle className="text-xl text-brand-700 flex items-center gap-2">
                  {key === 'short' ? (
                    <Film className="h-5 w-5" />
                  ) : key === 'medium' ? (
                    <Video className="h-5 w-5" />
                  ) : (
                    <Share2 className="h-5 w-5" />
                  )}
                  {script.title}
                </CardTitle>
                <Badge variant="outline" className="bg-brand-50 text-brand-700 border-brand-200 flex items-center gap-1">
                  <Clock className="h-3 w-3" />
                  {key === 'short' ? '15-30s' : key === 'medium' ? '1-2m' : '2-3m'}
                </Badge>
              </div>
              <CardDescription>
                {key === 'short' ? 'Idéal pour Instagram, TikTok et YouTube Shorts' : 
                 key === 'medium' ? 'Parfait pour LinkedIn et YouTube' : 
                 'Pour présentations d\'investissement et webinaires'}
              </CardDescription>
            </CardHeader>
            
            <CardContent>
              <div className="bg-gray-50 p-4 rounded-md relative">
                <pre className="text-sm text-gray-800 whitespace-pre-line font-sans">{script.content}</pre>
              </div>
            </CardContent>
            
            <CardFooter className="flex flex-col gap-4 pt-2">
              <Separator />
              <div className="flex justify-between w-full items-center">
                <p className="text-xs text-gray-500">
                  Ce script peut être adapté selon vos besoins spécifiques et votre audience cible.
                </p>
                <div className="flex gap-2">
                  <Button 
                    variant="outline" 
                    size="sm" 
                    className="flex items-center gap-2"
                    onClick={() => handleCopy(script.content, `copy-${key}`)}
                  >
                    {copied === `copy-${key}` ? <Check className="h-4 w-4" /> : <Copy className="h-4 w-4" />}
                    Copier
                  </Button>
                  <Button 
                    variant="default" 
                    size="sm" 
                    className="flex items-center gap-2 bg-brand-600 hover:bg-brand-700"
                    onClick={() => {
                      const blob = new Blob([script.content], { type: 'text/plain' });
                      const url = URL.createObjectURL(blob);
                      const a = document.createElement('a');
                      a.href = url;
                      a.download = `guteco-video-script-${key}.txt`;
                      document.body.appendChild(a);
                      a.click();
                      document.body.removeChild(a);
                      URL.revokeObjectURL(url);
                      
                      toast({
                        title: "Téléchargement réussi",
                        description: "Le script a été téléchargé sur votre appareil",
                        variant: "success"
                      });
                    }}
                  >
                    <Download className="h-4 w-4" />
                    Télécharger
                  </Button>
                </div>
              </div>
            </CardFooter>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default AIVideoScript;
