export const es = {
  header: {
    home: 'Inicio',
    projects: 'Proyectos',
    team: 'Equipo',
    simulator: 'Simulador',
    tokenEconomics: 'TokenEconomics',
    vcarbs: 'VCarbs',
    valuation: 'Valoraciones',
    dao: 'DAO',
    community: 'Comunidad',
    returns: 'Rendimientos',
    menu: 'Menú'
  },
  hero: {
    title: 'Tokens certificados de impacto carbono',
    subtitle: 'La plataforma que facilita la financiación de proyectos ambientales a través de blockchain'
  },
  features: {
    title: 'Nuestro ecosistema',
    description: 'Descubra cómo Guteco facilita la financiación y el seguimiento de proyectos ecológicos a través de un mercado descentralizado de créditos de carbono.',
    items: {
      certifiedProjects: {
        title: 'Proyectos ecológicos certificados',
        description: 'Proyectos verificados y certificados por nuestra comunidad para garantizar su impacto ambiental.'
      },
      carbonMarket: {
        title: 'Mercado de créditos de carbono',
        description: 'Compre, venda e intercambie créditos de carbono de forma segura y transparente.'
      },
      governance: {
        title: 'Gobernanza DAO',
        description: 'Participe en las decisiones a través de nuestra organización autónoma descentralizada para mayor transparencia.'
      },
      security: {
        title: 'Transacciones seguras',
        description: 'Sus inversiones y transacciones están protegidas por protocolos de seguridad avanzados.'
      },
      international: {
        title: 'Proyectos internacionales',
        description: 'Apoye iniciativas ambientales en todo el mundo según sus intereses.'
      },
      impact: {
        title: 'Seguimiento de impacto',
        description: 'Mida y visualice el impacto concreto de sus inversiones en el medio ambiente.'
      },
      incubation: {
        title: 'Incubación de proyectos',
        description: 'Apoyo a proyectos académicos, investigaciones científicas y startups con impacto ambiental positivo.'
      },
      crowdfunding: {
        title: 'Financiación participativa',
        description: 'Contribuya a proyectos según sus posibilidades, cada acción cuenta para el medio ambiente.'
      },
      compensation: {
        title: 'Compensación de carbono',
        description: 'Compense su huella de carbono apoyando proyectos de secuestro y reducción de CO₂.'
      }
    },
    learnMore: 'Saber más'
  },
  faq: {
    title: 'Preguntas Frecuentes',
    description: 'Encuentre respuestas a las preguntas más comunes sobre Guteco, nuestra tecnología y nuestro modelo de inversión.',
    notFound: '¿No encuentra su respuesta?',
    needMore: '¿Necesita más información?',
    contactUs: 'Nuestro equipo está disponible para responder a todas sus preguntas sobre Guteco y nuestras soluciones.',
    contact: 'Contáctenos',
    categories: {
      general: 'General',
      investment: 'Inversiones y Tokens',
      projects: 'Proyectos e Impacto',
      security: 'Seguridad y Conformidad'
    }
  },
  footer: {
    description: 'La plataforma que conecta a los líderes de proyectos ambientales con inversores para un futuro más sostenible.',
    explore: 'Explorar',
    projects: 'Proyectos',
    carbonCredits: 'Créditos de Carbono',
    daoEcosystem: 'Comunidad DAO',
    impact: 'Impacto Ambiental',
    legal: 'Legal',
    terms: 'Términos de Uso',
    privacy: 'Política de Privacidad',
    legalNotice: 'Aviso Legal',
    certifications: 'Certificaciones',
    contact: 'Contacto',
    copyright: '© 2023 Guteco. Todos los derechos reservados.'
  },
  aiInfluencer: {
    name: "EcoTech IA",
    bio: "Inteligencia Artificial dedicada a la ecología y las finanzas verdes. Comparto innovaciones sobre créditos de carbono y blockchain para el medio ambiente. #GuTeco #TokenizaciónNaturaleza",
    tags: ["#CréditosCarbono", "#FinanzasVerdes", "#Blockchain", "#TokenizaciónNaturaleza", "#ImpactoPositivo"],
    posts: {
      welcome: "¡Hola! Soy EcoTech IA, tu guía en el universo de las finanzas verdes y soluciones blockchain para la ecología. ¡Sígueme para descubrir cómo @GuTeco está revolucionando los créditos de carbono!",
      daily: "¿Sabías que? Los manglares capturan hasta 4 veces más CO2 que los bosques tradicionales. Por eso @GuTeco invierte masivamente en su restauración. #SolucionesClimáticas",
      educational: "🌿 HILO: Entendiendo los créditos de carbono en 5 puntos\n\n1/5 Un crédito de carbono = 1 tonelada de CO2 no emitida o retirada de la atmósfera...",
      news: "📢 Nueva asociación entre @GuTeco y @GreenInvest para acelerar la financiación de proyectos de reforestación. Objetivo: ¡1M de árboles para 2025! #ImpactoPositivo #FinanzasVerdes",
      engagement: "Pregunta del día: ¿Qué tecnología verde te parece más prometedora para combatir el cambio climático? A) Captura de carbono B) Blockchain verde C) Energías renovables D) Agricultura regenerativa"
    }
  }
};
