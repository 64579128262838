
import React, { useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';
import { 
  TrendingUp, 
  TrendingDown, 
  LineChart, 
  BarChart4, 
  Calculator, 
  Leaf, 
  HelpCircle,
  ChevronUp,
  ChevronDown,
  Briefcase,
  Clock
} from 'lucide-react';

const ExpectedReturns = () => {
  const navigate = useNavigate();
  const [expandedSection, setExpandedSection] = useState<string | null>('short');

  // Données de rendements pour les différents scénarios
  const scenarios = {
    conservative: {
      year1: 1.5,
      year2: 2.2,
      year3: 3.8,
      year4: 5.1,
      year5: 7.3,
      cagr: 4.7,
      total: 21.2
    },
    base: {
      year1: 2.9,
      year2: 8.9,
      year3: 29.5,
      year4: 89.4,
      year5: 254.2,
      cagr: 28.6,
      total: 254.2
    },
    optimistic: {
      year1: 3.6,
      year2: 11.2,
      year3: 38.5,
      year4: 125.6,
      year5: 320.8,
      cagr: 33.8,
      total: 320.8
    }
  };

  // Données pour les différentes sources de revenus
  const revenueSources = [
    {
      name: "Crédits Carbone Mangroves",
      description: "Crédits issus des projets de restauration de mangroves",
      year1: 850000,
      year2: 1950000,
      year3: 3800000,
      year4: 7200000,
      year5: 12500000,
      color: "text-green-700"
    },
    {
      name: "Crédits Carbone Forestiers",
      description: "Crédits issus des projets de reforestation traditionnelle",
      year1: 680000,
      year2: 1270000,
      year3: 2100000,
      year4: 3800000,
      year5: 6500000,
      color: "text-emerald-700"
    },
    {
      name: "Crédits Biodiversité",
      description: "Crédits liés à la restauration et protection de la biodiversité",
      year1: 310000,
      year2: 720000,
      year3: 1300000,
      year4: 2200000,
      year5: 3500000,
      color: "text-blue-700"
    },
    {
      name: "Staking GTC",
      description: "Revenus générés par le staking des tokens GTC",
      year1: 320000,
      year2: 520000,
      year3: 780000,
      year4: 1100000,
      year5: 1500000,
      color: "text-purple-700"
    },
    {
      name: "Fourniture de Liquidité",
      description: "Revenus des pools de liquidité et frais d'échange",
      year1: 180000,
      year2: 310000,
      year3: 560000,
      year4: 920000,
      year5: 1300000,
      color: "text-indigo-700"
    },
    {
      name: "Tokenisation",
      description: "Frais de tokenisation des crédits carbone et biodiversité",
      year1: 140000,
      year2: 290000,
      year3: 580000,
      year4: 980000,
      year5: 1400000,
      color: "text-amber-700"
    },
    {
      name: "Services Conseil",
      description: "Conseil aux entreprises et gouvernements",
      year1: 120000,
      year2: 740000,
      year3: 1080000,
      year4: 1300000,
      year5: 2000000,
      color: "text-rose-700"
    }
  ];

  const totalsByYear = {
    year1: revenueSources.reduce((acc, src) => acc + src.year1, 0),
    year2: revenueSources.reduce((acc, src) => acc + src.year2, 0),
    year3: revenueSources.reduce((acc, src) => acc + src.year3, 0),
    year4: revenueSources.reduce((acc, src) => acc + src.year4, 0),
    year5: revenueSources.reduce((acc, src) => acc + src.year5, 0),
  };

  const formatPercent = (value: number) => {
    return `${value.toFixed(1)}%`;
  };

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(value);
  };

  const toggleSection = (section: string) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  // Calcul du ROI pour un investissement de 100 000 €
  const investmentAmount = 100000;
  const calculateInvestmentReturn = (scenario: any) => {
    return {
      year1: investmentAmount * (1 + scenario.year1 / 100),
      year2: investmentAmount * (1 + scenario.total / 100) * (scenario.year2 / scenario.total),
      year3: investmentAmount * (1 + scenario.total / 100) * (scenario.year3 / scenario.total),
      year4: investmentAmount * (1 + scenario.total / 100) * (scenario.year4 / scenario.total),
      year5: investmentAmount * (1 + scenario.total / 100),
    };
  };

  const baseReturns = calculateInvestmentReturn(scenarios.base);
  const conservativeReturns = calculateInvestmentReturn(scenarios.conservative);
  const optimisticReturns = calculateInvestmentReturn(scenarios.optimistic);

  return (
    <div className="space-y-10">
      <Card className="bg-white shadow-md overflow-hidden">
        <CardHeader className="bg-blue-50">
          <CardTitle className="text-xl text-blue-800 flex items-center">
            <TrendingUp className="mr-2 h-5 w-5" />
            Aperçu des Rendements pour les Investisseurs
          </CardTitle>
        </CardHeader>
        <CardContent className="pt-6">
          <div className="mb-6">
            <p className="text-gray-700 mb-4">
              GuTeco offre une opportunité d'investissement unique combinant les rendements des marchés carbone en pleine croissance et les revenus des activités DeFi. Notre modèle économique repose sur deux piliers fondamentaux:
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <div className="bg-green-50 p-4 rounded-lg border border-green-100">
                <h3 className="font-medium text-green-800 flex items-center mb-2">
                  <Leaf className="h-4 w-4 mr-2" />
                  Revenus Environnementaux
                </h3>
                <p className="text-sm text-gray-700">
                  Génération et vente de crédits carbone et biodiversité issus de projets de restauration de mangroves et de forêts, avec une efficacité 4-10× supérieure aux méthodes traditionnelles.
                </p>
              </div>
              <div className="bg-purple-50 p-4 rounded-lg border border-purple-100">
                <h3 className="font-medium text-purple-800 flex items-center mb-2">
                  <LineChart className="h-4 w-4 mr-2" />
                  Revenus Financiers (DeFi)
                </h3>
                <p className="text-sm text-gray-700">
                  Staking de tokens GTC, fourniture de liquidité, frais de tokenisation et services financiers décentralisés générant des revenus dès la première année.
                </p>
              </div>
            </div>
          </div>

          <div 
            className="bg-gray-50 p-4 mb-6 rounded-lg border border-gray-200 cursor-pointer"
            onClick={() => toggleSection('short')}
          >
            <div className="flex justify-between items-center">
              <h3 className="font-semibold text-gray-800 flex items-center">
                <Calculator className="h-5 w-5 mr-2 text-blue-700" />
                Résumé des Rendements sur 5 ans
              </h3>
              {expandedSection === 'short' ? <ChevronUp className="h-5 w-5" /> : <ChevronDown className="h-5 w-5" />}
            </div>
            
            {expandedSection === 'short' && (
              <div className="mt-4 overflow-x-auto">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead className="w-[150px]">Scénario</TableHead>
                      <TableHead className="text-right">ROI Total (5 ans)</TableHead>
                      <TableHead className="text-right">CAGR</TableHead>
                      <TableHead className="text-right">
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger className="flex items-center">
                              Multiple
                              <HelpCircle className="h-4 w-4 ml-1" />
                            </TooltipTrigger>
                            <TooltipContent>
                              <p className="max-w-xs text-sm">Le multiple correspond à combien de fois l'investissement initial est multiplié après 5 ans.</p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    <TableRow>
                      <TableCell className="font-medium text-amber-700">Conservateur</TableCell>
                      <TableCell className="text-right font-semibold">{formatPercent(scenarios.conservative.total)}</TableCell>
                      <TableCell className="text-right">{formatPercent(scenarios.conservative.cagr)}</TableCell>
                      <TableCell className="text-right">×{(1 + scenarios.conservative.total / 100).toFixed(2)}</TableCell>
                    </TableRow>
                    <TableRow className="bg-blue-50">
                      <TableCell className="font-medium text-blue-700">Base</TableCell>
                      <TableCell className="text-right font-semibold">{formatPercent(scenarios.base.total)}</TableCell>
                      <TableCell className="text-right">{formatPercent(scenarios.base.cagr)}</TableCell>
                      <TableCell className="text-right">×{(1 + scenarios.base.total / 100).toFixed(2)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="font-medium text-green-700">Optimiste</TableCell>
                      <TableCell className="text-right font-semibold">{formatPercent(scenarios.optimistic.total)}</TableCell>
                      <TableCell className="text-right">{formatPercent(scenarios.optimistic.cagr)}</TableCell>
                      <TableCell className="text-right">×{(1 + scenarios.optimistic.total / 100).toFixed(2)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <p className="text-sm text-gray-600 italic mt-2">
                  CAGR = Taux de Croissance Annuel Composé
                </p>
              </div>
            )}
          </div>
          
          <div 
            className="bg-gray-50 p-4 mb-6 rounded-lg border border-gray-200 cursor-pointer"
            onClick={() => toggleSection('detailed')}
          >
            <div className="flex justify-between items-center">
              <h3 className="font-semibold text-gray-800 flex items-center">
                <BarChart4 className="h-5 w-5 mr-2 text-blue-700" />
                Évolution Annuelle des Rendements
              </h3>
              {expandedSection === 'detailed' ? <ChevronUp className="h-5 w-5" /> : <ChevronDown className="h-5 w-5" />}
            </div>
            
            {expandedSection === 'detailed' && (
              <div className="mt-4">
                <Tabs defaultValue="percent" className="w-full">
                  <TabsList className="grid w-full grid-cols-2 mb-4">
                    <TabsTrigger value="percent">Pourcentage (%)</TabsTrigger>
                    <TabsTrigger value="amount">Montant (€)</TabsTrigger>
                  </TabsList>
                  
                  <TabsContent value="percent" className="overflow-x-auto">
                    <Table>
                      <TableHeader>
                        <TableRow>
                          <TableHead className="w-[150px]">Scénario</TableHead>
                          <TableHead className="text-right">Année 1</TableHead>
                          <TableHead className="text-right">Année 2</TableHead>
                          <TableHead className="text-right">Année 3</TableHead>
                          <TableHead className="text-right">Année 4</TableHead>
                          <TableHead className="text-right">Année 5</TableHead>
                          <TableHead className="text-right">Total</TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        <TableRow>
                          <TableCell className="font-medium text-amber-700">Conservateur</TableCell>
                          <TableCell className="text-right">{formatPercent(scenarios.conservative.year1)}</TableCell>
                          <TableCell className="text-right">{formatPercent(scenarios.conservative.year2)}</TableCell>
                          <TableCell className="text-right">{formatPercent(scenarios.conservative.year3)}</TableCell>
                          <TableCell className="text-right">{formatPercent(scenarios.conservative.year4)}</TableCell>
                          <TableCell className="text-right">{formatPercent(scenarios.conservative.year5)}</TableCell>
                          <TableCell className="text-right font-semibold">{formatPercent(scenarios.conservative.total)}</TableCell>
                        </TableRow>
                        <TableRow className="bg-blue-50">
                          <TableCell className="font-medium text-blue-700">Base</TableCell>
                          <TableCell className="text-right">{formatPercent(scenarios.base.year1)}</TableCell>
                          <TableCell className="text-right">{formatPercent(scenarios.base.year2)}</TableCell>
                          <TableCell className="text-right">{formatPercent(scenarios.base.year3)}</TableCell>
                          <TableCell className="text-right">{formatPercent(scenarios.base.year4)}</TableCell>
                          <TableCell className="text-right">{formatPercent(scenarios.base.year5)}</TableCell>
                          <TableCell className="text-right font-semibold">{formatPercent(scenarios.base.total)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="font-medium text-green-700">Optimiste</TableCell>
                          <TableCell className="text-right">{formatPercent(scenarios.optimistic.year1)}</TableCell>
                          <TableCell className="text-right">{formatPercent(scenarios.optimistic.year2)}</TableCell>
                          <TableCell className="text-right">{formatPercent(scenarios.optimistic.year3)}</TableCell>
                          <TableCell className="text-right">{formatPercent(scenarios.optimistic.year4)}</TableCell>
                          <TableCell className="text-right">{formatPercent(scenarios.optimistic.year5)}</TableCell>
                          <TableCell className="text-right font-semibold">{formatPercent(scenarios.optimistic.total)}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TabsContent>
                  
                  <TabsContent value="amount" className="overflow-x-auto">
                    <div className="mb-2 text-sm text-gray-600 italic">
                      Exemple pour un investissement de {formatCurrency(investmentAmount)}
                    </div>
                    <Table>
                      <TableHeader>
                        <TableRow>
                          <TableHead className="w-[150px]">Scénario</TableHead>
                          <TableHead className="text-right">Initial</TableHead>
                          <TableHead className="text-right">Année 1</TableHead>
                          <TableHead className="text-right">Année 2</TableHead>
                          <TableHead className="text-right">Année 3</TableHead>
                          <TableHead className="text-right">Année 4</TableHead>
                          <TableHead className="text-right">Année 5</TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        <TableRow>
                          <TableCell className="font-medium text-amber-700">Conservateur</TableCell>
                          <TableCell className="text-right">{formatCurrency(investmentAmount)}</TableCell>
                          <TableCell className="text-right">{formatCurrency(conservativeReturns.year1)}</TableCell>
                          <TableCell className="text-right">{formatCurrency(conservativeReturns.year2)}</TableCell>
                          <TableCell className="text-right">{formatCurrency(conservativeReturns.year3)}</TableCell>
                          <TableCell className="text-right">{formatCurrency(conservativeReturns.year4)}</TableCell>
                          <TableCell className="text-right font-semibold">{formatCurrency(conservativeReturns.year5)}</TableCell>
                        </TableRow>
                        <TableRow className="bg-blue-50">
                          <TableCell className="font-medium text-blue-700">Base</TableCell>
                          <TableCell className="text-right">{formatCurrency(investmentAmount)}</TableCell>
                          <TableCell className="text-right">{formatCurrency(baseReturns.year1)}</TableCell>
                          <TableCell className="text-right">{formatCurrency(baseReturns.year2)}</TableCell>
                          <TableCell className="text-right">{formatCurrency(baseReturns.year3)}</TableCell>
                          <TableCell className="text-right">{formatCurrency(baseReturns.year4)}</TableCell>
                          <TableCell className="text-right font-semibold">{formatCurrency(baseReturns.year5)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="font-medium text-green-700">Optimiste</TableCell>
                          <TableCell className="text-right">{formatCurrency(investmentAmount)}</TableCell>
                          <TableCell className="text-right">{formatCurrency(optimisticReturns.year1)}</TableCell>
                          <TableCell className="text-right">{formatCurrency(optimisticReturns.year2)}</TableCell>
                          <TableCell className="text-right">{formatCurrency(optimisticReturns.year3)}</TableCell>
                          <TableCell className="text-right">{formatCurrency(optimisticReturns.year4)}</TableCell>
                          <TableCell className="text-right font-semibold">{formatCurrency(optimisticReturns.year5)}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TabsContent>
                </Tabs>
              </div>
            )}
          </div>
          
          <div 
            className="bg-gray-50 p-4 mb-6 rounded-lg border border-gray-200 cursor-pointer"
            onClick={() => toggleSection('sources')}
          >
            <div className="flex justify-between items-center">
              <h3 className="font-semibold text-gray-800 flex items-center">
                <Briefcase className="h-5 w-5 mr-2 text-blue-700" />
                Sources de Revenus Détaillées
              </h3>
              {expandedSection === 'sources' ? <ChevronUp className="h-5 w-5" /> : <ChevronDown className="h-5 w-5" />}
            </div>
            
            {expandedSection === 'sources' && (
              <div className="mt-4 overflow-x-auto">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead className="w-[200px]">Source de Revenus</TableHead>
                      <TableHead className="text-right">Année 1</TableHead>
                      <TableHead className="text-right">Année 2</TableHead>
                      <TableHead className="text-right">Année 3</TableHead>
                      <TableHead className="text-right">Année 4</TableHead>
                      <TableHead className="text-right">Année 5</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {revenueSources.map((source, index) => (
                      <TableRow key={index}>
                        <TableCell className={`font-medium ${source.color}`}>
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger className="text-left">
                                {source.name}
                              </TooltipTrigger>
                              <TooltipContent>
                                <p className="max-w-xs text-sm">{source.description}</p>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </TableCell>
                        <TableCell className="text-right">{formatCurrency(source.year1)}</TableCell>
                        <TableCell className="text-right">{formatCurrency(source.year2)}</TableCell>
                        <TableCell className="text-right">{formatCurrency(source.year3)}</TableCell>
                        <TableCell className="text-right">{formatCurrency(source.year4)}</TableCell>
                        <TableCell className="text-right">{formatCurrency(source.year5)}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow className="bg-blue-50 font-bold">
                      <TableCell>TOTAL</TableCell>
                      <TableCell className="text-right">{formatCurrency(totalsByYear.year1)}</TableCell>
                      <TableCell className="text-right">{formatCurrency(totalsByYear.year2)}</TableCell>
                      <TableCell className="text-right">{formatCurrency(totalsByYear.year3)}</TableCell>
                      <TableCell className="text-right">{formatCurrency(totalsByYear.year4)}</TableCell>
                      <TableCell className="text-right">{formatCurrency(totalsByYear.year5)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            )}
          </div>
          
          <div 
            className="bg-gray-50 p-4 mb-6 rounded-lg border border-gray-200 cursor-pointer"
            onClick={() => toggleSection('assumptions')}
          >
            <div className="flex justify-between items-center">
              <h3 className="font-semibold text-gray-800 flex items-center">
                <Clock className="h-5 w-5 mr-2 text-blue-700" />
                Hypothèses et Facteurs de Risque
              </h3>
              {expandedSection === 'assumptions' ? <ChevronUp className="h-5 w-5" /> : <ChevronDown className="h-5 w-5" />}
            </div>
            
            {expandedSection === 'assumptions' && (
              <div className="mt-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <h4 className="font-medium text-blue-800 mb-2">Facteurs de succès</h4>
                    <ul className="space-y-2">
                      <li className="flex items-start">
                        <ChevronUp className="h-4 w-4 text-green-600 mt-1 mr-2 flex-shrink-0" />
                        <span className="text-sm">Croissance du marché des crédits carbone de 30% par an</span>
                      </li>
                      <li className="flex items-start">
                        <ChevronUp className="h-4 w-4 text-green-600 mt-1 mr-2 flex-shrink-0" />
                        <span className="text-sm">Efficacité de captation carbone des mangroves 4-10× supérieure aux forêts</span>
                      </li>
                      <li className="flex items-start">
                        <ChevronUp className="h-4 w-4 text-green-600 mt-1 mr-2 flex-shrink-0" />
                        <span className="text-sm">Partenariats gouvernementaux et ONG déjà sécurisés</span>
                      </li>
                      <li className="flex items-start">
                        <ChevronUp className="h-4 w-4 text-green-600 mt-1 mr-2 flex-shrink-0" />
                        <span className="text-sm">Double source de revenus (environnementaux + DeFi)</span>
                      </li>
                      <li className="flex items-start">
                        <ChevronUp className="h-4 w-4 text-green-600 mt-1 mr-2 flex-shrink-0" />
                        <span className="text-sm">Prix du crédit carbone en augmentation (20-60€ d'ici 2030)</span>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h4 className="font-medium text-rose-800 mb-2">Facteurs de risque</h4>
                    <ul className="space-y-2">
                      <li className="flex items-start">
                        <ChevronDown className="h-4 w-4 text-rose-600 mt-1 mr-2 flex-shrink-0" />
                        <span className="text-sm">Volatilité potentielle du marché des cryptomonnaies</span>
                      </li>
                      <li className="flex items-start">
                        <ChevronDown className="h-4 w-4 text-rose-600 mt-1 mr-2 flex-shrink-0" />
                        <span className="text-sm">Évolutions réglementaires sur les marchés carbone et crypto</span>
                      </li>
                      <li className="flex items-start">
                        <ChevronDown className="h-4 w-4 text-rose-600 mt-1 mr-2 flex-shrink-0" />
                        <span className="text-sm">Délais potentiels dans la certification des crédits carbone</span>
                      </li>
                      <li className="flex items-start">
                        <ChevronDown className="h-4 w-4 text-rose-600 mt-1 mr-2 flex-shrink-0" />
                        <span className="text-sm">Risques climatiques locaux (cyclones, inondations)</span>
                      </li>
                      <li className="flex items-start">
                        <ChevronDown className="h-4 w-4 text-rose-600 mt-1 mr-2 flex-shrink-0" />
                        <span className="text-sm">Instabilité politique dans certains pays d'implantation</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="bg-amber-50 p-3 rounded-lg border border-amber-200 mt-4">
                  <p className="text-sm text-amber-800">
                    <strong>Note importante:</strong> Les projections financières présentées sont des estimations basées sur nos analyses de marché et modèles financiers. Les rendements réels peuvent varier en fonction de nombreux facteurs économiques et environnementaux. Nous recommandons aux investisseurs potentiels d'effectuer leur propre analyse de risque.
                  </p>
                </div>
              </div>
            )}
          </div>

          <div className="flex flex-col sm:flex-row items-center justify-center gap-4 mt-8">
            <Button 
              onClick={() => navigate('/investor-pitch')}
              className="bg-brand-600 hover:bg-brand-700 flex items-center gap-2"
            >
              <TrendingUp className="h-4 w-4" />
              Consulter le pitch deck complet
            </Button>
            <Button 
              onClick={() => navigate('/valuation')}
              variant="outline"
              className="border-brand-600 text-brand-600 hover:bg-brand-50 flex items-center gap-2"
            >
              <BarChart4 className="h-4 w-4" />
              Analyse détaillée de valorisation
            </Button>
          </div>
        </CardContent>
      </Card>
      
      <Card className="bg-white shadow-md">
        <CardHeader className="bg-green-50">
          <CardTitle className="text-xl text-green-800 flex items-center">
            <Leaf className="mr-2 h-5 w-5" />
            Comparaison avec d'Autres Classes d'Actifs
          </CardTitle>
        </CardHeader>
        <CardContent className="pt-6">
          <p className="text-gray-700 mb-4">
            Les rendements projetés de GuTeco se comparent favorablement à d'autres classes d'actifs, offrant un potentiel de croissance supérieur avec un impact environnemental positif mesurable.
          </p>
          <div className="overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[200px]">Classe d'actifs</TableHead>
                  <TableHead className="text-right">ROI annuel moyen</TableHead>
                  <TableHead className="text-right">Impact environnemental</TableHead>
                  <TableHead className="text-right">Liquidité</TableHead>
                  <TableHead className="text-right">Volatilité</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow className="bg-brand-50">
                  <TableCell className="font-medium text-brand-700">GuTeco (scénario Base)</TableCell>
                  <TableCell className="text-right font-semibold">{formatPercent(scenarios.base.cagr)}</TableCell>
                  <TableCell className="text-right">Très élevé (positif)</TableCell>
                  <TableCell className="text-right">Moyenne</TableCell>
                  <TableCell className="text-right">Moyenne</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-medium">Actions (S&P 500)</TableCell>
                  <TableCell className="text-right">8-10%</TableCell>
                  <TableCell className="text-right">Variable</TableCell>
                  <TableCell className="text-right">Élevée</TableCell>
                  <TableCell className="text-right">Moyenne</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-medium">Obligations d'État</TableCell>
                  <TableCell className="text-right">1-3%</TableCell>
                  <TableCell className="text-right">Aucun</TableCell>
                  <TableCell className="text-right">Moyenne</TableCell>
                  <TableCell className="text-right">Faible</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-medium">Immobilier</TableCell>
                  <TableCell className="text-right">5-8%</TableCell>
                  <TableCell className="text-right">Négatif</TableCell>
                  <TableCell className="text-right">Faible</TableCell>
                  <TableCell className="text-right">Moyenne</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-medium">Crypto (BTC/ETH)</TableCell>
                  <TableCell className="text-right">15-30%</TableCell>
                  <TableCell className="text-right">Négatif</TableCell>
                  <TableCell className="text-right">Élevée</TableCell>
                  <TableCell className="text-right">Très élevée</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-medium">Capital-risque</TableCell>
                  <TableCell className="text-right">15-25%</TableCell>
                  <TableCell className="text-right">Variable</TableCell>
                  <TableCell className="text-right">Très faible</TableCell>
                  <TableCell className="text-right">Très élevée</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-medium">Obligations vertes</TableCell>
                  <TableCell className="text-right">2-4%</TableCell>
                  <TableCell className="text-right">Moyen (positif)</TableCell>
                  <TableCell className="text-right">Moyenne</TableCell>
                  <TableCell className="text-right">Faible</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <p className="text-sm text-gray-600 italic mt-3">
            * Les rendements historiques ne garantissent pas les performances futures. Le ROI annuel moyen est basé sur les données historiques des 10 dernières années.
          </p>
        </CardContent>
      </Card>

      <div className="flex justify-center">
        <Button 
          onClick={() => navigate('/investor-strategy')}
          className="bg-blue-600 hover:bg-blue-700"
        >
          Découvrir notre stratégie pour attirer les investisseurs
        </Button>
      </div>
    </div>
  );
};

export default ExpectedReturns;

