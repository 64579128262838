
import React from 'react';
import { Layout } from '@/components/Layout';
import AIInfluencer from '@/components/AIInfluencer';
import SEOHeaders from '@/components/SEOHeaders';

const AIInfluencerPage = () => {
  return (
    <Layout>
      <SEOHeaders 
        title="AI Influenceur | GuTeco - Tokenised Carbon Credits"
        description="Utilisez notre AI Influenceur pour promouvoir le projet GuTeco sur les réseaux sociaux avec du contenu optimisé."
        canonicalPath="/ai-influencer"
        robots="index, follow"
      />
      <div className="header-spacing pt-16 md:pt-20"></div>
      <AIInfluencer />
    </Layout>
  );
};

export default AIInfluencerPage;
