
import React, { createContext, useContext, useState, useEffect } from 'react';
import { toast } from "@/hooks/use-toast";

// Types plus détaillés pour mieux gérer l'authentification
type User = {
  id: string;
  name: string;
  email: string;
  lastActive?: string;
  sessionToken?: string;
  securityLevel?: string; // Niveau de sécurité de l'utilisateur
  lastIp?: string; // Dernière IP connue
};

type AuthContextType = {
  user: User | null;
  isAuthenticated: boolean;
  login: (user: User) => void;
  logout: () => void;
  refreshSession: () => void;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Constantes de sécurité
const SESSION_KEY = 'guteco_user';
const SESSION_DURATION = 3600000; // 1 heure en millisecondes
const MAX_FAILED_ATTEMPTS = 5; // Nombre maximum de tentatives échouées
const LOCKOUT_DURATION = 15 * 60 * 1000; // 15 minutes en millisecondes

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [failedAttempts, setFailedAttempts] = useState<number>(0);
  const [lockoutUntil, setLockoutUntil] = useState<number | null>(null);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  // Vérifier si une adresse IP est suspecte (simplifiée pour la démonstration)
  const isIpSuspicious = (ip: string): boolean => {
    // Liste des motifs d'IP suspects (exemple simplifié)
    const suspiciousPatterns = [
      /^192\.168\./ // Adresses IP locales - à titre d'exemple uniquement
    ];
    
    return suspiciousPatterns.some(pattern => pattern.test(ip));
  };

  // Vérification de la session utilisateur avec validation améliorée
  useEffect(() => {
    const validateAndLoadUser = () => {
      try {
        // Vérifier si l'utilisateur est en période de verrouillage
        const storedLockout = localStorage.getItem('auth_lockout');
        if (storedLockout) {
          const lockoutTime = parseInt(storedLockout);
          if (Date.now() < lockoutTime) {
            setLockoutUntil(lockoutTime);
            setIsInitialized(true);
            return;
          } else {
            // Le verrouillage est expiré
            localStorage.removeItem('auth_lockout');
          }
        }
        
        if (lockoutUntil && Date.now() < lockoutUntil) {
          console.warn("Compte temporairement verrouillé suite à trop de tentatives échouées");
          setIsInitialized(true);
          return;
        }
        
        let storedSession = null;
        try {
          storedSession = localStorage.getItem(SESSION_KEY);
        } catch (storageError) {
          console.warn("Erreur d'accès au stockage local:", storageError);
          // En cas d'erreur de stockage, on continue sans session
        }
        
        if (!storedSession) {
          setIsInitialized(true);
          return;
        }

        try {
          const parsedData = JSON.parse(storedSession);
          
          // Validation des données
          if (!parsedData || typeof parsedData !== 'object' || !parsedData.id) {
            incrementFailedAttempt();
            throw new Error('Invalid session data');
          }
          
          // Vérification de l'expiration de la session
          const currentTime = new Date().getTime();
          const lastActive = parsedData.lastActive ? parseInt(parsedData.lastActive) : 0;
          
          if (currentTime - lastActive > SESSION_DURATION) {
            // Session expirée
            try {
              localStorage.removeItem(SESSION_KEY);
            } catch (error) {
              console.warn("Erreur lors de la suppression de session expirée:", error);
            }
            throw new Error('Session expired');
          }
          
          // Vérification de changement d'IP (simplifiée)
          const currentIp = "127.0.0.1"; // En production, il faudrait obtenir l'IP réelle
          if (parsedData.lastIp && parsedData.lastIp !== currentIp) {
            // Changement d'IP détecté, on pourrait demander une vérification supplémentaire
            console.warn("Changement d'adresse IP détecté, vérification supplémentaire recommandée");
            
            if (isIpSuspicious(currentIp)) {
              incrementFailedAttempt();
              throw new Error('Suspicious IP detected');
            }
          }

          // Mise à jour du timestamp d'activité et de l'IP
          parsedData.lastActive = currentTime.toString();
          parsedData.lastIp = currentIp;
          try {
            localStorage.setItem(SESSION_KEY, JSON.stringify(parsedData));
          } catch (error) {
            console.warn("Erreur lors de la mise à jour de session:", error);
          }
          
          setUser(parsedData);
          setIsAuthenticated(true);
          setFailedAttempts(0); // Réinitialiser les tentatives échouées après un succès
        } catch (parseError) {
          console.error("Erreur d'analyse des données de session:", parseError);
          try {
            localStorage.removeItem(SESSION_KEY);
          } catch (error) {
            console.warn("Erreur lors de la suppression de session invalide:", error);
          }
        }
      } catch (error) {
        console.error('Session validation error:', error);
        try {
          localStorage.removeItem(SESSION_KEY);
        } catch (removeError) {
          console.warn("Erreur lors de la suppression de session:", removeError);
        }
        setUser(null);
        setIsAuthenticated(false);
        
        // Ne pas afficher de toast si l'utilisateur n'était pas authentifié
        if (isAuthenticated) {
          toast({
            title: "Session terminée",
            description: "Votre session a expiré ou est invalide. Veuillez vous reconnecter.",
            variant: "warning"
          });
        }
      } finally {
        setIsInitialized(true);
      }
    };
    
    validateAndLoadUser();

    // Vérification périodique de la validité de session
    const sessionCheckInterval = setInterval(validateAndLoadUser, 300000); // Vérification toutes les 5 minutes
    
    return () => clearInterval(sessionCheckInterval);
  }, [isAuthenticated, failedAttempts, lockoutUntil]);

  // Incrémenter le compteur de tentatives échouées et verrouiller si nécessaire
  const incrementFailedAttempt = () => {
    const newCount = failedAttempts + 1;
    setFailedAttempts(newCount);
    
    if (newCount >= MAX_FAILED_ATTEMPTS) {
      const lockoutTime = Date.now() + LOCKOUT_DURATION;
      setLockoutUntil(lockoutTime);
      
      toast({
        title: "Compte temporairement verrouillé",
        description: "Trop de tentatives échouées. Veuillez réessayer dans 15 minutes.",
        variant: "destructive"
      });
      
      // Enregistrer le verrouillage dans le stockage local
      try {
        localStorage.setItem('auth_lockout', lockoutTime.toString());
      } catch (error) {
        console.warn("Erreur lors de l'enregistrement du verrouillage:", error);
      }
      
      // Réinitialiser après la période de verrouillage
      setTimeout(() => {
        setFailedAttempts(0);
        setLockoutUntil(null);
        try {
          localStorage.removeItem('auth_lockout');
        } catch (error) {
          console.warn("Erreur lors de la suppression du verrouillage:", error);
        }
      }, LOCKOUT_DURATION);
    }
  };

  const login = (userData: User) => {
    try {
      // Vérifier si l'utilisateur est en période de verrouillage
      if (lockoutUntil && Date.now() < lockoutUntil) {
        toast({
          title: "Connexion impossible",
          description: "Votre compte est temporairement verrouillé. Veuillez réessayer plus tard.",
          variant: "destructive"
        });
        return;
      }
      
      // Vérification de données suspectes
      if (!userData.email || !userData.id) {
        incrementFailedAttempt();
        throw new Error('Données d\'utilisateur invalides');
      }
      
      // Vérification d'adresse email (simplifié)
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(userData.email)) {
        incrementFailedAttempt();
        throw new Error('Format d\'email invalide');
      }
      
      // Ajout de métadonnées de sécurité
      const currentIp = "127.0.0.1"; // En production, il faudrait obtenir l'IP réelle
      
      const enhancedUserData = {
        ...userData,
        lastActive: new Date().getTime().toString(),
        sessionToken: generateSessionToken(),
        lastIp: currentIp,
        securityLevel: "standard" // Niveau de sécurité par défaut
      };
      
      setUser(enhancedUserData);
      setIsAuthenticated(true);
      setFailedAttempts(0); // Réinitialiser les tentatives échouées
      
      try {
        localStorage.setItem(SESSION_KEY, JSON.stringify(enhancedUserData));
      } catch (error) {
        console.warn("Erreur lors de l'enregistrement de la session:", error);
        // On continue même en cas d'erreur de stockage
      }
      
      console.log('Utilisateur connecté:', userData.email);
      toast({
        title: "Succès",
        description: "Vous êtes maintenant connecté",
        variant: "success"
      });
    } catch (error) {
      console.error('Erreur de connexion:', error);
      toast({
        title: "Erreur",
        description: "Une erreur est survenue lors de la connexion",
        variant: "destructive"
      });
    }
  };

  const logout = () => {
    try {
      setUser(null);
      setIsAuthenticated(false);
      try {
        localStorage.removeItem(SESSION_KEY);
      } catch (error) {
        console.warn("Erreur lors de la déconnexion:", error);
      }
      
      console.log('Utilisateur déconnecté');
      toast({
        title: "Information",
        description: "Vous avez été déconnecté",
        variant: "info"
      });
    } catch (error) {
      console.error('Erreur de déconnexion:', error);
    }
  };

  // Fonction pour rafraîchir la session
  const refreshSession = () => {
    if (!user) return;
    
    try {
      const currentIp = "127.0.0.1"; // En production, il faudrait obtenir l'IP réelle
      
      const refreshedUser = {
        ...user,
        lastActive: new Date().getTime().toString(),
        lastIp: currentIp
      };
      
      setUser(refreshedUser);
      try {
        localStorage.setItem(SESSION_KEY, JSON.stringify(refreshedUser));
      } catch (error) {
        console.warn("Erreur lors du rafraîchissement de session:", error);
      }
    } catch (error) {
      console.error('Erreur de rafraîchissement de session:', error);
    }
  };

  // Fonction pour générer un token de session unique avec entropie accrue
  const generateSessionToken = (): string => {
    const entropy = [
      Math.random().toString(36).substring(2, 15),
      Math.random().toString(36).substring(2, 15), 
      Date.now().toString(36)
    ].join('_');
    
    return 'st_' + entropy;
  };

  // Si l'initialisation n'est pas terminée, on affiche un fallback ou rien du tout
  if (!isInitialized) {
    return <>{children}</>; // Rendre les enfants sans contexte pendant l'initialisation
  }

  return (
    <AuthContext.Provider value={{ 
      user, 
      isAuthenticated, 
      login, 
      logout,
      refreshSession
    }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook personnalisé avec gestion d'erreur améliorée
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth doit être utilisé avec un AuthProvider');
  }
  
  // Rafraîchir la session à chaque utilisation du hook, mais de manière sécurisée
  if (context.isAuthenticated) {
    try {
      context.refreshSession();
    } catch (error) {
      console.warn("Erreur lors du rafraîchissement de session via hook:", error);
    }
  }
  
  return context;
};
