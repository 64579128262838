
import React, { useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Layout } from "@/components/Layout";
import { Button } from "@/components/ui/button";
import { AlertCircle, ExternalLink, RefreshCw, Home, ArrowLeft } from "lucide-react";
import { toast } from "@/hooks/use-toast";
import SEOHeaders from "@/components/SEOHeaders";
import { useLanguage } from "@/contexts/LanguageContext";

const NotFound = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const hostname = window.location.hostname;
  const pathname = location.pathname;
  const fullPath = `${window.location.protocol}//${hostname}${pathname}`;
  const { t } = useLanguage();

  useEffect(() => {
    // Log path information for debugging
    console.log("404 Page Information:");
    console.log("- Current location pathname:", location.pathname);
    console.log("- Current URL:", currentUrl);
    console.log("- Hostname:", hostname);
    console.log("- Full URL path constructed:", fullPath);
    
    toast({
      title: "Page non trouvée",
      description: `La page ${location.pathname} n'existe pas.`,
      variant: "destructive",
    });
  }, [location.pathname, currentUrl, hostname, fullPath]);

  const handleRefresh = () => {
    window.location.reload();
  };

  // Schema for structured data
  const pageSchema = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [{
      "@type": "ListItem",
      "position": 1,
      "name": "Accueil",
      "item": `${window.location.protocol}//${hostname}`
    }, {
      "@type": "ListItem",
      "position": 2,
      "name": "Page non trouvée",
      "item": currentUrl
    }]
  };

  return (
    <Layout>
      <SEOHeaders 
        title="Page non trouvée | GuTeco - Tokenised Carbon Credits"
        description="Page non trouvée sur GuTeco - Revenez à l'accueil pour découvrir nos solutions de crédits carbone tokenisés"
        canonicalPath={pathname}
        robots="noindex, follow"
        schema={pageSchema}
      />
      
      <div className="flex flex-col items-center justify-center py-20 px-4" itemScope itemType="https://schema.org/WebPage">
        <div className="bg-red-50 p-4 rounded-full mb-6">
          <AlertCircle className="h-16 w-16 text-red-500" />
        </div>
        <h1 className="text-9xl font-bold text-gray-200">404</h1>
        <h2 className="text-3xl font-bold text-gray-800 mt-4 mb-6 text-center" itemProp="name">Page non trouvée</h2>
        <p className="text-gray-600 mb-4 text-center max-w-md" itemProp="description">
          Nous ne pouvons pas trouver la page que vous recherchez. Elle a peut-être été déplacée ou supprimée.
        </p>
        
        <div className="bg-gray-50 p-4 rounded-lg border border-gray-200 mb-8 w-full max-w-md">
          <h3 className="text-sm font-medium text-gray-500 mb-2">Informations techniques</h3>
          <p className="text-sm text-gray-800 mb-2 break-all">
            <span className="font-medium">URL complète:</span> {currentUrl}
          </p>
          <p className="text-sm text-gray-800 mb-2">
            <span className="font-medium">Chemin:</span> {pathname}
          </p>
          <p className="text-sm text-gray-800 flex items-center">
            <span className="font-medium mr-1">Domaine:</span> 
            <a 
              href={`${window.location.protocol}//${hostname}`}
              className="text-brand-600 hover:underline flex items-center"
              target="_blank"
              rel="noopener noreferrer"
            >
              {hostname} <ExternalLink className="h-3 w-3 ml-1" />
            </a>
          </p>
        </div>
        
        <div className="mb-8 max-w-md">
          <h3 className="text-lg font-semibold mb-3">Pages populaires</h3>
          <ul className="space-y-2">
            <li>
              <Link to="/" className="text-brand-600 hover:underline flex items-center">
                <ArrowLeft className="h-3 w-3 mr-1" /> Page d'accueil
              </Link>
            </li>
            <li>
              <Link to="/projects" className="text-brand-600 hover:underline flex items-center">
                <ArrowLeft className="h-3 w-3 mr-1" /> Projets de crédits carbone
              </Link>
            </li>
            <li>
              <Link to="/simulator" className="text-brand-600 hover:underline flex items-center">
                <ArrowLeft className="h-3 w-3 mr-1" /> Simulateur de compensation
              </Link>
            </li>
            <li>
              <Link to="/token-economics" className="text-brand-600 hover:underline flex items-center">
                <ArrowLeft className="h-3 w-3 mr-1" /> Économie des tokens
              </Link>
            </li>
            <li>
              <Link to="/ai-influencer" className="text-brand-600 hover:underline flex items-center">
                <ArrowLeft className="h-3 w-3 mr-1" /> AI Influenceur
              </Link>
            </li>
          </ul>
        </div>
        
        <div className="flex flex-col sm:flex-row gap-4">
          <Button onClick={() => navigate('/')} size="lg" variant="default" className="gap-2">
            <Home className="h-4 w-4" />
            Retour à l'accueil
          </Button>
          <Button onClick={() => window.history.back()} size="lg" variant="outline" className="gap-2">
            Page précédente
          </Button>
          <Button onClick={handleRefresh} size="lg" variant="secondary" className="gap-2">
            <RefreshCw className="h-4 w-4" />
            Actualiser la page
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default NotFound;
