
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useLanguage } from '@/contexts/LanguageContext';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const [, forceUpdate] = useState({});
  const { t, language } = useLanguage();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 10);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    const handleLanguageChange = () => {
      forceUpdate({});
    };
    
    window.addEventListener('languageChanged', handleLanguageChange);
    return () => window.removeEventListener('languageChanged', handleLanguageChange);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const headerClass = cn(
    'fixed top-0 left-0 right-0 z-50 transition-all duration-300',
    scrolled ? 'bg-white shadow-md py-2' : 'bg-transparent py-4'
  );

  const navLinks = [
    { name: t('header.home'), path: '/' },
    { name: t('header.projects'), path: '/projects' },
    { name: t('header.team'), path: '/team' },
    { name: t('header.simulator'), path: '/simulator' },
    { name: t('header.tokenEconomics'), path: '/token-economics' },
    { name: t('header.vcarbs'), path: '/vcarbs' },
    { name: t('header.valuation'), path: '/valuation' },
    { name: t('header.dao'), path: '/dao' },
    { name: t('header.community'), path: '/community' },
    { name: t('header.returns'), path: '/expected-returns' },
  ];

  return (
    <header className={headerClass}>
      <div className="container mx-auto px-4 flex justify-between items-center">
        {/* Logo */}
        <Link to="/" className="flex items-center">
          <span className="text-2xl font-bold text-brand-600">GuTeco</span>
        </Link>

        {/* Desktop navigation */}
        <nav className="hidden md:flex items-center space-x-6">
          {navLinks.map((link) => (
            <Link
              key={link.path}
              to={link.path}
              className={cn(
                'text-sm font-medium transition-colors hover:text-brand-500',
                location.pathname === link.path
                  ? 'text-brand-600'
                  : scrolled
                  ? 'text-gray-700'
                  : 'text-gray-800'
              )}
            >
              {link.name}
            </Link>
          ))}
        </nav>

        {/* Mobile menu toggle button */}
        <button 
          onClick={toggleMenu} 
          aria-label="Toggle menu" 
          className="md:hidden flex items-center gap-2 py-2 px-3"
        >
          <span className="text-sm font-medium">Menu</span>
          {isMenuOpen ? (
            <X className="h-6 w-6 font-bold text-brand-600" />
          ) : (
            <Menu className="h-6 w-6 font-bold text-brand-600" strokeWidth={3} />
          )}
        </button>
      </div>

      {/* Mobile menu dropdown */}
      {isMenuOpen && (
        <div className="md:hidden bg-white shadow-lg">
          <nav className="container mx-auto px-4 py-4 flex flex-col space-y-4">
            {navLinks.map((link) => (
              <Link
                key={link.path}
                to={link.path}
                className={cn(
                  'text-base font-medium py-2 px-4 rounded-md hover:bg-gray-100',
                  location.pathname === link.path
                    ? 'text-brand-600 bg-gray-100'
                    : 'text-gray-800'
                )}
              >
                {link.name}
              </Link>
            ))}
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;
