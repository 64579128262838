import React, { useEffect } from 'react';
import { Layout } from '@/components/Layout';
import Hero from '@/components/Hero';
import Features from '@/components/Features';
import TokenInfo from '@/components/TokenInfo';
import CarbonNFT from '@/components/CarbonNFT';
import ProjectEvolution from '@/components/ProjectEvolution';
import ExpectedReturns from '@/components/ExpectedReturns';
import TokenEconomics from '@/components/TokenEconomics';
import FAQ from '@/components/FAQ';
import Roadmap from '@/components/Roadmap';
import InvestorPitchDeck from '@/components/InvestorPitchDeck';
import InvestorStrategy from '@/components/InvestorStrategy';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { FileText, TrendingUp, ArrowRight, BarChart3 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useIsMobile } from '@/hooks/use-mobile';

const Index = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  
  useEffect(() => {
    console.log("Page d'accueil chargée");
    document.title = "GuTeco - Tokenised Carbon Credits";
    
    // Force visibility of main content
    const root = document.getElementById('root');
    if (root) {
      root.style.display = 'block';
    }
    
    // Debug pour vérifier si la page s'affiche correctement
    console.log("Index.tsx rendu avec succès");
    
    // Force page to be visible
    document.body.style.display = 'block';
    document.documentElement.style.visibility = 'visible';
    
    // Add extra padding at the top of the page to prevent header overlap
    const mainContent = document.querySelector('main');
    if (mainContent) {
      if (isMobile) {
        mainContent.style.paddingTop = '4rem'; // Extra padding for mobile
      } else {
        mainContent.style.paddingTop = '2rem'; // Standard padding for desktop
      }
    }
    
    // Fix mobile display issues with charts and text
    if (isMobile) {
      // Move the function inside useEffect to avoid invalid hook calls
      const addMobileOptimizations = () => {
        try {
          // Add extra spacing to chart containers
          const chartContainers = document.querySelectorAll('.recharts-wrapper');
          chartContainers.forEach(container => {
            const parent = container.parentElement;
            if (parent) {
              parent.style.marginBottom = '2rem';
              parent.style.paddingBottom = '1rem';
            }
          });
          
          // Ensure headers have enough space
          const headers = document.querySelectorAll('h3');
          headers.forEach(header => {
            header.style.marginBottom = '1.5rem';
            header.style.marginTop = '2rem';
          });
          
          console.log("Mobile optimizations applied successfully");
        } catch (error) {
          console.error("Error applying mobile optimizations:", error);
        }
      };
      
      // Apply optimizations after a short delay to ensure DOM is ready
      setTimeout(addMobileOptimizations, 500);
    }
  }, [isMobile, navigate]);

  return (
    <Layout>
      {/* Add an empty div with padding to create space after the header */}
      <div className="header-spacing pt-16 md:pt-20"></div>
      
      <Hero />
      <Features />
      <TokenInfo />
      <CarbonNFT credits={100} id="demo-nft" projectName="Projet Démo Guteco" />
      <ProjectEvolution />
      <ExpectedReturns />
      
      {/* Bouton vers la page détaillée des rendements */}
      <div className="text-center my-6 md:my-8">
        <Button 
          onClick={() => navigate('/expected-returns')}
          className="bg-brand-600 hover:bg-brand-700 flex items-center gap-2"
        >
          <BarChart3 className="h-4 w-4" />
          <span className="text-sm sm:text-base">Voir l'analyse détaillée des rendements attendus</span>
        </Button>
      </div>
      
      <TokenEconomics />
      
      {/* Section Stratégie pour Attirer les Investisseurs - Mise en avant */}
      <div id="investor-strategy-anchor" className="py-4 bg-gray-100">
        <div className="container mx-auto px-4">
          <div className="text-center">
            <h2 className="text-2xl md:text-3xl font-bold text-brand-800 mb-2">Investisseurs</h2>
            <p className="text-gray-600 mb-0">Découvrez notre stratégie d'attraction des investisseurs</p>
          </div>
        </div>
      </div>
      
      <InvestorStrategy />
      
      {/* Documents Investisseurs Section */}
      <section className="py-10 md:py-16 bg-gradient-to-b from-white to-gray-50">
        <div className="container mx-auto px-3 sm:px-4">
          <div className="text-center mb-8 md:mb-10">
            <h2 className="text-2xl md:text-3xl font-bold text-brand-800 mb-3 md:mb-4">Documents pour Investisseurs</h2>
            <p className="text-gray-600 max-w-3xl mx-auto text-sm md:text-base">
              Accédez à nos documents détaillés pour les investisseurs, incluant notre mémorandum d'investissement et notre pitch deck.
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 max-w-4xl mx-auto">
            <Card className="border-2 border-brand-100 hover:border-brand-300 transition-all shadow-sm hover:shadow-md">
              <CardHeader className="pb-3 md:pb-4">
                <CardTitle className="flex items-center gap-2 text-brand-700 text-lg md:text-xl">
                  <FileText className="h-5 w-5" />
                  Mémorandum d'Investissement
                </CardTitle>
                <CardDescription className="text-xs md:text-sm">
                  Document détaillé présentant notre opportunité d'investissement, modèle économique et projections financières.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <ul className="space-y-1 md:space-y-2 text-xs md:text-sm">
                  <li className="flex items-start gap-2">
                    <ArrowRight className="h-3 w-3 md:h-4 md:w-4 text-brand-500 mt-0.5 shrink-0" />
                    <span>Valorisation pre-money de 90M€</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <ArrowRight className="h-3 w-3 md:h-4 md:w-4 text-brand-500 mt-0.5 shrink-0" />
                    <span>Levée de fonds de 12M€ pour 11,76% d'équité</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <ArrowRight className="h-3 w-3 md:h-4 md:w-4 text-brand-500 mt-0.5 shrink-0" />
                    <span>Revenus estimés de 8,4M€ sur 2 ans (prévisionnel)</span>
                  </li>
                </ul>
              </CardContent>
              <CardFooter>
                <Button 
                  className="w-full text-xs md:text-sm"
                  onClick={() => navigate('/investment-memorandum')}
                >
                  Consulter le Mémorandum
                </Button>
              </CardFooter>
            </Card>
            
            <Card className="border-2 border-brand-100 hover:border-brand-300 transition-all shadow-sm hover:shadow-md">
              <CardHeader className="pb-3 md:pb-4">
                <CardTitle className="flex items-center gap-2 text-brand-700 text-lg md:text-xl">
                  <TrendingUp className="h-5 w-5" />
                  Pitch Deck Investisseurs
                </CardTitle>
                <CardDescription className="text-xs md:text-sm">
                  Présentation synthétique de Guteco pour les investisseurs, avec aperçu de notre proposition de valeur.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <ul className="space-y-1 md:space-y-2 text-xs md:text-sm">
                  <li className="flex items-start gap-2">
                    <ArrowRight className="h-3 w-3 md:h-4 md:w-4 text-brand-500 mt-0.5 shrink-0" />
                    <span>Marché cible et opportunité</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <ArrowRight className="h-3 w-3 md:h-4 md:w-4 text-brand-500 mt-0.5 shrink-0" />
                    <span>Solution et avantages compétitifs</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <ArrowRight className="h-3 w-3 md:h-4 md:w-4 text-brand-500 mt-0.5 shrink-0" />
                    <span>Modèle économique et tokenomics</span>
                  </li>
                </ul>
              </CardContent>
              <CardFooter>
                <Button 
                  className="w-full text-xs md:text-sm"
                  onClick={() => navigate('/dao-pitch')}
                >
                  Voir le Pitch Deck
                </Button>
              </CardFooter>
            </Card>
          </div>
        </div>
      </section>
      
      <InvestorPitchDeck />
      <Roadmap />
      <FAQ />
    </Layout>
  );
};

export default Index;
