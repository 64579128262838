import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Leaf, Sprout, GraduationCap, BarChart3, FileText, TrendingUp, CreditCard, Calendar, Search, Briefcase } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Checkbox } from '@/components/ui/checkbox';
import { toast } from '@/hooks/use-toast';

const Hero = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showSignupDialog, setShowSignupDialog] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    acceptTerms: false
  });
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    console.log('Hero component mounted at path:', location.pathname);
    console.log('Full URL:', window.location.href);
    
    const urlDebug = document.getElementById('url-debug-info');
    if (urlDebug) {
      urlDebug.textContent = 'URL: ' + window.location.href;
    }
    
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.textContent = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "GuTeco",
      "url": "https://guteco.com",
      "logo": "https://guteco.com/favicon.ico",
      "description": "Premier marketplace de crédits carbone tokenisés pour un futur durable. Solutions pour entreprises, particuliers et investisseurs.",
      "sameAs": [
        "https://twitter.com/guteco",
        "https://linkedin.com/company/guteco"
      ],
      "offers": {
        "@type": "Offer",
        "name": "Crédits carbone tokenisés",
        "description": "Acheter et échanger des crédits carbone tokenisés pour compenser votre empreinte carbone."
      }
    });
    document.head.appendChild(script);
  }, [location.pathname]);

  const handleBuyCredits = () => {
    console.log("Navigation vers l'achat de crédits - URL avant:", window.location.href);
    
    try {
      navigate('/vcarbs');
      
      console.log("Navigation vers VCarbs effectuée");
      
      setTimeout(() => {
        console.log("URL après navigation:", window.location.href);
        const urlDebug = document.getElementById('url-debug-info');
        if (urlDebug) {
          urlDebug.textContent = 'URL: ' + window.location.href;
        }
      }, 100);
    } catch (error) {
      console.error("Erreur lors de la navigation:", error);
      toast({
        title: "Erreur de navigation",
        description: "Impossible d'accéder à la page d'achat de crédits",
        variant: "destructive"
      });
    }
  };

  const handleInvestmentMemo = () => {
    console.log("Navigation vers le mémorandum d'investissement - URL avant:", window.location.href);
    navigate('/expected-returns');
    
    setTimeout(() => {
      console.log("URL après navigation:", window.location.href);
      const urlDebug = document.getElementById('url-debug-info');
      if (urlDebug) {
        urlDebug.textContent = 'URL: ' + window.location.href;
      }
    }, 100);
  };

  const handleLearnMore = () => {
    console.log("Ouverture du formulaire d'inscription");
    setShowSignupDialog(true);
  };

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (!searchTerm.trim()) return;
    
    console.log("Recherche de:", searchTerm);
    toast({
      title: "Recherche en cours",
      description: `Recherche de "${searchTerm}" dans les projets`,
      variant: "default"
    });
    
    navigate(`/projects?search=${encodeURIComponent(searchTerm)}`);
  };

  const handleSubmitSignup = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!formData.name || !formData.email || !formData.acceptTerms) {
      toast({
        title: "Formulaire incomplet",
        description: "Veuillez remplir tous les champs et accepter les conditions",
        variant: "warning"
      });
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      toast({
        title: "Email invalide",
        description: "Veuillez entrer une adresse email valide",
        variant: "warning"
      });
      return;
    }

    console.log("Formulaire soumis:", formData);
    
    toast({
      title: "Inscription réussie!",
      description: "Bienvenue dans la communauté Guteco!",
      variant: "success"
    });
    
    setShowSignupDialog(false);
    navigate('/projects');
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  return (
    <section className="hero-gradient pt-24 md:pt-32 pb-20 md:pb-32" itemScope itemType="https://schema.org/WebPage">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 mb-10 md:mb-0 animate-fadeIn">
            <div className="mb-4">
              <span className="inline-block bg-highlight/20 text-brand-800 font-medium px-4 py-1 rounded-full text-sm md:text-base">
                Tokeniser la nature, protéger notre avenir
              </span>
            </div>
            <h1 className="mb-6 text-brand-800" itemProp="headline">Financez et soutenez<br />des projets écologiques</h1>
            <p className="text-xl text-brand-700 mb-6 max-w-lg" itemProp="description">
              <strong>Notre mission</strong> : Construire une économie verte (écologique) décentralisée, transparente, traçable et gouvernée par les communautés (DAO). <strong>Notre pilote</strong> : Madagascar, terrain d'expérimentation pour un modèle scalable dans d'autres pays.
            </p>
            
            <form onSubmit={handleSearch} className="mb-6">
              <div className="flex items-center bg-white/90 rounded-lg border border-brand-200 overflow-hidden">
                <Input 
                  type="search"
                  placeholder="Rechercher des projets, types de crédits carbone..."
                  className="border-0 focus-visible:ring-0 flex-1"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  aria-label="Rechercher dans GuTeco"
                />
                <Button type="submit" variant="ghost" className="px-3">
                  <Search className="h-5 w-5 text-brand-600" />
                </Button>
              </div>
            </form>
            
            <div className="bg-white/80 p-4 rounded-lg border border-brand-100 mb-6" itemScope itemType="https://schema.org/Report">
              <h3 className="text-brand-800 font-semibold mb-2 flex items-center gap-2" itemProp="name">
                <FileText className="h-5 w-5" />
                Mémorandum d'investissement - Mars 2025
              </h3>
              <ul className="text-sm text-brand-700 space-y-1" itemProp="description">
                <li className="flex items-center gap-2">
                  <BarChart3 className="h-4 w-4 text-brand-600" />
                  <span>Valorisation Pre-Money: <strong>90M€</strong></span>
                </li>
                <li className="flex items-center gap-2">
                  <TrendingUp className="h-4 w-4 text-brand-600" />
                  <span>Revenus estimés: <strong>8,4M€ en 2 ans</strong> <span className="text-xs italic">(prévisionnel)</span></span>
                </li>
                <li className="flex items-center gap-2">
                  <CreditCard className="h-4 w-4 text-brand-600" />
                  <span>Bénéfices: <strong>1,3M€ en 2 ans</strong> <span className="text-xs italic">(prévisionnel)</span></span>
                </li>
                <li className="flex items-center gap-2">
                  <GraduationCap className="h-4 w-4 text-brand-600" />
                  <span>ROI investisseurs: <strong>3,0% sur 2 ans</strong> <span className="text-xs italic">(prévisionnel)</span></span>
                </li>
                <li className="flex items-center gap-2">
                  <BarChart3 className="h-4 w-4 text-brand-600" />
                  <span>Levée de fonds: <strong>12M€ pour 11,76% d'équité</strong></span>
                </li>
                <li className="flex items-center gap-2 mt-2 text-xs italic bg-gray-50 p-1 rounded">
                  <Calendar className="h-3.5 w-3.5 text-brand-600" />
                  <span>Ces projections dépendent de la date de lancement et sont susceptibles d'évoluer</span>
                </li>
              </ul>
            </div>
            
            <div className="flex flex-col sm:flex-row gap-4">
              <Button 
                className="bg-highlight hover:bg-highlight-hover text-black text-lg px-6 py-3 flex items-center gap-2"
                onClick={handleBuyCredits}
              >
                <Sprout className="h-5 w-5" />
                <span>Acheter des crédits CO₂</span>
              </Button>
              <Button 
                variant="outline" 
                className="bg-white/80 hover:bg-white text-brand-700 border-brand-200 text-lg px-6 py-3 flex items-center gap-2"
                onClick={handleInvestmentMemo}
              >
                <Briefcase className="h-5 w-5" />
                <span>Opportunités d'investissement</span>
              </Button>
              <Button 
                className="bg-brand-600 hover:bg-brand-700 text-white text-lg px-6 py-3 flex items-center gap-2"
                onClick={handleLearnMore}
              >
                <Leaf className="h-5 w-5" />
                <span>Rejoignez-nous</span>
              </Button>
            </div>
          </div>
          <div className="md:w-1/2 flex justify-center animate-slideUp">
            <div className="bg-white shadow-xl p-8 rounded-xl border border-gray-200 w-full max-w-md">
              <div className="bg-brand-800/10 h-64 rounded-lg flex items-center justify-center mb-4">
                <div className="text-6xl text-brand-600">
                  <Leaf className="h-24 w-24" />
                </div>
              </div>
              <h3 className="text-xl text-brand-800 mb-2">Gouvernance DAO décentralisée</h3>
              <p className="text-gray-600">Participez aux décisions et à la validation des projets écologiques en toute transparence.</p>
            </div>
          </div>
        </div>
      </div>

      <Dialog open={showSignupDialog} onOpenChange={setShowSignupDialog}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle className="text-center text-2xl font-bold text-brand-800">Rejoignez la communauté Guteco</DialogTitle>
            <DialogDescription className="text-center">
              Inscrivez-vous pour accéder aux projets et participer à l'écosystème Guteco
            </DialogDescription>
          </DialogHeader>
          <form onSubmit={handleSubmitSignup} className="space-y-4 pt-4">
            <div className="space-y-2">
              <Label htmlFor="name">Nom complet</Label>
              <Input 
                id="name" 
                name="name" 
                placeholder="Entrez votre nom complet" 
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="email">Email</Label>
              <Input 
                id="email" 
                name="email" 
                type="email" 
                placeholder="votre@email.com" 
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
            <div className="flex items-center space-x-2">
              <Checkbox 
                id="terms" 
                name="acceptTerms" 
                checked={formData.acceptTerms}
                onCheckedChange={(checked) => 
                  setFormData(prev => ({ ...prev, acceptTerms: checked === true }))
                }
              />
              <label
                htmlFor="terms"
                className="text-sm text-gray-600 leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                J'accepte de recevoir des informations sur les projets Guteco
              </label>
            </div>
            <div className="pt-4">
              <Button type="submit" className="w-full bg-highlight hover:bg-highlight-hover text-black">
                S'inscrire et découvrir les projets
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </section>
  );
};

export default Hero;
