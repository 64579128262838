
import React, { useRef } from 'react';
import { Button } from "@/components/ui/button";
import { Layout } from '../components/Layout';
import { Leaf, Users, Shield, LineChart, ArrowBigRight, Info, Coins, Printer } from 'lucide-react';

const DaoPitch = () => {
  const contentRef = useRef<HTMLDivElement>(null);

  const handlePrint = () => {
    const printContent = contentRef.current?.innerHTML;
    const originalContent = document.body.innerHTML;
    
    if (printContent) {
      document.body.innerHTML = `
        <style>
          @media print {
            body { font-family: 'Arial', sans-serif; color: #333; line-height: 1.5; }
            h1, h2, h3 { color: #375e16; margin-top: 1.5em; margin-bottom: 0.8em; }
            .print-section { page-break-inside: avoid; margin-bottom: 20px; }
            .print-header { margin-bottom: 30px; text-align: center; }
            .print-grid { display: grid; grid-template-columns: 1fr 1fr; gap: 20px; }
            .print-card { border: 1px solid #eee; padding: 15px; margin-bottom: 15px; border-radius: 5px; }
            .no-print { display: none; }
            .icon-box { display: flex; align-items: center; margin-bottom: 10px; }
            .icon-placeholder { width: 32px; height: 32px; background: #7ac620; border-radius: 50%; margin-right: 10px; }
            ul { padding-left: 20px; }
            li { margin-bottom: 5px; }
            .print-footer { text-align: center; margin-top: 30px; padding-top: 15px; border-top: 1px solid #ddd; }
          }
        </style>
        <div class="print-container">
          ${printContent}
          <div class="print-footer">
            <p>© GUTECO DAO - www.guteco-dao.com - contact@guteco-dao.com</p>
          </div>
        </div>
      `;
      
      window.print();
      document.body.innerHTML = originalContent;
    }
  };

  return (
    <Layout>
      <div className="container mx-auto px-4 py-12">
        <div className="mb-6 flex justify-between items-center">
          <h1 className="text-3xl font-bold text-brand-800">Pitch Guteco DAO</h1>
          <div className="flex gap-2">
            <Button 
              onClick={handlePrint}
              className="bg-brand-600 hover:bg-brand-700 text-white flex items-center gap-2"
            >
              <Printer className="h-4 w-4" />
              Imprimer le pitch
            </Button>
          </div>
        </div>

        <div className="sticky top-0 z-10 bg-white py-2 shadow-sm mb-4 hidden md:flex justify-end print:hidden">
          <Button 
            onClick={handlePrint}
            size="sm"
            variant="outline"
            className="flex items-center gap-2"
          >
            <Printer className="h-3.5 w-3.5" />
            Version imprimable
          </Button>
        </div>

        <div ref={contentRef} className="bg-white p-8 rounded-lg shadow-md">
          {/* En-tête */}
          <div className="print-section print-header mb-10 text-center">
            <h1 className="text-4xl font-bold text-brand-800 mb-4">GUTECO DAO</h1>
            <h2 className="text-2xl text-brand-600 mb-6">Gouvernance décentralisée pour l'impact environnemental</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Une organisation autonome décentralisée dédiée à la transparence et l'efficacité 
              des projets environnementaux
            </p>
          </div>

          {/* Vision et Mission */}
          <div className="print-section mb-10">
            <h2 className="text-2xl font-bold text-brand-800 mb-4 border-b border-brand-200 pb-2">
              Notre Vision & Mission
            </h2>
            <p className="text-lg text-gray-700 mb-4">
              <strong>Guteco DAO</strong> transforme le financement et la gouvernance des projets environnementaux 
              grâce à la technologie blockchain, garantissant transparence et efficacité.
            </p>
            <p className="text-lg text-gray-700">
              Notre mission est de <strong>démocratiser l'accès</strong> aux projets à impact positif et 
              d'<strong>autonomiser les communautés</strong> pour la prise de décision collective 
              dans la lutte contre le changement climatique.
            </p>
          </div>

          {/* Tokenomics */}
          <div className="print-section mb-10">
            <h2 className="text-2xl font-bold text-brand-800 mb-4 border-b border-brand-200 pb-2">
              Tokenomics
            </h2>
            <div className="grid md:grid-cols-2 gap-8 print-grid">
              <div>
                <h3 className="text-xl font-semibold text-brand-700 mb-3">Token GTC</h3>
                <ul className="list-disc pl-5 text-gray-700 space-y-2">
                  <li>Supply total : 100 000 000 GTC</li>
                  <li>Modèle déflationniste avec mécanisme de burn</li>
                  <li>30% alloués aux récompenses utilisateurs</li>
                  <li>15% équipe et conseillers (vesting 4 ans)</li>
                  <li>20% trésorerie écosystème</li>
                </ul>
              </div>
              <div>
                <h3 className="text-xl font-semibold text-brand-700 mb-3">Token VCarbs</h3>
                <ul className="list-disc pl-5 text-gray-700 space-y-2">
                  <li>Tokens de carbone vérifiés</li>
                  <li>Basés sur des projets environnementaux réels</li>
                  <li>1 VCarb = 1 tonne CO₂ compensée</li>
                  <li>Actifs évolutifs en fonction des projets</li>
                  <li>Traçabilité complète sur blockchain</li>
                </ul>
              </div>
            </div>
            
            <div className="mt-6 bg-blue-50 p-4 rounded-lg border border-blue-100 flex items-start gap-2">
              <Info className="h-5 w-5 text-blue-600 shrink-0 mt-0.5" />
              <div>
                <h4 className="font-medium text-blue-700 mb-1">Comprendre les termes de vesting</h4>
                <p className="text-blue-600 text-sm">
                  <strong>Cliff</strong> : Période initiale pendant laquelle aucun token n'est débloqué, suivie d'un déblocage à l'échéance du cliff.
                  <br />
                  <strong>Vesting linéaire</strong> : Déblocage progressif et régulier des tokens sur une période définie (ex: 1/36 des tokens chaque mois sur 36 mois).
                </p>
              </div>
            </div>
          </div>

          {/* Gouvernance */}
          <div className="print-section mb-10">
            <h2 className="text-2xl font-bold text-brand-800 mb-4 border-b border-brand-200 pb-2">
              Structure de Gouvernance
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 print-grid">
              <div className="print-card">
                <div className="icon-box">
                  <div className="w-8 h-8 bg-brand-100 rounded-full flex items-center justify-center icon-placeholder">
                    <Users className="h-4 w-4 text-brand-700" />
                  </div>
                  <h3 className="text-xl font-semibold text-brand-700">Processus de Vote</h3>
                </div>
                <ul className="list-disc pl-5 text-gray-700 space-y-1">
                  <li>1 GTC = 1 vote dans le système</li>
                  <li>Période de discussion : 7 jours</li>
                  <li>Période de vote : 5 jours</li>
                  <li>Quorum minimum : 10%</li>
                </ul>
              </div>
              
              <div className="print-card">
                <div className="icon-box">
                  <div className="w-8 h-8 bg-brand-100 rounded-full flex items-center justify-center icon-placeholder">
                    <Shield className="h-4 w-4 text-brand-700" />
                  </div>
                  <h3 className="text-xl font-semibold text-brand-700">Niveaux d'Influence</h3>
                </div>
                <ul className="list-disc pl-5 text-gray-700 space-y-1">
                  <li><strong>Standard</strong> : Droits de vote basiques</li>
                  <li><strong>Ambassadeur</strong> : Staking &gt; 3 mois (+20% vote)</li>
                  <li><strong>Protecteur</strong> : Staking &gt; 1 an (+50% vote)</li>
                </ul>
              </div>
              
              <div className="print-card">
                <div className="icon-box">
                  <div className="w-8 h-8 bg-brand-100 rounded-full flex items-center justify-center icon-placeholder">
                    <Leaf className="h-4 w-4 text-brand-700" />
                  </div>
                  <h3 className="text-xl font-semibold text-brand-700">Domaines de Décision</h3>
                </div>
                <ul className="list-disc pl-5 text-gray-700 space-y-1">
                  <li>Allocation des fonds de trésorerie</li>
                  <li>Validation des projets environnementaux</li>
                  <li>Modifications des paramètres économiques</li>
                  <li>Améliorations techniques</li>
                </ul>
              </div>
              
              <div className="print-card">
                <div className="icon-box">
                  <div className="w-8 h-8 bg-brand-100 rounded-full flex items-center justify-center icon-placeholder">
                    <LineChart className="h-4 w-4 text-brand-700" />
                  </div>
                  <h3 className="text-xl font-semibold text-brand-700">Mécanismes Économiques</h3>
                </div>
                <ul className="list-disc pl-5 text-gray-700 space-y-1">
                  <li>Staking & récompenses passives</li>
                  <li>Échange GTC/VCarbs avec frais réduits</li>
                  <li>Incubation de projets via trésorerie</li>
                  <li>Financement participatif régulé</li>
                </ul>
              </div>
            </div>
          </div>

          {/* Projections financières et Impact */}
          <div className="print-section mb-10">
            <h2 className="text-2xl font-bold text-brand-800 mb-4 border-b border-brand-200 pb-2">
              Projections Financières
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 print-grid">
              <div className="print-card">
                <div className="icon-box">
                  <div className="w-8 h-8 bg-green-100 rounded-full flex items-center justify-center icon-placeholder">
                    <LineChart className="h-4 w-4 text-green-700" />
                  </div>
                  <h3 className="text-xl font-semibold text-green-700">Croissance des revenus</h3>
                </div>
                <ul className="list-disc pl-5 text-gray-700 space-y-1">
                  <li><strong>Année 1</strong> : 280 000 €</li>
                  <li><strong>Année 2</strong> : 820 000 €</li>
                  <li><strong>Année 3</strong> : 1 750 000 € (seuil de rentabilité)</li>
                  <li><strong>Année 4</strong> : 3 100 000 €</li>
                  <li><strong>Année 5</strong> : 4 800 000 €</li>
                </ul>
              </div>
              
              <div className="print-card">
                <div className="icon-box">
                  <div className="w-8 h-8 bg-green-100 rounded-full flex items-center justify-center icon-placeholder">
                    <Coins className="h-4 w-4 text-green-700" />
                  </div>
                  <h3 className="text-xl font-semibold text-green-700">Résultats nets</h3>
                </div>
                <ul className="list-disc pl-5 text-gray-700 space-y-1">
                  <li><strong>Année 1</strong> : -765 000 €</li>
                  <li><strong>Année 2</strong> : -380 000 €</li>
                  <li><strong>Année 3</strong> : +700 000 €</li>
                  <li><strong>Année 4</strong> : +1 650 000 €</li>
                  <li><strong>Année 5</strong> : +3 100 000 €</li>
                </ul>
                <div className="mt-3 text-xs text-green-700 italic">
                  *Ces projections pourront s'améliorer selon les circonstances et l'avancement du projet
                </div>
              </div>

              <div className="print-card">
                <div className="icon-box">
                  <div className="w-8 h-8 bg-green-100 rounded-full flex items-center justify-center icon-placeholder">
                    <Leaf className="h-4 w-4 text-green-700" />
                  </div>
                  <h3 className="text-xl font-semibold text-green-700">Impact Mangroves</h3>
                </div>
                <ul className="list-disc pl-5 text-gray-700 space-y-1">
                  <li><strong>Surface totale</strong> : 20 000 hectares</li>
                  <li><strong>Rendement</strong> : 30€/hectare/an</li>
                  <li><strong>Revenu annuel</strong> : 600 000€</li>
                  <li><strong>Captation CO₂</strong> : 4-10× plus élevée que les forêts classiques</li>
                  <li><strong>Partenaires</strong> : WWF, Nations Unies, Gouvernement malgache</li>
                </ul>
              </div>
              
              <div className="print-card">
                <div className="icon-box">
                  <div className="w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center icon-placeholder">
                    <Coins className="h-4 w-4 text-blue-700" />
                  </div>
                  <h3 className="text-xl font-semibold text-blue-700">Sources de Revenus</h3>
                </div>
                <ul className="list-disc pl-5 text-gray-700 space-y-1">
                  <li><strong>Crédits carbone</strong> : 60% (mangroves et forêts)</li>
                  <li><strong>Activités DeFi</strong> : 15% (staking, farming, échanges)</li>
                  <li><strong>Écotourisme</strong> : 10%</li>
                  <li><strong>Crédits biodiversité</strong> : 15%</li>
                  <li><strong>Temps jusqu'aux 1ers revenus</strong> : 1-9 mois selon l'activité</li>
                </ul>
              </div>
            </div>
          </div>

          {/* Avantages */}
          <div className="print-section mb-10">
            <h2 className="text-2xl font-bold text-brand-800 mb-4 border-b border-brand-200 pb-2">
              Avantages Guteco DAO
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 print-grid">
              <div>
                <h3 className="text-xl font-semibold text-brand-700 mb-2">Pour Investisseurs</h3>
                <ul className="list-disc pl-5 text-gray-700 space-y-1">
                  <li>Rendement lié à l'impact réel</li>
                  <li>Diversification dans l'environnement</li>
                  <li>Traçabilité et transparence</li>
                  <li>Droits de gouvernance évolutifs</li>
                </ul>
              </div>
              <div>
                <h3 className="text-xl font-semibold text-brand-700 mb-2">Pour Porteurs de Projets</h3>
                <ul className="list-disc pl-5 text-gray-700 space-y-1">
                  <li>Accès à des financements</li>
                  <li>Expertise technique et soutien</li>
                  <li>Communauté engagée</li>
                  <li>Visibilité internationale</li>
                </ul>
              </div>
              <div>
                <h3 className="text-xl font-semibold text-brand-700 mb-2">Pour l'Environnement</h3>
                <ul className="list-disc pl-5 text-gray-700 space-y-1">
                  <li>Financement de projets viables</li>
                  <li>Métriques d'impact vérifiables</li>
                  <li>Réduction effective des émissions</li>
                  <li>Préservation des écosystèmes</li>
                </ul>
              </div>
            </div>
          </div>

          {/* Appel à l'action */}
          <div className="print-section mt-12 text-center">
            <h2 className="text-2xl font-bold text-brand-800 mb-4">
              Rejoignez la Révolution Verte Décentralisée
            </h2>
            <p className="text-lg text-gray-700 mb-6 max-w-2xl mx-auto">
              Participez à la gouvernance de Guteco DAO et contribuez à créer un impact environnemental positif et mesurable.
            </p>
            <div className="bg-brand-50 p-6 rounded-lg inline-block mx-auto">
              <p className="text-brand-800 font-semibold">www.guteco-dao.com</p>
              <p className="text-brand-600">contact@guteco-dao.com</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DaoPitch;
