
import React from 'react';
import { Layout } from '@/components/Layout';
import ExpectedReturnsComponent from '@/components/ExpectedReturns';
import { Button } from '@/components/ui/button';
import { ArrowLeft, LineChart, Briefcase } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import InvestorStrategy from '@/components/InvestorStrategy';

const ExpectedReturnsPage = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <div className="container mx-auto px-4 py-8">
        <Button 
          variant="outline" 
          className="mb-6 flex items-center gap-2"
          onClick={() => navigate('/')}
        >
          <ArrowLeft className="h-4 w-4" />
          <span>Retour à l'accueil</span>
        </Button>

        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold text-brand-800 mb-4">Analyse Détaillée des Rendements</h1>
          <p className="text-gray-600 max-w-3xl mx-auto">
            Cette page présente une analyse complète des rendements attendus pour les différentes activités 
            et investissements dans l'écosystème GuTeco. Tous les calculs sont expliqués en détail.
          </p>
        </div>

        <div className="flex flex-wrap gap-4 justify-center mb-12">
          <Button 
            onClick={() => navigate('/valuation')}
            className="bg-indigo-600 hover:bg-indigo-700 flex items-center gap-2"
          >
            <LineChart className="h-4 w-4" />
            <span>Voir la valorisation complète</span>
          </Button>
          
          <Button 
            onClick={() => navigate('/investment-memorandum')}
            className="bg-brand-600 hover:bg-brand-700 flex items-center gap-2"
          >
            <Briefcase className="h-4 w-4" />
            <span>Mémorandum d'investissement</span>
          </Button>
        </div>

        <ExpectedReturnsComponent />
        
        <div className="mt-16">
          <InvestorStrategy />
        </div>
      </div>
    </Layout>
  );
};

export default ExpectedReturnsPage;
