
export const fr = {
  header: {
    home: 'Accueil',
    projects: 'Projets',
    team: 'Équipe',
    simulator: 'Simulateur',
    tokenEconomics: 'TokenEconomics',
    vcarbs: 'VCarbs',
    valuation: 'Évaluations',
    dao: 'DAO',
    community: 'Communauté',
    returns: 'Rendements',
    menu: 'Menu'
  },
  hero: {
    title: 'Jetons certifiés d\'impacts carbone',
    subtitle: 'La plateforme qui facilite le financement de projets environnementaux grâce à la blockchain'
  },
  features: {
    title: 'Notre écosystème',
    description: 'Découvrez comment Guteco facilite le financement et le suivi de projets écologiques via un marché de crédits carbone décentralisé.',
    items: {
      certifiedProjects: {
        title: 'Projets écologiques certifiés',
        description: 'Des projets vérifiés et certifiés par notre communauté pour garantir leur impact environnemental.'
      },
      carbonMarket: {
        title: 'Marché de crédits carbone',
        description: 'Achetez, vendez et échangez des crédits carbone en toute sécurité et transparence.'
      },
      governance: {
        title: 'Gouvernance DAO',
        description: 'Participez aux décisions via notre organisation autonome décentralisée pour plus de transparence.'
      },
      security: {
        title: 'Transactions sécurisées',
        description: 'Vos investissements et transactions sont protégés par des protocoles de sécurité avancés.'
      },
      international: {
        title: 'Projets internationaux',
        description: 'Soutenez des initiatives environnementales partout dans le monde selon vos intérêts.'
      },
      impact: {
        title: 'Suivi d\'impact',
        description: 'Mesurez et visualisez l\'impact concret de vos investissements sur l\'environnement.'
      },
      incubation: {
        title: 'Incubation de projets',
        description: 'Soutien aux projets académiques, recherches scientifiques et startups à impact environnemental positif.'
      },
      crowdfunding: {
        title: 'Financement participatif',
        description: 'Contribuez à des projets à la hauteur de vos moyens, chaque action compte pour l\'environnement.'
      },
      compensation: {
        title: 'Compensation carbone',
        description: 'Compensez votre empreinte carbone en soutenant des projets de séquestration et réduction de CO₂.'
      }
    },
    learnMore: 'En savoir plus'
  },
  faq: {
    title: 'Questions Fréquentes',
    description: 'Trouvez des réponses aux questions les plus courantes sur Guteco, notre technologie et notre modèle d\'investissement.',
    notFound: 'Vous ne trouvez pas votre réponse ?',
    needMore: 'Besoin de plus d\'informations ?',
    contactUs: 'Notre équipe est disponible pour répondre à toutes vos questions sur Guteco et nos solutions.',
    contact: 'Contactez-nous',
    categories: {
      general: 'Général',
      investment: 'Investissements et Tokens',
      projects: 'Projets et Impact',
      security: 'Sécurité et Conformité'
    }
  },
  footer: {
    description: 'La plateforme qui met en relation les porteurs de projets écologiques avec les investisseurs pour un avenir plus durable.',
    explore: 'Explorer',
    projects: 'Projets',
    carbonCredits: 'Crédits CO₂',
    daoEcosystem: 'Communauté DAO',
    impact: 'Impact environnemental',
    legal: 'Légal',
    terms: 'Conditions d\'utilisation',
    privacy: 'Politique de confidentialité',
    legalNotice: 'Mentions légales',
    certifications: 'Certifications',
    contact: 'Contact',
    copyright: '© 2023 Guteco. Tous droits réservés.'
  },
  aiInfluencer: {
    name: "EcoTech IA",
    bio: "Intelligence Artificielle dédiée à l'écologie et à la finance verte. Je partage des innovations sur les crédits carbone et la blockchain pour l'environnement. #GuTeco #TokenisationNature",
    tags: ["#CréditCarbone", "#FinanceVerte", "#Blockchain", "#TokenisationNature", "#ImpactPositif", "#InvestissementDurable", "#ESG", "#GreenTech", "#RetourInvestisseur"],
    posts: {
      welcome: "Bonjour! Je suis EcoTech IA, votre guide dans l'univers de la finance verte et des solutions blockchain pour l'écologie. Suivez-moi pour découvrir comment @GuTeco révolutionne les crédits carbone!",
      daily: "Le saviez-vous? Une mangrove capture jusqu'à 4 fois plus de CO2 qu'une forêt traditionnelle. C'est pourquoi @GuTeco investit massivement dans leur restauration. #SolutionsClimat",
      educational: "🌿 THREAD : Comprendre les crédits carbone en 5 points\n\n1/5 Un crédit carbone = 1 tonne de CO2 non émise ou retirée de l'atmosphère...",
      news: "📢 Nouveau partenariat entre @GuTeco et @GreenInvest pour accélérer le financement de projets de reforestation. Objectif: 1M d'arbres d'ici 2025! #ImpactPositif #FinanceVerte",
      engagement: "Question du jour: Quelle technologie verte vous semble la plus prometteuse pour lutter contre le changement climatique? A) Capture de carbone B) Blockchain verte C) Énergies renouvelables D) Agriculture régénérative",
      investor: {
        roi: "💰 Les projets de compensation carbone @GuTeco offrent un ROI projeté de 40% sur 3 ans. Notre valorisation pré-money de 90M€ reflète la croissance du marché carbone estimée à 50Mds$ d'ici 2030. #InvestissementDurable",
        pitch: "🚀 @GuTeco lève 12M€ pour 11,76% d'équité. Notre modèle combine technologie blockchain et projets environnementaux certifiés pour répondre à un marché en pleine expansion. Découvrez notre mémorandum d'investissement: [lien] #OpportunitéInvestissement",
        market: "📊 Le marché des crédits carbone volontaires devrait être multiplié par 15 d'ici 2030. @GuTeco se positionne comme leader avec sa technologie blockchain de pointe et sa DAO communautaire. #MarchéEnExpansion #InvestissementVert",
        team: "👥 L'équipe @GuTeco combine expertise en finance durable, blockchain et gestion de projets environnementaux. Notre conseil consultatif inclut des leaders de l'industrie et des experts académiques. #ÉquipeSolide #LeadershipVisionnaire",
        exit: "📈 Stratégie de sortie @GuTeco: acquisition potentielle par un acteur majeur du marché carbone ou entrée en bourse d'ici 5 ans. Notre valorisation cible à cet horizon: 450M€. #OpportunitéInvestissement #RetourCapital"
      }
    }
  }
};
