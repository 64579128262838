import React from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '@/components/Layout';
import { Button } from '@/components/ui/button';
import { ArrowLeft, Users, Leaf, MessageCircle, Globe, Flag, User } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription, CardFooter } from '@/components/ui/card';

const Community = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const communityMembers = [
    {
      id: 1,
      name: "Membre #2451",
      role: "Expert Forêt",
      location: "France",
      contributions: 18,
      description: "Spécialiste en reforestation et biodiversité",
      projects: 3,
    },
    {
      id: 2,
      name: "Membre #3872",
      role: "Expert Énergies",
      location: "Belgique",
      contributions: 24,
      description: "Spécialiste des technologies renouvelables",
      projects: 5,
    },
    {
      id: 3,
      name: "Membre #1923",
      role: "Expert Marin",
      location: "Canada",
      contributions: 15,
      description: "Spécialiste en conservation marine",
      projects: 2,
    },
    {
      id: 4,
      name: "Membre #4156",
      role: "Expert Économie",
      location: "Suisse",
      contributions: 21,
      description: "Spécialiste en économie circulaire",
      projects: 4,
    },
  ];

  return (
    <Layout>
      <div className="container mx-auto px-4 py-8">
        <Button 
          variant="outline" 
          className="mb-6 flex items-center gap-2"
          onClick={handleGoBack}
        >
          <ArrowLeft className="h-4 w-4" />
          <span>Retour</span>
        </Button>
        
        <div className="mb-12">
          <h1 className="text-3xl font-bold text-brand-800 mb-4">Communauté DAO Guteco</h1>
          <p className="text-gray-600 max-w-3xl">
            Notre organisation autonome décentralisée (DAO) est au cœur de la gouvernance des projets Guteco. 
            Elle permet à tous les membres de participer aux décisions concernant les projets écologiques et 
            la distribution des crédits carbone.
          </p>
        </div>
        
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-12">
          <Card className="bg-brand-50 border-brand-100">
            <CardHeader>
              <div className="w-12 h-12 bg-brand-100 rounded-full flex items-center justify-center mb-4">
                <Users className="h-6 w-6 text-brand-700" />
              </div>
              <CardTitle className="text-brand-800">Gouvernance Participative</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-gray-600">
                Chaque détenteur de jetons GUTECO peut voter sur les propositions 
                de projets, les allocations de fonds et les décisions stratégiques de la plateforme.
              </p>
            </CardContent>
          </Card>
          
          <Card className="bg-amber-50 border-amber-100">
            <CardHeader>
              <div className="w-12 h-12 bg-amber-100 rounded-full flex items-center justify-center mb-4">
                <Leaf className="h-6 w-6 text-amber-700" />
              </div>
              <CardTitle className="text-amber-800">Validation des Projets</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-gray-600">
                Les membres de la DAO examinent et certifient les projets écologiques 
                pour garantir leur impact positif réel sur l'environnement.
              </p>
            </CardContent>
          </Card>
          
          <Card className="bg-emerald-50 border-emerald-100">
            <CardHeader>
              <div className="w-12 h-12 bg-emerald-100 rounded-full flex items-center justify-center mb-4">
                <Globe className="h-6 w-6 text-emerald-700" />
              </div>
              <CardTitle className="text-emerald-800">Impact Global</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-gray-600">
                Notre communauté mondiale travaille ensemble pour maximiser l'impact 
                environnemental positif à travers différentes régions et écosystèmes.
              </p>
            </CardContent>
          </Card>
        </div>
        
        <div className="mb-12">
          <h2 className="text-2xl font-bold text-brand-800 mb-6">Membres Actifs de la Communauté</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
            {communityMembers.map((member) => (
              <Card key={member.id} className="hover:shadow-md transition-shadow">
                <CardHeader className="pb-2">
                  <div className="flex justify-between items-start">
                    <div className="flex items-center gap-3">
                      <div className="w-12 h-12 bg-brand-100 rounded-full flex items-center justify-center">
                        <User className="h-6 w-6 text-brand-600" />
                      </div>
                      <div>
                        <CardTitle className="text-lg">{member.name}</CardTitle>
                        <p className="text-sm text-brand-600 font-medium">{member.role}</p>
                      </div>
                    </div>
                    <div className="bg-gray-100 px-2 py-1 rounded text-xs text-gray-700 flex items-center gap-1">
                      <Flag className="h-3 w-3" />
                      {member.location}
                    </div>
                  </div>
                </CardHeader>
                <CardContent>
                  <p className="text-sm text-gray-600 mb-4">{member.description}</p>
                  <div className="grid grid-cols-2 gap-2">
                    <div className="bg-brand-50 p-2 rounded-md text-center">
                      <p className="text-brand-700 font-medium">{member.contributions}</p>
                      <p className="text-xs text-gray-500">Contributions</p>
                    </div>
                    <div className="bg-amber-50 p-2 rounded-md text-center">
                      <p className="text-amber-700 font-medium">{member.projects}</p>
                      <p className="text-xs text-gray-500">Projets</p>
                    </div>
                  </div>
                </CardContent>
                <CardFooter className="border-t pt-3">
                  <Button variant="outline" size="sm" className="w-full flex items-center gap-2">
                    <MessageCircle className="h-4 w-4" />
                    <span>Contacter</span>
                  </Button>
                </CardFooter>
              </Card>
            ))}
          </div>
        </div>
        
        <div className="flex justify-center">
          <Button className="bg-brand-600 hover:bg-brand-700 px-8 py-3 text-white">
            Rejoindre la communauté
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default Community;
