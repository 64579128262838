
import { toast as sonnerToast } from "sonner"

type ToastProps = {
  title?: string
  description?: string
  variant?: "default" | "destructive" | "success" | "warning" | "info"
}

export function useToast() {
  const toast = ({ title, description, variant = "default" }: ToastProps) => {
    switch (variant) {
      case "success":
        sonnerToast.success(title || "Succès", {
          description,
        })
        break
      case "destructive":
        sonnerToast.error(title || "Erreur", {
          description,
        })
        break
      case "warning":
        sonnerToast.warning(title || "Avertissement", {
          description,
        })
        break
      case "info":
        sonnerToast.info(title || "Information", {
          description,
        })
        break
      default:
        sonnerToast(title || "", {
          description,
        })
    }
  }

  return { toast }
}

// Export direct toast function for easier usage
export const toast = ({ title, description, variant = "default" }: ToastProps) => {
  const { toast: toastFn } = useToast()
  toastFn({ title, description, variant })
}

// Add shorthand methods for easier access
toast.info = (title: string, { description }: { description?: string } = {}) => {
  toast({ title, description, variant: "info" });
};

toast.success = (title: string, { description }: { description?: string } = {}) => {
  toast({ title, description, variant: "success" });
};

toast.error = (title: string, { description }: { description?: string } = {}) => {
  toast({ title, description, variant: "error" });
};

toast.warning = (title: string, { description }: { description?: string } = {}) => {
  toast({ title, description, variant: "warning" });
};
