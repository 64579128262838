
import React, { useState } from 'react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { Badge } from '@/components/ui/badge';
import { Copy, Twitter, Linkedin, Instagram, Share2, Check, Globe, AlertTriangle, TrendingUp } from 'lucide-react';
import { useLanguage } from '@/contexts/LanguageContext';
import { toast } from '@/hooks/use-toast';

const AIInfluencer = () => {
  const { t, language } = useLanguage();
  const [copied, setCopied] = useState<string | null>(null);
  const [activeCategory, setActiveCategory] = useState<'general' | 'investor'>('general');

  const handleCopy = (text: string, type: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setCopied(type);
        toast({
          title: "Copié !",
          description: "Le texte a été copié dans le presse-papier",
          variant: "success"
        });
        
        setTimeout(() => setCopied(null), 2000);
      },
      (err) => {
        console.error('Erreur lors de la copie :', err);
        toast({
          title: "Erreur",
          description: "Impossible de copier le texte",
          variant: "destructive"
        });
      }
    );
  };

  // Modified to prevent direct linking to existing social profiles
  const shareOnTwitter = (text: string) => {
    const modifiedText = text.replace(/@guteco/g, "#guteco");
    const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(modifiedText)}`;
    window.open(url, '_blank');
  };

  const shareOnLinkedIn = (text: string) => {
    const modifiedText = text.replace(/@guteco/g, "#guteco");
    const url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}&summary=${encodeURIComponent(modifiedText)}`;
    window.open(url, '_blank');
  };

  const shareOnInstagram = (text: string) => {
    // Instagram doesn't have a direct share API, but we can copy to clipboard
    // and show instructions to paste into Instagram
    handleCopy(text.replace(/@guteco/g, "#guteco"), 'instagram-copy');
    toast({
      title: "Prêt pour Instagram",
      description: "Le texte a été copié. Ouvrez Instagram et collez-le dans votre publication.",
      variant: "info"
    });
  };

  // Modify posts to replace @guteco mentions with #guteco hashtag
  const formatSocialText = (text: string) => {
    return text.replace(/@guteco/g, "#guteco");
  };

  const socialData = {
    name: t('aiInfluencer.name'),
    bio: formatSocialText(t('aiInfluencer.bio')),
    tags: t('aiInfluencer.tags'),
    posts: {
      general: {
        welcome: formatSocialText(t('aiInfluencer.posts.welcome')),
        daily: formatSocialText(t('aiInfluencer.posts.daily')),
        educational: formatSocialText(t('aiInfluencer.posts.educational')),
        news: formatSocialText(t('aiInfluencer.posts.news')),
        engagement: formatSocialText(t('aiInfluencer.posts.engagement'))
      },
      investor: t('aiInfluencer.posts.investor') ? {
        roi: formatSocialText(t('aiInfluencer.posts.investor.roi')),
        pitch: formatSocialText(t('aiInfluencer.posts.investor.pitch')),
        market: formatSocialText(t('aiInfluencer.posts.investor.market')),
        team: formatSocialText(t('aiInfluencer.posts.investor.team')),
        exit: formatSocialText(t('aiInfluencer.posts.investor.exit'))
      } : null
    }
  };

  const getActivePosts = () => {
    if (activeCategory === 'investor' && socialData.posts.investor) {
      return socialData.posts.investor;
    }
    return socialData.posts.general;
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-8 text-brand-700">
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-brand-600 to-brand-800">
          AI Influenceur GuTeco
        </span>
      </h1>

      <div className="flex justify-center mb-6">
        <div className="inline-flex items-center p-1 border rounded-md bg-white shadow-sm">
          <button
            onClick={() => setActiveCategory('general')}
            className={`px-4 py-2 rounded-md ${activeCategory === 'general' ? 'bg-brand-100 text-brand-700 font-medium' : 'text-gray-500'}`}
          >
            Contenu Général
          </button>
          <button
            onClick={() => setActiveCategory('investor')}
            className={`px-4 py-2 rounded-md flex items-center ${activeCategory === 'investor' ? 'bg-brand-100 text-brand-700 font-medium' : 'text-gray-500'}`}
          >
            <TrendingUp className="h-4 w-4 mr-1" />
            Attraction Investisseurs
          </button>
        </div>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Profile Card */}
        <Card className="md:col-span-1 border-brand-100">
          <CardHeader className="pb-3">
            <div className="flex items-center justify-between">
              <CardTitle className="text-xl text-brand-700">
                {socialData.name}
              </CardTitle>
              <Badge variant="outline" className="bg-brand-50 text-brand-700 border-brand-200">
                <Globe className="h-3 w-3 mr-1" />
                {language.toUpperCase()}
              </Badge>
            </div>
            <CardDescription className="text-gray-600">
              {activeCategory === 'investor' ? 'Influenceur IA pour attirer des investisseurs' : 'Influenceur IA pour GuTeco'}
            </CardDescription>
          </CardHeader>
          
          <CardContent className="space-y-4">
            <div className="bg-amber-50 border border-amber-200 rounded-md p-3 mb-4">
              <div className="flex items-start gap-2">
                <AlertTriangle className="h-5 w-5 text-amber-500 flex-shrink-0 mt-0.5" />
                <p className="text-xs text-amber-700">
                  Note: Utilisez #guteco au lieu de @guteco pour éviter de mentionner des profils existants non liés au projet GuTeco.
                </p>
              </div>
            </div>
            
            <div>
              <h3 className="text-sm font-medium text-gray-500 mb-2">Bio</h3>
              <div className="relative bg-gray-50 p-3 rounded-md text-sm text-gray-700">
                {socialData.bio}
                <Button 
                  variant="ghost" 
                  size="icon"
                  className="absolute top-2 right-2 h-7 w-7"
                  onClick={() => handleCopy(socialData.bio, 'bio')}
                >
                  {copied === 'bio' ? <Check className="h-4 w-4" /> : <Copy className="h-4 w-4" />}
                </Button>
              </div>
            </div>
            
            <div>
              <h3 className="text-sm font-medium text-gray-500 mb-2">Hashtags</h3>
              <div className="flex flex-wrap gap-2">
                {Array.isArray(socialData.tags) ? socialData.tags.map((tag: string, index: number) => (
                  <Badge key={index} variant="secondary" className="cursor-pointer hover:bg-gray-200" onClick={() => handleCopy(tag, `tag-${index}`)}>
                    {tag}
                    {copied === `tag-${index}` && <Check className="ml-1 h-3 w-3" />}
                  </Badge>
                )) : (
                  <p className="text-sm text-gray-500">Aucun hashtag disponible</p>
                )}
              </div>
            </div>
          </CardContent>
          
          <CardFooter className="flex flex-col gap-2 pt-2">
            <div className="flex justify-between w-full">
              <Button variant="outline" size="sm" onClick={() => handleCopy(socialData.bio, 'all-bio')} className="flex-1 mr-2">
                {copied === 'all-bio' ? <Check className="mr-2 h-4 w-4" /> : <Copy className="mr-2 h-4 w-4" />}
                Copier la bio
              </Button>
              <Button variant="outline" size="sm" onClick={() => handleCopy(Array.isArray(socialData.tags) ? socialData.tags.join(' ') : '', 'all-tags')} className="flex-1">
                {copied === 'all-tags' ? <Check className="mr-2 h-4 w-4" /> : <Copy className="mr-2 h-4 w-4" />}
                Copier les hashtags
              </Button>
            </div>
          </CardFooter>
        </Card>
        
        {/* Posts Card */}
        <Card className="md:col-span-2 border-brand-100">
          <CardHeader className="pb-3">
            <CardTitle>Posts prêts à partager</CardTitle>
            <CardDescription>Sélectionnez et partagez sur vos réseaux</CardDescription>
          </CardHeader>
          
          <CardContent>
            <Tabs defaultValue="twitter" className="w-full">
              <TabsList className="grid w-full grid-cols-3 mb-4">
                <TabsTrigger value="twitter" className="flex items-center gap-2">
                  <Twitter className="h-4 w-4" />
                  <span>Twitter</span>
                </TabsTrigger>
                <TabsTrigger value="linkedin" className="flex items-center gap-2">
                  <Linkedin className="h-4 w-4" />
                  <span>LinkedIn</span>
                </TabsTrigger>
                <TabsTrigger value="instagram" className="flex items-center gap-2">
                  <Instagram className="h-4 w-4" />
                  <span>Instagram</span>
                </TabsTrigger>
              </TabsList>
              
              {/* Twitter Tab */}
              <TabsContent value="twitter" className="space-y-4">
                {Object.entries(getActivePosts() || {}).map(([key, post]) => (
                  <div key={key} className="bg-gray-50 rounded-md p-4 relative">
                    <p className="text-sm text-gray-800 mb-4 whitespace-pre-line">{post}</p>
                    <div className="flex justify-between items-center mt-2">
                      <span className="text-xs font-medium text-gray-500 capitalize">{key}</span>
                      <div className="flex gap-2">
                        <Button variant="ghost" size="sm" onClick={() => handleCopy(post as string, `twitter-${key}`)}>
                          {copied === `twitter-${key}` ? <Check className="h-4 w-4" /> : <Copy className="h-4 w-4" />}
                        </Button>
                        <Button 
                          variant="default" 
                          size="sm" 
                          className="bg-[#1DA1F2] hover:bg-[#1a94df]"
                          onClick={() => shareOnTwitter(post as string)}
                        >
                          <Twitter className="h-3 w-3 mr-1" />
                          <span>Tweeter</span>
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
              </TabsContent>
              
              {/* LinkedIn Tab */}
              <TabsContent value="linkedin" className="space-y-4">
                {Object.entries(getActivePosts() || {}).map(([key, post]) => (
                  <div key={key} className="bg-gray-50 rounded-md p-4 relative">
                    <p className="text-sm text-gray-800 mb-4 whitespace-pre-line">{post}</p>
                    <div className="flex justify-between items-center mt-2">
                      <span className="text-xs font-medium text-gray-500 capitalize">{key}</span>
                      <div className="flex gap-2">
                        <Button variant="ghost" size="sm" onClick={() => handleCopy(post as string, `linkedin-${key}`)}>
                          {copied === `linkedin-${key}` ? <Check className="h-4 w-4" /> : <Copy className="h-4 w-4" />}
                        </Button>
                        <Button 
                          variant="default" 
                          size="sm" 
                          className="bg-[#0A66C2] hover:bg-[#0956a3]"
                          onClick={() => shareOnLinkedIn(post as string)}
                        >
                          <Linkedin className="h-3 w-3 mr-1" />
                          <span>Partager</span>
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
              </TabsContent>
              
              {/* Instagram Tab */}
              <TabsContent value="instagram" className="space-y-4">
                {Object.entries(getActivePosts() || {}).map(([key, post]) => (
                  <div key={key} className="bg-gray-50 rounded-md p-4 relative">
                    <p className="text-sm text-gray-800 mb-4 whitespace-pre-line">{post}</p>
                    <div className="flex justify-between items-center mt-2">
                      <span className="text-xs font-medium text-gray-500 capitalize">{key}</span>
                      <div className="flex gap-2">
                        <Button variant="ghost" size="sm" onClick={() => handleCopy(post as string, `instagram-${key}`)}>
                          {copied === `instagram-${key}` ? <Check className="h-4 w-4" /> : <Copy className="h-4 w-4" />}
                        </Button>
                        <Button 
                          variant="default" 
                          size="sm" 
                          className="bg-gradient-to-r from-[#405DE6] via-[#E1306C] to-[#FFDC80] text-white hover:opacity-90"
                          onClick={() => shareOnInstagram(post as string)}
                        >
                          <Instagram className="h-3 w-3 mr-1" />
                          <span>Préparer</span>
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
              </TabsContent>
            </Tabs>
          </CardContent>
          
          <CardFooter className="pt-0">
            <Separator className="mb-4" />
            <div className="flex justify-between w-full">
              <p className="text-xs text-gray-500">
                Ces contenus sont générés automatiquement pour attirer des investisseurs et peuvent être adaptés selon vos besoins.
              </p>
              <Button variant="outline" size="sm" className="gap-2">
                <Share2 className="h-4 w-4" /> 
                Partager la page
              </Button>
            </div>
          </CardFooter>
        </Card>
      </div>
    </div>
  );
};

export default AIInfluencer;
