
import React, { ReactNode, useEffect, useCallback } from 'react';
import Header from './Header';
import Footer from './Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDeviceType } from '@/hooks/use-mobile';

interface LayoutProps {
  children: ReactNode;
  className?: string;
  requireAuth?: boolean;
}

export const Layout = ({ children, className = "", requireAuth = false }: LayoutProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isMobile, isTablet, orientation } = useDeviceType();
  
  // Security verification
  const detectSuspiciousActivity = useCallback(() => {
    try {
      new URL(window.location.href);
    } catch (e) {
      console.error('Security alert: Invalid URL detected');
      navigate('/', { replace: true });
      return true;
    }
    return false;
  }, [navigate]);
  
  useEffect(() => {
    // Log that the layout has mounted - critical for debugging
    console.log('Layout component mounted');
    console.log('Current URL:', window.location.href);
    console.log('Location pathname:', location.pathname);
    
    // Basic security
    if (detectSuspiciousActivity()) {
      return;
    }
    
    // Scroll management
    window.scrollTo(0, 0);
    
    if (location.state && typeof location.state === 'object' && 'scrollTo' in location.state) {
      const sectionId = location.state.scrollTo as string;
      const scrollHandler = () => {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      };
      
      const timerId = setTimeout(scrollHandler, 300);
      return () => clearTimeout(timerId);
    }
    
    // URL sanitization
    const sanitizeUrl = () => {
      const url = new URL(window.location.href);
      let modified = false;
      
      const suspiciousParams = ['script', 'eval', 'javascript'];
      
      suspiciousParams.forEach(param => {
        if (url.searchParams.has(param)) {
          url.searchParams.delete(param);
          modified = true;
        }
      });
      
      if (modified) {
        navigate(url.pathname + url.search, { replace: true });
      }
    };
    
    sanitizeUrl();
    
    // Force content visibility for search engines and browsers
    document.body.style.visibility = 'visible';
    document.documentElement.style.visibility = 'visible';
    
    // Enhanced tablet/mobile specific improvements
    if (isTablet || isMobile) {
      console.log(`Device detected: ${isTablet ? 'Tablet' : 'Mobile'} - applying optimizations`);

      // Force viewport meta tag update
      document.querySelector('meta[name="viewport"]')?.setAttribute(
        'content', 
        'width=device-width, initial-scale=1.0, maximum-scale=5.0, viewport-fit=cover, user-scalable=yes'
      );
      
      // Add device classes
      document.documentElement.classList.add(isTablet ? 'tablet-device' : 'mobile-device');
      document.documentElement.classList.add(orientation);
      
      // Capture orientation changes
      const handleOrientationChange = () => {
        const isLandscape = window.innerWidth > window.innerHeight;
        document.documentElement.classList.toggle('landscape', isLandscape);
        document.documentElement.classList.toggle('portrait', !isLandscape);
        
        // Force layout recalculation
        document.body.style.display = 'none';
        setTimeout(() => {
          document.body.style.display = '';
        }, 10);
      };
      
      window.addEventListener('orientationchange', handleOrientationChange);
      window.addEventListener('resize', handleOrientationChange);
      
      // Fix iOS scroll issues - using standard CSS property instead of webkit prefix
      if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        document.body.style.overflow = 'auto';
        document.body.style['WebkitOverflowScrolling' as any] = 'touch';
      }
      
      return () => {
        document.documentElement.classList.remove('tablet-device', 'mobile-device');
        document.documentElement.classList.remove('landscape', 'portrait');
        window.removeEventListener('orientationchange', handleOrientationChange);
        window.removeEventListener('resize', handleOrientationChange);
      };
    }
    
    // SEO indexing optimization
    const reportVisibility = () => {
      if (document.visibilityState === 'visible') {
        console.log('Site visible, optimizing SEO presence');
      }
    };
    
    document.addEventListener('visibilitychange', reportVisibility);
    reportVisibility();
    
    return () => document.removeEventListener('visibilitychange', reportVisibility);
  }, [location, navigate, detectSuspiciousActivity, isTablet, isMobile, orientation]);

  // Device-specific class based on device type
  const getDeviceClass = () => {
    if (isTablet) return `tablet-layout ${orientation}`;
    if (isMobile) return 'mobile-layout';
    return 'desktop-layout';
  };
  
  const layoutClass = `min-h-screen flex flex-col ${getDeviceClass()} ${className}`;

  return (
    <div className={layoutClass}>
      <Header />
      <main className="flex-grow pt-0 overflow-x-hidden">
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
