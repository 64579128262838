
import React from 'react';
import { Layout } from '@/components/Layout';
import AIVideoScript from '@/components/AIVideoScript';
import SEOHeaders from '@/components/SEOHeaders';

const AIVideoScriptPage = () => {
  return (
    <Layout>
      <SEOHeaders 
        title="Scripts Vidéo | GuTeco - Tokenised Carbon Credits"
        description="Scripts prêts à l'emploi pour créer des vidéos promotionnelles convaincantes sur GuTeco et son potentiel d'investissement."
        canonicalPath="/ai-video-script"
        robots="index, follow"
      />
      <div className="header-spacing pt-16 md:pt-20"></div>
      <AIVideoScript />
    </Layout>
  );
};

export default AIVideoScriptPage;
