import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';
import { 
  TrendingUp, 
  BarChart4, 
  Leaf, 
  Users, 
  Lock,
  DollarSign, 
  Clock, 
  Target, 
  Globe, 
  ShieldCheck,
  CheckCircle2,
  CalendarDays,
  Rocket,
  Briefcase
} from 'lucide-react';

const InvestorPitchDeck = () => {
  const navigate = useNavigate();

  return (
    <section className="py-16 bg-slate-50">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-brand-800 mb-4">Pitch Deck pour Investisseurs</h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto mb-6">
            GuTeco présente une opportunité d'investissement exceptionnelle combinant technologies blockchain avancées 
            et impact environnemental mesurable et rentable.
          </p>
          <Button 
            className="bg-brand-600 hover:bg-brand-700 text-white"
            onClick={() => navigate('/investment-memorandum')}
          >
            Consulter le mémorandum d'investissement complet
          </Button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
          <Card className="bg-white border-t-4 border-t-brand-600 shadow-md hover:shadow-lg transition-shadow">
            <CardHeader className="pb-2">
              <div className="flex items-center gap-2">
                <div className="w-10 h-10 rounded-full bg-brand-100 flex items-center justify-center">
                  <Target className="h-5 w-5 text-brand-700" />
                </div>
                <CardTitle className="text-xl text-brand-800">Opportunité de Marché</CardTitle>
              </div>
            </CardHeader>
            <CardContent>
              <ul className="space-y-2 text-gray-700">
                <li className="flex items-start">
                  <span className="text-brand-600 font-bold mr-2">•</span>
                  <span>Marché des crédits carbone : <span className="font-semibold">2 milliards $</span> (2022) → <span className="font-semibold">25 milliards $</span> d'ici 2030</span>
                </li>
                <li className="flex items-start">
                  <span className="text-brand-600 font-bold mr-2">•</span>
                  <span>Marché DeFi : <span className="font-semibold">43 milliards $</span> de valeur totale verrouillée</span>
                </li>
                <li className="flex items-start">
                  <span className="text-brand-600 font-bold mr-2">•</span>
                  <span>Investissements ESG : <span className="font-semibold">30 000 milliards $</span> d'ici 2025</span>
                </li>
                <li className="flex items-start">
                  <span className="text-brand-600 font-bold mr-2">•</span>
                  <span>Taux de croissance annuel composé : <span className="font-semibold">30%+</span> pour les crédits carbone</span>
                </li>
              </ul>
            </CardContent>
          </Card>

          <Card className="bg-white border-t-4 border-t-purple-600 shadow-md hover:shadow-lg transition-shadow">
            <CardHeader className="pb-2">
              <div className="flex items-center gap-2">
                <div className="w-10 h-10 rounded-full bg-purple-100 flex items-center justify-center">
                  <ShieldCheck className="h-5 w-5 text-purple-700" />
                </div>
                <CardTitle className="text-xl text-purple-800">Avantage Concurrentiel</CardTitle>
              </div>
            </CardHeader>
            <CardContent>
              <ul className="space-y-2 text-gray-700">
                <li className="flex items-start">
                  <span className="text-purple-600 font-bold mr-2">•</span>
                  <span>Projets de mangroves à haut rendement : <span className="font-semibold">4-10×</span> plus efficaces que les forêts classiques</span>
                </li>
                <li className="flex items-start">
                  <span className="text-purple-600 font-bold mr-2">•</span>
                  <span>Double source de revenus : <span className="font-semibold">projets environnementaux + activités DeFi</span></span>
                </li>
                <li className="flex items-start">
                  <span className="text-purple-600 font-bold mr-2">•</span>
                  <span>Partenariats exclusifs avec <span className="font-semibold">gouvernements et ONG</span> (Madagascar et autres)</span>
                </li>
                <li className="flex items-start">
                  <span className="text-purple-600 font-bold mr-2">•</span>
                  <span>Infrastructure blockchain <span className="font-semibold">zéro-connaissance</span> pour conformité réglementaire</span>
                </li>
              </ul>
            </CardContent>
          </Card>

          <Card className="bg-white border-t-4 border-t-emerald-600 shadow-md hover:shadow-lg transition-shadow">
            <CardHeader className="pb-2">
              <div className="flex items-center gap-2">
                <div className="w-10 h-10 rounded-full bg-emerald-100 flex items-center justify-center">
                  <BarChart4 className="h-5 w-5 text-emerald-700" />
                </div>
                <CardTitle className="text-xl text-emerald-800">Retour sur Investissement</CardTitle>
              </div>
            </CardHeader>
            <CardContent>
              <ul className="space-y-2 text-gray-700">
                <li className="flex items-start">
                  <span className="text-emerald-600 font-bold mr-2">•</span>
                  <span>ROI cible sur 5 ans : <span className="font-semibold">+250%</span></span>
                </li>
                <li className="flex items-start">
                  <span className="text-emerald-600 font-bold mr-2">•</span>
                  <span>Point d'équilibre : <span className="font-semibold">Année 3</span></span>
                </li>
                <li className="flex items-start">
                  <span className="text-emerald-600 font-bold mr-2">•</span>
                  <span>Revenus DeFi disponibles dès <span className="font-semibold">l'Année 1</span> (staking, liquidité)</span>
                </li>
                <li className="flex items-start">
                  <span className="text-emerald-600 font-bold mr-2">•</span>
                  <span>Stratégie de sortie flexible : <span className="font-semibold">vente à des stratégiques ou IPO</span></span>
                </li>
              </ul>
            </CardContent>
          </Card>
        </div>

        <div className="mb-16">
          <h3 className="text-2xl font-bold text-center text-brand-800 mb-8">Projections Financières (5 ans)</h3>
          
          <div className="bg-white p-6 rounded-xl shadow-md overflow-hidden">
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Indicateur</th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Année 1</th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Année 2</th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Année 3</th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Année 4</th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Année 5</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Revenus (€)</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-800 font-mono">2,6M</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-800 font-mono">5,8M</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-800 font-mono">10,2M</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-800 font-mono">18,5M</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-800 font-mono">28,7M</td>
                  </tr>
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Dépenses (€)</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-800 font-mono">2,2M</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-800 font-mono">4,9M</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-800 font-mono">7,8M</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-800 font-mono">12,3M</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-800 font-mono">18,6M</td>
                  </tr>
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Marge opérationnelle</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-800 font-mono">15,4%</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-800 font-mono">15,5%</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-800 font-mono">23,5%</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-800 font-mono">33,5%</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-800 font-mono">35,2%</td>
                  </tr>
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Bénéfice net (€)</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-800 font-mono">0,4M</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-800 font-mono">0,9M</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-800 font-mono">2,4M</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-800 font-mono">6,2M</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-800 font-mono">10,1M</td>
                  </tr>
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Valorisation (€)</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-800 font-mono">93M</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-800 font-mono">105M</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-800 font-mono">135M</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-800 font-mono">198M</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-800 font-mono">260M</td>
                  </tr>
                  <tr className="bg-emerald-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-emerald-800">ROI pour investisseurs</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-emerald-800 font-mono">+2,9%</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-emerald-800 font-mono">+11,8%</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-emerald-800 font-mono">+43,5%</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-emerald-800 font-mono">+109,8%</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-emerald-800 font-mono">+254,2%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
          <Card className="bg-white shadow-md overflow-hidden">
            <CardHeader className="bg-blue-50">
              <CardTitle className="text-xl text-blue-800 flex items-center gap-2">
                <Leaf className="h-5 w-5" />
                Stratégie de Croissance
              </CardTitle>
            </CardHeader>
            <CardContent className="p-6">
              <ul className="space-y-4">
                <li className="flex items-start">
                  <div className="w-8 h-8 rounded-full bg-green-100 flex-shrink-0 flex items-center justify-center mr-3">
                    <CheckCircle2 className="h-4 w-4 text-green-700" />
                  </div>
                  <div>
                    <h4 className="font-semibold text-gray-800 mb-1">Phase 1: Fondation (2025-2026)</h4>
                    <p className="text-gray-600 text-sm">Restauration de 10,000 hectares de mangroves à Madagascar avec des partenariats gouvernementaux déjà sécurisés. Développement initial de la plateforme et mise en place de l'équipe fondatrice.</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <div className="w-8 h-8 rounded-full bg-amber-100 flex-shrink-0 flex items-center justify-center mr-3">
                    <Clock className="h-4 w-4 text-amber-700" />
                  </div>
                  <div>
                    <h4 className="font-semibold text-gray-800 mb-1">Phase 2: Développement (2025-2026)</h4>
                    <p className="text-gray-600 text-sm">Lancement du token GTC et VCarbs en version test. Expansion régionale vers l'Indonésie et les Philippines avec 20,000 hectares supplémentaires de mangroves et forêts tropicales. Implémentation de la structure DAO.</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <div className="w-8 h-8 rounded-full bg-blue-100 flex-shrink-0 flex items-center justify-center mr-3">
                    <CalendarDays className="h-4 w-4 text-blue-700" />
                  </div>
                  <div>
                    <h4 className="font-semibold text-gray-800 mb-1">Phase 3: Croissance (2026+)</h4>
                    <p className="text-gray-600 text-sm">Expansion internationale à 5 nouveaux pays incluant l'Amérique Latine (d'autres pays). Lancement officiel du marché de crédits carbone et développement de crédits biodiversité.</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <div className="w-8 h-8 rounded-full bg-purple-100 flex-shrink-0 flex items-center justify-center mr-3">
                    <Rocket className="h-4 w-4 text-purple-700" />
                  </div>
                  <div>
                    <h4 className="font-semibold text-gray-800 mb-1">Vision à long terme</h4>
                    <p className="text-gray-600 text-sm">Atteinte de 100,000 hectares sous gestion avec capture de plus de 1 million de tonnes de CO2 annuellement. Devenir la référence mondiale en finance d'impact décentralisée et créer un écosystème complet de services financiers durables.</p>
                  </div>
                </li>
              </ul>
            </CardContent>
          </Card>

          <Card className="bg-white shadow-md overflow-hidden">
            <CardHeader className="bg-purple-50">
              <CardTitle className="text-xl text-purple-800 flex items-center gap-2">
                <Lock className="h-5 w-5" />
                Écosystème DeFi et Tokenomics
              </CardTitle>
            </CardHeader>
            <CardContent className="p-6">
              <ul className="space-y-4">
                <li className="flex items-start">
                  <div className="w-8 h-8 rounded-full bg-purple-100 flex-shrink-0 flex items-center justify-center mr-3">
                    <span className="text-purple-700 font-semibold">1</span>
                  </div>
                  <div>
                    <h4 className="font-semibold text-gray-800 mb-1">Token GTC et VCarbs</h4>
                    <p className="text-gray-600 text-sm">Double tokenomics avec GTC (gouvernance) et VCarbs (crédit carbone tokenisé) pour équilibrer spéculation et utilité réelle.</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <div className="w-8 h-8 rounded-full bg-purple-100 flex-shrink-0 flex items-center justify-center mr-3">
                    <span className="text-purple-700 font-semibold">2</span>
                  </div>
                  <div>
                    <h4 className="font-semibold text-gray-800 mb-1">Mécanismes de revenus DeFi</h4>
                    <p className="text-gray-600 text-sm">Staking (5-15% APY), fourniture de liquidité (10-20% APY), et frais d'échange générant des revenus dès l'année 1.</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <div className="w-8 h-8 rounded-full bg-purple-100 flex-shrink-0 flex items-center justify-center mr-3">
                    <span className="text-purple-700 font-semibold">3</span>
                  </div>
                  <div>
                    <h4 className="font-semibold text-gray-800 mb-1">Gouvernance DAO</h4>
                    <p className="text-gray-600 text-sm">Prise de décision collective sur l'allocation des fonds et la sélection des projets, avec droits de vote proportionnels aux GTC détenus.</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <div className="w-8 h-8 rounded-full bg-purple-100 flex-shrink-0 flex items-center justify-center mr-3">
                    <span className="text-purple-700 font-semibold">4</span>
                  </div>
                  <div>
                    <h4 className="font-semibold text-gray-800 mb-1">Mécanismes déflationnistes</h4>
                    <p className="text-gray-600 text-sm">Rachat et destruction automatiques de tokens GTC (2% des frais) pour soutenir la valeur à long terme et récompenser les détenteurs.</p>
                  </div>
                </li>
              </ul>
            </CardContent>
          </Card>
        </div>

        <div className="mb-16">
          <Card className="bg-white shadow-md overflow-hidden">
            <CardHeader className="bg-amber-50">
              <CardTitle className="text-xl text-amber-800 flex items-center gap-2">
                <Briefcase className="h-5 w-5" />
                Modèle d'affaire
              </CardTitle>
            </CardHeader>
            <CardContent className="p-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                  <h3 className="font-semibold text-lg mb-4 text-amber-700">Sources de revenus</h3>
                  <ul className="space-y-4">
                    <li className="flex items-start">
                      <div className="w-8 h-8 rounded-full bg-green-100 flex-shrink-0 flex items-center justify-center mr-3">
                        <Leaf className="h-4 w-4 text-green-700" />
                      </div>
                      <div>
                        <h4 className="font-semibold text-gray-800 mb-1">Crédits carbone</h4>
                        <p className="text-gray-600 text-sm">Vente de crédits carbone certifiés issus de nos projets de mangroves (jusqu'à 10× plus efficaces que les forêts traditionnelles). Objectif: 500 000 crédits/an d'ici 5 ans.</p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <div className="w-8 h-8 rounded-full bg-blue-100 flex-shrink-0 flex items-center justify-center mr-3">
                        <Lock className="h-4 w-4 text-blue-700" />
                      </div>
                      <div>
                        <h4 className="font-semibold text-gray-800 mb-1">Revenus DeFi</h4>
                        <p className="text-gray-600 text-sm">Frais de transaction (0,3%), staking (5-15% APY), fourniture de liquidité (10-20% APY) et autres services financiers décentralisés.</p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <div className="w-8 h-8 rounded-full bg-purple-100 flex-shrink-0 flex items-center justify-center mr-3">
                        <Globe className="h-4 w-4 text-purple-700" />
                      </div>
                      <div>
                        <h4 className="font-semibold text-gray-800 mb-1">Services écosystémiques</h4>
                        <p className="text-gray-600 text-sm">Monétisation des crédits biodiversité, écotourisme durable et services de conseil pour entreprises et gouvernements.</p>
                      </div>
                    </li>
                  </ul>
                </div>
                
                <div>
                  <h3 className="font-semibold text-lg mb-4 text-amber-700">Structure des coûts</h3>
                  <ul className="space-y-4">
                    <li className="flex items-start">
                      <div className="w-8 h-8 rounded-full bg-amber-100 flex-shrink-0 flex items-center justify-center mr-3">
                        <Clock className="h-4 w-4 text-amber-700" />
                      </div>
                      <div>
                        <h4 className="font-semibold text-gray-800 mb-1">Restauration & maintenance</h4>
                        <p className="text-gray-600 text-sm">5M€ pour la restauration initiale des 10 000 hectares de mangroves et 1,5M€/an pour la maintenance et la surveillance.</p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <div className="w-8 h-8 rounded-full bg-indigo-100 flex-shrink-0 flex items-center justify-center mr-3">
                        <Target className="h-4 w-4 text-indigo-700" />
                      </div>
                      <div>
                        <h4 className="font-semibold text-gray-800 mb-1">Technologie & développement</h4>
                        <p className="text-gray-600 text-sm">2M€ pour le développement de la plateforme DeFi et 2M€ pour les drones de surveillance. Coûts opérationnels technologiques de 1M€/an.</p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <div className="w-8 h-8 rounded-full bg-rose-100 flex-shrink-0 flex items-center justify-center mr-3">
                        <Users className="h-4 w-4 text-rose-700" />
                      </div>
                      <div>
                        <h4 className="font-semibold text-gray-800 mb-1">Ressources humaines</h4>
                        <p className="text-gray-600 text-sm">Équipe actuelle de 3 personnes, combinant expertise en blockchain, finance, développement durable et botanique.</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              
              <div className="mt-8 bg-amber-50 p-4 rounded-lg border border-amber-100">
                <h3 className="font-semibold text-lg mb-2 text-amber-800">Avantages économiques clés</h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div className="bg-white p-3 rounded-lg border border-amber-200">
                    <h4 className="font-medium text-amber-700 mb-1 flex items-center">
                      <TrendingUp className="h-4 w-4 mr-1" /> Rendement élevé
                    </h4>
                    <p className="text-sm text-gray-600">ROI de 250%+ sur 5 ans grâce à la double source de revenus (environnementaux + DeFi)</p>
                  </div>
                  <div className="bg-white p-3 rounded-lg border border-amber-200">
                    <h4 className="font-medium text-amber-700 mb-1 flex items-center">
                      <ShieldCheck className="h-4 w-4 mr-1" /> Résilience modèle
                    </h4>
                    <p className="text-sm text-gray-600">Diversification des sources de revenus protégeant contre les fluctuations des marchés carbone ou crypto</p>
                  </div>
                  <div className="bg-white p-3 rounded-lg border border-amber-200">
                    <h4 className="font-medium text-amber-700 mb-1 flex items-center">
                      <Rocket className="h-4 w-4 mr-1" /> Scalabilité
                    </h4>
                    <p className="text-sm text-gray-600">Modèle facilement déployable dans d'autres régions tropicales dotées de mangroves/forêts à restaurer</p>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
          <Card className="bg-white shadow-md">
            <CardHeader className="bg-amber-50 pb-3">
              <div className="flex items-center gap-2">
                <Clock className="h-5 w-5 text-amber-700" />
                <CardTitle className="text-lg text-amber-800">Chronologie d'Investissement</CardTitle>
              </div>
            </CardHeader>
            <CardContent className="p-5">
              <ul className="space-y-3 text-sm">
                <li className="flex gap-2">
                  <span className="text-amber-600 font-bold">•</span>
                  <span><strong>Mai 2024:</strong> Clôture du tour de financement</span>
                </li>
                <li className="flex gap-2">
                  <span className="text-amber-600 font-bold">•</span>
                  <span><strong>Juin 2024:</strong> Lancement de la plateforme DeFi</span>
                </li>
                <li className="flex gap-2">
                  <span className="text-amber-600 font-bold">•</span>
                  <span><strong>Juil. 2024:</strong> Début des projets de restauration</span>
                </li>
                <li className="flex gap-2">
                  <span className="text-amber-600 font-bold">•</span>
                  <span><strong>T4 2024:</strong> Premier rapport de vérification</span>
                </li>
                <li className="flex gap-2">
                  <span className="text-amber-600 font-bold">•</span>
                  <span><strong>T2 2025:</strong> Premiers crédits carbone certifiés</span>
                </li>
              </ul>
            </CardContent>
          </Card>

          <Card className="bg-white shadow-md">
            <CardHeader className="bg-green-50 pb-3">
              <div className="flex items-center gap-2">
                <Users className="h-5 w-5 text-green-700" />
                <CardTitle className="text-lg text-green-800">Équipe et Conseillers</CardTitle>
              </div>
            </CardHeader>
            <CardContent className="p-5">
              <ul className="space-y-3 text-sm">
                <li className="flex gap-2">
                  <span className="text-green-600 font-bold">•</span>
                  <span><strong>CEO:</strong> 15+ ans en développement durable</span>
                </li>
                <li className="flex gap-2">
                  <span className="text-green-600 font-bold">•</span>
                  <span><strong>CTO:</strong> spécialiste blockchain</span>
                </li>
                <li className="flex gap-2">
                  <span className="text-green-600 font-bold">•</span>
                  <span><strong>CFO:</strong> 10+ ans en finance carbone</span>
                </li>
                <li className="flex gap-2">
                  <span className="text-green-600 font-bold">•</span>
                  <span><strong>Conseiller:</strong> Ex-ministre de l'Environnement</span>
                </li>
                <li className="flex gap-2">
                  <span className="text-green-600 font-bold">•</span>
                  <span><strong>Scientifique:</strong> Doctorat en droit écologie</span>
                </li>
              </ul>
            </CardContent>
          </Card>

          <Card className="bg-white shadow-md">
            <CardHeader className="bg-indigo-50 pb-3">
              <div className="flex items-center gap-2">
                <Globe className="h-5 w-5 text-indigo-700" />
                <CardTitle className="text-lg text-indigo-800">Partenaires Stratégiques</CardTitle>
              </div>
            </CardHeader>
            <CardContent className="p-5">
              <ul className="space-y-3 text-sm">
                <li className="flex gap-2">
                  <span className="text-indigo-600 font-bold">•</span>
                  <span><strong>Gouvernement malgache</strong> (accord officiel)</span>
                </li>
                <li className="flex gap-2">
                  <span className="text-indigo-600 font-bold">•</span>
                  <span><strong>Gold Standard</strong> (certification carbone)</span>
                </li>
                <li className="flex gap-2">
                  <span className="text-indigo-600 font-bold">•</span>
                  <span><strong>Organisation des Nations Unies</strong> (PNUD)</span>
                </li>
                <li className="flex gap-2">
                  <span className="text-indigo-600 font-bold">•</span>
                  <span><strong>Exchanges crypto</strong> majeurs (en discussion)</span>
                </li>
                <li className="flex gap-2">
                  <span className="text-indigo-600 font-bold">•</span>
                  <span><strong>Universités</strong> pour R&D et vérification</span>
                </li>
              </ul>
            </CardContent>
          </Card>
        </div>

        <div className="text-center mt-12">
          <h3 className="text-2xl font-bold text-brand-800 mb-6">Offre d'Investissement</h3>
          <div className="bg-brand-50 p-8 rounded-xl inline-block shadow-md">
            <div className="flex flex-col md:flex-row items-center justify-center gap-12">
              <div className="text-center">
                <DollarSign className="h-12 w-12 text-brand-600 mx-auto mb-2" />
                <h4 className="text-lg font-semibold text-gray-800 mb-1">Montant Recherché</h4>
                <p className="text-3xl font-bold text-brand-700">12 000 000 €</p>
              </div>
              <div className="h-16 w-px bg-brand-200 hidden md:block"></div>
              <div className="text-center">
                <TrendingUp className="h-12 w-12 text-brand-600 mx-auto mb-2" />
                <h4 className="text-lg font-semibold text-gray-800 mb-1">Valorisation</h4>
                <p className="text-3xl font-bold text-brand-700">102 000 000 €</p>
                <p className="text-sm text-gray-600">(post-money)</p>
              </div>
              <div className="h-16 w-px bg-brand-200 hidden md:block"></div>
              <div className="text-center">
                <Lock className="h-12 w-12 text-brand-600 mx-auto mb-2" />
                <h4 className="text-lg font-semibold text-gray-800 mb-1">Équité Offerte</h4>
                <p className="text-3xl font-bold text-brand-700">11,76%</p>
              </div>
            </div>
            <div className="mt-8">
              <Button 
                className="bg-brand-600 hover:bg-brand-700 text-white text-lg px-8 py-4"
                onClick={() => navigate('/investment-memorandum')}
              >
                Consulter le mémorandum d'investissement détaillé
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InvestorPitchDeck;
