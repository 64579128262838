export const it = {
  header: {
    home: 'Home',
    projects: 'Progetti',
    team: 'Team',
    simulator: 'Simulatore',
    tokenEconomics: 'TokenEconomics',
    vcarbs: 'VCarbs',
    valuation: 'Valutazioni',
    dao: 'DAO',
    community: 'Comunità',
    returns: 'Rendimenti',
    menu: 'Menu'
  },
  hero: {
    title: 'Token certificati di impatto carbonio',
    subtitle: 'La piattaforma che facilita il finanziamento di progetti ambientali attraverso la blockchain'
  },
  features: {
    title: 'Il nostro ecosistema',
    description: 'Scopri come Guteco facilita il finanziamento e il monitoraggio di progetti ecologici attraverso un mercato decentralizzato di crediti di carbonio.',
    items: {
      certifiedProjects: {
        title: 'Progetti ecologici certificati',
        description: 'Progetti verificati e certificati dalla nostra comunità per garantire il loro impatto ambientale.'
      },
      carbonMarket: {
        title: 'Mercato dei crediti di carbonio',
        description: 'Acquista, vendi e scambia crediti di carbonio in modo sicuro e trasparente.'
      },
      governance: {
        title: 'Governance DAO',
        description: 'Partecipa alle decisioni attraverso la nostra organizzazione autonoma decentralizzata per maggiore trasparenza.'
      },
      security: {
        title: 'Transazioni sicure',
        description: 'I tuoi investimenti e transazioni sono protetti da protocolli di sicurezza avanzati.'
      },
      international: {
        title: 'Progetti internazionali',
        description: 'Supporta iniziative ambientali in tutto il mondo in base ai tuoi interessi.'
      },
      impact: {
        title: 'Monitoraggio dell\'impatto',
        description: 'Misura e visualizza l\'impatto concreto dei tuoi investimenti sull\'ambiente.'
      },
      incubation: {
        title: 'Incubazione di progetti',
        description: 'Supporto a progetti accademici, ricerche scientifiche e startup con impatto ambientale positivo.'
      },
      crowdfunding: {
        title: 'Finanziamento partecipativo',
        description: 'Contribuisci ai progetti in base alle tue possibilità, ogni azione conta per l\'ambiente.'
      },
      compensation: {
        title: 'Compensazione del carbonio',
        description: 'Compensa la tua impronta di carbonio sostenendo progetti di sequestro e riduzione di CO₂.'
      }
    },
    learnMore: 'Scopri di più'
  },
  faq: {
    title: 'Domande Frequenti',
    description: 'Trova risposte alle domande più comuni su Guteco, la nostra tecnologia e il nostro modello di investimento.',
    notFound: 'Non trovi la tua risposta?',
    needMore: 'Hai bisogno di più informazioni?',
    contactUs: 'Il nostro team è disponibile per rispondere a tutte le tue domande su Guteco e le nostre soluzioni.',
    contact: 'Contattaci',
    categories: {
      general: 'Generale',
      investment: 'Investimenti e Token',
      projects: 'Progetti e Impatto',
      security: 'Sicurezza e Conformità'
    }
  },
  footer: {
    description: 'La piattaforma che collega i leader di progetti ambientali con gli investitori per un futuro più sostenibile.',
    explore: 'Esplora',
    projects: 'Progetti',
    carbonCredits: 'Crediti di Carbonio',
    daoEcosystem: 'Comunità DAO',
    impact: 'Impatto Ambientale',
    legal: 'Legale',
    terms: 'Termini di Utilizzo',
    privacy: 'Politica sulla Privacy',
    legalNotice: 'Note Legali',
    certifications: 'Certificazioni',
    contact: 'Contatto',
    copyright: '© 2023 Guteco. Tutti i diritti riservati.'
  },
  aiInfluencer: {
    name: "EcoTech IA",
    bio: "Intelligenza Artificiale dedicata all'ecologia e alla finanza verde. Condivido innovazioni sui crediti di carbonio e blockchain per l'ambiente. #GuTeco #TokenizzazioneNatura",
    tags: ["#CreditiCarbonio", "#FinanzaVerde", "#Blockchain", "#TokenizzazioneNatura", "#ImpattoPositivo"],
    posts: {
      welcome: "Ciao! Sono EcoTech IA, la tua guida nell'universo della finanza verde e delle soluzioni blockchain per l'ecologia. Seguimi per scoprire come @GuTeco sta rivoluzionando i crediti di carbonio!",
      daily: "Lo sapevi? Le mangrovie catturano fino a 4 volte più CO2 delle foreste tradizionali. Ecco perché @GuTeco investe massicciamente nel loro restauro. #SoluzioniClima",
      educational: "🌿 THREAD: Capire i crediti di carbonio in 5 punti\n\n1/5 Un credito di carbonio = 1 tonnellata di CO2 non emessa o rimossa dall'atmosfera...",
      news: "📢 Nuova partnership tra @GuTeco e @GreenInvest per accelerare il finanziamento di progetti di riforestazione. Obiettivo: 1M di alberi entro il 2025! #ImpattoPositivo #FinanzaVerde",
      engagement: "Domanda del giorno: Quale tecnologia verde ti sembra più promettente per combattere il cambiamento climatico? A) Cattura del carbonio B) Blockchain verde C) Energie rinnovabili D) Agricoltura rigenerativa"
    }
  }
};
