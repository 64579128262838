
import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Layout } from '@/components/Layout';
import CarbonNFT from '@/components/CarbonNFT';
import { Badge } from '@/components/ui/badge';
import { Check, Shield } from 'lucide-react';

const VerifyNFT = () => {
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const credits = searchParams.get('credits') ? parseInt(searchParams.get('credits')!) : 0;
  const projectName = searchParams.get('project') || "Projet Carbone";
  
  const nftId = id || "unknown-id";
  
  return (
    <Layout>
      <div className="container mx-auto px-4 py-16">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-10">
            <h1 className="text-3xl font-bold text-brand-800 mb-4">Vérification de NFT Carbone</h1>
            <p className="text-gray-600">
              Confirmez l'authenticité et les détails d'un certificat de crédit carbone tokenisé.
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            <div>
              <div className="bg-white p-6 rounded-xl shadow-md">
                <div className="flex items-center justify-center mb-6">
                  <Badge className="bg-green-100 text-green-800 px-3 py-1 text-sm flex items-center gap-1.5">
                    <Check className="h-4 w-4" />
                    Authentique & Vérifié
                  </Badge>
                </div>
                
                <CarbonNFT 
                  credits={credits} 
                  id={nftId} 
                  projectName={projectName}
                />
              </div>
            </div>
            
            <div className="space-y-6">
              <div className="bg-white p-6 rounded-xl shadow-md">
                <h2 className="text-xl font-semibold text-brand-700 mb-4 flex items-center gap-2">
                  <Shield className="h-5 w-5 text-brand-500" />
                  Certificat d'Authenticité
                </h2>
                
                <div className="space-y-4">
                  <div>
                    <h3 className="text-sm font-medium text-gray-500">ID du Certificat</h3>
                    <p className="font-mono text-gray-800">{nftId}</p>
                  </div>
                  
                  <div>
                    <h3 className="text-sm font-medium text-gray-500">Émis Par</h3>
                    <p className="text-gray-800">Guteco Certification Authority</p>
                  </div>
                  
                  <div>
                    <h3 className="text-sm font-medium text-gray-500">Date d'Émission</h3>
                    <p className="text-gray-800">{new Date().toLocaleDateString()}</p>
                  </div>
                  
                  <div>
                    <h3 className="text-sm font-medium text-gray-500">Standard de Vérification</h3>
                    <p className="text-gray-800">ISO 14064 / GHG Protocol</p>
                  </div>
                </div>
              </div>
              
              <div className="bg-white p-6 rounded-xl shadow-md">
                <h2 className="text-xl font-semibold text-brand-700 mb-4">Détails du Crédit Carbone</h2>
                
                <div className="space-y-4">
                  <div>
                    <h3 className="text-sm font-medium text-gray-500">Quantité de Crédits</h3>
                    <p className="text-gray-800">{credits} tonnes de CO2e</p>
                  </div>
                  
                  <div>
                    <h3 className="text-sm font-medium text-gray-500">Type de Projet</h3>
                    <p className="text-gray-800">Reforestation / Énergies Renouvelables</p>
                  </div>
                  
                  <div>
                    <h3 className="text-sm font-medium text-gray-500">Localisation</h3>
                    <p className="text-gray-800">Afrique Centrale / Amérique Latine</p>
                  </div>
                  
                  <div>
                    <h3 className="text-sm font-medium text-gray-500">Impact Social</h3>
                    <p className="text-gray-800">Soutient 15 emplois locaux et 3 communautés</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default VerifyNFT;
