
import { TokenReturn } from './TokenReturnsTable';
import { ProjectReturn } from './ProjectReturnsGrid';

export const tokenReturnsData: TokenReturn[] = [
  {
    type: "Staking GTC",
    estimatedYield: "10-12%",
    riskLevel: "Modéré",
    lockupPeriod: "3-12 mois",
    notes: "Rendement variable selon la participation à la DAO et l'évolution des projets. Le staking génère environ 840k€ de revenus sur 2 ans."
  },
  {
    type: "Liquidité GTC/VCarbs",
    estimatedYield: "5-8%",
    riskLevel: "Faible à modéré",
    lockupPeriod: "1-6 mois",
    notes: "Commissions sur les transactions + récompenses du protocole. La liquidité désigne les euros/dollars/etc. disponibles pour faciliter les échanges."
  },
  {
    type: "Détention à long terme GTC",
    estimatedYield: "Potentiel +3-5% sur 2 ans",
    riskLevel: "Élevé",
    lockupPeriod: "24+ mois",
    notes: "Basé sur l'adoption progressive et la valorisation croissante de GuTeco vers 105M€."
  },
  {
    type: "VCarbs (stablecoin)",
    estimatedYield: "1-3%",
    riskLevel: "Très faible",
    lockupPeriod: "Aucun",
    notes: "Stabilité garantie par l'indexation sur les crédits carbone"
  },
  {
    type: "Farming de liquidité",
    estimatedYield: "8-10%",
    riskLevel: "Modéré à élevé",
    lockupPeriod: "1-12 mois",
    notes: "Fourniture de liquidité (euros, dollars) dans les pools d'échange (AMM), rendement combiné des frais et des récompenses"
  },
  {
    type: "Yield farming",
    estimatedYield: "10-15%",
    riskLevel: "Élevé",
    lockupPeriod: "Variable",
    notes: "Culture de rendement: stratégie consistant à maximiser les gains en déplaçant des actifs entre différents protocoles DeFi"
  },
  {
    type: "Gouvernance active (votes)",
    estimatedYield: "Bonus 1-2%",
    riskLevel: "Faible",
    lockupPeriod: "Aucun",
    notes: "Récompenses supplémentaires pour la participation active à la gouvernance de la DAO"
  },
  {
    type: "NFTs de crédits carbone",
    estimatedYield: "ROI 3-5% sur 2 ans",
    riskLevel: "Modéré",
    lockupPeriod: "12-24 mois",
    notes: "Basé sur l'augmentation de la valeur des crédits carbone tokenisés et les revenus générés par la marketplace"
  }
];

export const projectReturnsData: ProjectReturn[] = [
  {
    category: "Mangroves (Madagascar)",
    financialReturn: "Modéré (valorisé à 2.8M€)",
    carbonCredits: "Très élevé (4-10x plus efficace)",
    timeToMaturity: "9-12 mois",
    riskLevel: 50
  },
  {
    category: "Projets forestiers (Madagascar)",
    financialReturn: "3-5%",
    carbonCredits: "Moyen à Élevé",
    timeToMaturity: "12-36 mois",
    riskLevel: 65
  },
  {
    category: "Crédits biodiversité",
    financialReturn: "2-4%",
    carbonCredits: "Moyen",
    timeToMaturity: "18-30 mois",
    riskLevel: 55
  },
  {
    category: "Énergie renouvelable",
    financialReturn: "3-6%",
    carbonCredits: "Moyen à élevé",
    timeToMaturity: "24-36 mois",
    riskLevel: 60
  },
  {
    category: "Agriculture régénérative",
    financialReturn: "2-4%",
    carbonCredits: "Moyen",
    timeToMaturity: "12-24 mois",
    riskLevel: 50
  },
  {
    category: "Conservation marine",
    financialReturn: "2-3%",
    carbonCredits: "Élevé",
    timeToMaturity: "24-36 mois",
    riskLevel: 60
  }
];

export const monthlyCostsData = [
  { category: "Personnel technique", monthlyCost: 23000 },
  { category: "Personnel opérationnel (terrain)", monthlyCost: 18000 },
  { category: "Location bureaux et matériel", monthlyCost: 5000 },
  { category: "Déplacements et logistique", monthlyCost: 8000 },
  { category: "Développement technologique", monthlyCost: 12000 },
  { category: "Marketing et acquisition", monthlyCost: 7000 },
  { category: "Frais administratifs", monthlyCost: 3500 },
  { category: "Imprévus", monthlyCost: 4500 }
];

export const revenueSourcesData = [
  { source: "Vente de crédits carbone", percentage: 50, timeToFirstRevenue: 12 },
  { source: "Vente de crédits biodiversité", percentage: 25, timeToFirstRevenue: 18 },
  { source: "Frais de staking GTC", percentage: 10, timeToFirstRevenue: 6 },
  { source: "Marketplace (frais NFT)", percentage: 8, timeToFirstRevenue: 3 },
  { source: "Services conseil", percentage: 5, timeToFirstRevenue: 3 },
  { source: "Écotourisme", percentage: 2, timeToFirstRevenue: 9 }
];

export const defiActivitiesData = [
  { 
    activity: "Staking simple GTC", 
    annualReturn: "5-6%",
    minimumAmount: "100 GTC",
    lockPeriod: "30 jours minimum",
    rewardType: "VCarbs (distribués mensuellement)"
  },
  { 
    activity: "Staking à long terme GTC", 
    annualReturn: "6-9%",
    minimumAmount: "500 GTC",
    lockPeriod: "6-12 mois",
    rewardType: "VCarbs + bonus GTC"
  },
  { 
    activity: "Fourniture de liquidité GTC/VCarbs", 
    annualReturn: "5-8%",
    minimumAmount: "Équivalent 200€",
    lockPeriod: "Variable",
    rewardType: "Frais de transaction + tokens de récompense"
  },
  { 
    activity: "Farming stratégique", 
    annualReturn: "8-12%",
    minimumAmount: "Équivalent 500€",
    lockPeriod: "3-12 mois selon la stratégie",
    rewardType: "Mixte (VCarbs, GTC, tokens partenaires)"
  },
  { 
    activity: "Participation active DAO", 
    annualReturn: "+1-2% (bonus)",
    minimumAmount: "50 GTC stakés",
    lockPeriod: "Aucun",
    rewardType: "Bonus sur les récompenses de staking existantes"
  },
  { 
    activity: "NFT Crédits Carbone", 
    annualReturn: "ROI 3-5% sur 2 ans",
    minimumAmount: "Équivalent 1000€",
    lockPeriod: "12-24 mois",
    rewardType: "Appréciation valeur + dividendes"
  },
  { 
    activity: "Projet Mangroves (10 000 ha)", 
    annualReturn: "15€/hectare/an",
    minimumAmount: "Équivalent 5000€",
    lockPeriod: "24-36 mois",
    rewardType: "VCarbs + crédits carbone tokenisés"
  },
  { 
    activity: "Partenariats certifications", 
    annualReturn: "Rendement progressif 3-5%",
    minimumAmount: "Équivalent 2000€",
    lockPeriod: "24+ mois",
    rewardType: "VCarbs + avantages fiscaux potentiels"
  }
];
